import { Box, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import clsx from "clsx";
import {
  HotelCarousel,
  PageTabNavigations,
  PremierCollectionShopChooseRoomSkeleton,
  PremierCollectionShopDetailsSkeleton,
  PremiumStaysInfoAccordian,
} from "halifax";
import { DesktopRewardsHeader } from "../../../rewards/components";
import { RouteComponentProps } from "react-router";
import { DesktopShopDetailsConnectorProps } from "./container";
import "./styles.scss";
import {
  AmenityEnum,
} from "redmond";
import { useInView } from "react-intersection-observer";
import { DesktopHotelPhotoModal } from "../../../shop/components/DesktopHotelPhotoModal";
import { DesktopEarnBanner } from "../../../shop/components/DesktopEarnBanner";
import * as textConstants from "../../textConstants";
import { ShopHotelDescription } from "../../../shop/components/ShopHotelDescription";
import { TripAdvisorReviews } from "../../../shop/components/TripAdvisorReviews";
import { PopularAmenities } from "../../../shop/components/PopularAmenities";
import { RoomInfoPicker } from "../RoomInfoPicker";
import { ShopDetailsAvailabilityBanner } from "../ShopDetailsAvailabilityBanner";
import { DesktopPremierCollectionShopChooseDates } from "../DesktopShopDetailsChooseDates";
import {PATH_HOME, PATH_SHOP} from "../../../../utils/paths";
import { parseShopDetailsQueryString, transformToShopDetailsStringifiedQuery } from "../../utils/queryStringHelpers";

export interface IDesktopShopDetailsProps
  extends DesktopShopDetailsConnectorProps,
    RouteComponentProps {
  handleReadyToRedirect?: () => void;
  variant?: "default" | "lifestyle-collection";
}

export const DesktopShopDetails = ({
  lodgingData,
  hotelShopDetailsCallInProgress,
  hasFailed,
  shopCallFromShopDetails,
  handleReadyToRedirect,
  variant = "default",
  history,
}: IDesktopShopDetailsProps) => {
  const [clickedSectionId, setClickedSectionId] = React.useState<string>();
  const [galleryRef, galleryInView] = useInView({ threshold: 0.8 });
  const [aboutRef, aboutInView] = useInView({ threshold: 0.8 });
  const [, amenitiesInView] = useInView({ threshold: 0.8 });
  const [, reviewsInView] = useInView({ threshold: 0.8 });
  const [chooseRoomRef, chooseRoomInView] = useInView({ threshold: 0.3 });

  const selectedTab = React.useMemo(() => {
    if (
      galleryInView &&
      (clickedSectionId ? clickedSectionId === "#gallery-section" : true)
    ) {
      return 0;
    }
    if (
      aboutInView &&
      (clickedSectionId ? clickedSectionId === "#about-section" : true)
    ) {
      return 1;
    }
    if (
      amenitiesInView &&
      (clickedSectionId ? clickedSectionId === "#amenities-section" : true)
    ) {
      return 2;
    }
    if (
      reviewsInView &&
      (clickedSectionId ? clickedSectionId === "#reviews-section" : true)
    ) {
      return 3;
    }
    if (
      chooseRoomInView &&
      (clickedSectionId ? clickedSectionId === "#choose-room-section" : true)
    ) {
      return lodgingData &&
        lodgingData?.tripAdvisorReviews &&
        lodgingData.tripAdvisorReviews?.comments?.length > 0
        ? 4
        : 3;
    }

    return undefined;
  }, [
    galleryInView,
    aboutInView,
    amenitiesInView,
    reviewsInView,
    chooseRoomInView,
  ]);
  const scrollToSection = (sectionId: string) => {
    const BANNER_HEIGHT =
      document
        .getElementById("b2bportal-banner-container")
        ?.getBoundingClientRect().height ?? 0;
    const HEADER_HEIGHT =
      document.querySelector(".app-header")?.getBoundingClientRect().height ??
      0;
    const MARGIN = 20;
    const yOffset =
      window.pageYOffset - (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN);
    const element =
      sectionId === "choose-room-section"
        ? document.getElementsByClassName("cta-select-dates-text")?.[0]
        : document.getElementById(sectionId);

    const y = (element?.getBoundingClientRect().top ?? 0) + yOffset;
    setClickedSectionId(sectionId);
    setTimeout(() => {
      setClickedSectionId(undefined);
    }, 500);
    if (sectionId === "choose-room-section" && element) {
      element?.scrollIntoView({ behavior: "smooth", block: "end" });
    } else {
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const tabs = [
    {
      label: "Gallery",
      onClick: () => scrollToSection("gallery-section"),
    },
    { label: "About", onClick: () => scrollToSection("about-section") },
    {
      label: "Amenities",
      onClick: () => scrollToSection("amenities-section"),
    },
    ...(lodgingData &&
    lodgingData?.tripAdvisorReviews &&
    lodgingData.tripAdvisorReviews?.comments?.length > 0
      ? [
          {
            label: "Reviews",
            onClick: () => scrollToSection("reviews-section"),
          },
        ]
      : []),
    {
      label: "Choose Room",
      onClick: () => scrollToSection("choose-room-section"),
    },
  ];

  const handleShopCall = (queryParams: string) => {
    const paramsForFailure = transformToShopDetailsStringifiedQuery({
      lodgingId: lodgingData?.id || "",
      unavailable: true,
    });
    shopCallFromShopDetails(true, `${history.location.pathname}${paramsForFailure}`);
    history.push(`${PATH_SHOP}${queryParams}`, {
      fromPage: history.location.pathname,
    });
  }

  const isUnavailable = parseShopDetailsQueryString(history.location.search).unavailable;

  useEffect(() => {
    if (lodgingData && !!lodgingData.amenities.length) {
      const paidBreakfastIndex = lodgingData?.amenities.findIndex(
        (amenity) => amenity.amenity === AmenityEnum.PaidBreakfast
      );

      if (paidBreakfastIndex && paidBreakfastIndex > -1) {
        lodgingData.amenities.splice(paidBreakfastIndex, 1);
      }
    }
  }, [lodgingData]);

  const renderSkeleton = () => (
    <Box className="desktop-pc-shop-skeleton">
      <div id="gallery-section">
        <HotelCarousel isSkeleton />
      </div>
      <div
        className={"section-wrapper description-and-date-picker-section"}
        id="about-section"
      >
        <Box className="description-wrapper">
          <PremierCollectionShopDetailsSkeleton />
        </Box>

        <PremierCollectionShopChooseRoomSkeleton />
      </div>
    </Box>
  ); // note: skeleton still needs to be built

  const renderShop = () => {
    return (
        lodgingData && (
        <Box
          className={clsx("desktop-pc-shop-container", {
            "lifestyle-collection": variant === "lifestyle-collection",
          })}
        >
          <div id="gallery-section" ref={galleryRef}>
            <DesktopHotelPhotoModal />
          </div>

          <PageTabNavigations
            navItems={tabs}
            selectedTab={selectedTab}
            className="pc-shop-navigation"
          />
          <ShopDetailsAvailabilityBanner isUnavailable={isUnavailable}/>
          <div
            className={"section-wrapper description-and-date-picker-section"}
            id="about-section"
            ref={aboutRef}
          >
            <ShopHotelDescription />
            <DesktopPremierCollectionShopChooseDates
              variant={variant}
              handleShopCall={handleShopCall}
              isUnavailable={isUnavailable}
            />
          </div>
         <PopularAmenities />
          {
            lodgingData?.tripAdvisorReviews &&
            lodgingData?.tripAdvisorReviews?.comments?.length > 0 &&
            <TripAdvisorReviews lodgingData={lodgingData}/>
          }
          <DesktopEarnBanner />
          <div
            id="choose-room-section"
            className="pc-shop-room-type-section-wrapper-redesign"
            ref={chooseRoomRef}
          >
            <Typography className="choose-dates-type-header" variant="h2">
              {textConstants.CHOOSE_DATES}
            </Typography>
            <RoomInfoPicker
              handleReadyToRedirect={handleReadyToRedirect}
              isMobile={false}
              handleShopCall={handleShopCall}
            />
            <PremiumStaysInfoAccordian variant="only-experience-credit" />
          </div>
        </Box>
      )
    );
  };

  if (hasFailed) {
    history.push(PATH_HOME);
    return null;
  }

  return (
    <Box
      className={clsx("desktop-pc-shop-wrapper", {
        "lifestyle-collection": variant.includes("lifestyle-collection"),
      })}
    >
      <DesktopRewardsHeader isShopDetails={true}/>
      {hotelShopDetailsCallInProgress ? renderSkeleton() : renderShop()}
    </Box>
  );
};
