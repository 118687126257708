import React from "react";
import {
  LoadingPopup,
  B2BSpinner,
  PoweredByHopper,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";
import { BookingInProgressModalConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import {
  CartFulfillState,
  CartQuoteState,
  getChildState,
  getParentState,
  ParentState,
  useCheckoutState,
} from "@capone/checkout";
import { TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";

export interface IBookingInProgressModalProps
  extends BookingInProgressModalConnectorProps {}

export const BookingInProgressModal = ({}: IBookingInProgressModalProps) => {
  // const runtime = dayjs();
  const { matchesMobile } = useDeviceTypes();

  const [state] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;
  const childState = getChildState(state.value);

  const isRunningCartQuote =
    parentState === ParentState.cartQuote &&
    [CartQuoteState.polling, CartQuoteState.schedule].includes(
      childState as CartQuoteState
    );

  const isRunningCartFulfil =
    parentState === ParentState.cartFulfill &&
    [CartFulfillState.polling, CartFulfillState.schedule].includes(
      childState as CartFulfillState
    );

  const isRunningCartUpdateBeforeFulfil =
    parentState === ParentState.cartUpdateBeforeFulfill;

  // useEffect(() => {
  //   switch (flightBookType) {
  //     case FlightBookType.DEFAULT:
  //       if (finalizedItinerary) {
  //         trackEvent({
  //           eventName: VIEWED_FLIGHT_BOOK_PROCESSING,
  //           properties: {
  //             runtime: dayjs().diff(runtime, "second"),
  //           },
  //         });
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // }, [inProgress, finalizedItinerary, flightBookType]);

  const getMessage = () => {
    if (isRunningCartFulfil || isRunningCartUpdateBeforeFulfil) {
      return textConstants.BOOKING_PACKAGE;
    }

    return textConstants.SAVING_TRAVELERS_AND_CHECKING_AVAILABILITY;
  };

  return (
    <LoadingPopup
      classes={[
        clsx("packages-book-booking-in-progress-modal", {
          mobile: matchesMobile,
        }),
      ]}
      indicatorSize={"small"}
      indicator={B2BSpinner}
      open={
        isRunningCartQuote ||
        isRunningCartFulfil ||
        isRunningCartUpdateBeforeFulfil
      }
      popupSize={"small"}
      message={getMessage()}
      footer={PoweredByHopper}
      textAlign={"center"}
      verticalAlignment={matchesMobile ? "center" : undefined}
      fullScreen={matchesMobile}
    />
  );
};
