import { Box, Typography } from "@material-ui/core";
import {
  ActionButton,
  BackToTopButton,
  HotelAmenities,
  HotelCarousel,
  HotelName,
  HotelOverview,
  HotelTripAdvisorReviews,
  Icon,
  IconName,
  PageTabNavigations,
  PremiumStaysInfoAccordian,
} from "halifax";
import React, { useEffect } from "react";
import { RewardsAccountSelection } from "../../../rewards/components";
import clsx from "clsx";
import { MobileShopDetailsConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import * as textConstants from "./textConstants";
import { useInView } from "react-intersection-observer";
import "./styles.scss";
import {
  AmenityEnum,
  CustomerAccountRole,
} from "redmond";
import { ShopErrorModal } from "../../../shop/components/ShopErrorModal";
import { MobileHotelPhotoModal } from "../../../shop/components/MobileHotelPhotoModal";
import { PATH_HOME, PATH_SHOP } from "../../../../utils/paths";
import { MobileShopDetailsHeader } from "../MobileShopDetailsHeader";
import {parseShopDetailsQueryString, transformToShopDetailsStringifiedQuery} from "../../utils/queryStringHelpers";
import {ShopDetailsAvailabilityBanner} from "../ShopDetailsAvailabilityBanner";
import { MobilePopoverCalendarButton } from "../MobilePopoverCalendarButton";
import {PopularAmenities} from "../../../shop/components/PopularAmenities";
import {ShopHotelDescription} from "../../../shop/components/ShopHotelDescription";
export interface IMobileShopDetailsProps
  extends MobileShopDetailsConnectorProps,
    RouteComponentProps {
  handleReadyToRedirect?: () => void;
  variant?: "default" | "lifestyle-collection";
}

export const MobileShopDetails = (props: IMobileShopDetailsProps) => {
  const {
    history,
    lodgingData,
    hotelShopDetailsCallInProgress,
    hasFailed,
    largestEarnAccount,
    variant = "default",
    shopCallFromShopDetails,
    setOpenDatesModal,
  } = props;
  const [clickedSectionId, setClickedSectionId] = React.useState<string>();
  const [galleryRef, galleryInView] = useInView({ threshold: 1 });
  const [aboutRef, aboutInView] = useInView({ threshold: 1 });
  const [, amenitiesInView] = useInView({ threshold: 1 });
  const [reviewsRef, reviewsInView] = useInView({ threshold: 1 });
  const [openPhotoModal, setOpenPhotoModal] = React.useState(false);
  const handleShopCall = (queryParams: string) => {
    const paramsForFailure = transformToShopDetailsStringifiedQuery({
      lodgingId: lodgingData?.id || "",
      unavailable: true,
    });
    shopCallFromShopDetails(true, `${history.location.pathname}${paramsForFailure}`);
    history.push(`${PATH_SHOP}${queryParams}`, {
      fromPage: history.location.pathname,
    });
  }
  const selectedTab = React.useMemo(() => {
    if (
      galleryInView &&
      (clickedSectionId ? clickedSectionId === "#gallery-section" : true)
    ) {
      return 0;
    }
    if (
      aboutInView &&
      (clickedSectionId ? clickedSectionId === "#about-section" : true)
    ) {
      return 1;
    }
    if (
      amenitiesInView &&
      (clickedSectionId ? clickedSectionId === "#amenities-section" : true)
    ) {
      return 2;
    }
    if (
      reviewsInView &&
      (clickedSectionId ? clickedSectionId === "#reviews-section" : true)
    ) {
      return 3;
    }

    return undefined;
  }, [galleryInView, aboutInView, amenitiesInView, reviewsInView]);
  const scrollToSection = (sectionId: string) => {
    const BANNER_HEIGHT =
      document
        .getElementById("b2bportal-banner-container")
        ?.getBoundingClientRect().height ?? 0;
    const HEADER_HEIGHT =
      document
        .querySelector(".pc-shop-progress-header-container")
        ?.getBoundingClientRect().height ?? 0;
    const MARGIN = 20;
    const yOffset =
      window.pageYOffset - (BANNER_HEIGHT + HEADER_HEIGHT + MARGIN);
    const element = document.getElementById(sectionId);

    const y = (element?.getBoundingClientRect().top ?? 0) + yOffset;
    setClickedSectionId(sectionId);
    setTimeout(() => {
      setClickedSectionId(undefined);
    }, 500);
    window.scrollTo({ top: y, behavior: "smooth" });
  };
  const tabs = [
    { label: "Gallery", onClick: () => scrollToSection("gallery-section") },
    { label: "About", onClick: () => scrollToSection("about-section") },
    { label: "Amenities", onClick: () => scrollToSection("amenities-section") },
    ...(lodgingData &&
    lodgingData?.tripAdvisorReviews &&
    lodgingData.tripAdvisorReviews?.comments?.length > 0
      ? [
          {
            label: "Reviews",
            onClick: () => scrollToSection("reviews-section"),
          },
        ]
      : []),
  ];

  React.useEffect(() => {
    // removes medallia from the fixed bottom that was covering pricing
    if (document && document.getElementById("nebula_div_btn")) {
      document!.getElementById("nebula_div_btn")!.style.display = "none";
    }
    return () => {
      if (document && document.getElementById("nebula_div_btn")) {
        document!.getElementById("nebula_div_btn")!.style.display = "unset";
      }
    };
  }, [document.getElementById("nebula_div_btn")]);

  useEffect(() => {
    if (lodgingData && !!lodgingData.amenities.length) {
      const paidBreakfastIndex = lodgingData?.amenities.findIndex(
        (amenity) => amenity.amenity === AmenityEnum.PaidBreakfast
      );

      if (paidBreakfastIndex && paidBreakfastIndex > -1) {
        lodgingData?.amenities.splice(paidBreakfastIndex, 1);
      }
    }
  }, [lodgingData]);

  const renderRewardsAccount = () => {
    return (
      <Box className="mobile-pc-shop-rewards-account-contents">
        <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
      </Box>
    );
  };

  const isUnavailable = parseShopDetailsQueryString(history.location.search).unavailable;

  const renderSkeleton = () => (
    <Box className={"mobile-pc-shop-skeleton"}>
      <Box>
        <HotelName isSkeleton />
      </Box>
      <HotelCarousel isSkeleton />
      <Box className={"section-wrapper"}>
        <HotelOverview isSkeleton />
        <HotelAmenities isSkeleton />
        <HotelOverview isSkeleton />
      </Box>
      <Box className={"section-wrapper"}>
        <HotelAmenities isSkeleton />
        <HotelAmenities isSkeleton />
        <HotelOverview isSkeleton />
      </Box>
      <Box className={"section-wrapper"}>
        <HotelName isSkeleton />
        <HotelCarousel isSkeleton />
      </Box>
    </Box>
  );

  const renderShopDetails = () => {
    return (
      <Box className={"mobile-pc-shop-container"}>
        {lodgingData ? (
          <>
            <MobileShopDetailsHeader
              handleShopCall={handleShopCall}
              isUnavailable={isUnavailable}
            />
            <ShopDetailsAvailabilityBanner isUnavailable={isUnavailable} isMobile/>

            {renderRewardsAccount()}

            <MobileHotelPhotoModal
              openModal={openPhotoModal}
              setOpenModal={setOpenPhotoModal}
            />

            <div
              id="gallery-section"
              onClick={() => {
                setOpenPhotoModal(true);
              }}
              ref={galleryRef}
            >
              <HotelCarousel media={lodgingData?.media} />
            </div>

            <PageTabNavigations
              navItems={tabs}
              selectedTab={selectedTab}
              className="pc-shop-navigation"
            />

            {largestEarnAccount && largestEarnAccount.earn.hotelsMultiplier && (
              <Box
                className={clsx("shop-benefits", {
                  "lifestyle-collection": variant === "lifestyle-collection",
                })}
              >
                <Box className="shop-benefits-logo">
                  <Icon
                    name={
                      variant === "lifestyle-collection"
                        ? IconName.LifestyleCollectionHorizontalIcon
                        : IconName.PremierCollectionHorizontalIcon
                    }
                  />
                </Box>

                <Typography className="shop-benefits-list">
                  {textConstants.getBenefitsList(
                    largestEarnAccount.earn.hotelsMultiplier,
                    variant,
                    largestEarnAccount.customerAccountRole ===
                      CustomerAccountRole.Primary
                  )}
                </Typography>
                <Box className="additional-benefits">
                  <Icon name={IconName.GiftOutline} />
                  <Typography className="additional-benefits-text">
                    {variant === "lifestyle-collection"
                      ? textConstants.LC_ADDITIONAL_BENEFITS
                      : textConstants.ADDITIONAL_BENEFITS}
                  </Typography>
                </Box>
              </Box>
            )}
            <div
              className={"section-wrapper description-section"}
              id="about-section"
              ref={aboutRef}
            >
              <ShopHotelDescription />
            </div>
            <PopularAmenities isMobile/>

            {lodgingData?.tripAdvisorReviews &&
              lodgingData?.tripAdvisorReviews?.comments?.length > 0 && (
                <div
                  className={clsx("section-wrapper reviews-section")}
                  id="reviews-section"
                  ref={reviewsRef}
                >
                  <Typography variant="h2">{textConstants.REVIEWS}</Typography>
                  <HotelTripAdvisorReviews
                    lodgingData={lodgingData}
                    trim={1000}
                  />
                </div>
              )}
            <PremiumStaysInfoAccordian
              variant="only-experience-credit"
              isMobile
            />
            <Box className="select-dates-container">
              <Box className="select-dates-button-section">
                <ActionButton
                    buttonClassName="mobile-select-dates-button"
                    className="select-dates-button"
                    onClick={() => {
                      setOpenDatesModal(true);
                    }}
                    message={textConstants.SELECT_DATES}
                />
              </Box>
            </Box>
            <MobilePopoverCalendarButton handleShopCall={handleShopCall}/>
          </>
        ) : null}
        <BackToTopButton
          bottom={115}
        />
      </Box>
    );
  };

  if (hasFailed) {
    history.push(PATH_HOME);
    return null;
  }

  return !hasFailed ? (
    <Box
      className={clsx("mobile-pc-shop-wrapper", {
        "lifestyle-collection": variant.includes("lifestyle-collection"),
      })}
    >
      {hotelShopDetailsCallInProgress
        ? renderSkeleton()
        : renderShopDetails()}
    </Box>
  ) : (
    <ShopErrorModal />
  );
};
