import React from "react";
import { Box, Button, Divider } from "@material-ui/core";

import {
  ActionButton,
  AvailabilityDuration,
  AvailabilityLanguage,
  AvailabilityReviewRating,
} from "halifax";

import styles from "./styles.module.scss";
import { CONTINUE_TO_CHECKOUT, VIEW_DETAILS } from "../../../../textConstants";
import { transformToStringifiedShopQuery } from "../../../../../../utils/queryStringHelpers";
import { PATH_SHOP } from "../../../../../../utils/paths";
import { ExperiencesSimilarAvailability } from "../ExperiencesSimilarCard";
import { ItineraryDetails } from "../../../ShopWhatToExpect/components/ItineraryDetails.tsx";

export interface IExperiencesCompareCardProps {
  experiencesAvailabilityInfo: ExperiencesSimilarAvailability;
  selectedExperienceIdValue: string;
  fromDate: Date | null;
  untilDate: Date | null;
  closeModal: () => void;
}

export const ExperiencesCompareCard = (
  props: IExperiencesCompareCardProps
): React.ReactElement => {
  const {
    experiencesAvailabilityInfo,
    selectedExperienceIdValue,
    fromDate,
    untilDate,
    closeModal,
  } = props;

  const { name, rating, duration, coverImage, id, languageGuides, itinerary } =
    experiencesAvailabilityInfo;

  const isMainExpCard = selectedExperienceIdValue === id?.value;

  return (
    <Box className={styles["card-container"]}>
      <Box className={styles["info-container"]}>
        <img className={styles["cover-img-thumbnail"]} src={coverImage} />
        <Box className={styles["experience-details"]}>
          <Box className={styles["experience-name"]}>{name}</Box>
          <Box className={styles["experience-other-details"]}>
            <AvailabilityReviewRating
              reviewCount={rating.numberOfReviews}
              scaledScore={4.5} //// TODO: Remove this it's to deal with mock data
              // scaledScore={props.rating.reviewAverage}
              shortReviews
            />
            <Divider
              className={styles["experiences-shop-overview-subtext-dividers"]}
              orientation="vertical"
            />
            <AvailabilityDuration duration={duration} />
            <Divider
              className={styles["experiences-shop-overview-subtext-dividers"]}
              orientation="vertical"
            />
            <AvailabilityLanguage language={languageGuides[0].language} />
          </Box>
        </Box>
      </Box>
      {isMainExpCard ? (
        <ActionButton
          className={styles["checkout-cta-button"]}
          onClick={() => {
            closeModal();
            setTimeout(() => {
              document
                .getElementsByClassName("continue-button")[0]
                ?.scrollIntoView({ behavior: "smooth", block: "end" });
            }, 300);
          }}
          message={CONTINUE_TO_CHECKOUT}
        />
      ) : (
        <Button
          variant="outlined"
          color="primary"
          className={styles["view-details-button"]}
          onClick={() => {
            if (id) {
              window.open(
                `${PATH_SHOP}${transformToStringifiedShopQuery(
                  id,
                  fromDate,
                  untilDate
                )}`,
                "_blank"
              );
            }
          }}
        >
          {VIEW_DETAILS}
        </Button>
      )}
      <ItineraryDetails itinerary={itinerary} />
    </Box>
  );
};
