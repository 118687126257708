import React from "react";
import { DesktopEarnBannerConnectorProps } from "./container";
import { Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import {
  Icon,
  IconName,
  BannerSeverity,
  NotificationBanner,
} from "halifax";
import { CustomerAccountRole } from "redmond";
import * as textConstants from "./textConstants";

export interface DesktopEarnBannerProps
  extends DesktopEarnBannerConnectorProps,
    RouteComponentProps {
  variant?: "default" | "lifestyle-collection";
}

export const DesktopEarnBanner = (props: DesktopEarnBannerProps) => {
  const {
    variant,
    largestEarnAccount,
  } = props;

  return (
    <NotificationBanner
      className="pc-earn-banner"
      content={
        <Typography
           className="pc-earn-banner-text"
           dangerouslySetInnerHTML={{
              __html:
                variant === "lifestyle-collection"
                  ? textConstants.getLCEarnBannerText(
                    largestEarnAccount.earn.hotelsMultiplier,
                    largestEarnAccount.customerAccountRole === CustomerAccountRole.Primary) : textConstants.PC_EARN_BANNER_TEXT,
            }}
        />
      }
      severity={BannerSeverity.INFO}
      icon={<Icon className="banner-icon" name={IconName.StarOutline} />}
    />
  );
};
