import { isCorpTenant, useShowPolicyBanner } from "@capone/common";
import { Box, Dialog, Typography } from "@material-ui/core";
import {
  DirectionsRenderer,
  GoogleMap,
  OverlayView,
} from "@react-google-maps/api";
import clsx from "clsx";
import {
  ActionLink,
  B2BSpinner,
  BackButton,
  ButtonWrap,
  CloseButtonIcon,
  HotelAvailabilityMapTooltip,
  HotelMapPin,
  Icon,
  IconName,
  MobileHotelMapTooltip,
  MobilePopoverCard,
  MobileSearchFieldButton,
  PremierCollectionBenefitsModal,
} from "halifax";
import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  CallState,
  HotelDetailsEntrySourceEnum,
  HotelEntryTypeEnum,
  IIdLodgings,
  IResult,
  Lodging,
  LodgingAddressExact,
  LodgingCollectionEnum,
  SEARCH_DISTANCE_FROM_SHOP,
  ViewedCorpRateDescriptorEntryPoints,
} from "redmond";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PaneNames } from "@react-google-maps/api/dist/components/dom/OverlayView";
import { config } from "../../../../api/config";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CACHE_HOTEL_TOKEN,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  HOTELS_SEARCH_DISTANCE_FROM_NEARBY_EXPERIMENT,
  getExperimentVariant,
  useExperiments,
} from "../../../../context/experiments";
import {
  PATH_SHOP,
  PREMIER_COLLECTION_PATH_SHOP,
} from "../../../../utils/paths";
import { PC_BENEFITS_BUTTON_TEXT } from "../../../availability/components/AvailabilityList/textConstants";
import { AvailabilityMapPricePin } from "../../../availability/components/AvailabilityMap";
import { convertICoordinatesToGoogleMapCoords } from "../../../availability/components/AvailabilityMap/googleMapsHelpers";
import { transformToStringifiedQuery } from "../../utils/queryStringHelpers";
import { DistanceFromLocationAutocomplete } from "../DistanceFromLocationAutocomplete";
import { MobileDistanceFromLocationSearchModal } from "../MobileDistanceFromLocationSearchModal";
import { HotelShopExpandedMapConnectorProps } from "./container";
import "./styles.scss";
import { VIEW_DISTANCE_FROM } from "./textConstants";
import { onOpenCompareBarTooltip } from "../../../../utils/events";
import { ClientContext } from "../../../../App";

export interface IHotelShopExpandedMapProps
  extends HotelShopExpandedMapConnectorProps {
  lodging: Lodging;
  showExpandedHotelMap: boolean;
  setShowExpandedHotelMap: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile?: boolean;
}

export const HotelShopExpandedMap = (props: IHotelShopExpandedMapProps) => {
  const {
    lodging,
    showExpandedHotelMap,
    setShowExpandedHotelMap,
    isMobile,
    distanceFromLocation,
    searchDistanceFromShopProperties,
    setDistanceFromLocation,
    fetchDistanceFromLocationCategories,
    nearbyLodgings,
    fetchNearbyLodgings,
    setNearbyLodgings,
    nightCount,
    rewardsAccountId,
    hotelQueryParams,
    roomsCount,
    petsCount,
    isVentureX,
    fetchNearbyLodgingsCallState,
    largestValueAccount,
  } = props;
  const [openDistanceFromLocationModal, setOpenDistanceFromLocationModal] =
    useState(false);

  const expState = useExperiments();
  const [directionsToUse, setDirectionsToUse] =
    useState<google.maps.DirectionsResult | null>(null);
  const [mapInFocus, setMapInFocus] = useState(false);
  const [openBenefitsModal, setOpenBenefitsModal] = useState(false);
  const [lodgingInFocus, setLodgingInFocus] = useState<Lodging | null>(null);
  const hotelSearchDistanceFromNearby = getExperimentVariant(
    expState.experiments,
    HOTELS_SEARCH_DISTANCE_FROM_NEARBY_EXPERIMENT
  );
  const isHotelSearchDistanceFromNearbyExperiment = React.useMemo(
    () => hotelSearchDistanceFromNearby === AVAILABLE,
    [hotelSearchDistanceFromNearby]
  );

  const { policies } = useContext(ClientContext);
  const showPolicyBanner = useShowPolicyBanner(policies);

  var directionsService = new google.maps.DirectionsService();

  const lodgingLatLng = convertICoordinatesToGoogleMapCoords(
    lodging.lodging.location.coordinates
  );

  const isNearbyLodgingsLoading = useMemo(
    () => fetchNearbyLodgingsCallState === CallState.InProcess,
    [fetchNearbyLodgingsCallState]
  );

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  useEffect(() => {
    if (distanceFromLocation) {
      trackEvent({
        eventName: SEARCH_DISTANCE_FROM_SHOP,
        ...searchDistanceFromShopProperties,
      });
      const request = {
        origin: lodgingLatLng,
        destination: {
          placeId: (distanceFromLocation?.id as IIdLodgings).lodgingSelection
            .placeId,
        },
        travelMode: google.maps.TravelMode.DRIVING,
      };

      directionsService.route(request, function (result, status) {
        if (status == google.maps.DirectionsStatus.OK) {
          setDirectionsToUse(result);
        }
      });
      if (isHotelSearchDistanceFromNearbyExperiment) {
        fetchNearbyLodgings();
      }
    } else {
      setNearbyLodgings([]);
      directionsToUse && setDirectionsToUse(null);
    }
  }, [distanceFromLocation]);

  const center = directionsToUse
    ? directionsToUse?.routes[0].legs[0].end_location
    : convertICoordinatesToGoogleMapCoords(
        lodging.lodging.location.coordinates
      );

  useEffect(() => {
    if (directionsToUse) {
      setMapInFocus(false);
    }
  }, [directionsToUse]);

  const getSelectedOption = (option: IResult | null, value: IResult | null) => {
    const selection = value ? (value.id as IIdLodgings) : null;
    const opt = option ? (option.id as IIdLodgings) : null;
    return (
      !!selection &&
      !!opt &&
      selection.lodgingSelection.placeId === opt.lodgingSelection.placeId
    );
  };

  const onCloseClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setLodgingInFocus(null);
  };

  const onClickHotelDetails = () => {
    if (!lodgingInFocus || !distanceFromLocation) {
      return;
    }
    const params = transformToStringifiedQuery({
      lodgingId: lodgingInFocus.lodging.id,
      lodgingSelection: (distanceFromLocation.id as IIdLodgings)
        .lodgingSelection,
      ...hotelQueryParams,
      roomsCount: roomsCount,
      petsCount: petsCount,
      fromHotelAvailability:
        lodging.lodgingCollection === LodgingCollectionEnum.Premier ||
        lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
          ? true
          : undefined,
      entryPoint: HotelEntryTypeEnum.SEARCH_DISTANCE_FROM,
      hotelDetailsEntrySource: HotelDetailsEntrySourceEnum.MAP,
      shopToken: cacheHotelTokenEnabled
        ? lodging.price?.opaqueShopRequest
        : undefined,
    });
    const path =
      lodging.lodgingCollection === LodgingCollectionEnum.Premier
        ? PREMIER_COLLECTION_PATH_SHOP
        : PATH_SHOP;
    window.open(`${path}${params}`, "_blank");
  };

  const handleCloseMap = () => {
    setShowExpandedHotelMap(false);
    setNearbyLodgings([]);
    setDistanceFromLocation(null);
    fetchDistanceFromLocationCategories("");
  };

  const checkForDrag = () => {
    setMapInFocus(true);
  };

  const renderPricePin = React.useCallback(
    (mapPaneName: PaneNames, lodging?: Lodging) => {
      if (!lodging?.price) {
        return null;
      }

      const coordinates = convertICoordinatesToGoogleMapCoords(
        lodging.lodging.location.coordinates
      );

      return (
        <OverlayView
          key={lodging.lodging.id}
          mapPaneName={mapPaneName}
          position={coordinates}
        >
          <AvailabilityMapPricePin
            isPreview={false}
            isDesktop={!isMobile}
            isPreviouslyShown={false}
            lodging={lodging}
            isInFocus={lodgingInFocus?.lodging.id === lodging.lodging.id}
            isHovered={false}
            nightCount={nightCount}
            setLodgingIdInFocus={() => {
              setLodgingInFocus(lodging);
            }}
          />
        </OverlayView>
      );
    },
    []
  );

  return (
    <Dialog
      open={showExpandedHotelMap}
      onClose={() => setShowExpandedHotelMap(false)}
      className={clsx("expanded-hotel-map-popover", { mobile: isMobile })}
      /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
      TransitionProps={{ role: "none" } as never}
      PaperProps={{
        /* eslint-disable */
        // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
        tabIndex: 0,
        /* eslint-enable */
      }}
      disableEnforceFocus
    >
      {isMobile ? (
        <BackButton className={"b2b"} onClick={handleCloseMap}></BackButton>
      ) : (
        <ActionLink
          className="expanded-map-close-button"
          content={<CloseButtonIcon />}
          label="Close"
          onClick={handleCloseMap}
        />
      )}
      <Box className="expanded-hotel-map-content">
        <Box className="expanded-hotel-map-title">{lodging.lodging.name}</Box>
        <Box className="expanded-hotel-map-address">
          {(lodging.lodging.address as LodgingAddressExact).line1}
        </Box>
        <Box
          className="expanded-hotel-map-container"
          onClick={() => checkForDrag()}
        >
          <GoogleMap
            id="expanded-hotel-map"
            mapContainerStyle={{
              height: "100%",
              width: "100%",
              borderRadius: !isMobile ? "8px" : undefined,
            }}
            options={{
              clickableIcons: false,
              zoomControl: true,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: !isMobile,
              gestureHandling: isGlobalMobileNavExperiment
                ? "greedy"
                : undefined,
            }}
            center={center}
            zoom={16}
          >
            <ExpandedHotelMapContext
              {...props}
              directionsToUse={null}
              mapInFocus={mapInFocus}
              setMapInFocus={setMapInFocus}
              lodgingInFocus={lodgingInFocus}
              setLodgingInFocus={setLodgingInFocus}
              onClickHotelDetails={onClickHotelDetails}
            ></ExpandedHotelMapContext>
            {directionsToUse && (
              <DirectionsRenderer
                directions={directionsToUse}
                options={{
                  suppressMarkers: true,
                }}
              />
            )}
            <ExpandedHotelMapContext
              {...props}
              mapInFocus={mapInFocus}
              setMapInFocus={setMapInFocus}
              directionsToUse={directionsToUse}
              lodgingInFocus={lodgingInFocus}
              setLodgingInFocus={setLodgingInFocus}
              onClickHotelDetails={onClickHotelDetails}
            ></ExpandedHotelMapContext>
            {nearbyLodgings
              ? nearbyLodgings.map((lodging) =>
                  renderPricePin(OverlayView.OVERLAY_MOUSE_TARGET, lodging)
                )
              : null}
            {!!lodgingInFocus && !isMobile ? (
              <OverlayView
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                position={convertICoordinatesToGoogleMapCoords(
                  lodgingInFocus.lodging.location.coordinates
                )}
              >
                <Box
                  className={clsx(
                    "lodging-marker-wrapper",
                    "inFocus",
                    "hovered",
                    {
                      "premier-collection":
                        lodgingInFocus.lodgingCollection ===
                        LodgingCollectionEnum.Premier,
                    }
                  )}
                >
                  <Box className="lodging-details-container">
                    {lodgingInFocus.lodgingCollection ===
                      LodgingCollectionEnum.Premier && (
                      <Icon name={IconName.PremierCollectionRibbon} />
                    )}
                    <HotelAvailabilityMapTooltip
                      rewardsKey={rewardsAccountId}
                      onCloseClick={(ev) => onCloseClick(ev)}
                      onClickHotelDetails={() => {
                        onClickHotelDetails();
                      }}
                      isVentureX={isVentureX}
                      nightCount={nightCount || 1}
                      lodging={lodgingInFocus}
                      searchedRoomsCount={
                        isCorpTenant(config.TENANT) ? roomsCount : undefined
                      }
                      onViewCorpCompareBar={onOpenCompareBarTooltip(
                        ViewedCorpRateDescriptorEntryPoints.HOTELS_LIST_MAP
                      )}
                    />
                  </Box>
                </Box>
              </OverlayView>
            ) : null}
          </GoogleMap>
          {isMobile ? (
            <MobileSearchFieldButton
              value={distanceFromLocation?.label}
              label={VIEW_DISTANCE_FROM}
              icon={<Icon name={IconName.MagnifyingGlass} />}
              renderPopup={() => (
                <MobileDistanceFromLocationSearchModal
                  openLocationModal={openDistanceFromLocationModal}
                  setOpenLocationModal={setOpenDistanceFromLocationModal}
                />
              )}
              onClick={() => setOpenDistanceFromLocationModal(true)}
              endIcon={
                distanceFromLocation ? (
                  <FontAwesomeIcon
                    className="clear-icon"
                    icon={faTimesCircle}
                    onClick={(event: React.MouseEvent) => {
                      event.stopPropagation();
                      setNearbyLodgings([]);
                      setDistanceFromLocation(null);
                      fetchDistanceFromLocationCategories("");
                    }}
                  />
                ) : undefined
              }
            />
          ) : (
            <DistanceFromLocationAutocomplete
              className={clsx("distance-from-search-auto-complete", "b2b")}
              label={VIEW_DISTANCE_FROM}
              getOptionSelected={getSelectedOption}
              customIcon={
                <Icon
                  name={IconName.MagnifyingGlass}
                  ariaLabel=""
                  aria-hidden={true}
                />
              }
              endIcon={distanceFromLocation ? faTimesCircle : undefined}
              endIconOnClick={() => {
                setDistanceFromLocation(null);
                fetchDistanceFromLocationCategories("");
              }}
            />
          )}
        </Box>
        {isNearbyLodgingsLoading ? (
          <Box className="nearby-lodgings-loading">
            <Typography className="nearby-lodgings-loading-label">
              Searching hotels near this location...
            </Typography>
            <B2BSpinner style={{ width: "20px", height: "20px" }} />
          </Box>
        ) : null}
        {isMobile && lodgingInFocus ? (
          <MobilePopoverCard
            className={clsx("search-distance-from-hotel-popup")}
            open={!!lodgingInFocus}
            onClose={() => setLodgingInFocus(null)}
          >
            <Box
              className={clsx("search-distance-from-hotel-container", {
                "premier-collection":
                  lodgingInFocus.lodgingCollection ===
                  LodgingCollectionEnum.Premier,
              })}
            >
              {lodgingInFocus.lodgingCollection ===
                LodgingCollectionEnum.Premier && (
                <>
                  <PremierCollectionBenefitsModal
                    openBenefitsModal={openBenefitsModal}
                    handleClosePCBenefitsModal={() => {
                      setOpenBenefitsModal(false);
                    }}
                    isMobile
                    largestValueAccount={largestValueAccount}
                  />
                  <ButtonWrap
                    onClick={() => setOpenBenefitsModal(true)}
                    className="benefits-modal-button"
                    aria-label="benefits modal button"
                  >
                    <Typography className="modal-button-text">
                      {PC_BENEFITS_BUTTON_TEXT}
                    </Typography>
                    <Icon name={IconName.InfoCircle} />
                  </ButtonWrap>
                  <Icon name={IconName.PremierCollectionRibbon} />
                </>
              )}
              <MobileHotelMapTooltip
                lodging={lodgingInFocus}
                nightCount={nightCount || 1}
                rewardsKey={rewardsAccountId}
                isVentureX={isVentureX}
                onClick={() => {
                  onClickHotelDetails();
                }}
                roomsCount={roomsCount}
                showPolicyBanner={showPolicyBanner}
              />
            </Box>
          </MobilePopoverCard>
        ) : null}
      </Box>
    </Dialog>
  );
};

export interface IExpandedHotelMapContextProps
  extends IHotelShopExpandedMapProps {
  directionsToUse: google.maps.DirectionsResult | null;
  mapInFocus: boolean;
  setMapInFocus: (arg: boolean) => void;
  lodgingInFocus: Lodging | null;
  setLodgingInFocus: (arg: Lodging | null) => void;
  onClickHotelDetails: () => void;
}
const ExpandedHotelMapContext = (props: IExpandedHotelMapContextProps) => {
  const {
    lodging,
    directionsToUse,
    mapInFocus,
    setMapInFocus,
    distanceFromLocation,
  } = props;

  const center = directionsToUse
    ? directionsToUse?.routes[0].legs[0].end_location
    : convertICoordinatesToGoogleMapCoords(
        lodging.lodging.location.coordinates
      );

  const getDirectionInfoContent = () => {
    const [locationName] = distanceFromLocation?.label
      ? distanceFromLocation.label.split(",")
      : [];
    if (directionsToUse) {
      return (
        <Box className="distance-from-location-info-box-content">
          <Typography className="distance-from-location-name" variant="body1">
            {locationName}
          </Typography>
          <Box className="distance-from-location-distance-wapper">
            <Icon name={IconName.DistanceFromCarIcon} />
            <Typography
              className="direction-from-location-distance"
              variant="body2"
            >
              {directionsToUse?.routes[0].legs[0]?.distance?.text},{" "}
              <span className="direction-from-location-distance-duration">
                {directionsToUse?.routes[0].legs[0].duration?.text}
              </span>
            </Typography>
          </Box>
        </Box>
      );
    }
    return undefined;
  };

  return (
    <OverlayView
      mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
      position={center}
    >
      {directionsToUse ? (
        <Box className="distance-from-location-pin-wrapper">
          {!mapInFocus && (
            <Box className="distance-from-location-info">
              <FontAwesomeIcon
                className="icon info-box-close-button"
                icon={faTimesCircle}
                onClick={() => setMapInFocus(false)}
              />
              {getDirectionInfoContent()}
            </Box>
          )}
          <HotelMapPin
            className="distance-from-location-pin"
            onClick={(e) => {
              e?.stopPropagation();
              setMapInFocus(false);
            }}
          />
        </Box>
      ) : (
        <HotelMapPin className="distance-from-location-pin" />
      )}
    </OverlayView>
  );
};
