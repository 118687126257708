import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { actions } from "../../actions";
import { RoomContent } from "./component";

const mapStateToProps = () => {
  return {};
};

const mapDispatchToProps = {
  selectRoomType: actions.selectRoomType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RoomContentConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRoomContent = withRouter(
  connector(RoomContent)
);
