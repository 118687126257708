import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";

import { ReviewFlightItinerary } from "./component";
import {
  getAirportMap,
  getFareTripDetailsById,
  getIsTripVoidWindowEligible,
  getPackagesByReturnFareSlice,
  getParsedPackagesByOutboundFareSlice,
  getSelectedTrip,
  getUserSelectedRecommendedFlight,
  isOutgoingMultiTicketSelector,
  isReturnMultiTicketSelector,
  selectedFareDetailsSelector,
  tripDetailsSelector,
} from "../../../flight-shop/reducer";
import { finalizePackage } from "./../../actions/actions";
import {
  getHotelShopChosenProduct,
  getPackageSelectedLodging,
  getSelectedPackageByLodgingId,
} from "../../../hotel-shop/reducer";
import {
  getDepartureDate,
  getNightCount,
  getReturnDate,
} from "../../../search/reducer";
import { getTravelersCount } from "../../../availability/reducer";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";
import { setPackagesFlightShopProgress } from "../../../flight-shop/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = getSelectedTrip(state);
  const outboundPackageSlices = getParsedPackagesByOutboundFareSlice(state);
  // const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip?.tripId || "";
  const tripDetails = tripDetailsSelector(state, tripId);

  return {
    selectedTrip: getSelectedTrip(state),
    tripDetailsById: getFareTripDetailsById(state),
    getPackagesByReturnFareSlice: getPackagesByReturnFareSlice(state),
    selectedLodging: getPackageSelectedLodging(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    chosenProduct: getHotelShopChosenProduct(state),
    selectedPackageByLodging: getSelectedPackageByLodgingId(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    travelersCount: getTravelersCount(state),
    selectedPackageByFlight: selectedTrip?.outgoingFareSliceId
      ? outboundPackageSlices[selectedTrip.outgoingFareSliceId]
      : undefined,
    airports: getAirportMap(state),
    tripDetails,
    fareDetails: selectedFareDetailsSelector(state),
    isMultiTicket:
      isOutgoingMultiTicketSelector(state) ||
      isReturnMultiTicketSelector(state),
    isVoidWindowEligible: getIsTripVoidWindowEligible(state),
    userSelectedRecommendedFlight: getUserSelectedRecommendedFlight(state),
  };
};

export const mapDispatchToProps = {
  finalizePackage,
  setPackagesFlightShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type ReviewFlightItineraryConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedReviewFlightItinerary = withRouter(
  connector(ReviewFlightItinerary)
);
