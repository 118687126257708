import {
  CorpBusinessAccount,
  CorpSessionInfo,
  PolicyTier,
  RewardsAccount,
  SessionInfo,
  Tenant,
} from "redmond";
import { isCorpTenant } from "@capone/common";
import { getUserDeviceData } from "./userDeviceHelper";

export const SESSION_APPROVAL_PROCESS_KEY = "sessionApprovalProcess";

type StoreState<T extends Tenant> = {
  sessionInfo?: T extends "capone-corporate" ? CorpSessionInfo : SessionInfo;
  corpBusinessAccounts?: CorpBusinessAccount[];
  rewardsAccounts?: RewardsAccount[];
  selectedAccountReferenceId?: string | null;
  policyTier?: PolicyTier;
};

const getCorpUserSessionData = (state: StoreState<"capone-corporate">) => {
  const { sessionInfo, corpBusinessAccounts = [] } = state;

  return {
    business_id: sessionInfo?.corporateInfo.businessId,
    business_name: sessionInfo?.corporateInfo.businessName,
    user_role: sessionInfo?.corporateInfo.role?.join(","),
    user_policy_tier: sessionInfo?.corporateInfo.policyTier,
    user_approval_process: sessionStorage.getItem(SESSION_APPROVAL_PROCESS_KEY),
    num_eligible_businesses: corpBusinessAccounts.length.toString() || 0,
    num_optedin_businesses:
      corpBusinessAccounts
        .filter((account) => "optedIn" in account)
        .length.toString() || 0,
    cap1Role: sessionInfo?.corporateInfo.cap1Role,
  };
};

export const getGlobalTrackingProperties = <T extends Tenant>(
  tenant: T,
  state: StoreState<T>
) => {
  const { rewardsAccounts = [], selectedAccountReferenceId } = state;

  const currentRewardsAccount = rewardsAccounts.find(
    (account) => account.accountReferenceId === selectedAccountReferenceId
  );
  return {
    customer_account_role: currentRewardsAccount?.customerAccountRole,
    product: currentRewardsAccount?.productDisplayName,
    currency: currentRewardsAccount?.rewardsBalance.currency,
    ...getUserDeviceData(),
    ...(isCorpTenant(tenant) &&
      getCorpUserSessionData(state as StoreState<"capone-corporate">)),
  };
};
