import React, { ReactElement, useContext } from "react";
import { GenericBanner } from "halifax";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { B2B_PORTAL_BANNER_CONTAINER_ID, CustomerAccountRole } from "redmond";
import {
  isCorpTenant,
  REWARDS_BANNER_TEXT,
  getCurrentCorpAccount,
} from "@capone/common";

import { ClientContext } from "../../App";
import { useDeviceTypes } from "../../hooks/useDeviceTypes";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnBanner,
} from "../../selectors";
import { config } from "../../api/config";

export interface IRewardsBannerProps extends RouteComponentProps {}

const defaultProps: Partial<IRewardsBannerProps> = {};

const RewardsBanner = (_props: IRewardsBannerProps) => {
  const { sessionInfo } = useContext(ClientContext);
  const firstName = sessionInfo?.userInfo?.firstName || "";

  const { matchesMobile } = useDeviceTypes();
  const largestEarnAccount = useSelector(
    getRewardsAccountWithLargestEarnBanner
  );
  const rewardsAccounts = useSelector(getRewardsAccounts);

  const currentAccount = getCurrentCorpAccount(rewardsAccounts, sessionInfo);

  const rewardsAccount = isCorpTenant(config.TENANT)
    ? currentAccount
    : largestEarnAccount;

  if (!rewardsAccount) return null;

  const {
    earn: {
      hotelsMultiplier: earnRate = "",
      flightsMultiplier: flightEarnRate = "",
    },
    rewardsBalance,
    lastFour = "",
    productDisplayName: productId = "",
  } = rewardsAccount;
  const { currency } = rewardsBalance || {};

  let contentText: string | ReactElement = "";

  if (!matchesMobile) {
    contentText = REWARDS_BANNER_TEXT({
      firstName,
      earnRate,
      productId,
      lastFour,
      flightEarnRate,
      currency,
      isPrimary:
        rewardsAccount.customerAccountRole === CustomerAccountRole.Primary,
      tenant: config.TENANT,
    });
  } else {
    contentText = "";
  }

  if (!contentText) return null;

  return (
    <GenericBanner
      className="rewards-banner-root"
      containerId={B2B_PORTAL_BANNER_CONTAINER_ID}
      content={contentText}
      isMobile={matchesMobile}
    />
  );
};

RewardsBanner.defaultProps = defaultProps;

export default withRouter(RewardsBanner);
