import {
  CreditCard,
  Invalid,
  IPerson,
  PassengerError,
  PassengersResponse,
  Payment,
  PaymentVerifyResultEnum,
  PersonId,
  CallState,
  RewardsPrice,
  FiatPrice,
  GordianSeat,
  FlightBookType,
  PriceFreeze,
  PriceFreezeTripDetails,
  PriceDropProtection,
  TravelWalletOffer,
  TravelWalletCredit,
  PurchaseError,
  PriceFreezeFlightPaymentFinalizeResult,
  AirportMap,
  ICorpPerson,
} from "redmond";
import {
  BookedFlightItinerary,
  ErrorCode,
  FlightError,
  PriceQuoteData,
  Prices,
  PurchaseResult,
  TripPricingSummary,
} from "@b2bportal/air-booking-api";
import { CipherText, Product } from "@b2bportal/purchase-api";
import { isCorpTenant } from "@capone/common";
import { actions, actionTypes } from "../actions";
import { config } from "../../../api/config";

export const DO_NOT_APPLY_REWARDS_KEY = "do-not-apply-rewards";

const defaultRewardsAccountRefId = isCorpTenant(config.TENANT)
  ? DO_NOT_APPLY_REWARDS_KEY
  : undefined;

interface DisruptionProtectionAlertOptIn {
  SMS: boolean;
  appNotif: boolean;
}

export interface IFlightBookState {
  userPassengers: IPerson[] | ICorpPerson[];
  userPassengerCallState: CallState;
  userSelectedPassengerIds: PersonId[];
  userSelectedLapInfantIds: PersonId[];
  userSelectedTravelerIds: PersonId[];
  userSelectedTravelersList: IPerson[];
  confirmationEmailAddress?: string;
  confirmationPhoneNumber?: string;
  tcpaConsentPhoneNumber?: string;
  disruptionProtectionAlertOptIn: DisruptionProtectionAlertOptIn;
  paymentMethods: Payment[];
  paymentMethod?: CreditCard;
  selectedPaymentMethodId?: string;
  verifyPaymentMethodResult?: PaymentVerifyResultEnum;
  listPaymentMethodCallState: CallState;
  verifyPaymentMethodCallState: CallState;
  fetchPaymentMethodCallState: CallState;
  deletePaymentMethodCallState: CallState;
  session?: CipherText;
  sessionCallState: CallState;
  pollPriceQuoteCallState: CallState;
  schedulePriceQuoteCallState: CallState;
  schedulePriceQuoteError: Invalid | null;
  validatePassengersCallState: CallState;
  passengersValid: boolean;
  passengerErrors: PassengerError[];
  tripPricingCallState: CallState;
  tripPricing: TripPricingSummary | null;
  tripPricingErrors: ErrorCode[];
  schedulePaymentCallState: CallState;
  schedulePaymentError: Invalid | null;
  priceQuote: PriceQuoteData | null;
  priceQuoteErrors: (PurchaseError | FlightError)[];
  priceQuoteSuccessTime: string | null;
  priceDifferenceAcknowledged: boolean;
  passengersResponse: PassengersResponse | null;
  finalizedCallState: CallState;
  finalizedErrors: (PurchaseError | FlightError)[];
  finalizedItinerary: BookedFlightItinerary | null;
  finalizedPriceFreeze: PriceFreeze | null;
  finalizedPriceFreezeTripDetails: PriceFreezeTripDetails | null;
  finalizedPriceFreezeFare: any | null;
  finalizedUserEmail: string | null;
  chargeAgentBookingFeeCallState: CallState;
  pollQuoteOnly?: boolean;
  seatMapHtml: string | null;
  cheapestSeat: Prices | null;
  seatMapLoading: boolean;
  seatMapFailed: boolean;
  skipSeatSelection: boolean;
  selectedSeats: GordianSeat[];
  flightBookType: FlightBookType;
  quotedRewardsTotal: RewardsPrice | null;
  setFlightBookQueryParamsCallState: CallState;
  priceDropProtection: PriceDropProtection | null;
  fetchPriceDropEligibilityCallState: CallState;
  infantOnlyError: boolean;
  isSimilarFlightsEnabled: boolean;
  offers?: TravelWalletOffer[];
  bestOfferOverall?: TravelWalletOffer;
  offerToApply?: TravelWalletOffer;
  fetchApplicableTravelWalletItemsCallState: CallState;
  credit?: TravelWalletCredit;
  creditToApply?: TravelWalletCredit;
  airportMap?: AirportMap;

  // Payment Details
  paymentMethodRewardsAccountId?: string;
  rewardsAccountReferenceId?: string | null;
  rewardsPaymentTotal: RewardsPrice | null;
  rewardsPaymentInFiatCurrency: FiatPrice | null;
  productEarnValue: number | null;
  rewardsConversionFailed: boolean;

  //Policy Approval
  submitForApprovalCallState: CallState;
  submitForApprovalError: Invalid | null;
  requestId?: string;

  //Approvals V2
  approvalRequestReason?: string;

  //Trip Purpose
  tripPurpose?: string;
}

export const initialState: IFlightBookState = {
  userPassengers: [],
  userPassengerCallState: CallState.NotCalled,
  userSelectedPassengerIds: [],
  userSelectedLapInfantIds: [],
  userSelectedTravelerIds: [],
  userSelectedTravelersList: [],
  confirmationEmailAddress: undefined,
  confirmationPhoneNumber: undefined,
  tcpaConsentPhoneNumber: undefined,
  disruptionProtectionAlertOptIn: { SMS: false, appNotif: false },
  paymentMethods: [],
  paymentMethod: undefined,
  selectedPaymentMethodId: undefined,
  verifyPaymentMethodResult: undefined,
  listPaymentMethodCallState: CallState.NotCalled,
  fetchPaymentMethodCallState: CallState.NotCalled,
  verifyPaymentMethodCallState: CallState.NotCalled,
  deletePaymentMethodCallState: CallState.NotCalled,
  session: undefined,
  sessionCallState: CallState.NotCalled,
  pollPriceQuoteCallState: CallState.NotCalled,
  schedulePriceQuoteCallState: CallState.NotCalled,
  schedulePriceQuoteError: null,
  schedulePaymentError: null,
  schedulePaymentCallState: CallState.NotCalled,
  validatePassengersCallState: CallState.NotCalled,
  passengersValid: false,
  passengerErrors: [],
  passengersResponse: null,
  tripPricing: null,
  tripPricingErrors: [],
  tripPricingCallState: CallState.NotCalled,
  priceQuote: null,
  priceQuoteErrors: [],
  priceQuoteSuccessTime: null,
  priceDifferenceAcknowledged: false,
  finalizedItinerary: null,
  finalizedPriceFreeze: null,
  finalizedPriceFreezeTripDetails: null,
  finalizedUserEmail: null,
  finalizedPriceFreezeFare: null,
  finalizedErrors: [],
  finalizedCallState: CallState.NotCalled,
  chargeAgentBookingFeeCallState: CallState.NotCalled,
  rewardsAccountReferenceId: defaultRewardsAccountRefId,
  rewardsPaymentTotal: null,
  rewardsPaymentInFiatCurrency: null,
  productEarnValue: null,
  rewardsConversionFailed: false,
  pollQuoteOnly: false,
  seatMapHtml: null,
  cheapestSeat: null,
  seatMapLoading: false,
  seatMapFailed: false,
  skipSeatSelection: false,
  selectedSeats: [],
  flightBookType: FlightBookType.DEFAULT,
  quotedRewardsTotal: null,
  setFlightBookQueryParamsCallState: CallState.NotCalled,
  priceDropProtection: null,
  fetchPriceDropEligibilityCallState: CallState.NotCalled,
  infantOnlyError: false,
  isSimilarFlightsEnabled: false,
  offers: [],
  fetchApplicableTravelWalletItemsCallState: CallState.NotCalled,
  submitForApprovalCallState: CallState.NotCalled,
  submitForApprovalError: null,
  requestId: undefined,
  approvalRequestReason: undefined,
  tripPurpose: "null",
};

export function reducer(
  state: IFlightBookState = initialState,
  action: actions.FlightBookActions
): IFlightBookState {
  switch (action.type) {
    case actionTypes.FETCH_USER_PASSENGERS:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };

    case actionTypes.UPDATE_USER_PASSENGER:
    case actionTypes.UPDATE_USER_PASSENGERS_MULTIPLE:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };

    case actionTypes.DELETE_USER_PASSENGER:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };

    case actionTypes.SET_USER_PASSENGERS:
      return {
        ...state,
        userPassengerCallState: CallState.Success,
        userPassengers: [...action.userPassengers],
      };

    case actionTypes.SET_USER_SELECTED_PASSENGER_IDS:
      return {
        ...state,
        userSelectedPassengerIds: [...action.userSelectedPassengerIds],
      };

    case actionTypes.SET_USER_SELECTED_LAP_INFANT_IDS:
      return {
        ...state,
        userSelectedLapInfantIds: [...action.userSelectedLapInfantIds],
      };

    case actionTypes.SET_USER_SELECTED_TRAVELERS_LIST:
      return {
        ...state,
        userSelectedTravelersList: action.userSelectedTravelersList,
        userSelectedTravelerIds: action.userSelectedTravelersList.map(
          (traveler) => traveler.id
        ),
      };

    case actionTypes.SET_USER_PASSENGERS_CALL_STATE_FAILED:
      return {
        ...state,
        userPassengerCallState: CallState.Failed,
      };

    case actionTypes.SET_CONTACT_INFO:
      return {
        ...state,
        confirmationEmailAddress: action.email,
        confirmationPhoneNumber: action.phoneNumber,
      };

    case actionTypes.SET_USER_TCPA_CONSENT_PHONE_NUMBER:
      return {
        ...state,
        tcpaConsentPhoneNumber: action.tcpaConsentPhoneNumber,
      };

    case actionTypes.TOGGLE_SMS_OPT_IN:
      const currentSMSState = state.disruptionProtectionAlertOptIn;
      return {
        ...state,
        disruptionProtectionAlertOptIn: {
          ...currentSMSState,
          SMS: !currentSMSState.SMS,
        },
      };

    case actionTypes.TOGGLE_APP_NOTIF_OPT_IN:
      const currentAppNotifState = state.disruptionProtectionAlertOptIn;
      return {
        ...state,
        disruptionProtectionAlertOptIn: {
          ...currentAppNotifState,
          appNotif: !currentAppNotifState.appNotif,
        },
      };

    case actionTypes.FETCH_PAYMENT_METHOD:
      return {
        ...state,
        fetchPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.paymentMethod,
        selectedPaymentMethodId: action.paymentMethod?.id,
        fetchPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.SET_SELECTED_PAYMENT_METHOD_ID:
      const selectedPaymentMethod = state.paymentMethods.find(
        (method) => method.id === action.paymentMethodId
      );

      return {
        ...state,
        paymentMethod: selectedPaymentMethod,
        paymentMethodRewardsAccountId: action.accountId,
        selectedPaymentMethodId: action.paymentMethodId,
      };

    case actionTypes.RESET_PAYMENT_CARD_SELECTED_ACCOUNTS:
      return {
        ...state,
        paymentMethod: initialState.paymentMethod,
        paymentMethodRewardsAccountId:
          initialState.paymentMethodRewardsAccountId,
        rewardsAccountReferenceId: initialState.rewardsAccountReferenceId,
        selectedPaymentMethodId: initialState.selectedPaymentMethodId,
      };

    case actionTypes.SET_PAYMENT_METHOD_CALL_STATE_FAILED:
      return {
        ...state,
        fetchPaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.LIST_PAYMENT_METHODS:
      return {
        ...state,
        listPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.SET_PAYMENT_METHODS:
      return {
        ...state,
        paymentMethods: action.paymentMethods,
        listPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.SET_PAYMENT_METHODS_CALL_STATE_FAILED:
      return {
        ...state,
        listPaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.DELETE_PAYMENT_METHOD:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.Success,
      };

    case actionTypes.DELETE_PAYMENT_METHOD_CALL_STATE_FAILED:
      return {
        ...state,
        deletePaymentMethodCallState: CallState.Failed,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD:
      return {
        ...state,
        verifyPaymentMethodCallState: CallState.InProcess,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS:
      return {
        ...state,
        verifyPaymentMethodCallState: CallState.Success,
      };

    case actionTypes.VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED:
      return {
        ...state,
        verifyPaymentMethodResult: action.result,
        verifyPaymentMethodCallState: CallState.Failed,
      };

    // This action is called when we need to remove Failed state; for example,
    // when `userPassengerCallState === CallState.Failed` is used as a condition for opening
    // a failure popup, we might want to reset the callState
    case actionTypes.ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE:
      return {
        ...state,
        userPassengerCallState:
          state.userPassengerCallState === CallState.Failed
            ? CallState.NotCalled
            : state.userPassengerCallState,
      };

    case actionTypes.CLOSE_SESSION:
      return {
        ...state,
        sessionCallState: CallState.InProcess,
      };
    case actionTypes.SET_SESSION:
      return {
        ...state,
        session: action.payload,
        sessionCallState: CallState.Success,
      };

    case actionTypes.CLOSE_SESSION_CALL_STATE_SUCCESS:
      return {
        ...initialState,
        // note: flightBookType should remain consistent throughout the booking workflow it's in;
        // for now, REDO_SEARCH should be the only action that can reset it
        flightBookType: state.flightBookType,
      };

    case actionTypes.CLOSE_SESSION_CALL_STATE_FAILED:
      return {
        ...state,
        session: undefined,
        sessionCallState: CallState.Failed,
      };

    /*
      note: scheduleQuoteSaga and schedulePaymentSaga are the only workflows where the session is set (e.g. SET_SESSION);
      whenever scheduleQuoteSaga has started, it will reset the session (e.g. CLOSE_SESSION) in the very beginning to avoid
      having any obsolete session being consumed (while scheduleQuoteSaga is still in-progress)
    */
    case actionTypes.SCHEDULE_QUOTE:
      return {
        ...state,
        priceQuote: action.preserveQuote ? state.priceQuote : null,
        priceDifferenceAcknowledged: action.preserveQuote
          ? state.priceDifferenceAcknowledged
          : false,
        schedulePriceQuoteError: null,
        schedulePriceQuoteCallState: CallState.InProcess,
        pollQuoteOnly: action.pollQuoteOnly,
        // note: reset the selected payment in the beginning of scheduleQuote to avoid having any incomplete payment
        selectedPaymentMethodId: undefined,
        rewardsAccountReferenceId: defaultRewardsAccountRefId,
        rewardsPaymentInFiatCurrency: null,
        rewardsPaymentTotal: null,
      };

    case actionTypes.SCHEDULED_QUOTE_CANCELLED:
      return {
        ...state,
        schedulePriceQuoteCallState: CallState.NotCalled,
      };

    case actionTypes.SCHEDULE_QUOTE_CALL_STATE_SUCCESS:
      return {
        ...state,
        schedulePriceQuoteError: null,
        schedulePriceQuoteCallState: CallState.Success,
      };

    case actionTypes.SCHEDULE_QUOTE_CALL_STATE_FAILED:
      return {
        ...state,
        schedulePriceQuoteCallState: CallState.Failed,
        schedulePriceQuoteError: action.invalid,
      };

    case actionTypes.POLL_QUOTE:
      return {
        ...state,
        priceQuoteErrors: [],
        pollPriceQuoteCallState: CallState.InProcess,
      };

    case actionTypes.SET_POLL_QUOTE_CALL_STATE_SUCCESS:
      return {
        ...state,
        pollPriceQuoteCallState: CallState.Success,
      };

    case actionTypes.SET_POLL_QUOTE_CALL_STATE_FAILED:
      return {
        ...state,
        pollPriceQuoteCallState: CallState.Failed,
        priceQuoteErrors: action.errors,
      };

    case actionTypes.SET_QUOTE:
      return {
        ...state,
        priceQuoteErrors: [],
        priceQuote: action.priceQuote,
        selectedPaymentMethodId: action.preservePayment
          ? state.selectedPaymentMethodId
          : undefined,
        rewardsAccountReferenceId: action.preservePayment
          ? state.rewardsAccountReferenceId
          : defaultRewardsAccountRefId,
        rewardsPaymentInFiatCurrency: action.preservePayment
          ? state.rewardsPaymentInFiatCurrency
          : null,
        rewardsPaymentTotal: action.preservePayment
          ? state.rewardsPaymentTotal
          : null,
      };

    case actionTypes.SET_QUOTE_REWARDS_TOTAL: {
      return {
        ...state,
        quotedRewardsTotal: action.quotedRewardsTotal,
      };
    }
    case actionTypes.SET_PRICE_QUOTE_SUCCESS_TIME: {
      return {
        ...state,
        priceQuoteSuccessTime: action.priceQuoteSuccessTime,
      };
    }
    case actionTypes.VALIDATE_PASSENGERS:
      return {
        ...state,
        passengerErrors: [],
        validatePassengersCallState: CallState.InProcess,
      };

    case actionTypes.PASSENGER_VALIDATION_CALL_STATE_SUCCESS:
      return {
        ...state,
        passengerErrors: [],
        validatePassengersCallState: CallState.Success,
      };

    case actionTypes.PASSENGER_VALIDATION_CALL_STATE_FAILED:
      return {
        ...state,
        validatePassengersCallState: CallState.Failed,
      };

    case actionTypes.SET_PASSENGERS_VALID:
      return {
        ...state,
        passengersValid: true,
        passengerErrors: [],
        validatePassengersCallState: CallState.Success,
      };

    case actionTypes.SET_PASSENGERS_INVALID:
      return {
        ...state,
        passengersValid: false,
        passengerErrors: action.errors,
        validatePassengersCallState: CallState.Success,
      };

    case actionTypes.FETCH_TRIP_PRICING:
      return {
        ...state,
        tripPricingErrors: [],
        tripPricingCallState: CallState.InProcess,
      };

    case actionTypes.FETCH_TRIP_PRICING_CALL_STATE_SUCCESS:
      return {
        ...state,
        tripPricingErrors: [],
        tripPricingCallState: CallState.Success,
      };

    case actionTypes.FETCH_TRIP_PRICING_CALL_STATE_FAILURE:
      return {
        ...state,
        tripPricingErrors: action.errors,
        tripPricingCallState: CallState.Failed,
      };

    case actionTypes.SET_TRIP_PRICING:
      return {
        ...state,
        tripPricing: action.tripPricing,
        tripPricingErrors: [],
        tripPricingCallState: CallState.Success,
      };

    case actionTypes.SCHEDULE_PAYMENT:
      return {
        ...state,
        schedulePaymentError: null,
        schedulePaymentCallState: CallState.InProcess,
        approvalRequestReason: action.approvalRequestReason,
      };

    case actionTypes.SET_SCHEDULE_PAYMENT_SUCCESS:
      return {
        ...state,
        schedulePaymentError: null,
        schedulePaymentCallState: CallState.Success,
      };

    case actionTypes.SET_SCHEDULE_PAYMENT_FAILED:
      return {
        ...state,
        schedulePaymentError: action.invalid,
        schedulePaymentCallState: CallState.Failed,
      };

    case actionTypes.SET_SCHEDULE_PAYMENT_NOT_CALLED:
      return {
        ...state,
        schedulePaymentCallState: CallState.NotCalled,
      };

    case actionTypes.REDO_SEARCH:
      return {
        ...initialState,
      };

    case actionTypes.POLL_FINALIZED:
      return {
        ...state,
        finalizedErrors: [],
        finalizedCallState: CallState.InProcess,
      };

    case actionTypes.POLL_FINALIZED_CALL_STATE_SUCCESS:
      return {
        ...state,
        finalizedCallState: CallState.Success,
      };

    case actionTypes.POLL_FINALIZED_CALL_STATE_FAILED:
      return {
        ...state,
        finalizedCallState: CallState.Failed,
        finalizedErrors: action.errors,
      };

    case actionTypes.POLL_FINALIZED_CALL_STATE_PENDING:
      return {
        ...state,
        finalizedCallState: CallState.InProcess,
      };

    case actionTypes.SET_FINALIZED:
      return {
        ...state,
        finalizedErrors: [],
        finalizedItinerary:
          action.product === Product.Flight
            ? (action.finalizeResult as PurchaseResult).itinerary
            : state.finalizedItinerary,
        finalizedPriceFreeze:
          action.product === Product.AirPriceFreeze
            ? (action.finalizeResult as PriceFreezeFlightPaymentFinalizeResult)
                .priceFreeze
            : state.finalizedPriceFreeze,
        finalizedPriceFreezeTripDetails:
          action.product === Product.AirPriceFreeze
            ? (action.finalizeResult as PriceFreezeFlightPaymentFinalizeResult)
                .frozenFlight
            : state.finalizedPriceFreezeTripDetails,
        finalizedPriceFreezeFare:
          action.product === Product.AirPriceFreeze
            ? (action.finalizeResult as PriceFreezeFlightPaymentFinalizeResult)
                .frozenFare
            : state.finalizedPriceFreezeFare,
        finalizedUserEmail:
          action.product === Product.AirPriceFreeze
            ? (action.finalizeResult as PriceFreezeFlightPaymentFinalizeResult)
                .attachedEmail
            : state.finalizedUserEmail,
        pollPriceQuoteCallState: CallState.Success,
      };

    case actionTypes.RESET_FINALIZED:
      return {
        ...state,
        finalizedErrors: initialState.finalizedErrors,
        finalizedItinerary: initialState.finalizedItinerary,
        finalizedPriceFreeze: initialState.finalizedPriceFreeze,
        finalizedPriceFreezeTripDetails:
          initialState.finalizedPriceFreezeTripDetails,
        finalizedPriceFreezeFare: initialState.finalizedPriceFreezeFare,
        finalizedUserEmail: initialState.finalizedUserEmail,
        pollPriceQuoteCallState: initialState.pollPriceQuoteCallState,
      };

    case actionTypes.ACKNOWLEDGE_PRICE_DIFFERENCE:
      return {
        ...state,
        priceDifferenceAcknowledged: true,
      };

    case actionTypes.SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID:
      return {
        ...state,
        rewardsAccountReferenceId: action.rewardsAccountReferenceId,
      };

    case actionTypes.SET_SELECTED_REWARDS_PAYMENT_TOTAL:
      if (
        action.accountReferenceId === DO_NOT_APPLY_REWARDS_KEY ||
        !action.rewardsPaymentTotal ||
        !action.rewardsPaymentInFiatCurrency
      ) {
        return {
          ...state,
          rewardsPaymentInFiatCurrency: null,
          rewardsPaymentTotal: null,
        };
      }

      const { total } = action;

      // This occurs when fetching rewards fails during search, don't allow the user to accidentally use all their rewards
      if (total && !total.accountSpecific[action.accountReferenceId]) {
        return {
          ...state,
          rewardsAccountReferenceId: DO_NOT_APPLY_REWARDS_KEY,
          rewardsPaymentInFiatCurrency: null,
          rewardsPaymentTotal: null,
        };
      }

      if (
        total &&
        total.accountSpecific[action.accountReferenceId].value <
          action.rewardsPaymentTotal.value
      ) {
        return {
          ...state,
          rewardsPaymentInFiatCurrency: total.fiat,
          rewardsPaymentTotal: total.accountSpecific[action.accountReferenceId],
        };
      }

      return {
        ...state,
        rewardsPaymentInFiatCurrency: action.rewardsPaymentInFiatCurrency,
        rewardsPaymentTotal: action.rewardsPaymentTotal,
      };

    case actionTypes.SET_PRODUCT_EARN_VALUE:
      return {
        ...state,
        productEarnValue: action.productEarnValue,
      };

    case actionTypes.REWARDS_CONVERSION_FAILED:
      return {
        ...state,
        rewardsConversionFailed: true,
      };

    case actionTypes.RESET_BOOKING_ERRORS:
      return {
        ...state,
        tripPricingCallState: CallState.NotCalled,
        validatePassengersCallState: CallState.NotCalled,
        finalizedCallState: CallState.NotCalled,
        verifyPaymentMethodCallState: CallState.NotCalled,
        verifyPaymentMethodResult: undefined,
        finalizedErrors: [],
        passengerErrors: [],
        rewardsConversionFailed: false,
        ...(action.isPostQuote
          ? {
              selectedPaymentMethodId: initialState.selectedPaymentMethodId,
            }
          : {
              schedulePriceQuoteCallState: CallState.NotCalled,
              pollPriceQuoteCallState: CallState.NotCalled,
              priceQuoteErrors: [],
            }),
      };
    case actionTypes.CHARGE_AGENT_BOOKING_FEE:
      return {
        ...state,
        chargeAgentBookingFeeCallState: CallState.InProcess,
      };
    case actionTypes.CHARGE_AGENT_BOOKING_FEE_FAILED:
      return {
        ...state,
        chargeAgentBookingFeeCallState: CallState.Failed,
      };
    case actionTypes.CHARGE_AGENT_BOOKING_FEE_SUCCESS:
      return {
        ...state,
        chargeAgentBookingFeeCallState: CallState.Success,
      };
    case actionTypes.FETCH_SEAT_MAP:
      return {
        ...state,
        seatMapLoading: true,
      };

    case actionTypes.SET_SEAT_MAP:
      return {
        ...state,
        seatMapLoading: false,
        seatMapHtml: action.seatMapHtml,
        seatMapFailed: false,
      };
    case actionTypes.SET_CHEAPEST_SEAT:
      return {
        ...state,
        seatMapLoading: false,
        cheapestSeat: action.cheapestSeat,
        seatMapFailed: false,
      };
    case actionTypes.FETCH_SEAT_MAP_FAILED:
      return {
        ...state,
        seatMapLoading: false,
        seatMapHtml: null,
        cheapestSeat: null,
        seatMapFailed: true,
      };
    case actionTypes.SET_SKIP_SEAT_SELECTION:
      return {
        ...state,
        skipSeatSelection: action.skipSelection,
      };
    case actionTypes.SET_SELECTED_SEATS:
      return {
        ...state,
        selectedSeats: action.selectedSeats,
      };
    case actionTypes.SET_FLIGHT_BOOK_TYPE:
      return {
        ...state,
        flightBookType: action.flightBookType,
      };
    case actionTypes.SET_UP_FLIGHT_BOOK_PARAMS:
      return {
        ...state,
        setFlightBookQueryParamsCallState: CallState.InProcess,
      };
    case actionTypes.SET_UP_FLIGHT_BOOK_PARAMS_FAILED:
      return {
        ...state,
        setFlightBookQueryParamsCallState: CallState.Failed,
      };
    case actionTypes.SET_UP_FLIGHT_BOOK_PARAMS_SUCCESS:
      return {
        ...state,
        setFlightBookQueryParamsCallState: CallState.Success,
      };
    case actionTypes.FETCH_PRICE_DROP_ELIGIBILITY:
      return {
        ...state,
        priceDropProtection: null,
        fetchPriceDropEligibilityCallState: CallState.InProcess,
      };
    case actionTypes.SET_PRICE_DROP_PROTECTION:
      return {
        ...state,
        priceDropProtection: action.priceDropProtection,
        fetchPriceDropEligibilityCallState:
          action.fetchPriceDropEligibilityCallState,
      };
    case actionTypes.SET_IS_SIMILAR_FLIGHTS_ENABLED:
      return {
        ...state,
        isSimilarFlightsEnabled: action.isSimilarFlightsEnabled,
      };
    case actionTypes.SET_INFANT_ONLY_ERROR: {
      return {
        ...state,
        infantOnlyError: action.infantOnlyError,
      };
    }
    case actionTypes.SET_BEST_OFFER_OVERALL:
      return {
        ...state,
        bestOfferOverall: action.offer,
      };
    case actionTypes.SET_OFFER_TO_APPLY:
      return {
        ...state,
        offerToApply: action.offer,
      };
    case actionTypes.SET_TRAVEL_WALLET_OFFERS:
      return {
        ...state,
        offers: action.offers,
      };
    case actionTypes.SET_CREDIT_TO_APPLY:
      return {
        ...state,
        creditToApply: action.credit,
      };
    case actionTypes.SET_TRAVEL_WALLET_CREDIT:
      return {
        ...state,
        credit: action.credit,
      };
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.InProcess,
      };
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.Success,
      };
    case actionTypes.FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE:
      return {
        ...state,
        fetchApplicableTravelWalletItemsCallState: CallState.Failed,
      };

    case actionTypes.SET_SUBMIT_FOR_APPROVAL_FAILURE:
      return {
        ...state,
        submitForApprovalError: action.invalid,
        submitForApprovalCallState: CallState.Failed,
      };
    case actionTypes.SET_SUBMIT_FOR_APPROVAL_SUCCESS:
      return {
        ...state,
        submitForApprovalCallState: CallState.Success,
        submitForApprovalError: null,
      };
    case actionTypes.FETCH_CORP_USER_PASSENGERS:
      return {
        ...state,
        userPassengerCallState: CallState.InProcess,
      };
    case actionTypes.SET_TRIP_PURPOSE:
      return {
        ...state,
        tripPurpose: action.tripPurpose,
      };
    default:
      return state;
  }
}

export * from "./selectors";
