import React, { useState, useEffect, useMemo } from "react";
import { HotelCrossSell } from "halifax";

import {
  HotelEntryTypeEnum,
  IIdLodgings,
  Lodging,
  ProductFeedTileTappedProperties,
  ProductFeedViewedProperties,
  PRODUCT_FEED_TILE_TAPPED,
  PRODUCT_FEED_VIEWED_EVENT,
  LodgingCollectionEnum,
} from "redmond";
import { FlightToHotelCrossSellConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import {
  useExperiments,
  getExperimentVariantCustomVariants,
  getExperimentVariant,
  AVAILABLE,
  ActiveExperiments,
  LODGING_PROMOTIONS_VARIANTS,
  LODGING_PROMOTIONS_AVAILABLE,
  HOTEL_CROSS_SELL_V3_VARIANTS,
  CONTROL,
} from "../../../../../../context/experiments";
import {
  HOTEL_PATH_AVAILABILITY,
  HOTEL_PATH_SHOP,
} from "../../../../../../utils/paths";
import {
  transformToStringifiedAvailabilityQuery,
  transformToStringifiedQuery,
} from "./queryStringHelpers";
import { trackEvent } from "../../../../../../api/v1/analytics/trackEvent";
import "./styles.scss";

export interface IFlightToHotelCrossSellProps
  extends FlightToHotelCrossSellConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
}
export const FlightToHotelCrossSell = (props: IFlightToHotelCrossSellProps) => {
  const [lodgingsToDisplay, setLodgingsToDisplay] = useState<Lodging[]>([]);
  const {
    lodgings,
    searchLocationResult,
    fromDate,
    untilDate,
    largestValueAccount,
    isMobile,
    hotelShopParams,
    history,
    setSelectedLodgingIndex,
    adultsCount,
    children,
    viewedHotelListProperties,
    latency,
    firstCrossSellOffer,
  } = props;

  if (!lodgings?.length || !fromDate || !untilDate || !largestValueAccount)
    return null;

  const expState = useExperiments();

  const lodgingPromotions = getExperimentVariantCustomVariants(
    expState.experiments,
    ActiveExperiments.LODGING_PROMOTIONS,
    LODGING_PROMOTIONS_VARIANTS
  );
  const isLodgingPromotionsExperiment = useMemo(
    () => lodgingPromotions === LODGING_PROMOTIONS_AVAILABLE,
    [lodgingPromotions]
  );

  const showEarnEnhancement =
    !!largestValueAccount && !!largestValueAccount.earn.hotelsMultiplier;

  const hotelCrossSellV3Experiment = getExperimentVariantCustomVariants(
    expState.experiments,
    ActiveExperiments.HOTEL_CROSS_SELL_V3_EXPERIMENT,
    HOTEL_CROSS_SELL_V3_VARIANTS
  );
  const isHotelCrossSellV3Experiment = useMemo(
    () => hotelCrossSellV3Experiment !== CONTROL,
    [hotelCrossSellV3Experiment]
  );

  const premierCollectionExperiment = getExperimentVariant(
    expState.experiments,
    ActiveExperiments.PREMIER_COLLECTION_EXPERIMENT
  );
  const isPremierCollectionEnabled = useMemo(
    () => premierCollectionExperiment === AVAILABLE,
    [premierCollectionExperiment]
  );

  const cacheHotelTokenExperiment = getExperimentVariant(
    expState.experiments,
    ActiveExperiments.CACHE_HOTEL_TOKEN
  );
  const isCacheHotelTokenEnabled = useMemo(
    () => cacheHotelTokenExperiment === AVAILABLE,
    [cacheHotelTokenExperiment]
  );

  useEffect(() => {
    const filteredLodgings = lodgings.filter(
      (l) => l.lodgingCollection !== LodgingCollectionEnum.Premier
    );
    if (isMobile && filteredLodgings.length > 5) {
      setLodgingsToDisplay(filteredLodgings.slice(0, 5));
    } else if (!isMobile && filteredLodgings.length > 8) {
      setLodgingsToDisplay(filteredLodgings.slice(0, 8));
    } else {
      setLodgingsToDisplay(filteredLodgings);
    }
  }, [lodgings, isMobile]);

  useEffect(() => {
    if (lodgingsToDisplay.length) {
      const xSellProps: ProductFeedViewedProperties = {
        feed_placement: "mytrips",
        feed_type: "cross_sell_hotels",
        latency: latency!,
        tile_type: "cross_sell_hotel",
      };
      trackEvent({
        eventName: PRODUCT_FEED_VIEWED_EVENT,
        properties: {
          ...xSellProps,
        },
      });
    }
  }, [lodgingsToDisplay]);

  const onViewAllHotelsCTAClick = () => {
    const xSellProps: ProductFeedTileTappedProperties = {
      feed_type: "cross_sell",
      tile_type: "cross_sell_hotel",
      feed_placement: "my_trips",
    };
    trackEvent({
      eventName: PRODUCT_FEED_TILE_TAPPED,
      properties: {
        ...viewedHotelListProperties.properties,
        ...xSellProps,
      },
      encryptedProperties: [...viewedHotelListProperties.encryptedProperties],
    });
    history.push(
      `${HOTEL_PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
        (searchLocationResult?.id as IIdLodgings).lodgingSelection.searchTerm,
        fromDate,
        untilDate,
        adultsCount,
        children
      )}`
    );
  };

  const onHotelCardClick = (lodgingData: Lodging, index: number) => {
    const { isPreferred, lodging } = lodgingData;
    const available =
      typeof lodgingData.available === "undefined"
        ? true
        : lodgingData.available;
    const xSellProps: ProductFeedTileTappedProperties = {
      feed_type: "cross_sell",
      tile_type: "cross_sell_hotel",
      feed_placement: "my_trips",
    };
    if (available) {
      trackEvent({
        eventName: PRODUCT_FEED_TILE_TAPPED,
        properties: {
          ...viewedHotelListProperties.properties,
          ...lodgingData?.trackingPropertiesV2?.properties,
          is_preferred_cot: isPreferred,
          lodging_row_index: index,
          is_pc:
            lodgingData.lodgingCollection === LodgingCollectionEnum.Premier,
          is_lc:
            lodgingData.lodgingCollection === LodgingCollectionEnum.Lifestyle,
          ...xSellProps,
        },
        encryptedProperties: [
          ...viewedHotelListProperties.encryptedProperties,
          lodgingData?.trackingPropertiesV2?.encryptedProperties ?? "",
        ],
      });
      setSelectedLodgingIndex(index);
      const params = transformToStringifiedQuery({
        lodgingId: lodging.id,
        lodgingSelection: (searchLocationResult?.id as IIdLodgings)
          .lodgingSelection,
        ...hotelShopParams,
        selectedLodgingIndex: index,
        entryPoint: HotelEntryTypeEnum.MY_TRIPS,
        shopToken: isCacheHotelTokenEnabled
          ? lodgingData.price?.opaqueShopRequest
          : undefined,
      });

      if (isMobile) history.push(`${HOTEL_PATH_SHOP}${params}`);
      else window.open(`${HOTEL_PATH_SHOP}${params}`, "_blank");
    }
  };

  {
    return lodgingsToDisplay?.length ? (
      <HotelCrossSell
        {...props}
        lodgingsToDisplay={lodgingsToDisplay}
        onViewAllHotelsCTAClick={onViewAllHotelsCTAClick}
        onHotelCardClick={onHotelCardClick}
        showEarnEnhancement={showEarnEnhancement}
        isLodgingPromotionsExperiment={isLodgingPromotionsExperiment}
        className="my-trips-cross-sell"
        isPremierCollectionEnabled={isPremierCollectionEnabled}
        bannerText={
          firstCrossSellOffer ? firstCrossSellOffer.description : undefined
        }
        isHotelCrossSellV3={isHotelCrossSellV3Experiment}
      />
    ) : null;
  }
};
