import dayjs from "dayjs";
import { HotelEntryTypeEnum, LodgingSelection } from "redmond";

export interface HotelShopQuery {
  lodgingId: string;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  children: number[];
  selectedAccountIndex?: number;
  selectedLodgingIndex: number;
  lodgingSelection?: LodgingSelection;
  entryPoint?: HotelEntryTypeEnum;
  shopToken?: string;
}

export const transformToStringifiedQuery = ({
  lodgingId,
  fromDate,
  untilDate,
  adultsCount = 2,
  children = [],
  selectedAccountIndex = 0,
  selectedLodgingIndex,
  lodgingSelection,
  entryPoint,
  shopToken,
}: HotelShopQuery): string => {
  if (fromDate === null || untilDate === null) {
    return `?lodgingId=${lodgingId}`;
  }

  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  const childrenParams = children?.reduce(
    (ageString, age) => `${ageString}&children=${age}`,
    ""
  );

  const lodgingSelectionParam = encodeURIComponent(
    JSON.stringify(lodgingSelection)
  );
  let string = "";

  string = `?lodgingId=${lodgingId}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}${childrenParams}&selectedAccountIndex=${selectedAccountIndex}&lodgingSelection=${lodgingSelectionParam}`;

  if (selectedLodgingIndex != null) {
    string += `&selectedLodgingIndex=${selectedLodgingIndex}`;
  }
  if (entryPoint) {
    string += `&entryPoint=${entryPoint}`;
  }
  if (shopToken) {
    string += `&shopToken=${shopToken}`;
  }
  return string;
};

export const transformToStringifiedAvailabilityQuery = (
  location: string,
  fromDate: Date | null,
  untilDate: Date | null,
  adultsCount: number | null,
  children: number[] | null
): string => {
  location = encodeURIComponent(location);
  if (fromDate === null || untilDate === null) {
    return `?locationName=${location}`;
  }

  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  const childrenParams = children?.reduce(
    (ageString, age) => `${ageString}&children=${age}`,
    ""
  );

  return `?locationName=${location}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}${childrenParams}&entryPoint=${HotelEntryTypeEnum.MY_TRIPS}`;
};
