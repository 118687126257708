// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5J2iE28xpbJWg8BcunAViQ\\=\\={margin-top:10px}.LSGUZD8hNi0MpXe5pdPV-Q\\=\\={display:grid;grid-template-columns:1fr 1fr;margin-top:30px;gap:10px}.LSGUZD8hNi0MpXe5pdPV-Q\\=\\= .fOIc5eHfk2mB2aZKYKh9yA\\=\\={width:12px;height:20px}.LSGUZD8hNi0MpXe5pdPV-Q\\=\\= .fOIc5eHfk2mB2aZKYKh9yA\\=\\= path{fill:#0276b1}.LSGUZD8hNi0MpXe5pdPV-Q\\=\\= .omEASXGvfASmKoOvtZ40BA\\=\\={display:flex;flex-direction:column;align-items:center;border:.5px solid #cecfcd;border-radius:4px;padding:20px;gap:5px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/experiences-module/src/modules/shop/components/ShopContactInformation/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,eAAA,CAGF,4BACE,YAAA,CACA,6BAAA,CACA,eAAA,CACA,QAAA,CAEA,wDACE,UAAA,CACA,WAAA,CAEA,6DACE,YAAA,CAIJ,wDACE,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,yBAAA,CACA,iBAAA,CACA,YAAA,CACA,OAAA","sourcesContent":[".description-container {\n  margin-top: 10px;\n}\n\n.contact-methods-container {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  margin-top: 30px;\n  gap: 10px;\n\n  .phone-icon {\n    width: 12px;\n    height: 20px;\n\n    path {\n      fill: #0276B1\n    }\n  }\n\n  .contact-method-container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    border: 0.5px solid #CECFCD;\n    border-radius: 4px;\n    padding: 20px;\n    gap: 5px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"description-container": "_5J2iE28xpbJWg8BcunAViQ==",
	"contact-methods-container": "LSGUZD8hNi0MpXe5pdPV-Q==",
	"phone-icon": "fOIc5eHfk2mB2aZKYKh9yA==",
	"contact-method-container": "omEASXGvfASmKoOvtZ40BA=="
};
export default ___CSS_LOADER_EXPORT___;
