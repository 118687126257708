import { takeLatest } from "redux-saga/effects";
import { actionTypes } from "../actions";
import { fetchExperiencesShopSaga } from "./fetchExperiencesShopSaga";
import { fetchExperiencesShopReviewsSaga } from "./fetchExperiencesShopReviewsSaga";
import { fetchExperiencesShopOptionsSaga } from "./fetchExperiencesShopOptionsSaga";

export function* watchFetchExperiencesShop() {
  yield takeLatest(
    actionTypes.FETCH_EXPERIENCES_SHOP,
    fetchExperiencesShopSaga
  );
}

export function* watchFetchExperiencesShopOptions() {
  yield takeLatest(
    actionTypes.FETCH_EXPERIENCES_SHOP_OPTIONS,
    fetchExperiencesShopOptionsSaga
  );
}

export function* watchFetchExperiencesShopReviews() {
  yield takeLatest(
    actionTypes.FETCH_EXPERIENCES_SHOP_REVIEWS,
    fetchExperiencesShopReviewsSaga
  );
}
