import { put, select } from "redux-saga/effects";
import isEqual from "lodash-es/isEqual";
import { CallState, IPerson } from "redmond";
import { actions } from "../../actions";
import { updateUserPassengersMultiple } from "../../../../api/v0/book/user-passengers/capone-corporate/updateUserPassengersMultiple";
import {
  fetchUserPassengers,
  IListUserPassengersResponse,
} from "../../../../api/v0/book/user-passengers/fetchUserPassengers";
import { getUserPassengers } from "../../reducer";

export function* updateUserPassengersMultipleSaga(
  action: actions.IUpdateUserPassengersMultiple
) {
  try {
    const persons = action.payload;
    const currentTravelers: IPerson[] = yield select(getUserPassengers);

    const passengersToUpdate = persons.filter((person) => {
      const existingTraveler = currentTravelers.find(
        (traveler) => traveler.id === person.id
      );
      return !isEqual(person, existingTraveler);
    });

    if (passengersToUpdate.length > 0) {
      yield updateUserPassengersMultiple({
        persons: passengersToUpdate,
      });
    }

    const userPassengers: IListUserPassengersResponse =
      yield fetchUserPassengers({});

    yield put(
      actions.setUserPassengers({
        userPassengerCallState: CallState.Success,
        userPassengers: userPassengers,
      })
    );
  } catch (e) {
    yield put(actions.setUserPassengersCallStateFailed());
  }
}
