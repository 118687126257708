import {
  Cfar,
  LodgingAvailabilityCheckInTime,
  LodgingAvailabilityCheckInTimeEnum,
} from "redmond/hotels-module/interfaces";
import dayjs from "dayjs";
import { getTotalPriceText } from "halifax";

export const BACK_TO_RESULTS = "Back to results";

export const getEarnText = (
  earnRate: string | number,
  productDisplayName: string,
  currency: string
) => {
  const multiplier = currency.includes("cash") ? "%" : "x";
  return `Plus, earn <span class="font-bold">${earnRate}${multiplier} ${currency}</span> on hotels with your ${productDisplayName} account.`;
};

export const getMinNightStayBannerText = (minNightOfStay: number) => {
  return `This property requires a <strong>minimum ${minNightOfStay}-night</strong> stay to receive Premier Collection benefits. If you're staying for 1 night, you'll still earn 10X miles through Capital One Travel.`;
};

export const CHECK_IN_INSTRUCTIONS_TITLE =
  "Scheduled check-in and check-out time";

export const formattedCheckIn = (
  date: Date,
  time?: LodgingAvailabilityCheckInTime
) => {
  return `<strong>Check-in:</strong> ${dayjs(date).format(
    "dddd, MMMM D, YYYY"
  )}${
    time && time.CheckInTime == LodgingAvailabilityCheckInTimeEnum.Restricted
      ? " at " + dayjs(time.checkInTimeFrom, "HH:mm").format("h:mm A")
      : ""
  }`;
};

export const formattedCheckOut = (date: Date, time?: string) =>
  `<strong>Checkout:</strong> ${dayjs(date).format("dddd, MMMM D, YYYY")}${
    time ? " at " + dayjs(time, "HH:mm").format("h:mm A") : ""
  }`;

export const CALIFORNIA_BILL_644_CANCELLATION_TEXT = `This hotel is in California, therefore you are entitled to free cancellation without penalty for 24 hours after booking. If you choose to change or cancel this booking after this 24 hour window, you will not be refunded any of the payment.`;

export const getCfarSecondaryText = (cfarCancellation: Cfar) =>
  `Refund amount: ${getTotalPriceText({
    price: cfarCancellation.refundAmount.fiat,
  })}`;
