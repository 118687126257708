import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName } from "halifax";

import styles from "./styles.module.scss";
import { ShopComponentSkeleton } from "../../component";
import { ExperienceInclusions } from "redmond";

export interface IShopWhatsIncludedProps {
  isSkeleton: boolean;
  additionalInformation?: Array<string>;
  knowBeforeYouBook?: Array<string>;
  inclusionsAndExclusions?: ExperienceInclusions;
}

const Skeleton = () => (
  <Box className="experiences-shop-skeleton">
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
    </Box>
  </Box>
);

export const ShopWhatsIncluded = (props: IShopWhatsIncludedProps) => {
  const {
    isSkeleton,
    additionalInformation = [],
    knowBeforeYouBook = [],
    inclusionsAndExclusions,
  } = props;

  if (isSkeleton) {
    return <Skeleton />;
  }

  const { exclusions = [], inclusions = [] } = inclusionsAndExclusions ?? {};

  return (
    <Box>
      <Box className={styles["icon-content-list"]}>
        {inclusions.map((inclusion) => (
          <Box key={inclusion} className={styles["icon-description-container"]}>
            <Icon name={IconName.CheckCircleFilledGreen} />
            <Typography variant="body2">{inclusion}</Typography>
          </Box>
        ))}
        {exclusions.map((exclusion) => (
          <Box key={exclusion} className={styles["icon-description-container"]}>
            <Icon name={IconName.NotAllowedSign} />
            <Typography variant="body2">{exclusion}</Typography>
          </Box>
        ))}
      </Box>
      {knowBeforeYouBook.length >= 1 && (
        <Box className={styles["segment-container"]}>
          <Typography variant="h6">Know Before You Book</Typography>
          <ul>
            {knowBeforeYouBook.map((item) => (
              <li key={item}>
                <Typography variant="body2">{item}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
      {additionalInformation.length >= 1 && (
        <Box className={styles["segment-container"]}>
          <Typography variant="h6">Additional Information</Typography>
          <ul>
            {additionalInformation.map((item) => (
              <li key={item}>
                <Typography variant="body2">{item}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
    </Box>
  );
};
