import React, { Fragment, useEffect, useState } from "react";
import { Button, Divider, Typography } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { CallState, IFlyerOption, IPerson, ITravelerStepErrors } from "redmond";
import {
  LoyaltyInfoFormComponentProps,
  TravelerSelectDropdown,
  TravelerSelectStep,
} from "halifax";
import { FlightTravelerSelectDropdownFormConnectorProps } from "./container";
import { FrequentFlyerForm } from "../FrequentFlyerForm/component";
import { getFrequentFlyerProgramFromTrip } from "./utils";
import "./styles.scss";
import { InfantSeatPickerModal } from "../../InfantSeatPickerModal";
import { useFlightBookPassengerSelect } from "../../FlightBookPassengerSelection";
import { getIsLapInfant } from "../../FlightBookPassengerSelection/useFlightBookPassengerSelect";

export interface FlightTravelerSelectDropdownFormProps
  extends FlightTravelerSelectDropdownFormConnectorProps {
  disableEditTravelerInfo: boolean;
  selectedTravelers: IPerson[];
  updateSelectedTravelersList: (traveler: IPerson) => void;
  removeSelectedTraveler: (traveler: IPerson) => void;
  setProgress: (progress: TravelerSelectStep) => void;
  showErrors: ITravelerStepErrors;
  setShowErrors: (showErrors: ITravelerStepErrors) => void;
}

export const FlightTravelerSelectDropdownForm = ({
  disableEditTravelerInfo,
  selectedTravelers,
  updateSelectedTravelersList,
  updateUserPassenger,
  setProgress,
  removeSelectedTraveler,
  showErrors,
  setShowErrors,
  userPassengerCallState,
  fetchCorpUserPassengers,
  tripDetails,
  setUserSelectedPassengerIds,
  setUserSelectedLapInfantIds,
}: FlightTravelerSelectDropdownFormProps) => {
  const [dropdownCount, setDropdownCount] = useState(
    selectedTravelers.length || 1
  );

  const {
    selectedTravelerIds: selectedAdultTravelerIds,
    selectedLapInfantIds,
    travelers,
    viewedTripSummaryProperties,
    currentInfantToSelectSeat,
    setCurrentInfantToSelectSeat,
    handleSelectedTravelerIds,
    tripFinalFlightDate,
  } = useFlightBookPassengerSelect();

  useEffect(() => {
    fetchCorpUserPassengers();
  }, []);

  useEffect(() => {
    if (disableEditTravelerInfo) {
      setDropdownCount(selectedTravelers.length);
    }
  }, [disableEditTravelerInfo]);

  const frequentFlyerPrograms = getFrequentFlyerProgramFromTrip(tripDetails);

  const selectedTravelerIds = [
    ...selectedAdultTravelerIds,
    ...selectedLapInfantIds,
  ];

  const updateSelectedTraveler = (traveler: IPerson) => {
    updateSelectedTravelersList(traveler);
    // note: we need to call this function used in the old FlightBookPassengerSelection component
    // to hook up the rest of the booking flow with the existing infrastructure
    handleSelectedTravelerIds([...selectedTravelerIds, traveler.id]);
  };

  return (
    <div className="traveler-select-dropdown-container">
      <div className="form-container">
        <Typography variant="h3" className="title">
          Select or add travelers
        </Typography>
        <Typography variant="body2" component="p" color="textPrimary">
          Select or add travelers for this flight booking.
        </Typography>
        {[...Array(dropdownCount)].map((_, index) => {
          return (
            <Fragment key={index}>
              {index > 0 && <Divider style={{ margin: "20px 0" }} />}
              <TravelerSelectDropdown
                travelers={travelers}
                disableEditTravelerInfo={disableEditTravelerInfo}
                selectedTravelers={selectedTravelers}
                updateSelectedTraveler={updateSelectedTraveler}
                removeSelectedTraveler={(traveler) => {
                  removeSelectedTraveler(traveler);
                  const travelerIds = selectedTravelerIds.filter(
                    (id) => id !== traveler.id
                  );
                  handleSelectedTravelerIds(travelerIds);
                  setDropdownCount((prevCount) =>
                    prevCount === 1 ? 1 : prevCount - 1
                  );
                }}
                setProgress={setProgress}
                index={index}
                updateUserPassenger={(payload, isNewTraveler) => {
                  updateUserPassenger(
                    payload,
                    false,
                    false,
                    false,
                    isNewTraveler
                  );
                  if (getIsLapInfant(payload.person, tripFinalFlightDate)) {
                    setCurrentInfantToSelectSeat(payload.person.id);
                  }
                }}
                showErrors={showErrors}
                setShowErrors={setShowErrors}
                loyaltyProgram={null}
                loading={userPassengerCallState === CallState.InProcess}
                renderLoyaltyComponent={
                  frequentFlyerPrograms !== null
                    ? getRenderLoyaltyComponent(frequentFlyerPrograms)
                    : undefined
                }
                productType="flight"
              />
            </Fragment>
          );
        })}

        <Button
          variant="outlined"
          color="secondary"
          className="add-traveler-button"
          startIcon={<Add />}
          disabled={
            dropdownCount > Object.keys(selectedTravelers).length ||
            disableEditTravelerInfo
          }
          onClick={() => setDropdownCount((prevCount) => prevCount + 1)}
        >
          Add additional traveler
        </Button>
      </div>
      <InfantSeatPickerModal
        currentInfantToSelectSeat={currentInfantToSelectSeat}
        setCurrentInfantToSelectSeat={setCurrentInfantToSelectSeat}
        userSelectedPassengerIds={selectedTravelerIds}
        userSelectedLapInfantIds={selectedLapInfantIds}
        setUserSelectedPassengerIds={(ids: string[]) => {
          setUserSelectedPassengerIds({
            userSelectedPassengerIds: ids,
            multiTicketType: viewedTripSummaryProperties?.multi_ticket_type,
          });
        }}
        setUserSelectedLapInfantIds={(ids: string[]) => {
          setUserSelectedLapInfantIds({
            userSelectedLapInfantIds: ids,
          });
        }}
      />
    </div>
  );
};

const getRenderLoyaltyComponent =
  (frequentFlyerPrograms: IFlyerOption[]) =>
  ({ onChange, disabled, traveler }: LoyaltyInfoFormComponentProps) =>
    (
      <FrequentFlyerForm
        onChange={(flyerMap) => onChange(undefined, flyerMap)}
        disabled={disabled}
        traveler={traveler}
        frequentFlyerPrograms={frequentFlyerPrograms}
      />
    );
