import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getUntilDate,
} from "../../../search/reducer";
import { IStoreState } from "../../../../reducers/types";
import { MobileShopDetailsHeader } from "./component";
import { actions } from "../../../search/actions";
import { getOpenDatesModal } from "../../../availability/reducer";
import { setOpenDatesModal } from "../../../availability/actions/actions";
import { getPremierCollectionShopDetailsSelectedLodgingData } from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    openDatesModal: getOpenDatesModal(state),
    shopDetailsLodgingData: getPremierCollectionShopDetailsSelectedLodgingData(state),
  };
};

const mapDispatchToProps = {
  setFromDate: actions.setFromDate,
  setUntilDate: actions.setUntilDate,
  setOpenDatesModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopDetailsHeaderConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShopDetailsHeader = withRouter(
  connector(MobileShopDetailsHeader)
);
