import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import { actions } from "../../actions";
import {
  getPremierCollectionShopDetailsChosenRoomInfo,
  getPremierCollectionShopDetailsChosenRoomInfoIndex,
  getPremierCollectionShopDetailsRoomsInfo,
  getPremierCollectionShopDetailsSelectedLodgingData,
} from "../../reducer/selectors";
import { RoomInfoPicker } from "./component";
import {
  getFromDate,
  getUntilDate,
} from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    chosenRoomInfoIndex: getPremierCollectionShopDetailsChosenRoomInfoIndex(state),
    chosenRoomInfo: getPremierCollectionShopDetailsChosenRoomInfo(state),
    lodgingData: getPremierCollectionShopDetailsSelectedLodgingData(state),
    roomsInfo: getPremierCollectionShopDetailsRoomsInfo(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
  };
};

const mapDispatchToProps = {
  selectRoomType: actions.selectRoomType,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RoomInfoPickerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRoomInfoPicker = withRouter(
  connector(RoomInfoPicker)
);
