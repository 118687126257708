import { ExperiencesShopRequest, ExperiencesShopResponse } from "redmond";
import { call, put, select } from "redux-saga/effects";
import { actions } from "../actions";
import Logger from "../../../utils/logger";
import dayjs from "dayjs";
import * as H from "history";
import queryStringParser from "query-string";
import { actions as searchActions } from "../../search/actions";
import { PATH_HOME } from "../../../utils/paths";
import { fetchExperienceShop } from "../../../api/v0/shop/fetchExperiencesShop";
import { IStoreState } from "../../../reducers/types";
import { getFromDate, getUntilDate } from "../../search/reducer";
import { getExperienceId } from "../reducer";

export function* fetchExperiencesShopSaga(
  action: actions.IFetchExperiencesShop
) {
  try {
    const requestData: ExperiencesShopRequest = yield call(
      getShopRequestParameters,
      action
    );

    const shopResponse: ExperiencesShopResponse = yield fetchExperienceShop(
      requestData
    );
    yield put(
      actions.setExperiencesShopDetails({
        response: shopResponse,
      })
    );
  } catch (e) {
    yield put(actions.setExperiencesShopDetailsCallStateFailed());
    Logger.debug(e);
  }
}

const getExistingStateVariables = (state: IStoreState) => {
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    experienceId: getExperienceId(state),
  };
};

function* getShopRequestParameters(
  fetchExperiencesShop: actions.IFetchExperiencesShop
) {
  const state: IStoreState = yield select();
  let { fromDate, untilDate, experienceId } = getExistingStateVariables(state);

  const history = fetchExperiencesShop.history;

  const parsedQueryString = parseQueryString(history);

  if (!fromDate) {
    fromDate = parsedQueryString.fromDate;
    yield put(
      searchActions.setFromDate(dayjs(parsedQueryString.fromDate).toDate())
    );
  }

  if (!untilDate) {
    untilDate = parsedQueryString.untilDate;
    yield put(
      searchActions.setUntilDate(dayjs(parsedQueryString.untilDate).toDate())
    );
  }

  if (!experienceId) {
    experienceId = { value: parsedQueryString.experienceId };
    yield put(
      actions.setSelectedExperience({
        value: parsedQueryString.experienceId,
      })
    );
  }

  const shopRequestData: ExperiencesShopRequest = {
    id: {
      value: experienceId.value,
    },
    dateRange: {
      from: dayjs(fromDate).format("YYYY-MM-DD"),
      until: dayjs(untilDate).format("YYYY-MM-DD"),
    },
  };

  return shopRequestData;
}

export interface IExperiencesShopParsedQuery {
  fromDate: Date | null;
  untilDate: Date | null;
  experienceId: string;
}

const parseQueryString = (history: H.History): IExperiencesShopParsedQuery => {
  const queryString = history?.location?.search || "";

  const { experienceId, fromDate, untilDate } =
    queryStringParser.parse(queryString);

  if (!experienceId || !fromDate || !untilDate) {
    history.push(PATH_HOME);
  }

  return {
    fromDate: dayjs(fromDate as string).toDate(),
    untilDate: dayjs(untilDate as string).toDate(),
    experienceId: experienceId as string,
  };
};
