import React from "react";
import { Box, Typography } from "@material-ui/core";
import { Icon, IconName, useDeviceTypes } from "halifax";
import "./styles.scss";
import clsx from "clsx";
import { getPortalName } from "@capone/common";

export const PassportAwarenessCard = () => {
  const { matchesMobile } = useDeviceTypes();
  return (
    <Box
      className={clsx("passport-awareness-card-root", {
        mobile: matchesMobile,
      })}
    >
      <Box className="passport-awareness-card-flex-container">
        <Icon name={IconName.Passport} />
        <Box>
          <Typography variant="body1" className="passport-awareness-title">
            {`The airline operating this international flight may require you to
            provide your passport details through ${getPortalName()} to complete
            your booking.`}
          </Typography>
          {!matchesMobile && (
            <Typography variant="body2" className="passport-awareness-subtitle">
              Add passport details to existing travelers by editing their
              information, or include passport details while adding a new
              traveler.
            </Typography>
          )}
        </Box>
      </Box>
      {matchesMobile && (
        <Typography variant="body2" className="passport-awareness-subtitle">
          Add passport details to existing travelers by editing their
          information, or include passport details while adding a new traveler.
        </Typography>
      )}
    </Box>
  );
};
