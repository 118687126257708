import React from "react";
import { ButtonWrap, Icon, IconName, SliderDropdown } from "halifax";
import "./styles.scss";
import {
  DURATION_CONTENT_HEADING_TEXT,
  DURATION_DROPDOWN_LABEL_TEXT,
  DURATION_POPOVER_HEADING_TEXT,
} from "./textConstants";
import { debounce } from "lodash-es";

import clsx from "clsx";
import { initialFilterOptions } from "../../../../reducer";
import { generateFilterTrackingEvent } from "../../../../../search/actions/actions";
import { trackEvent } from "../../../../../../api/v0/trackEvent";

interface DurationSelectionProps {
  durationFilter: number;
  setDurationFilter: (duration: number) => void;
  minDuration: number;
  maxDuration: number;
  flightCount: number;
  appliedLabel?: string;
  showDropdownContentOnly?: boolean;
  isMobile?: boolean;
}

export const DurationSelection = ({
  durationFilter,
  setDurationFilter,
  minDuration,
  maxDuration,
  flightCount,
  appliedLabel,
  showDropdownContentOnly,
  isMobile,
}: DurationSelectionProps) => {
  const [isMinValue, setIsMinValue] = React.useState<boolean>(false);
  const debouncedAction = debounce((duration: number) => {
    setDurationFilter(duration);
    trackEvent(generateFilterTrackingEvent("duration", "list"));
  }, 300);
  const [stateDebounceDispatchAction] = React.useState(() =>
    debounce(debouncedAction, 300, {
      leading: false,
      trailing: true,
    })
  );

  const checkIfMinValue = (value: number) => {
    if (!showDropdownContentOnly) {
      if (value === initialFilterOptions.durationFilter) {
        setIsMinValue(false);
      } else {
        const sliderValuePercentage =
          (value - roundedMin) / (roundedMax - roundedMin);

        setIsMinValue(sliderValuePercentage <= 0.05);
      }
    }
  };

  const handleChange = (newValue: number) => {
    stateDebounceDispatchAction(newValue);

    checkIfMinValue(newValue);
  };

  const handleReset = () => {
    setDurationFilter(initialFilterOptions.durationFilter);
    setIsMinValue(false);
  };

  const roundedMin = React.useMemo(
    () => minDuration + (60 - (minDuration % 60)), // round up to nearest hour
    [minDuration]
  );
  const roundedMax = React.useMemo(
    () => maxDuration + (60 - (maxDuration % 60)),
    [maxDuration]
  );

  React.useEffect(() => {
    if (durationFilter >= maxDuration) {
      handleReset();
    }

    checkIfMinValue(durationFilter);
  }, [durationFilter]);

  const sliderLabelFormat = React.useCallback(
    (newValue: number) => {
      return newValue < maxDuration ? `Under ${newValue / 60}h` : "Any";
    },
    [maxDuration]
  );

  const minMaxLabelFormat = React.useCallback((newValue: number) => {
    return `${newValue / 60}h`;
  }, []);

  const durationFilterToShow = durationFilter || roundedMax;

  return (
    <div className="flight-shop-duration-filter-container" tabIndex={0}>
      {showDropdownContentOnly && (
        <div className="header-container">
          <Icon name={IconName.StopwatchOutline} />
          <div className="titles-wrapper">{DURATION_CONTENT_HEADING_TEXT}</div>
        </div>
      )}
      <SliderDropdown
        className={clsx("b2b-shop-filter", "duration", {
          "has-value": !!durationFilter,
          "content-only": showDropdownContentOnly,
          mobile: isMobile,
          "min-value": isMinValue,
        })}
        popoverClassName="b2b duration-selection-popover"
        dropdownLabel={DURATION_DROPDOWN_LABEL_TEXT(appliedLabel)}
        sliderHeader={
          showDropdownContentOnly
            ? undefined
            : DURATION_POPOVER_HEADING_TEXT(flightCount)
        }
        minValue={roundedMin}
        maxValue={roundedMax}
        value={durationFilterToShow}
        setValue={handleChange}
        alwaysShowTooltip
        showResetButton={durationFilter !== 0}
        showMinLabel
        sliderLabelFormat={sliderLabelFormat}
        minMaxLabelFormat={minMaxLabelFormat}
        reset={handleReset}
        dropdownIcon={
          appliedLabel ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                handleReset();
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        showDropdownContentOnly={showDropdownContentOnly}
      />
    </div>
  );
};
