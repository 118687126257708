import React from "react";
import { Box, Button, Paper, Slide, Typography } from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import { Icon, IconName } from "halifax";
import { defaultColors } from "redmond";

export interface SnackbarNoticeProps {
  open: boolean;
  onClose: () => void;
  title: string;
  mainSubtitle: string;
  secondarySubtitle?: string | JSX.Element;
  chipLabel: string;
  ctaOkText: string;
  ctaCancelText: string;
  onCtaOkClick: () => void;
  onCtaCancelClick: () => void;
}

const SlideTransition = (props: any) => <Slide {...props} direction="up" />;

const SnackbarNotice: React.FC<SnackbarNoticeProps> = ({
  open,
  onClose,
  title,
  mainSubtitle,
  secondarySubtitle,
  chipLabel,
  ctaOkText,
  ctaCancelText,
  onCtaOkClick,
  onCtaCancelClick,
}) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      TransitionComponent={SlideTransition}
      style={{
        bottom: 16,
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Paper
        elevation={4}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "30px",
          backgroundColor: defaultColors["blue-14"],
          color: "white",
          width: "90%",
          borderRadius: 16,
        }}
      >
        <Box
          style={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            gap: 8,
            overflow: "visible",
            marginRight: "30px",
          }}
        >
          <Box
            style={{
              flexShrink: 0,
              display: "flex",
              alignItems: "center",
              marginTop: 5,
            }}
          >
            <Icon name={IconName.DisruptionProtectionDoubleLineCircle} />
          </Box>
          <Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
              }}
            >
              <Typography
                variant="subtitle1"
                style={{ fontSize: "20px", fontWeight: "bold" }}
              >
                {title}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  display: "inline-block",
                  backgroundColor: defaultColors["success-light"],
                  color: "white",
                  borderRadius: 4,
                  paddingRight: 8,
                  paddingLeft: 8,
                  paddingTop: 4,
                  paddingBottom: 4,
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                {chipLabel}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              style={{
                fontSize: "16px",
                fontWeight: 400,
                marginBottom: 4,
                color: "white",
              }}
            >
              {mainSubtitle}
            </Typography>
            <>
              {React.isValidElement(secondarySubtitle) ? (
                secondarySubtitle
              ) : (
                <Typography
                  variant="body2"
                  style={{ fontSize: "14px", marginBottom: 1 }}
                >
                  {secondarySubtitle}
                </Typography>
              )}
            </>
          </Box>
        </Box>

        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <Button
            variant="contained"
            onClick={onCtaOkClick}
            style={{
              flex: 1,
              backgroundColor: "white",
              color: defaultColors["blue-14"],
              textTransform: "none",
              fontSize: "14px",
              minWidth: 146,
            }}
          >
            {ctaOkText}
          </Button>
          <Button
            variant="outlined"
            color="inherit"
            onClick={onCtaCancelClick}
            style={{
              flex: 1,
              minWidth: 146,
              textTransform: "none",
              borderColor: "white",
              color: "white",
              fontSize: "14px",
            }}
          >
            {ctaCancelText}
          </Button>
        </Box>
      </Paper>
    </Snackbar>
  );
};

export default SnackbarNotice;
