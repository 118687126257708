import { put } from "redux-saga/effects";
import { actions } from "../actions";
import {
  fetchCorpFintechEligibility,
  FetchCorpFintechEligibilityResponse,
} from "b2b-base/src/api/v1/cotbFintech/fetchCorpFintechEligibility";

export function* fetchCorpFintechEligibilitySaga({
  businessId,
}: actions.IFetchCorpFintechEligibility) {
  try {
    const response: FetchCorpFintechEligibilityResponse =
      yield fetchCorpFintechEligibility({
        businessId,
      });

    if ("isEligibleToOptin" in response) {
      yield put(actions.setCorpFintechEligibility(response.isEligibleToOptin));
    } else {
      yield put(actions.setCorpFintechEligibility(false));
    }
  } catch (e) {
    yield put(actions.setCorpFintechEligibility(false));
  }
}
