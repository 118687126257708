import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import {
  getPremierCollectionShopCancellationSummary,
  PremierCollectionShopCallState,
  showOfferBasedOnSelectedLodgingSelector,
} from "../../reducer";
import {
  getCheapestProduct,
  getPremierCollectionShopCallState,
  getPremierCollectionShopChosenProduct,
  getPremierCollectionShopSelectedAvailability,
  getViewedPremierCollectionDetailsProperties,
  hasPremierCollectionShopFailed,
  premierCollectionShopProgressSelector,
} from "../../reducer/selectors";
import { MobileShop } from "./component";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import { getNightCount } from "../../../search/reducer";
import {getShopCallFailureUrl, getShopCallFromShopDetails} from "../../../shop-details/reducer";
import { actions as shopDetailsActions } from "../../../shop-details/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    lodging: getPremierCollectionShopSelectedAvailability(state),
    premierCollectionShopCallInProgress:
      getPremierCollectionShopCallState(state) ===
      PremierCollectionShopCallState.InProcess,
    chosenProduct: getPremierCollectionShopChosenProduct(state),
    hasFailed: hasPremierCollectionShopFailed(state),
    premierCollectionShopStep: premierCollectionShopProgressSelector(state),
    cheapestProduct: getCheapestProduct(state),
    viewedPremierCollectionDetailsProperties:
      getViewedPremierCollectionDetailsProperties(state),
    largestEarnAccount: getRewardsAccountWithLargestEarnForBanner(state),
    showOfferBasedOnSelectedLodging:
      showOfferBasedOnSelectedLodgingSelector(state),
    nightCount: getNightCount(state),
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    cancellationSummary: getPremierCollectionShopCancellationSummary(state),
    isShopCallFromShopDetails: getShopCallFromShopDetails(state),
    shopCallFailureUrl: getShopCallFailureUrl(state),
  };
};

export const mapDispatchToProps = {
  shopCallFromShopDetails: shopDetailsActions.shopCallFromShopDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShop = withRouter(connector(MobileShop));
