import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { ShopDetailsAvailabilityBanner } from "./component";
const mapStateToProps = () => ({
});

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type ShopDetailsAvailabilityBannerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedShopDetailsAvailabilityBanner = withRouter(connector(ShopDetailsAvailabilityBanner));
