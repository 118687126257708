import queryStringParser from "query-string";

export interface IPremierCollectionShopDetailsParsedQuery {
  lodgingId: string;
  unavailable?: boolean;
}

export interface PremierCollectionShopDetailsQuery {
  lodgingId: string;
  unavailable?: boolean;
}

export const transformToShopDetailsStringifiedQuery = ({
  lodgingId,
  unavailable = false,
}: PremierCollectionShopDetailsQuery): string => {
  const base = `?lodgingId=${lodgingId}`;
  return unavailable ? `${base}&unavailable=${unavailable}` : base;
};

export const parseShopDetailsQueryString = (
  url: string,
): IPremierCollectionShopDetailsParsedQuery => {
  const parsedQueryStringPrimitive = queryStringParser.parse(url);
  const parsedQueryString: IPremierCollectionShopDetailsParsedQuery = {
    lodgingId: parsedQueryStringPrimitive.lodgingId as string,
    unavailable: parsedQueryStringPrimitive.unavailable === "true",
  };

  return parsedQueryString;
}
