import axios from "axios";
import { config } from "../../config";
import {
  ExperiencesShopReviewsRequest,
  ExperiencesShopReviewsResponse,
} from "redmond";
import { experiencesShopReviewsApiPrefix } from "../paths";

export const fetchExperiencesShopReviews = (
  body: ExperiencesShopReviewsRequest
): Promise<ExperiencesShopReviewsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.post(experiencesShopReviewsApiPrefix, body, {
        baseURL: config.baseURL,
      });

      resolve(res.data.value);
    } catch (e) {
      reject(e);
    }
  });
