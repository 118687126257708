import axios from "axios";
import {
  IUpdateUserPassengersMultipleRequest,
  IUpdateUserPassengersMultipleResponse,
} from "redmond";
import { config } from "../../../../config";
import { passengersApiPrefix } from "../../../paths";

const END_POINT: string = `${passengersApiPrefix}/update_multiple`;

export const updateUserPassengersMultiple = (
  body: IUpdateUserPassengersMultipleRequest
): Promise<IUpdateUserPassengersMultipleResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(END_POINT, body, {
        baseURL: config.baseURL,
      });

      const responseBody: IUpdateUserPassengersMultipleResponse = res.data;
      resolve(responseBody);
    } catch (e) {
      reject(e);
    }
  });
