import React from "react";
import { Typography } from "@material-ui/core";

export const MULTIROOM_TOOLTIP_TITLE = "Why can't I add additional travelers?";
export const MULTIROOM_TOOLTIP_TEXT =
  "This hotel requests only one primary traveler for the reservation. In order to add additional names for checkin please call the hotel once you have placed the booking.";

export const TRAVELER_SELECT_DROPDOWN_TITLE = (
  roomsCount: number,
  isAmadeusProvider: boolean,
  isMultiroomAmadeus?: boolean
) => {
  if (!isMultiroomAmadeus) {
    return "Add Primary Traveler";
  }
  if (isAmadeusProvider && roomsCount > 1) {
    return "Select or add travelers";
  }
  return "Select or add the primary traveler for this reservation.";
};
export const TRAVELER_SELECT_DROPDOWN_DESCRIPTION = (
  roomsCount: number,
  isAmadeusProvider: boolean,
  isMultiroomAmadeus?: boolean
) => {
  if (!isMultiroomAmadeus) {
    return "Select or add the primary traveler for this hotel reservation.";
  }
  if (isAmadeusProvider && roomsCount > 1) {
    return (
      <Typography component="span">
        Select or add <strong>up to {roomsCount} travelers</strong> for this
        hotel reservation.
      </Typography>
    );
  }
  return `Your booking will be reserved under the primary traveler's name.`;
};
