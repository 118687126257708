import {
  getClickCancelOOPModalEvent,
  getClickContinueOOPModalEvent,
  getShowOOPModalEvent,
  isCorpTenant,
  useMultiroomCountText,
  useShowPolicyBanner,
} from "@capone/common";
import {
  useExperimentIsVariant,
  useExperimentsById,
} from "@capone/experiments";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  HotelShopRoomTypePickerEnum,
  HotelShopRoomTypePickerRedesign,
  HotelShopRoomTypePickerVariant,
  Icon,
  IconName,
  TrackingEventControlType,
} from "halifax";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  ModalScreens,
  POLICY_DESCRIPTOR,
  PolicyViolation,
  RoomInfoProductsWithTransformedIndexes,
  SELECT_HOTEL_ROOM,
  VIEWED_POLICY_DESCRIPTOR,
  ViewedCorpRateDescriptorEntryPoints,
} from "redmond";
import { ClientContext } from "../../../../App";
import { config } from "../../../../api/config";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CONTROL,
  DESKTOP_RECENTLY_VIEWED_HOTELS,
  DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS,
  RECENTLY_VIEWED_MASTER_V1,
  SIMILAR_HOTELS_EXPERIMENT,
  SIMILAR_HOTELS_VARIANTS,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { getEarnTagText } from "../../textConstants";
import { shouldTrackLodgingLocation } from "../../utils/shouldTrackLodgingLocation";
import { DesktopRoomPickerConnectorProps } from "./container";
import { onOpenCompareBarTooltip } from "../../../../utils/events";
import "./styles.scss";

export interface IDesktopRoomPickerRedesignProps
  extends DesktopRoomPickerConnectorProps,
    RouteComponentProps {
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: (
    trackingEventControl?: TrackingEventControlType
  ) => void;
  setRoomInfoProduct?: (
    roomInfoProduct: RoomInfoProductsWithTransformedIndexes
  ) => void;
  setRoomProductIndex?: (roomProductIndex: number) => void;
  nonRefundablePolicyOverrideText?: string;
}

export const DesktopRoomPickerRedesign = (
  props: IDesktopRoomPickerRedesignProps
) => {
  const {
    checkinDate,
    checkoutDate,
    history,
    fetchHotelShop,
    roomsCount,
    adultsCount,
    children,
    rooms,
    largestValueAccount,
    handleReadyToRedirect,
    roomInfoProductsType,
    isFintechHotelUpdatedUxEnabled,
    isHotelDisplayRoomPricingUpdateEnabled,
    setRoomInfoProduct,
    setRoomProductIndex,
    isHotelCfarModelV1Enabled,
    isHotelCfarModelV1RefundableRoomUpdatedUX,
    isHotelCfarModelV1RefundableRoomUpdatedCopy,
    canEarnRewards,
  } = props;
  const isFirstUpdate = useRef<boolean>(true);
  const [corpPolicyDescriptorViewCount, setCorpPolicyDescriptorViewCount] =
    useState(0);

  const expState = useExperiments();

  const { isAutoApprovalEnabled, policies } = useContext(ClientContext);
  const showPolicyBanner = useShowPolicyBanner(policies);

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const modalType = isAutoApprovalEnabled
    ? "out_of_policy_auto"
    : isApprovalsV2Enabled
    ? "out_of_policy_24hr_review"
    : "out_of_policy";

  const similarHotelsVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    SIMILAR_HOTELS_EXPERIMENT,
    SIMILAR_HOTELS_VARIANTS
  );

  const showEarnEnhancement =
    !!largestValueAccount &&
    !!largestValueAccount.earn.hotelsMultiplier &&
    canEarnRewards;

  const recentlyViewedDesktopP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    DESKTOP_RECENTLY_VIEWED_HOTELS,
    DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS
  );

  const recentlyViewedExperiment = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_MASTER_V1
  );
  const isRecentlyViewedV1Experiment = useMemo(() => {
    return recentlyViewedExperiment === AVAILABLE;
  }, [recentlyViewedExperiment]);

  const isMultiroomAmadeus =
    useExperimentsById("corp-amadeus-multiroom")?.variant === "available";

  const showMultiroomCheckout = roomsCount > 1;

  useEffect(() => {
    if (checkinDate && checkoutDate && (adultsCount || children) && rooms) {
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      } else {
        fetchHotelShop(history, {
          forceCallHotelAvailability: true,
          fetchSimilarHotels: similarHotelsVariant !== CONTROL,
          includeLocationSearchTerm:
            isRecentlyViewedV1Experiment &&
            recentlyViewedDesktopP0Variant !== CONTROL &&
            shouldTrackLodgingLocation(history.location.search),
        });
      }
    }
  }, [checkinDate, checkoutDate, adultsCount, children, rooms]);

  const onOpenPolicyDescriptor = (
    entryPoint: string,
    reasons: PolicyViolation[]
  ) => {
    if (corpPolicyDescriptorViewCount <= 5) {
      trackEvent({
        eventName: VIEWED_POLICY_DESCRIPTOR,
        properties: {
          type: POLICY_DESCRIPTOR,
          entry_point: entryPoint,
          funnel: "hotels",
          policy_reason: reasons.join(", "),
        },
      });
      setCorpPolicyDescriptorViewCount((prevState) => prevState + 1);
    }
  };

  return (
    <HotelShopRoomTypePickerRedesign
      {...props}
      roomInfoProductsType={
        roomInfoProductsType ?? {
          roomInfoProducts: props.roomInfoProducts,
          variant: HotelShopRoomTypePickerEnum.Default,
        }
      }
      onClickContinue={(
        room_type?: string,
        in_policy?: boolean,
        hotel_loyalty_eligible?: boolean
      ) => {
        if (handleReadyToRedirect) {
          handleReadyToRedirect({
            [SELECT_HOTEL_ROOM]: {
              properties: {
                room_type,
                ...(isCorpTenant(config.TENANT) && {
                  in_policy,
                  hotel_loyalty_eligible,
                }),
              },
            },
          });
        }
      }}
      className={clsx("b2b", "desktop-room-picker")}
      reserveButtonClassName={clsx("reserve-cta", {
        multiroom: showMultiroomCheckout,
      })}
      reserveRoomPrefixText={
        showMultiroomCheckout ? (
          <Typography className="reserve-text">
            Reserve{" "}
            <span className={clsx("rooms-count")}>{roomsCount} Rooms</span> for
          </Typography>
        ) : (
          <Typography className="reserve-text">Select room for</Typography>
        )
      }
      desktopReserveButtonText={<span>{`Continue for `}</span>}
      earnTagContent={
        showEarnEnhancement ? (
          <>
            <Icon name={IconName.StarIcon} />
            <Typography
              className="earn-tag-text"
              dangerouslySetInnerHTML={{
                __html: getEarnTagText(
                  largestValueAccount.earn.hotelsMultiplier,
                  largestValueAccount.rewardsBalance.currencyDescription ??
                    largestValueAccount.rewardsBalance.currency
                ),
              }}
            />
          </>
        ) : undefined
      }
      earnTagClassName={showEarnEnhancement ? "b2b" : undefined}
      columnSize={isFintechHotelUpdatedUxEnabled ? "column-430px" : undefined}
      variant={
        isFintechHotelUpdatedUxEnabled ? "refundable-room-ux" : "default"
      }
      isHotelDisplayRoomPricingUpdateEnabled={
        isHotelDisplayRoomPricingUpdateEnabled
      }
      setRoomInfoProduct={setRoomInfoProduct}
      setRoomProductIndex={setRoomProductIndex}
      isHotelCfarModelV1Enabled={isHotelCfarModelV1Enabled}
      isHotelCfarModelV1RefundableRoomUpdatedUX={
        isHotelCfarModelV1RefundableRoomUpdatedUX
      }
      isHotelCfarModelV1RefundableRoomUpdatedCopy={
        isHotelCfarModelV1RefundableRoomUpdatedCopy
      }
      policyLimit={policies?.hotels.policies[0].maxPricePerNight}
      isApprovalRequired={
        isApprovalsV2Enabled
          ? policies?.settings && policies.settings.isApprovalRequired
          : !isAutoApprovalEnabled
      }
      onShowOutOfPolicyModal={() =>
        trackEvent(
          getShowOOPModalEvent(ModalScreens.HOTELS_SHOP, "hotels", modalType)
        )
      }
      onClickOutOfPolicyContinue={() =>
        trackEvent(
          getClickContinueOOPModalEvent(
            ModalScreens.HOTELS_SHOP,
            "hotels",
            modalType
          )
        )
      }
      onClickOutOfPolicyCancel={() =>
        trackEvent(
          getClickCancelOOPModalEvent(
            ModalScreens.HOTELS_SHOP,
            "hotels",
            modalType
          )
        )
      }
      onOpenPolicyDescriptor={onOpenPolicyDescriptor}
      roomNameSuffixText={
        isMultiroomAmadeus ? useMultiroomCountText(roomsCount) : ""
      }
      roomsCount={roomsCount}
      onViewCorpCompareBar={onOpenCompareBarTooltip(
        ViewedCorpRateDescriptorEntryPoints.HOTELS_ROOM_DETAILS
      )}
      showPolicyBanner={showPolicyBanner}
    />
  );
};
