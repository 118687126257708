import { connect, ConnectedProps } from "react-redux";

import { KeywordSearch } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { actions } from "../../actions";
import {
  getExperiencesByCategory,
  getKeyword,
  getKeywordCategories,
  getKeywordCategoriesLoading,
} from "../../reducer";
import { getFromDate, getUntilDate } from "../../../search/reducer";

export const mapStateToProps = (state: IStoreState) => {
  let loading = !!getKeywordCategoriesLoading(state);

  return {
    overrideInputValue: undefined,
    value: getKeyword(state),
    valueCategories: getKeywordCategories(state),
    loading,
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    experiencesByCategory: getExperiencesByCategory(state),
  };
};

const mapDispatchToProps = {
  setValue: actions.setKeyword,
  fetchValueCategories: actions.fetchExperiencesAvailabilityAutocomplete,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type KeywordSearchConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedKeywordSearch = connector(KeywordSearch);
