import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import { Header, ActionButton, HotelName, HotelStarRating } from "halifax";
import clsx from "clsx";

import { IDesktopRewardsHeaderProps } from "./container";
import { RewardsAccountSelection } from "../../components";
import { PATH_HOME } from "../../../../utils/paths";
import { ClientContext } from "../../../../App";
import { goToAvailability } from "../../../shop/utils/queryStringHelpers";
import {
  AVAILABLE,
  getExperimentVariant,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import * as textConstants from "./textConstants";
import "./styles.scss";
import { TravelWalletDrawer } from "../../../travel-wallet/components";
import { goToVRAvailability } from "../../../vacation-rental-shop/utils/queryStringHelpers";
import { BaseAddress, StayTypesEnum } from "redmond";

export const DesktopRewardsHeader = (props: IDesktopRewardsHeaderProps) => {
  const {
    history,
    lodging,
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    premierCollectionShopCallInProgress,
    variant = "shop",
    isVR = false,
    selectedListing,
    petsCount,
    stayType,
    isShopDetails = false,
    shopDetailsLodgingData,
    premierCollectionShopDetailsCallInProgress,
  } = props;
  const clientContext = useContext(ClientContext);
  const expState = useExperiments();

  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = React.useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const returnToAvailability = () => {
    isVR
      ? goToVRAvailability({
          history,
          listing: selectedListing,
          fromDate,
          untilDate,
          adultsCount,
          childrenCount,
          petsCount,
        })
      : goToAvailability({
          history,
          lodging,
          fromDate,
          untilDate,
          adultsCount,
          childrenCount,
          isShopDetails,
        });
  };

  const LeftContent = () => {
    switch (variant) {
      case "customize":
        return (
          <Box className="desktop-pc-customize-basic-info-contents">
            <Box className={"logo"} onClick={() => history.push(PATH_HOME)}>
              {clientContext.logo}
            </Box>
            <Box className={"desktop-pc-customize-header-details"}>
              <Typography variant={"body1"} tabIndex={0}>
                {textConstants.CUSTOMIZE}
              </Typography>
              <Typography variant={"body2"}>
                {textConstants.CUSTOMIZE_SUBTITLE}
              </Typography>
            </Box>
          </Box>
        );
      case "shop":
      default:
        return (
          <Box className="desktop-pc-shop-basic-info-contents">
            <ActionButton
              className="back-to-results"
              message={isShopDetails ? textConstants.GO_TO_HOMEPAGE : textConstants.BACK_TO_RESULTS}
              onClick={() => {
                returnToAvailability();
              }}
              defaultStyle="h4r-secondary"
            />
            {
              isShopDetails ? (
                <Box className="desktop-pc-shop-header-details" tabIndex={0}>
                  <HotelName
                    name={shopDetailsLodgingData?.name}
                    isSkeleton={premierCollectionShopDetailsCallInProgress}
                  />
                  <HotelStarRating
                    starRating={shopDetailsLodgingData?.starRating}
                    city={shopDetailsLodgingData?.city}
                    isSkeleton={premierCollectionShopDetailsCallInProgress}
                  />
                </Box>
              ) : (
                <Box className="desktop-pc-shop-header-details" tabIndex={0}>
                  <HotelName
                    name={
                    stayType === StayTypesEnum.VacationRentals
                      ? selectedListing?.listing.content.name
                      : lodging?.lodging?.name
                    }
                    isSkeleton={premierCollectionShopCallInProgress}
                  />
                  <HotelStarRating
                    starRating={lodging?.lodging?.starRating}
                    city={
                      stayType === StayTypesEnum.VacationRentals
                      ? (
                        selectedListing?.listing.content.location
                            .address as BaseAddress
                        )?.city
                      : lodging?.lodging?.city
                    }
                    isSkeleton={premierCollectionShopCallInProgress}
                  />
                </Box>
              )
            }
          </Box>
        );
    }
  };

  return (
    <Header
      className="desktop-pc-rewards-header-root"
      left={<LeftContent />}
      right={
        <Box className="desktop-pc-shop-rewards-account-contents">
          <RewardsAccountSelection
            className={clsx("b2b", {
              "hide-balance-border": isTravelWalletCreditsExperiment,
            })}
            popoverClassName="b2b"
          />
          {isTravelWalletCreditsExperiment && <TravelWalletDrawer />}
        </Box>
      }
    />
  );
};
