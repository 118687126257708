import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import {
  getCheapestProduct,
}
from "../../reducer";
import { TripAdvisorReviews } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    cheapestProduct: getCheapestProduct(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type TripAdvisorReviewsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedTripAdvisorReviews = withRouter(connector(TripAdvisorReviews));
