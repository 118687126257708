import React, { ReactElement, useContext, useEffect } from "react";
import { GenericBanner, useDeviceTypes } from "halifax";
import { RouterProps } from "react-router-dom";
import { B2B_PORTAL_BANNER_CONTAINER_ID, CustomerAccountRole } from "redmond";
import {
  isCorpTenant,
  REWARDS_BANNER_TEXT,
  getCurrentCorpAccount,
} from "@capone/common";
import { ClientContext } from "../../../../App";

import { RewardsBannerConnectorProps } from "./container";
import {
  PATH_BEST_PRICE_GUARANTEE,
  PATH_CANCEL_FOR_ANY_REASON,
  PATH_HOTEL_CANCEL_FOR_ANY_REASON,
  PATH_DISRUPTION_PROTECTION,
  PATH_FROZEN_PRICES,
  PATH_HOME,
  PATH_PRICE_DROP_PROTECTION,
  PATH_PRICE_PREDICTION_PRICE_WATCH,
  PATH_SITE_TERMS_OF_USE,
  PATH_TERMS_OF_SERVICE,
  PATH_TRAVEL_CREDITS,
  PATH_TRAVEL_OFFERS,
} from "../../../../utils/paths";
import { config } from "../../../../api/config";

export interface IRewardsBannerProps
  extends RewardsBannerConnectorProps,
    RouterProps {}

export const RewardsBanner = (props: IRewardsBannerProps) => {
  const {
    history,
    largestValueAccount,
    fetchRewardsAccounts,
    rewardsAccounts,
  } = props;

  useEffect(() => {
    fetchRewardsAccounts();
  }, []);

  const { matchesMobile } = useDeviceTypes();
  const { sessionInfo } = useContext(ClientContext);
  const firstName = sessionInfo?.userInfo?.firstName || "";

  let contentText: string | ReactElement = "";

  const currentAccount = getCurrentCorpAccount(rewardsAccounts, sessionInfo);

  const account = isCorpTenant(config.TENANT)
    ? currentAccount || largestValueAccount
    : largestValueAccount;

  const {
    earn,
    lastFour = "",
    productDisplayName: productId = "",
    rewardsBalance,
  } = account || {};
  const { currency } = rewardsBalance || {};
  const {
    hotelsMultiplier: earnRate = "",
    carsMultiplier: carEarnRate = "",
    flightsMultiplier: flightEarnRate = "",
  } = earn || {};

  //if no earn multiplier do not display a banner or if its less than 0
  if (!account || !account?.earn?.hotelsMultiplier || Number(earnRate) <= 0) {
    return null;
  }

  // TODO: handle texts properly once the backend is ready
  switch (history.location.pathname) {
    case PATH_HOME.slice(0, -1):
    case PATH_HOME:
    case PATH_PRICE_DROP_PROTECTION:
    case PATH_CANCEL_FOR_ANY_REASON:
    case PATH_HOTEL_CANCEL_FOR_ANY_REASON:
    case PATH_DISRUPTION_PROTECTION:
    case PATH_TRAVEL_OFFERS:
    case PATH_TRAVEL_CREDITS:
    case PATH_FROZEN_PRICES:
    case PATH_BEST_PRICE_GUARANTEE:
    case PATH_PRICE_PREDICTION_PRICE_WATCH:
    case PATH_TERMS_OF_SERVICE:
    case PATH_SITE_TERMS_OF_USE:
      if (!matchesMobile) {
        contentText = REWARDS_BANNER_TEXT({
          firstName,
          earnRate,
          flightEarnRate,
          carEarnRate,
          productId,
          lastFour,
          currency,
          isPrimary:
            account.customerAccountRole === CustomerAccountRole.Primary,
          tenant: config.TENANT,
        });
      }
      // hide the banner on mobile search workflow
      else {
        contentText = "";
      }
      break;
  }

  if (!contentText) {
    return null;
  }
  return (
    <GenericBanner
      className="rewards-banner-root"
      content={contentText}
      containerId={B2B_PORTAL_BANNER_CONTAINER_ID}
      isMobile={matchesMobile}
    />
  );
};
