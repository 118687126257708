import React from "react";
import { TripAdvisorReviewsConnectorProps } from "./container";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import {
  HotelTripAdvisorReviews,
  getCancellationPolicyInfo,
} from "halifax";
import clsx from "clsx";
import * as textConstants from "./textConstants";
import { useInView } from "react-intersection-observer";
import { LodgingData } from "redmond/build/hotels-module";

export interface TripAdvisorReviewsProps
  extends TripAdvisorReviewsConnectorProps,
    RouteComponentProps {
  lodgingData: LodgingData;
}

export const TripAdvisorReviews = (props: TripAdvisorReviewsProps) => {
  const {
    lodgingData,
    cheapestProduct,
  } = props;
  const [reviewsRef,] = useInView({ threshold: 0.8 });

  const cancellationPolicyInfo = cheapestProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(
      cheapestProduct?.cancellationPolicy,
      textConstants.getCfarSecondaryText
    )
  : null;

  return (
      <div
        className={clsx(
           "section-wrapper reviews-and-cancellation-section"
        )}
        id="reviews-section"
        ref={reviewsRef}
      >
        <Typography variant="h2">{textConstants.REVIEWS}</Typography>
        <HotelTripAdvisorReviews
          lodgingData={lodgingData}
          trim={1000}
        />
        {cancellationPolicyInfo && (
          <Box
            className={clsx(
              "section-wrapper",
              "cancellation-policy-section"
            )}
          >
            <Typography
              variant="h4"
              className="hotel-shop-cancellation-policy-heading"
            >
              {cancellationPolicyInfo.primaryText}
            </Typography>
            <Typography variant="subtitle2">
              {cancellationPolicyInfo.secondaryText}
            </Typography>
          </Box>
        )}
      </div>
  );
};
