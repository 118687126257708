import { isCaponeTenant } from "@capone/common";
import { config } from "../../../../api/config";

export const PC_EARN_BANNER_TEXT = `<strong>Earn 10X miles</strong> on Premier Collection purchases,
and enjoy a <strong>$100 experience credit</strong> to spend at qualifying dining, spa or
hotel activities.`;

export const getLCEarnBannerText = (
    hotelsMultiplier: number,
    isUserPrimary?: boolean
) => {
    return isCaponeTenant(config.TENANT) || isUserPrimary
        ? `<strong>Earn ${hotelsMultiplier}X miles</strong> on Lifestyle Collection purchases,
  and enjoy a <strong>$50 experience credit</strong> to use toward things like signature hotel restaurants and room service.`
        : `<strong>Enjoy a <strong>$50 experience credit</strong> to use toward things like signature hotel restaurants and room service.`;
};
