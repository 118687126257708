import React from "react";
import { ShopDetailsAvailabilityBannerConnectorProps } from "./container";
import { Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import {
  Icon,
  IconName,
  BannerSeverity,
  NotificationBanner,
} from "halifax";
import * as textConstants from "./textConstants";
import clsx from "clsx";

export interface IShopDetailsAvailabilityBannerProps
  extends ShopDetailsAvailabilityBannerConnectorProps,
    RouteComponentProps {
    isUnavailable?: boolean;
    isMobile?: boolean;
}

export const ShopDetailsAvailabilityBanner = ({
  isUnavailable,
  isMobile,
}: IShopDetailsAvailabilityBannerProps) => {
  return (
    <NotificationBanner
        className={clsx("pc-date-details-banner", {
          mobile: isMobile,
        })}
      content={
        <Typography className="pc-date-details-text">
          {isUnavailable ? textConstants.ERROR_BANNER : textConstants.TEXT_BANNER}
        </Typography>
      }
      severity={BannerSeverity.INFO}
      icon={<Icon className="banner-icon" name={IconName.InfoCircle} />}
    />
  );
};
