import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  MobileCorpMenuDropdown,
  MobilePopoverCard,
  getTravelCreditAndOfferTitle,
} from "halifax";
import H from "history";
import React from "react";
import { useHistory } from "react-router-dom";
import {
  B2B_PORTAL_ENDSESSION_PATH,
  PORTAL_SWITCHED,
  PortalSwitchedProperties,
  PortalTypes,
  Tenant,
  VIEWED_CONTACT_MODAL,
} from "redmond";

import { isCorpTenant, useUserContext } from "@capone/common";
import { useExperimentIsVariant } from "@capone/experiments";
import { trackEvent } from "../../api/v1/trackEvent";
import { AVAILABLE } from "../../context/experiments";
import config from "../../utils/config";
import {
  PATH_CARS,
  PATH_CUSTOMER_PROFILE,
  PATH_EXPERIENCES,
  PATH_FLIGHTS,
  PATH_HOTELS,
  PATH_PREMIER_COLLECTION,
  PATH_TRAVEL_WALLET,
  PATH_TRIPS,
} from "../../utils/urlPaths";
import { SupportModalContent } from "../Header/components/SupportModal";
import "./styles.scss";

interface IStyledMainNavLinkProps {
  label: string | React.ReactElement;
  path: string;
}

interface ConnectedMainNavLinkProps extends IStyledMainNavLinkProps {
  history: H.History;
  onClose: () => void;
}
const StyledMainNavLink = ({
  label,
  path,
  history,
  onClose,
}: ConnectedMainNavLinkProps) => {
  return (
    <ActionLink
      className={"b2b-main-nav-link"}
      content={
        typeof label === "string" ? (
          <Typography variant="h3">{label}</Typography>
        ) : (
          label
        )
      }
      onClick={() => {
        history.push(path);
        onClose();
      }}
    />
  );
};

interface IStyledSubNavLinkProps {
  label: string | JSX.Element;
  onClick: () => void;
}

interface ConnectedStyledSubNavLinkProps extends IStyledSubNavLinkProps {
  onClose: () => void;
}

const StyledSubNavLink = ({
  label,
  onClick,
  onClose,
}: ConnectedStyledSubNavLinkProps) => {
  return (
    <ActionLink
      className={"b2b-sub-nav-link"}
      content={<Typography variant="h5">{label}</Typography>}
      onClick={() => {
        onClick();
        onClose();
      }}
    />
  );
};

const MobileHamburgerMenu = ({
  onClose,
  offerCount,
  isTravelWalletOfferExperiment,
  isPremierCollectionEnabled,
  corporatePortalUrl,
  consumerPortalUrl,
  adminPortalUrl,
  tenant,
  isCustomerProfileExperiment,
  showCustomerProfileNewBadge,
  isExperiencesExperiment,
  openSupportModal,
  setOpenSupportModal,
}: {
  onClose: () => void;
  offerCount?: number;
  isTravelWalletOfferExperiment?: boolean;
  isPremierCollectionEnabled?: boolean;
  tenant: Tenant;
  corporatePortalUrl?: string;
  consumerPortalUrl?: string;
  adminPortalUrl?: string;
  isCustomerProfileExperiment?: boolean;
  showCustomerProfileNewBadge?: boolean;
  isExperiencesExperiment?: boolean;
  openSupportModal: boolean;
  setOpenSupportModal: (isOpen: boolean) => void;
}) => {
  const history = useHistory();
  const { logo } = useUserContext(config.TENANT);

  const isCorpHideTravelOffers = useExperimentIsVariant(
    "corp-hide-travel-wallet-offers",
    AVAILABLE
  );

  const getLinkLabel = (
    text: string,
    iconName?: IconName,
    endIcon?: IconName,
    showNewBadge?: boolean
  ) => {
    return (
      <Box className="hamburger-funnel-link-icon-label-wrapper">
        <Box className="funnel-link-icon-and-text">
          {iconName && <Icon name={iconName} />}
          <Typography className="label-text">{text}</Typography>
          {showNewBadge && <Box className="nav-item-new-badge">New</Box>}
        </Box>
        {endIcon && <Icon name={endIcon} />}
      </Box>
    );
  };
  const ConnectedSubStyledNavLink = (props: IStyledSubNavLinkProps) => (
    <StyledSubNavLink {...props} onClose={onClose} />
  );
  const ConnectedMainStyledNavLink = (props: IStyledMainNavLinkProps) => (
    <StyledMainNavLink {...props} onClose={onClose} history={history} />
  );
  const handleSupportModalClose = () => setOpenSupportModal(false);

  const trackPortalSwitched = (portal: PortalTypes) => {
    trackEvent({
      eventName: PORTAL_SWITCHED,
      properties: {
        current_portal: "corporate",
        portal_selected: portal,
      } satisfies PortalSwitchedProperties,
    });
  };

  return (
    <Box className="hamburger-menu">
      <MobilePopoverCard
        className={"mobile-contact-support-popup"}
        open={openSupportModal}
        onClose={handleSupportModalClose}
        centered={true}
        topRightButton={<CloseButtonIcon onClick={handleSupportModalClose} />}
      >
        <Box className="mobile-contact-support-popup-content">
          <SupportModalContent />
        </Box>
      </MobilePopoverCard>
      <Box className="hamburger-top-section">
        <img className="hamburger-logo" src={logo?.src} alt={logo?.alt} />
        <ActionLink
          className="hamburger-close-button"
          content={<CloseButtonIcon />}
          label="Close"
          onClick={onClose}
        />
      </Box>
      <Box className="hamburger-mid-section">
        <ConnectedMainStyledNavLink
          label={getLinkLabel(
            "Hotels",
            IconName.HotelFunnelIcon,
            IconName.RightChevronIcon
          )}
          path={PATH_HOTELS}
        />

        <ConnectedMainStyledNavLink
          label={getLinkLabel(
            "Flights",
            IconName.FlightFunnelIcon,
            IconName.RightChevronIcon
          )}
          path={PATH_FLIGHTS}
        />

        <ConnectedMainStyledNavLink
          label={getLinkLabel(
            "Car Rentals",
            IconName.CarFunnelIcon,
            IconName.RightChevronIcon
          )}
          path={PATH_CARS}
        />
        {isPremierCollectionEnabled && (
          <ConnectedMainStyledNavLink
            label={getLinkLabel(
              "Premium Stays",
              IconName.HotelFunnelIcon,
              IconName.RightChevronIcon
            )}
            path={PATH_PREMIER_COLLECTION}
          />
        )}
        {isExperiencesExperiment && (
          <ConnectedMainStyledNavLink
            label={getLinkLabel(
              "Experiences",
              IconName.ExperiencesIcon,
              IconName.RightChevronIcon
            )}
            path={PATH_EXPERIENCES}
          />
        )}
      </Box>
      {(isCorpTenant(tenant) || corporatePortalUrl) && (
        <MobileCorpMenuDropdown
          tenant={tenant}
          corporatePortalUrl={corporatePortalUrl}
          consumerPortalUrl={consumerPortalUrl}
          adminPortalUrl={adminPortalUrl}
          trackPortalSwitched={trackPortalSwitched}
        />
      )}

      <Box className={clsx("hamburger-bottom-section", tenant)}>
        <Box className="hamburger-sub-links">
          {isTravelWalletOfferExperiment && (
            <ConnectedSubStyledNavLink
              label={
                <Box className="offer-nav-link-container">
                  {getLinkLabel(
                    getTravelCreditAndOfferTitle(!isCorpHideTravelOffers),
                    IconName.TravelWalletIcon
                  )}
                  {!!offerCount && (
                    <Typography className="offer-count">
                      {offerCount}
                    </Typography>
                  )}
                </Box>
              }
              onClick={() => {
                history.push(PATH_TRAVEL_WALLET);
              }}
            />
          )}
          <ConnectedSubStyledNavLink
            label={getLinkLabel("My Trips", IconName.MyTripIcon)}
            onClick={() => {
              history.push(PATH_TRIPS);
            }}
          />
          <StyledSubNavLink
            onClose={() => {}}
            label={getLinkLabel("Support", IconName.QuestionMarkSupportIcon)}
            onClick={() => {
              trackEvent({
                eventName: VIEWED_CONTACT_MODAL,
                properties: { contact_reason: "general_contact" },
              });
              setOpenSupportModal(true);
            }}
          />
          {isCustomerProfileExperiment && (
            <StyledSubNavLink
              onClose={() => {}}
              label={getLinkLabel(
                "My travel profile",
                IconName.UserOutline,
                undefined,
                showCustomerProfileNewBadge
              )}
              onClick={() => {
                history.push(PATH_CUSTOMER_PROFILE);
                onClose?.();
              }}
            />
          )}
        </Box>
        <ActionLink
          className="hamburger-menu-logout"
          content={getLinkLabel("Logout")}
          onClick={() => {
            history.push(B2B_PORTAL_ENDSESSION_PATH);
          }}
        />
      </Box>
    </Box>
  );
};

export default MobileHamburgerMenu;
