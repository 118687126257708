import { Box, Typography } from "@material-ui/core";
import React from "react";
import { PackageItinerary } from "redmond";
import * as textConstants from "./constants";
import {
  getTotalPriceText,
  Icon,
  IconName,
  twoDecimalFormatter,
} from "halifax";
import "./styles.scss";
import { getCurrencyString } from "../../../../../../../../utils/helpers";
import { formatPaymentBreakdown, ILineItem } from "../../../FlightCard/helpers";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IPackagePaymentModalContent {
  packageItinerary: PackageItinerary;
}
export const PackagePaymentModalContent = (
  props: IPackagePaymentModalContent
) => {
  const { packageItinerary } = props;
  const { pricingBreakdown, paymentBreakdown, hotel } = packageItinerary;

  const renderPricingBreakdown = () => {
    return (
      <Box className="package-pricing-breakdown">
        <Box className="package-pricing-row">
          <Typography variant="body2">
            Starting price per traveler (
            {pricingBreakdown?.subtotal.numTravelers} travelers)
          </Typography>
          <Typography variant="body2">
            {getTotalPriceText({
              price: pricingBreakdown.subtotal.perTravelerAmount.fiat,
              priceFormatter: twoDecimalFormatter,
            })}
          </Typography>
        </Box>
        <Box className="package-pricing-row">
          <Typography variant="body2">Subtotal</Typography>
          <Typography variant="body2">
            {getTotalPriceText({
              price: pricingBreakdown.subtotal.totalAmount.fiat,
              priceFormatter: twoDecimalFormatter,
            })}
          </Typography>
        </Box>
        {pricingBreakdown.savingsAmount && (
          <Box className="package-pricing-row savings">
            <Typography variant="body2">
              {textConstants.PACKAGE_SAVINGS}
            </Typography>
            <Typography variant="body2">
              {getTotalPriceText({
                price: pricingBreakdown.savingsAmount?.fiat,
                priceFormatter: twoDecimalFormatter,
              })}
            </Typography>
          </Box>
        )}
        {pricingBreakdown.seatsAmount && (
          <Box className="package-pricing-row seats">
            <Typography variant="body2">
              {textConstants.SEAT_SELECTION}
            </Typography>
            <Typography variant="body2">
              {getTotalPriceText({
                price: pricingBreakdown.seatsAmount?.fiat,
                priceFormatter: twoDecimalFormatter,
              })}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const renderTripTotal = () => {
    return (
      <Box className="trip-totals">
        <Box className="package-pricing-row">
          <Typography variant="body1" className="trip-total-text">
            {textConstants.TOTAL_DUE_TODAY}
          </Typography>
          <Typography variant="body1" className="trip-total-text">
            {getCurrencyString(
              packageItinerary.pricingBreakdown.payNowAmount.fiat
            )}
          </Typography>
        </Box>
        {pricingBreakdown.payAtHotelAmount && (
          <Box className="package-pricing-row savings">
            <Typography variant="body2">
              {textConstants.HOTEL_SERVICE_FEE}
            </Typography>
            <Typography variant="body2">
              {getTotalPriceText({
                price: pricingBreakdown.payAtHotelAmount?.fiat,
                priceFormatter: twoDecimalFormatter,
              })}{" "}
              (due at hotel)
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const lineItems =
    paymentBreakdown && formatPaymentBreakdown(paymentBreakdown);

  const renderRewards = () => {
    const rewards = lineItems?.rewards;
    if (!rewards) {
      return null;
    }
    return (
      <Box className="rewards-container">
        <Typography variant="body2">{rewards.label}</Typography>
        <Typography variant="body1" className="rewards-points">
          {rewards.amount}
        </Typography>
      </Box>
    );
  };

  const renderPayment = () => {
    const userCard = lineItems?.userCard;
    if (!userCard) {
      return null;
    }
    return (
      <Box className="payment-card-container">
        <Box className="payment-card-details-container">
          <FontAwesomeIcon icon={faCreditCard} className="credit-card-icon" />
          <Typography variant="body2" className="payment-card-details">
            {userCard.label}
          </Typography>
        </Box>
        <Typography variant="body1" className="payment-card-amount">
          {userCard.amount}
        </Typography>
      </Box>
    );
  };

  const renderTravelWallet = (
    travelWallet: ILineItem | null,
    icon: IconName
  ) => {
    if (!travelWallet) return null;
    return (
      <Box className="offers-container">
        <Box className="offers-icon-label">
          <Icon name={icon} />
          <Typography variant="body2">{travelWallet.label}</Typography>
        </Box>
        <Typography variant="body1" className="offer-amount">
          {travelWallet.amount}
        </Typography>
      </Box>
    );
  };

  const renderOffers = () =>
    lineItems &&
    renderTravelWallet(lineItems?.travelWalletOffer, IconName.OfferTag);

  const renderCredits = () =>
    lineItems?.travelWalletCredit?.map((credit) =>
      renderTravelWallet(credit, IconName.PiggyBank)
    );

  const renderEarnOffer = () => (
    <Box className="earn-offer-container">
      {hotel.itinerary.redeemedEarnOffers?.map((offer) => (
        <Box
          className="earn-offer-banner"
          key={`hotel-itinerary-earn-offer-${offer.description}`}
        >
          <Icon name={IconName.CheckCircleFilledGreen} />
          <Typography className="earn-offer-banner-text">
            {offer.description}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <Box className="package-payment-modal-content">
      <Box className="package-payment-title-container">
        <Typography variant="h4" className="title-text">
          {textConstants.CHECKOUT_BREAKDOWN}
        </Typography>
      </Box>
      {renderPricingBreakdown()}
      {renderTripTotal()}
      {renderOffers()}
      {renderCredits()}
      {renderRewards()}
      {renderPayment()}
      {renderEarnOffer()}
    </Box>
  );
};
