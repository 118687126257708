import React from "react";
import { Icon, IconName } from "halifax";
import { RewardsAccount } from "redmond";

import styles from "./styles.module.scss";

interface IEarnTagTextProps {
  rewardsAccount?: RewardsAccount;
}

export const EarnTagText = (props: IEarnTagTextProps) => {
  const { rewardsAccount } = props;

  if (!rewardsAccount) return null;

  const { earn, rewardsBalance } = rewardsAccount;

  const currency =
    rewardsBalance.currencyDescription ??
    rewardsAccount.rewardsBalance.currency;
  const multiplier = currency.includes("cash") ? "%" : "X";

  // TODO: Remove `hotelsMultiplier` and use `experiencesMultiplier` once we have that from BE https://hopper-jira.atlassian.net/browse/COTA-1548
  const earnRate = earn.experiencesMultiplier ?? earn.hotelsMultiplier;

  return (
    <>
      <Icon name={IconName.StarIcon} />
      <div className={styles["earn-tag-text"]}>
        Earn{" "}
        <b>
          {earnRate}
          {multiplier}
        </b>{" "}
        {currency} on experiences
      </div>
    </>
  );
};
