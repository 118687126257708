import { Box, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import {
  PackageItinerary,
  IOpenModal,
  MyTripsFilter,
  ItineraryWithType,
  ItineraryEnum,
  Reservation,
  LodgingAddressExact,
  MyTripsModalTypes,
  Airline,
} from "redmond";
import clsx from "clsx";
import {
  MyTripsCard,
  TripInfoDetails,
  ActionLinks,
  IconName,
  getTwoLineAddresses,
  IActionLink,
  ActionLink,
} from "halifax";
import {
  MOBILE_OFFSET_SCROLL,
  DESKTOP_OFFSET_SCROLL,
} from "../../../constants";
import * as textConstants from "../constants";
import { isCorpTenant, useMultiroomCountText } from "@capone/common";
import { config } from "../../../../../../../api/config";
import "./styles.scss";

import { ConfirmationSummary } from "../../ConfirmationSummary";
import {
  HotelCancellation,
  HotelCardRoomTypeContent,
  HotelCheckInInstructions,
} from "../../HotelCard/components/HotelInfoParts";
import { HotelMap } from "../../HotelCard/components/HotelMap";
import { SummaryCard } from "../../SummaryCard";
import { TravelersSummary } from "../../TravelersSummary";
import { PaymentSummary } from "../../PaymentSummary";
import {
  calculateHotelTotals,
  getCurrencyString,
} from "../../../../../../../utils/helpers";
import { getFlightConfirmationNumbers } from "../../ItinerariesModal/components/ConfirmationModalPackageContent/component";

interface IDesktopPackageCardProps {
  expandedCard: string;
  packageItinerary: PackageItinerary;
  isPastTrips?: boolean;
  banner?: JSX.Element;
  confirmationCodeClassName?: string;
  isCancel?: boolean;
  isMobile?: boolean;
  onExpandCard: (cardId: string) => void;
  setOpenModal: (modalType: IOpenModal) => void;
  travelerNames?: string[];
  tripsFilter: MyTripsFilter;
  airlineMap: { [key: string]: Airline };
}

export const addPackageType = (
  packageItinerary: PackageItinerary
): ItineraryWithType => ({
  ...packageItinerary,
  type: ItineraryEnum.Package,
});

export const getPostalCode = (reservation: Reservation): string => {
  const { lodgingData } = reservation;
  const city = lodgingData?.city ? `${lodgingData.city},` : "";
  const state = lodgingData?.state?.code?.trim()
    ? `${lodgingData.state.code},`
    : "";
  return `${city} ${state} ${lodgingData?.country || ""}`;
};

export const DesktopPackageHotelCard = (props: IDesktopPackageCardProps) => {
  const {
    expandedCard,
    packageItinerary,
    isMobile,
    onExpandCard,
    setOpenModal,
    banner,
    isCancel,
    isPastTrips = false,
    confirmationCodeClassName,
    travelerNames,
    // tripsFilter,
    airlineMap,
  } = props;

  const hotelItinerary = packageItinerary.hotel.itinerary;
  const { reservation } = hotelItinerary;
  const { pricingBreakdown, paymentBreakdown } = packageItinerary;

  const totals = paymentBreakdown
    ? calculateHotelTotals({
        reservation,
        paymentBreakdown: packageItinerary.paymentBreakdown!,
      })
    : undefined;

  const hotelAddress = reservation.lodgingData?.address as
    | LodgingAddressExact
    | undefined;
  const hotelStreet = getTwoLineAddresses(hotelAddress).line1;

  const { roomInfo, count: roomsCount } = reservation.bookedRooms[0];
  const tripCardSubtitle = isCorpTenant(config.TENANT) ? (
    <Box>
      <Typography className="info-container" variant="body1">
        {reservation.lodgingData?.name}
      </Typography>
      <Typography variant="caption" color="inherit">
        {roomInfo.name} {useMultiroomCountText(roomsCount)}
      </Typography>
    </Box>
  ) : (
    reservation.lodgingData?.name
  );

  const onOpenModal = (type: MyTripsModalTypes) =>
    setOpenModal({
      type,
      selectedItinerary: addPackageType(packageItinerary),
    });
  const showCancelButton = !isPastTrips;

  const actions = useMemo(() => {
    const actionsArr: IActionLink[] = [];

    if (!isCancel && showCancelButton) {
      actionsArr.push({
        content: "Cancel hotel",
        onClick: () => onOpenModal(MyTripsModalTypes.CancelHotel),
      });
      actionsArr.push({
        content: "Change hotel",
        onClick: () => onOpenModal(MyTripsModalTypes.ChangeHotel),
      });
    }

    actionsArr.push({
      content: "Resend confirmation",
      onClick: () => onOpenModal(MyTripsModalTypes.ResendConfirmation),
    });
    if (!isCancel) {
      actionsArr.push({
        content: textConstants.VIEW_ITEMIZED_RECEIPT,
        onClick: () => onOpenModal(MyTripsModalTypes.ItemizedPackageReceipt),
      });
    }
    return actionsArr;
  }, [isCancel]);

  return (
    <Box
      id={reservation.reservationId}
      key={reservation.reservationId}
      className={clsx("package-hotel-trip-container", {
        mobile: isMobile,
        expanded: expandedCard === reservation.reservationId,
      })}
    >
      <MyTripsCard
        className="trip-card-title"
        banner={banner}
        content={
          <TripInfoDetails
            hideTitleTag={isCancel}
            confirmationCodeClassName={confirmationCodeClassName}
            titles={{
              confirmationLabel: textConstants.CONFIRMATION,
              startLabel: `${textConstants.CHECK_IN}:`,
              endLabel: `${textConstants.CHECK_OUT}:`,
              postalCode: getPostalCode(reservation),
              phoneNum: reservation.lodgingData?.phone || "",
              streetAddress: hotelStreet,
              ...textConstants.getHotelInfoDetails(hotelItinerary),
              subtitle: reservation.lodgingData?.name ? tripCardSubtitle : "",
            }}
          />
        }
        actions={<ActionLinks actions={actions} />}
        expandString={
          expandedCard === reservation.reservationId
            ? textConstants.VIEW_LESS
            : textConstants.VIEW_DETAILS
        }
        expandIcon={
          expandedCard === reservation.reservationId
            ? IconName.MinusBlueCircle
            : IconName.PlusBlueCircle
        }
        isExpanded={expandedCard === reservation.reservationId}
        topRightAction={null}
        onExpandClick={() => {
          onExpandCard(reservation.reservationId);
          setTimeout(() => {
            const OFFSET = isMobile
              ? MOBILE_OFFSET_SCROLL
              : DESKTOP_OFFSET_SCROLL;
            const cardTop =
              document
                ?.getElementById(reservation.reservationId)
                ?.getBoundingClientRect().top || 0;
            window.scrollBy({
              top: (cardTop as number) - OFFSET,
              behavior: isMobile ? "auto" : "smooth",
            });
          }, 100);
        }}
        travelers={travelerNames}
      />{" "}
      {reservation.reservationId === expandedCard && (
        <Box className="package-hotel-desktop-expanded-container">
          <Box className="package-hotel-summary-container">
            <HotelMap lodging={reservation.lodgingData} />
            <HotelCardRoomTypeContent bookedRooms={reservation.bookedRooms} />
            {totals?.refundableAmount && (
              <HotelCancellation
                cancellationPolicy={reservation.cancellationPolicy}
                refundAmount={totals?.refundableAmount}
              />
            )}
            {reservation.checkInDate && reservation.checkOutDate && (
              <HotelCheckInInstructions
                fromDate={reservation.checkInDate}
                untilDate={reservation.checkOutDate}
                checkInInstructions={
                  reservation.lodgingData.checkInInstructions
                }
              />
            )}
          </Box>
          <Box className="summary-info-container">
            <SummaryCard
              className="travellers-summary"
              action={
                <ActionLink
                  className="details-link"
                  onClick={() => onOpenModal(MyTripsModalTypes.TravelersModal)}
                  content="Details"
                />
              }
            >
              <TravelersSummary
                label={"Guest Information"}
                travelers={reservation.guests
                  .map((person) => person?.givenName)
                  .join(", ")}
              />
            </SummaryCard>
            {paymentBreakdown && (
              <SummaryCard
                className="payment-summary"
                action={
                  <ActionLink
                    className="details-link"
                    onClick={() => onOpenModal(MyTripsModalTypes.PaymentModal)}
                    content="Details"
                  />
                }
              >
                <PaymentSummary
                  tripTotalAmount={getCurrencyString(
                    pricingBreakdown.payNowAmount.fiat
                  )}
                  cardLabel={
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).cardLabel
                  }
                  cardAmount={
                    textConstants.getValues(paymentBreakdown).cardValue
                  }
                  rewardsLabel={
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).rewardLabel
                  }
                  rewardsAmount={
                    textConstants.getValues(paymentBreakdown).rewardValue
                  }
                  showCardLabel={Boolean(
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).cardLabel
                  )}
                  showRewardsLabel={Boolean(
                    textConstants.getLabelsFromPaymentBreakdown(
                      paymentBreakdown
                    ).rewardLabel
                  )}
                />
              </SummaryCard>
            )}
            <SummaryCard
              className="confirmation-summary"
              action={
                <ActionLink
                  className="details-link"
                  onClick={() =>
                    onOpenModal(MyTripsModalTypes.ConfirmationModal)
                  }
                  content={`View all (${
                    getFlightConfirmationNumbers({
                      flight: packageItinerary.flight.itinerary,
                      airlineMap,
                    }).length + 2
                  })`}
                />
              }
            >
              <ConfirmationSummary
                confirmationCode={`H-${packageItinerary.customerConfirmationId}`}
              />
            </SummaryCard>
          </Box>
        </Box>
      )}
    </Box>
  );
};
