import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../../../rewards/reducer";
import {
  getDepartureDate,
  getNightCount,
  getReturnDate,
} from "../../../search/reducer";
import {
  getCheapestLodgingRoomProduct,
  getPackageLodgingRoomInfoProducts,
  getPackageSelectedLodging,
  getPackageRatesById,
  showOfferBasedOnSelectedLodgingSelector,
  getHotelShopCallState,
} from "../../reducer/selectors";
import { DesktopHotelShopChooseRoom } from "./component";
import {
  setDepartureDate,
  setReturnDate,
} from "../../../search/actions/actions";
import { getTravelersCount } from "../../../availability/reducer";
import { PackageHotelShopCallState } from "../../reducer/state";

const mapStateToProps = (state: IStoreState) => {
  return {
    roomInfoProducts: getPackageLodgingRoomInfoProducts(state),
    selectedLodging: getPackageSelectedLodging(state),
    cheapestProduct: getCheapestLodgingRoomProduct(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    packageRatesById: getPackageRatesById(state),
    travelersCount: getTravelersCount(state),
    showOfferBasedOnSelectedLodging:
      showOfferBasedOnSelectedLodgingSelector(state),
    loading: [
      PackageHotelShopCallState.InProcess,
      PackageHotelShopCallState.NotCalled,
    ].includes(getHotelShopCallState(state)),
  };
};

export const mapDispatchToProps = {
  setDepartureDate,
  setReturnDate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopHotelShopChooseRoomConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDesktopHotelShopChooseRoom = withRouter(
  connector(DesktopHotelShopChooseRoom)
);
