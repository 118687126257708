import type { AxiosInstance, AxiosRequestConfig, AxiosStatic } from "axios";
import { useEffect } from "react";
import { B2B_PORTAL_AUTH_REDIRECT_TO, Tenant } from "redmond";
import { isCorpTenant } from "../utils/getTenant";
import { getPathFromURL } from "../utils/getPathFromURL";
import { PATH_CORP_ACKNOWLEDGEMENT } from "../constants/capone-corporate/paths";

export const CORPORATE_ADMIN_URL =
  window.__portal_env__?.ADMIN_PORTAL_URL ?? "";

export const onboardingPage = `${CORPORATE_ADMIN_URL}/onboarding`;

const redirectPathForErrorCode = {
  40103: "/access-denied",
  40102: "/access-denied-not-beta",
};

export const isCurrentPageAnErrorPage = (path: String) => {
  return Object.values(redirectPathForErrorCode).some((errorPagePath) =>
    path.includes(errorPagePath)
  );
};

const isLoginApi = (config: AxiosRequestConfig) => {
  return (
    config.url?.includes("login/code") &&
    config.method?.toLowerCase() === "post"
  );
};

export const useCorpRedirects = (
  axios: AxiosStatic | AxiosInstance,
  tenant: Tenant
) => {
  if (!isCorpTenant(tenant)) {
    return;
  }
  const redirectUrl = sessionStorage.getItem(B2B_PORTAL_AUTH_REDIRECT_TO);
  const utm_source = redirectUrl && redirectUrl.match(/\butm_source=[^&?]+\b/);

  useEffect(() => {
    const responseInterceptor = axios.interceptors.response.use(
      (res) => {
        if (isLoginApi(res.config)) {
          // if login from onboarding page, redirect back to onboarding page
          if (redirectUrl && redirectUrl?.includes("onboarding")) {
            // Need to `location.replace` because we change React app
            window.location.replace(getPathFromURL(redirectUrl));
          } else if (
            // non-admin trying to access admin portal
            redirectUrl?.startsWith(CORPORATE_ADMIN_URL) &&
            res.data?.permissions &&
            !res.data.permissions.canViewAdmin
          ) {
            window.location.pathname = "/access-denied";
          }
        }

        return res;
      },
      (error) => {
        const responseStatus = parseInt(error.response?.status);
        const responseData = error.response?.data;

        // if from onboarding page it will get an error since there is no business associated yet
        // there is additional redirect logic to handle access denied on onboarding page
        if (window.location.pathname.includes("onboarding")) {
          // prevents any redirect loops
          return Promise.reject(error);
        }

        if (responseData?.code == 40105) {
          console.log("DEBUG: useRedirect to onboarding");
          if (utm_source) {
            window.location.replace(`${onboardingPage}?${utm_source[0]}`);
          } else {
            window.location.replace(onboardingPage);
          }
          return Promise.resolve(responseData);
        }
        if (responseData?.code == 40106) {
          console.log("DEBUG: useRedirect to acknowledgement");

          window.location.pathname = PATH_CORP_ACKNOWLEDGEMENT;

          return Promise.resolve(responseData);
        }

        if (responseStatus === 401) {
          if (
            // If user authentication fails, redirect to access denied page
            isLoginApi(error.config) &&
            !(responseData?.code in redirectPathForErrorCode)
          ) {
            responseData.code = 40103;
          }

          if (
            responseData?.code in redirectPathForErrorCode &&
            !isCurrentPageAnErrorPage(window.location.pathname)
          ) {
            window.location.pathname =
              redirectPathForErrorCode[
                responseData.code as keyof typeof redirectPathForErrorCode
              ];
          }
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);
};
