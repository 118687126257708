import {actions} from "../actions";
import {CorpRoomInfoProducts, LodgingData, RoomInfoProducts, TrackingProperties,} from "redmond";
import {
  ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_DETAILS_FAILURE,
  FETCH_PREMIER_COLLECTION_SHOP_DETAILS,
  SELECT_LODGING,
  SELECT_ROOM_TYPE,
  SET_PREMIER_COLLECTION_SHOP_DETAILS_CALL_STATE_FAILED,
  SET_PREMIER_COLLECTION_SHOP_DETAILS_RESULTS,
  SHOP_CALL_FROM_SHOP_DETAILS,
} from "../actions/constants";

export interface IPremierCollectionShopDetailsState {
  lodgingData: LodgingData | null;
  roomInfoProducts: RoomInfoProducts[] | CorpRoomInfoProducts[];
  premierCollectionShopDetailsCallState: PremierCollectionShopDetailsCallState;
  premierCollectionShopDetailsCallError: PremierCollectionShopDetailsCallError | null;
  hotelShopTrackingPropertiesV2: TrackingProperties | null;
  chosenRoomInfoIndex: number | null;
  shopCallFromShopDetails: boolean;
  shopCallFailureUrl: string | null;
}

export enum PremierCollectionShopDetailsCallState {
  NotCalled = "NotCalled",
  InProcess = "InProcess",
  Success = "Success",
  Failed = "Failed",
  FailureAcknowledged = "FailureAcknowledged",
}

export enum PremierCollectionShopDetailsCallError {
  NoAvailability = "NoAvailability",
  Unknown = "Unknown",
}

const initialState: IPremierCollectionShopDetailsState = {
  lodgingData: null,
  roomInfoProducts: [],
  premierCollectionShopDetailsCallState: PremierCollectionShopDetailsCallState.NotCalled,
  premierCollectionShopDetailsCallError: null,
  hotelShopTrackingPropertiesV2: null,
  chosenRoomInfoIndex: null,
  shopCallFromShopDetails: false,
  shopCallFailureUrl: null,
};

export const reducer = (
  state: IPremierCollectionShopDetailsState = initialState,
  action: actions.PremierCollectionShopDetailsActions
): IPremierCollectionShopDetailsState => {
  switch (action.type) {
    case FETCH_PREMIER_COLLECTION_SHOP_DETAILS:
      return {
        ...state,
        roomInfoProducts: [],
        premierCollectionShopDetailsCallState:
          PremierCollectionShopDetailsCallState.InProcess,
        premierCollectionShopDetailsCallError: null,
        chosenRoomInfoIndex: null,
      };

    case SET_PREMIER_COLLECTION_SHOP_DETAILS_RESULTS:
      return {
        ...state,
        premierCollectionShopDetailsCallState: action.premierCollectionShopDetailsCallState,
        premierCollectionShopDetailsCallError: action.premierCollectionShopDetailsCallError,
        ...action.payload,
      };

    case SET_PREMIER_COLLECTION_SHOP_DETAILS_CALL_STATE_FAILED:
      return {
        ...state,
        premierCollectionShopDetailsCallState: PremierCollectionShopDetailsCallState.Failed,
        premierCollectionShopDetailsCallError: action.premierCollectionShopDetailsCallError,
      };

    case ACKNOWLEDGE_PREMIER_COLLECTION_SHOP_DETAILS_FAILURE:
      return {
        ...state,
        premierCollectionShopDetailsCallState:
          PremierCollectionShopDetailsCallState.FailureAcknowledged,
      };

    case SELECT_ROOM_TYPE:
      return {
        ...state,
        chosenRoomInfoIndex: action.roomInfoIndex,
      };

    case SELECT_LODGING:
      return {
        ...state,
        lodgingData: action.lodgingData,
      };
    case SHOP_CALL_FROM_SHOP_DETAILS:
      return {
        ...state,
        shopCallFromShopDetails: action.shopCallFromShopDetails,
        shopCallFailureUrl: action.shopCallFailureUrl,
      }
    default:
      return { ...state };
  }
};

export * from "./selectors";
