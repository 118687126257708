import { getConfigTenant, isCorpTenant } from "@capone/common";
import { useExperimentIsVariant } from "@capone/experiments";
import { PolicyTier } from "redmond";

export const useShowPolicyBanner = (policies?: PolicyTier) => {
  const isPolicyImprovementEnabled = useExperimentIsVariant(
    "corp-policy-improvement",
    "available"
  );

  const isCorporate = isCorpTenant(getConfigTenant());

  const shouldHidePolicy =
    isCorporate &&
    isPolicyImprovementEnabled &&
    isDefaultHotelPoilicySelected(policies);

  return !shouldHidePolicy;
};

const isDefaultHotelPoilicySelected = (policyTier?: PolicyTier): boolean => {
  return (
    !!policyTier &&
    policyTier.hotels.policies.every((policy) => {
      const isAllStarRatingsAllowed = policy.allowedStarRatings.length === 5;
      const noMaxPricePolicy = policy.maxPricePerNight === undefined;
      return isAllStarRatingsAllowed && noMaxPricePolicy;
    })
  );
};
