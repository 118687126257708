import {
  ITripTerminus,
  IDepartureCalendarReport,
  IMonthBucket,
  SliceStopCountFilter,
  IResult,
  AppliedFilterPropertyType,
  AppliedFilterProperties,
  APPLIED_FILTER,
} from "redmond";

import * as actionTypes from "./constants";
import { FareclassOptionFilter } from "../reducer";
import { ITripTerminusCategory } from "../types";

export interface ISetOrigin {
  type: actionTypes.SET_ORIGIN;
  origin: ITripTerminus | null;
}

export const setOrigin = (origin: ITripTerminus | null): ISetOrigin => ({
  type: actionTypes.SET_ORIGIN,
  origin,
});

export interface ISetOpenDatesModal {
  type: actionTypes.SET_OPEN_DATES_MODAL;
  openDatesModal: boolean;
}

export const setOpenDatesModal = (
  openDatesModal: boolean
): ISetOpenDatesModal => ({
  type: actionTypes.SET_OPEN_DATES_MODAL,
  openDatesModal,
});

export interface ISetOriginCategories {
  type: actionTypes.SET_ORIGIN_CATEGORIES;
  originCategories: ITripTerminusCategory[];
}

export const setOriginCategories = (
  originCategories: ITripTerminusCategory[]
): ISetOriginCategories => ({
  type: actionTypes.SET_ORIGIN_CATEGORIES,
  originCategories,
});

export interface IFetchOriginCategories {
  type: actionTypes.FETCH_ORIGIN_CATEGORIES;
  queryString: string;
}

export const fetchOriginCategories = (
  queryString: string
): IFetchOriginCategories => ({
  type: actionTypes.FETCH_ORIGIN_CATEGORIES,
  queryString,
});

export interface ISetDestination {
  type: actionTypes.SET_DESTINATION;
  destination: IResult | null;
}

export const setDestination = (
  destination: IResult | null
): ISetDestination => ({
  type: actionTypes.SET_DESTINATION,
  destination,
});

export interface ISetDestinationCategories {
  type: actionTypes.SET_DESTINATION_CATEGORIES;
  destinationCategories: ITripTerminusCategory[];
}

export const setDestinationCategories = (
  destinationCategories: ITripTerminusCategory[]
): ISetDestinationCategories => ({
  type: actionTypes.SET_DESTINATION_CATEGORIES,
  destinationCategories,
});

export interface IFetchDestinationCategories {
  type: actionTypes.FETCH_DESTINATION_CATEGORIES;
  queryString: string;
}

export const fetchDestinationCategories = (
  queryString: string
): IFetchDestinationCategories => ({
  type: actionTypes.FETCH_DESTINATION_CATEGORIES,
  queryString,
});

export interface ISetDepartureDate {
  type: actionTypes.SET_DEPARTURE_DATE;
  date: Date | null;
}

export const setDepartureDate = (date: Date | null): ISetDepartureDate => ({
  type: actionTypes.SET_DEPARTURE_DATE,
  date,
});

export interface ISetReturnDate {
  type: actionTypes.SET_RETURN_DATE;
  date: Date | null;
}

export const setReturnDate = (date: Date | null): ISetReturnDate => ({
  type: actionTypes.SET_RETURN_DATE,
  date,
});

export interface IFetchDepartureCalendar {
  type: actionTypes.FETCH_DEPARTURE_CALENDAR;
  origin: ITripTerminus;
  destination: ITripTerminus;
}

export const fetchDepartureCalendar = (
  origin: ITripTerminus,
  destination: ITripTerminus
): IFetchDepartureCalendar => ({
  type: actionTypes.FETCH_DEPARTURE_CALENDAR,
  origin,
  destination,
});

export interface ISetDepartureCalendar extends ISetDepartureCalendarArgs {
  type: actionTypes.SET_DEPARTURE_CALENDAR;
}

export interface ISetDepartureCalendarArgs
  extends Partial<IDepartureCalendarReport> {
  departureMonths?: IMonthBucket[];
}

export const setCalendar = (
  report: ISetDepartureCalendarArgs = {}
): ISetDepartureCalendar => {
  return { type: actionTypes.SET_DEPARTURE_CALENDAR, ...report };
};

export interface ISetStopsOption {
  type: actionTypes.SET_STOPS_OPTION;
  stopsOption: SliceStopCountFilter;
}

export const setStopsOption = (
  stopsOption: SliceStopCountFilter
): ISetStopsOption => {
  return {
    type: actionTypes.SET_STOPS_OPTION,
    stopsOption,
  };
};

export interface ISetFareclassOptionFilter {
  type: actionTypes.SET_FARECLASS_OPTION_FILTER;
  fareclassOptionFilter: FareclassOptionFilter;
}

export const setFareclassOptionFilter = (
  fareclassOptionFilter: FareclassOptionFilter
): ISetFareclassOptionFilter => {
  return {
    type: actionTypes.SET_FARECLASS_OPTION_FILTER,
    fareclassOptionFilter,
  };
};

export interface ISetOriginAutocompleteError {
  type: actionTypes.SET_ORIGIN_AUTOCOMPLETE_ERROR;
  error: boolean;
}

export const setOriginAutocompleteError = (
  error: boolean
): ISetOriginAutocompleteError => ({
  type: actionTypes.SET_ORIGIN_AUTOCOMPLETE_ERROR,
  error,
});

export interface ISetDestinationAutocompleteError {
  type: actionTypes.SET_DESTINATION_AUTOCOMPLETE_ERROR;
  error: boolean;
}

export const setDestinationAutocompleteError = (
  error: boolean
): ISetDestinationAutocompleteError => ({
  type: actionTypes.SET_DESTINATION_AUTOCOMPLETE_ERROR,
  error,
});

export interface ISetTravelers {
  type: actionTypes.SET_TRAVELERS;
  travelers: {
    adultsCount: number;
    children: number[];
    infants: { age: number; inSeat: boolean }[];
  };
}

export const setTravelers = (travelers: {
  adultsCount: number;
  children: number[];
  infants: { age: number; inSeat: boolean }[];
}): ISetTravelers => ({
  type: actionTypes.SET_TRAVELERS,
  travelers,
});

export type PackagesSearchActions =
  | ISetOrigin
  | ISetOriginCategories
  | IFetchOriginCategories
  | ISetDestination
  | ISetDestinationCategories
  | IFetchDestinationCategories
  | ISetDepartureDate
  | ISetReturnDate
  | IFetchDepartureCalendar
  | ISetDepartureCalendar
  | ISetStopsOption
  | ISetFareclassOptionFilter
  | ISetOpenDatesModal
  | ISetOriginAutocompleteError
  | ISetDestinationAutocompleteError
  | ISetTravelers;

export const generateFilterTrackingEvent = (
  type: AppliedFilterPropertyType,
  location: "search" | "list"
): { eventName: string; properties: AppliedFilterProperties } => {
  return {
    eventName: APPLIED_FILTER,
    properties: { filter_type: type, location, funnel: "packages" },
  };
};
