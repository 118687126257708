import {
  ExperienceId,
  ExperiencesPriceBreakdown,
  ExperiencesShopOptionRequest,
  ExperiencesShopResponse,
  ExperiencesShopReviewsRequest,
  ExperiencesShopReviewsResponse,
} from "redmond";
import * as H from "history";
import * as actionTypes from "./constants";

export interface IFetchExperiencesShop {
  type: actionTypes.FETCH_EXPERIENCES_SHOP;
  history: H.History;
}

export const fetchExperiencesShop = (args: {
  history: H.History;
}): IFetchExperiencesShop => ({
  type: actionTypes.FETCH_EXPERIENCES_SHOP,
  ...args,
});

export interface ISetExperiencesShopDetails {
  type: actionTypes.SET_EXPERIENCES_SHOP_DETAILS;
  response: ExperiencesShopResponse;
}

export const setExperiencesShopDetails = (args: {
  response: ExperiencesShopResponse;
}): ISetExperiencesShopDetails => ({
  type: actionTypes.SET_EXPERIENCES_SHOP_DETAILS,
  ...args,
});

export interface ISetExperiencesShopDetailsCallStateFailed {
  type: actionTypes.SET_EXPERIENCES_SHOP_CALL_FAILED;
}

export const setExperiencesShopDetailsCallStateFailed =
  (): ISetExperiencesShopDetailsCallStateFailed => ({
    type: actionTypes.SET_EXPERIENCES_SHOP_CALL_FAILED,
  });

export interface ISetSelectedExperience {
  type: actionTypes.SET_SELECTED_EXPERIENCE;
  experienceId: ExperienceId;
}

export const setSelectedExperience = (
  experienceId: ExperienceId
): ISetSelectedExperience => ({
  type: actionTypes.SET_SELECTED_EXPERIENCE,
  experienceId,
});

export interface IFetchExperiencesShopOptions {
  type: actionTypes.FETCH_EXPERIENCES_SHOP_OPTIONS;
  request: ExperiencesShopOptionRequest;
}

export const fetchExperiencesShopOptions = (args: {
  request: ExperiencesShopOptionRequest;
}): IFetchExperiencesShopOptions => ({
  type: actionTypes.FETCH_EXPERIENCES_SHOP_OPTIONS,
  ...args,
});

export interface ISetExperiencesShopOptions {
  type: actionTypes.SET_EXPERIENCES_SHOP_OPTIONS;
  priceBreakdown: ExperiencesPriceBreakdown;
}

export const setExperiencesShopOptions = (args: {
  priceBreakdown: ExperiencesPriceBreakdown;
}): ISetExperiencesShopOptions => ({
  type: actionTypes.SET_EXPERIENCES_SHOP_OPTIONS,
  ...args,
});

export interface ISetExperiencesShopOptionsCallStateFailed {
  type: actionTypes.SET_EXPERIENCES_SHOP_OPTIONS_CALL_FAILED;
}

export const setExperiencesShopOptionsCallStateFailed =
  (): ISetExperiencesShopOptionsCallStateFailed => ({
    type: actionTypes.SET_EXPERIENCES_SHOP_OPTIONS_CALL_FAILED,
  });

export interface IFetchExperiencesShopReviews {
  type: actionTypes.FETCH_EXPERIENCES_SHOP_REVIEWS;
  request: ExperiencesShopReviewsRequest;
}

export const fetchExperiencesShopReviews = (
  request: ExperiencesShopReviewsRequest
): IFetchExperiencesShopReviews => ({
  type: actionTypes.FETCH_EXPERIENCES_SHOP_REVIEWS,
  request: request,
});

export interface ISetExperiencesShopReviews {
  type: actionTypes.SET_EXPERIENCES_SHOP_REVIEWS;
  response: ExperiencesShopReviewsResponse;
}

export const setExperiencesShopReviews = (
  response: ExperiencesShopReviewsResponse
): ISetExperiencesShopReviews => ({
  type: actionTypes.SET_EXPERIENCES_SHOP_REVIEWS,
  response: response,
});

export interface ISetExperiencesShopReviewsCallStateFailed {
  type: actionTypes.SET_EXPERIENCES_SHOP_REVIEWS_CALL_STATE_FAILED;
}

export const setExperiencesShopReviewsCallStateFailed =
  (): ISetExperiencesShopReviewsCallStateFailed => ({
    type: actionTypes.SET_EXPERIENCES_SHOP_REVIEWS_CALL_STATE_FAILED,
  });

export type ExperiencesShopActions =
  | IFetchExperiencesShop
  | ISetExperiencesShopDetails
  | ISetExperiencesShopDetailsCallStateFailed
  | ISetSelectedExperience
  | IFetchExperiencesShopOptions
  | ISetExperiencesShopOptions
  | ISetExperiencesShopOptionsCallStateFailed
  | IFetchExperiencesShopReviews
  | ISetExperiencesShopReviews
  | ISetExperiencesShopReviewsCallStateFailed;
