import React, { useCallback } from "react";
import { useDeviceTypes } from "halifax";
import { useHistory } from "react-router-dom";
import { PATH_HOME } from "../../../../utils/paths";
import { MyTripsHeader } from "../../../common";
import { HEADER_TITLE, HEADER_SUBTITLE } from "../../constants";
import styles from "./styles.module.scss";
import { Box, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

export const ChangeForAnyReasonHeader = () => {
  const history = useHistory();
  const { matchesMobile } = useDeviceTypes();
  const handleClick = useCallback(() => {
    history.push(PATH_HOME);
  }, [history]);

  if (matchesMobile) {
    return (
      <Box className={styles["mobile-chfar-page-header"]}>
        <FontAwesomeIcon
          className={styles["go-back-button"]}
          icon={faChevronLeft}
          onClick={handleClick}
        />
        <Box className={styles["info-section"]}>
          <Typography className={styles["title-copy"]} variant="subtitle1">
            {HEADER_TITLE}
          </Typography>
          <Typography className={styles["subtitle-copy"]} variant="caption">
            {HEADER_SUBTITLE}
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <MyTripsHeader
      className={styles["desktop-chfar-page-header"]}
      onClick={handleClick}
      tripHeaderCopy={{
        title: HEADER_TITLE,
        subtitle: HEADER_SUBTITLE,
      }}
      hidden={matchesMobile}
    />
  );
};
