import * as React from "react";
import { DesktopPopupModal } from "halifax";
import H from "history";
import clsx from "clsx";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../MonthAndDatePicker";
import { ConnectedDesktopCalendarPicker } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";
import { HotelSearchButton } from "../SearchButton";
import ReactFocusLock from "react-focus-lock";

export interface IDesktopCalendarPickerProps
  extends ConnectedDesktopCalendarPicker {
  open: boolean;
  onSearchDates?: (history: H.History) => void;
  closePopup: () => void;
  unavailableDates?: Date[];
  isSearchButton?: boolean;
}

export const DesktopCalendarPicker = (props: IDesktopCalendarPickerProps) => {
  const {
    open,
    closePopup,
    fromDate,
    untilDate,
    setOpenDatesModal,
    setFromDate,
    setUntilDate,
    onSearchDates,
    unavailableDates,
    isSearchButton,
  } = props;

  const [from, setFrom] = React.useState<Date | null>(null);
  const [until, setUntil] = React.useState<Date | null>(null);

  React.useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  const handleClickDone = (history: H.History) => {
    if (!from || !until) return;
    setFromDate(from);
    setUntilDate(until);
    closePopup();
    setOpenDatesModal(false);
    onSearchDates?.(history);
  };

  return (
    <>
      {open && (
        <ReactFocusLock>
          <DesktopPopupModal
            open={open}
            onClose={closePopup}
            className={clsx(
              "desktop-calendar-picker-popup-root",
              "hotels-module"
            )}
            contentClassName="desktop-calendar-picker-wrapper"
            invisibleBackdrop={false}
            headerElement={textConstants.DESKTOP_CALENDAR_TITLE}
          >
            <MonthAndDatePicker
              className="b2b"
              viewType={MonthAndDatePickerType.Horizontal}
              from={from}
              until={until}
              setFromDate={(val: Date | null) => {
                setFrom(val);
              }}
              setUntilDate={(val: Date | null) => {
                setUntil(val);
              }}
              header={textConstants.DESKTOP_CALENDAR_TITLE}
              unavailableDates={unavailableDates}
            />
            <HotelSearchButton
              onClick={(history: H.History<unknown>) =>
                handleClickDone(history)
              }
              className="select-dates-button-with-search"
              message={
                isSearchButton
                  ? textConstants.SEARCH_BUTTON_TEXT
                  : textConstants.DONE_BUTTON_TEXT
              }
              enabled={!!from && !!until}
            />
          </DesktopPopupModal>
        </ReactFocusLock>
      )}
    </>
  );
};
