import { createSelector } from "@reduxjs/toolkit";
import { ActiveExperiments } from "../../../../context/experiments";
import { ChFARExperimentSliceState } from "./slice";

export type ChFARExperimentState = {
  changeForAnyReason: {
    experiments: ChFARExperimentSliceState;
  };
};

export const selectExperiments = (state: ChFARExperimentState) =>
  state.changeForAnyReason.experiments.values;

export const selectIsChFAREnabled = createSelector(
  [selectExperiments],
  (experiments) =>
    experiments[ActiveExperiments.CHFAR] === "available" ||
    experiments[ActiveExperiments.CHFAR] === "declineAll"
);
