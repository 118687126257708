import { takeLatest } from "redux-saga/effects";

import { FETCH_PREMIER_COLLECTION_SHOP_DETAILS } from "../actions/constants";
import fetchPremiereCollectionHotelDetailsSaga from "./fetchPremiereCollectionHotelDetailsSaga";

export function* watchFetchPremierCollectionShopDetailsSaga() {
  yield takeLatest(
    FETCH_PREMIER_COLLECTION_SHOP_DETAILS,
    fetchPremiereCollectionHotelDetailsSaga
  );
}
