import { ExperiencesShopReviewsResponse } from "redmond";
import { put } from "redux-saga/effects";
import { actions } from "../actions";
import Logger from "../../../utils/logger";
import { fetchExperiencesShopReviews } from "../../../api/v0/shop/fetchExperiencesShopReviews";

export function* fetchExperiencesShopReviewsSaga(
  action: actions.IFetchExperiencesShopReviews
) {
  try {
    const reviewsResponse: ExperiencesShopReviewsResponse =
      yield fetchExperiencesShopReviews(action.request);
    yield put(actions.setExperiencesShopReviews(reviewsResponse));
  } catch (e) {
    yield put(actions.setExperiencesShopReviewsCallStateFailed());
    Logger.debug(e);
  }
}
