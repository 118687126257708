import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";

import {
  getPremierCollectionShopSelectedAvailability,
  getViewedPremierCollectionDetailsProperties,
} from "../../reducer/selectors";
import { MobileHotelPhotoModal } from "./component";
import { getStayType } from "../../../search/reducer";
import { getVacationRentalShopSelectedListing } from "../../../vacation-rental-shop/reducer";
import { getPremierCollectionShopDetailsSelectedLodgingData } from "../../../shop-details/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    lodging: getPremierCollectionShopSelectedAvailability(state) || undefined,
    viewedPremierCollectionDetailsProperties:
      getViewedPremierCollectionDetailsProperties(state),
    stayType: getStayType(state),
    listing: getVacationRentalShopSelectedListing(state),
    shopDetailsLodgingData: getPremierCollectionShopDetailsSelectedLodgingData(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileHotelPhotoModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileHotelPhotoModal = connector(MobileHotelPhotoModal);
