import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  ActionLink,
  MobilePopoverCard,
  MobileFloatingButton,
  Icon,
  IconName,
  CloseButtonIcon,
} from "halifax";
import { RouteComponentProps } from "react-router";
import { MobilePopoverCalendarButtonConnectorProps } from "./container";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../search/components/PremierCollectionSearchControl/components/MonthAndDatePicker";
import "./styles.scss";
import * as textConstants from "./textConstants";
import {transformToStringifiedQuery} from "../../../shop/utils/queryStringHelpers";

export interface IMobilePopoverCalendarButtonProps
  extends RouteComponentProps,
    MobilePopoverCalendarButtonConnectorProps {
  handleShopCall: (queryParams: string) => void;
}

export const MobilePopoverCalendarButton = (props: IMobilePopoverCalendarButtonProps) => {
  const {
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    setFromDate,
    setUntilDate,
    handleShopCall,
    openDatesModal,
    setOpenDatesModal,
    shopDetailsLodgingData,
  } = props;
  const [from, setFrom] = useState<Date | null>(fromDate);
  const [until, setUntil] = useState<Date | null>(untilDate);

  const onClose = () => setOpenDatesModal(false);

  const onDatesSelect = () => {
    const params = transformToStringifiedQuery({
      lodgingId: shopDetailsLodgingData?.id || "",
      fromDate: from,
      untilDate: until,
      selectedLodgingIndex: 0, // defaulting to 0 since not from availability list
      adultsCount: adultsCount,
      childrenCount: childrenCount,
      selectedAccountIndex: 0,
    });
    handleShopCall(params);
  }

  useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {textConstants.CHOOSE_DATES}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={onClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };

  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <MobilePopoverCard
        open={openDatesModal}
        onClose={onClose}
        className="mobile-pc-shop-header-root"
        contentClassName="mobile-shop-header-content-wrapper"
        headerElement={<MobileSearchPopoverHeader />}
      >
        <Box className="mobile-shop-header-content-container">
          <Box className="pc-mobile-calendar-picker-root">
            <MonthAndDatePicker
              viewType={MonthAndDatePickerType.Column}
              from={from}
              until={until}
              setFromDate={setFrom}
              setUntilDate={setUntil}
              className="b2b"
              header={
                <Box className="premier-collection-mobile-calendar-header">
                  <DatePickerHeader
                    label={textConstants.CHECK_IN}
                    value={from ? dayjs(from).format("ddd D, MMM") : undefined}
                  />
                  <DatePickerHeader
                    label={textConstants.CHECK_OUT}
                    value={
                      until ? dayjs(until).format("ddd D, MMM") : undefined
                    }
                  />
                </Box>
              }
            />
            <MobileFloatingButton
              className="mobile-calendar-picker-search-button"
              disabled={!(!!from && !!until)}
              onClick={() => {
                setFromDate(from);
                setUntilDate(until);
                onDatesSelect()
                setOpenDatesModal(false);
              }}
              wrapperClassName="b2b"
            >
              {textConstants.SEARCH_HOTELS}
            </MobileFloatingButton>
          </Box>
        </Box>
      </MobilePopoverCard>
    </>
  );
};
