import axios from "axios";
import {
  AirExchangeBookPollResponse,
  IPollExchangeBookReq,
  Uuid,
} from "redmond";

import { config } from "../../config";
import { pollExchangeBookFinalizePath } from "../paths";
import { CipherText } from "@b2bportal/purchase-api";

const pollExchangeBook = (
  sessionId: Uuid,
  token?: CipherText,
  cartQuoteId?: Uuid
): Promise<AirExchangeBookPollResponse> => {
  let intervalId: number;

  return new Promise((resolve, reject) => {
    if (!sessionId) reject("Session ID is required");
    intervalId = window.setInterval(async () => {
      try {
        const req: IPollExchangeBookReq = {
          sessionId,
          token,
          cartQuoteId,
        };
        const res = await axios.put(pollExchangeBookFinalizePath, req, {
          baseURL: config.baseURL,
        });

        if (
          res.data.AirExchangeBookPollResponse ===
          AirExchangeBookPollResponse.success
        ) {
          clearInterval(intervalId);
          return resolve(res.data.AirExchangeBookPollResponse);
        } else if (
          res.data.AirExchangeBookPollResponse !==
          AirExchangeBookPollResponse.pending
        ) {
          clearInterval(intervalId);
          return reject(res.data.error);
        }
      } catch (e) {
        clearInterval(intervalId);
        return reject(e);
      }
    }, 3000);
  });
};

export default pollExchangeBook;
