import React, { ReactElement, useContext, useEffect } from "react";
import { GenericBanner, useDeviceTypes } from "halifax";
import { RouterProps } from "react-router-dom";
import { B2B_PORTAL_BANNER_CONTAINER_ID, CustomerAccountRole } from "redmond";
import { ClientContext } from "../../../../App";
import { RewardsBannerConnectorProps } from "./container";
import {
  PATH_BOOK,
  PATH_BOOK_CONFIRMATION,
  PATH_FLIGHT_SHOP,
  PATH_HOME,
  PATH_HOTEL_SHOP,
  PATH_HOTELS_AVAILABILITY,
  PATH_REVIEW,
} from "../../../../utils/paths";
import { REWARDS_BANNER_TEXT } from "@capone/common";
import { config } from "../../../../api/config";

export interface IRewardsBannerProps
  extends RewardsBannerConnectorProps,
    RouterProps {}

export const RewardsBanner = (props: IRewardsBannerProps) => {
  const { history, largestValueAccount: account, fetchRewardsAccounts } = props;

  useEffect(() => {
    fetchRewardsAccounts();
  }, []);

  const { matchesMobile } = useDeviceTypes();
  const firstName =
    useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  let contentText: string | ReactElement = "";

  const {
    earn,
    lastFour = "",
    productDisplayName: productId = "",
    rewardsBalance,
  } = account || {};
  const { currency, currencyDescription } = rewardsBalance || {};
  const {
    hotelsMultiplier: earnRate = "",
    flightsMultiplier: flightEarnRate = "",
  } = earn || {};

  //if no earn multiplier do not display a banner or if its less than 0
  if (
    !account ||
    !account?.earn?.hotelsMultiplier ||
    (earnRate && earnRate <= 0)
  ) {
    return null;
  }

  // TODO: handle texts properly once the backend is ready
  switch (history.location.pathname) {
    case PATH_HOME.slice(0, -1):
    case PATH_HOME:
      if (!matchesMobile) {
        contentText = REWARDS_BANNER_TEXT({
          firstName,
          earnRate,
          productId,
          lastFour,
          flightEarnRate,
          currency: currencyDescription || currency,
          isPrimary:
            account.customerAccountRole === CustomerAccountRole.Primary,
          tenant: config.TENANT,
        });
      }
      // hide the banner on mobile search workflow
      else {
        contentText = "";
      }
      break;
    case PATH_HOTELS_AVAILABILITY.slice(0, -1):
    case PATH_HOTELS_AVAILABILITY:
    case PATH_HOTEL_SHOP.slice(0, -1):
    case PATH_HOTEL_SHOP:
    case PATH_FLIGHT_SHOP.slice(0, -1):
    case PATH_FLIGHT_SHOP:
    case PATH_REVIEW:
    case PATH_BOOK:
    case PATH_BOOK_CONFIRMATION:
    case PATH_REVIEW.slice(0, -1):
      contentText = REWARDS_BANNER_TEXT({
        firstName,
        earnRate,
        productId,
        lastFour,
        flightEarnRate,
        currency: currencyDescription || currency,
        isPrimary: account.customerAccountRole === CustomerAccountRole.Primary,
        tenant: config.TENANT,
      });
      break;
  }

  if (!contentText) {
    return null;
  }

  return (
    <GenericBanner
      className="rewards-banner-root"
      content={contentText}
      containerId={B2B_PORTAL_BANNER_CONTAINER_ID}
      isMobile={matchesMobile}
    />
  );
};
