import { Box, Divider, Typography } from "@material-ui/core";
import React, { useState, useCallback, useMemo, useContext } from "react";
import * as textConstants from "./constants";
import {
  ActionLink,
  CurrencyFormatters,
  getTotalPriceText,
  twoDecimalFormatter,
} from "halifax";
import {
  Airline,
  getDepartureSlice,
  getReturnSlice,
  PackageItinerary,
} from "redmond";
import dayjs from "dayjs";
import "./styles.scss";
import { ClientContext } from "../../../../../../../../App";
import { getFlightItineraryDetails } from "./constants";
import { formatPaymentBreakdown, ILineItem } from "../../../FlightCard/helpers";
import clsx from "clsx";

interface IPackageItemizedReceiptModalContentProps {
  packageItinerary: PackageItinerary;
  documentId?: string;
  isMobile?: boolean;
  airlineMap: { [key: string]: Airline };
}

export const PackageItemizedReceiptModalContent = ({
  packageItinerary,
  documentId = textConstants.DEFAULT_DOC_ID,
  isMobile,
  airlineMap,
}: IPackageItemizedReceiptModalContentProps) => {
  const { logo } = useContext(ClientContext);
  const { pricingBreakdown, hotel, flight, paymentBreakdown } =
    packageItinerary;
  const { reservation } = hotel.itinerary;
  const {
    guests,
    lodgingData,
    reservationId,
    createdAt,
    checkInDate,
    checkOutDate,
  } = reservation;
  const { bookedItinerary } = flight.itinerary;

  const [printing, setPrinting] = useState(false);

  const guestName = `${guests[0].givenName} ${guests[0].surname}`;
  const resId = `H-${reservationId}`;
  const addCurrency = (num: number) =>
    `${CurrencyFormatters.get(
      pricingBreakdown.subtotal.totalAmount.fiat.currencyCode
    ).format(Math.abs(num))}`;
  const invoiceDate = dayjs(createdAt, "YYYY-MM-DDTHH:mm:ss.SSS").format(
    textConstants.MMDDYYYY_FORMAT
  );

  const flightDetails = getFlightItineraryDetails(
    bookedItinerary.travelItinerary.locators,
    getDepartureSlice(bookedItinerary),
    getReturnSlice(bookedItinerary),
    airlineMap
  );

  const lineItems =
    paymentBreakdown && formatPaymentBreakdown(paymentBreakdown);

  const handlePrint = useCallback(() => {
    setPrinting(true);

    setTimeout(() => {
      const divContents = document.getElementById(documentId)?.innerHTML;
      const cssHeadContents = document.head?.getElementsByTagName("style");
      const cssBodyContents = document.body.getElementsByTagName("style");
      const cssBodyStyle = document.body.getAttribute("style");
      const a = window.open(" ", " ", "height=800, width=400");
      const doc = a?.document;
      if (doc) {
        doc.write("<html><head>");
        for (let i = 0; i < cssHeadContents.length; i++) {
          doc.write(
            `<style>${cssHeadContents.item(i)?.innerHTML || ""}</style>`
          );
        }

        doc.write(`</head><body style="${cssBodyStyle}" >${divContents || ""}`);

        for (let i = 0; i < cssBodyContents.length; i++) {
          doc.write(
            `<style>${cssBodyContents.item(i)?.innerHTML || ""}</style>`
          );
        }
        doc.write("</body></html>");
        if (a)
          a.document.title = `${guestName} - ${resId} ${textConstants.TITLE}`;
        setTimeout(() => {
          a?.print();
          doc.close();
          setPrinting(false);
        }, 500);
      }
    }, 10);
  }, []);

  const renderRewards = () => {
    const rewards = lineItems?.rewards;
    if (!rewards) {
      return null;
    }
    return (
      <Box className="line-item">
        <Typography variant="body1" className="green bold">
          {rewards.label}
        </Typography>
        <Typography variant="body1" className="rewards-points bold">
          {rewards.amount}
        </Typography>
      </Box>
    );
  };

  const renderPayment = () => {
    const userCard = lineItems?.userCard;
    if (!userCard) {
      return null;
    }
    return (
      <Box className="line-item">
        <Typography variant="body1" className="payment-card-details">
          {`Card ending in ${userCard.label?.slice(-4)}`}
        </Typography>

        <Typography variant="body1" className="payment-card-amount bold">
          {userCard.amount}
        </Typography>
      </Box>
    );
  };

  const renderTravelWallet = (travelWallet: ILineItem | null) => {
    if (!travelWallet) return null;
    return (
      <Box className="line-item">
        <Typography variant="body1" className="green bold">
          {travelWallet.label}
        </Typography>
        <Typography variant="body1" className="offer-amount bold green">
          {travelWallet.amount}
        </Typography>
      </Box>
    );
  };

  const renderOffers = () =>
    lineItems && renderTravelWallet(lineItems?.travelWalletOffer);

  const renderCredits = () =>
    lineItems?.travelWalletCredit?.map((credit) => renderTravelWallet(credit));

  const renderPrintButton = useMemo(() => {
    if (printing) return null;

    return isMobile ? (
      <></>
    ) : (
      <ActionLink
        className="print-btn"
        content={textConstants.PRINT}
        onClick={handlePrint}
      />
    );
  }, [isMobile, printing]);

  return (
    <Box className="itemized-receipt-modal-container" id={documentId}>
      <Box className="itemized-package-receipt-modal-content">
        <Box className="itemized-receipt-logo-container">
          {logo}
          {renderPrintButton}
        </Box>
        {/* hotel section */}
        <Box className="itemized-receipt-title-container">
          <Typography variant="h2">{textConstants.TITLE}</Typography>
        </Box>
        <Box className="itemized-receipt-details">
          <Box className="line-item">
            <Typography variant="body1">{textConstants.HOTEL_NAME}:</Typography>
            <Typography variant="body1" className="hotel-name bold">
              {lodgingData.name}
            </Typography>
          </Box>
          <Box className="line-item">
            <Typography variant="body1">{textConstants.GUEST_NAME}:</Typography>
            <Typography variant="body1" className="bold">
              {guestName}
            </Typography>
          </Box>
          <Box className="line-item gutter-bottom">
            <Typography variant="body1">
              {textConstants.CONFIRMATION_NUMBER}:
            </Typography>
            <Typography variant="body1" className="bold green">
              {resId}
            </Typography>
          </Box>
          <Divider className="line-item gutter-bottom" />
          {/* flight section */}
          <Box className="line-item">
            <Typography variant="body1">
              {textConstants.OUTBOUND_AIRLINE}
            </Typography>
            <Typography variant="body1" className="hotel-name bold">
              {flightDetails.outboundAirlineName}
            </Typography>
          </Box>
          <Box className="line-item">
            <Typography variant="body1">
              {textConstants.RETURN_AIRLINE}:
            </Typography>
            <Typography variant="body1" className="hotel-name bold">
              {flightDetails.returnAirlineName}
            </Typography>
          </Box>
          <Box className="line-item">
            <Typography variant="body1">{textConstants.TRAVELERS}:</Typography>
            <Box>
              {bookedItinerary.passengers.alone.map((pax) => {
                return (
                  <Typography className="bold right-align">
                    {pax.person.givenName} {pax.person.surname}
                  </Typography>
                );
              })}
              {bookedItinerary.passengers.withLapInfants.map((pax) => {
                return (
                  <>
                    <Typography className="bold right-align">
                      {pax.adult.person.givenName} {pax.adult.person.surname}
                    </Typography>
                    <Typography className="bold right-align">
                      {pax.infant.person.givenName} {pax.infant.person.surname}
                    </Typography>
                  </>
                );
              })}
            </Box>
          </Box>

          <Box className="line-item gutter-bottom">
            <Typography variant="body1">
              {textConstants.CONFIRMATION_NUMBER}:
            </Typography>
            <Typography variant="body1" className="bold green">
              {flightDetails.confirmationCode}
            </Typography>
          </Box>
          <Divider className="line-item gutter-bottom" />

          <Box className="line-item">
            <Typography variant="body1">
              {textConstants.INVOICE_DATE}:
            </Typography>
            <Typography variant="body1" className="bold">
              {invoiceDate}
            </Typography>
          </Box>
          <Box className="line-item">
            <Typography variant="body1">{textConstants.CHECK_IN}:</Typography>
            <Typography variant="body1" className="bold">
              {dayjs(checkInDate).format(textConstants.MMDDYYYY_FORMAT)}
            </Typography>
          </Box>
          <Box className="line-item gutter-bottom">
            <Typography variant="body1">{textConstants.CHECKOUT}:</Typography>
            <Typography variant="body1" className="bold">
              {dayjs(checkOutDate).format(textConstants.MMDDYYYY_FORMAT)}
            </Typography>
          </Box>

          <Divider className="line-item gutter-bottom" />
          <Typography variant="body1" className="bold section-title">
            {textConstants.TOTAL_PER_TRAVELER}:
          </Typography>

          <Box
            className={clsx("line-item", {
              "gutter-bottom": !pricingBreakdown.seatsAmount,
            })}
          >
            <Typography variant="body1">
              {textConstants.PER_TRAVELER}:
            </Typography>
            <Typography variant="body1" className="bold">
              {addCurrency(
                pricingBreakdown.subtotal.perTravelerAmount.fiat.value
              )}
            </Typography>
          </Box>
          {pricingBreakdown.seatsAmount && (
            <Box className="line-item gutter-bottom">
              <Typography variant="body1">
                {textConstants.SEAT_SELECTION}:
              </Typography>
              <Typography variant="body1" className="bold">
                {getTotalPriceText({
                  price: pricingBreakdown.seatsAmount?.fiat,
                  priceFormatter: twoDecimalFormatter,
                })}
              </Typography>
            </Box>
          )}
          <Divider className="line-item gutter-bottom" />
          <Box className="line-item">
            <Typography variant="body1" className="gutter-bottom bold">
              {textConstants.getPackageTotal(
                pricingBreakdown.subtotal.numTravelers
              )}
            </Typography>
            <Typography variant="body1" className="gutter-bottom bold">
              {addCurrency(pricingBreakdown.subtotal.totalAmount.fiat.value)}
            </Typography>
          </Box>
          <Typography variant="caption" className="line-item gutter-bottom">
            {textConstants.COPY1}
          </Typography>
          <Divider className="line-item gutter-bottom" />

          <Box className="gutter-bottom">
            <Typography variant="body1" className="bold section-title">
              {textConstants.PAYMENT_INFO}
            </Typography>

            {renderOffers()}
            {renderCredits()}
            {renderRewards()}
            {renderPayment()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
