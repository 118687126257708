import React, { useEffect } from "react";
import { Icon, IconName, MultiSelectDropdown } from "halifax";
import "./styles.scss";
import * as textConstants from "./textConstants";

import { Box, Typography } from "@material-ui/core";
import { IAirportOptions } from "../../../../reducer";
import { trackEvent } from "../../../../../../api/v0/trackEvent";
import { generateFilterTrackingEvent } from "../../../../../search/actions/actions";

interface IAirportFilterProps {
  airportFilter: string[];
  setAirportFilter: (airportFilter: string[]) => void;
  allAirports: IAirportOptions[];
  showDropdownContentOnly?: boolean;
  icon?: IconName;
  title?: string;
  optionLabelPlacement?: "start" | "end";
  displayLabelAndValue?: boolean;
}

export const AirportFilterSelection = (props: IAirportFilterProps) => {
  const {
    airportFilter = [],
    setAirportFilter,
    allAirports,
    showDropdownContentOnly,
    icon,
    title,
    optionLabelPlacement,
    displayLabelAndValue,
  } = props;

  useEffect(() => {
    if (airportFilter.length > 0)
      trackEvent(generateFilterTrackingEvent("airport", "list"));
  }, [airportFilter]);

  return (
    <Box className="flight-shop-airport-filter-container" tabIndex={0}>
      {!!title && showDropdownContentOnly && (
        <Box className="header-container">
          {icon && <Icon name={icon} />} {title}
        </Box>
      )}
      <MultiSelectDropdown
        currentValue={airportFilter}
        dropdownLabel={<Typography>{textConstants.AIRPORTS}</Typography>}
        options={allAirports}
        setValue={setAirportFilter}
        showDropdownContentOnly={showDropdownContentOnly}
        className="b2b-shop-filter"
        popoverClassName="b2b"
        optionLabelPlacement={optionLabelPlacement}
        displayLabelAndValue={displayLabelAndValue}
      />
    </Box>
  );
};
