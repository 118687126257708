import React, { useState } from "react";
import clsx from "clsx";
import { Button } from "@material-ui/core";

import { DatePickerButton, DesktopPopupModal } from "halifax";

import * as textConstants from "../../textConstants";
import "./styles.scss";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../../../common/MonthAndDatePicker";

export interface ICalendarPickerButtonProps {
  classes?: string[];
  fromDate: Date | null;
  untilDate: Date | null;
  setFromDate: (date: Date | null) => void;
  setUntilDate: (date: Date | null) => void;
  hasMissingSearchInfoError?: boolean;
}

export const CalendarPickerButton = ({
  classes = [],
  fromDate,
  untilDate,
  hasMissingSearchInfoError,
  setFromDate,
  setUntilDate,
}: ICalendarPickerButtonProps) => {
  const [isDatesModalOpen, setIsDatesModalOpen] = useState(false);

  const handleOpen = () => {
    setIsDatesModalOpen(true);
  };

  const handleClose = () => {
    setIsDatesModalOpen(false);
  };

  return (
    <DatePickerButton
      startDate={fromDate}
      startLabel={textConstants.SEARCH_DATES_LABEL}
      endDate={untilDate}
      classes={classes}
      variant="combinedDates"
      renderCalendarPopup={() => (
        <DesktopPopupModal
          open={isDatesModalOpen}
          onClose={handleClose}
          className={clsx(
            "desktop-calendar-picker-popup-root",
            "desktop-experiences-calendar-picker-popup-root",
            "experiences-module"
          )}
          contentClassName="desktop-calendar-picker-wrapper"
          invisibleBackdrop={false}
        >
          <MonthAndDatePicker
            viewType={MonthAndDatePickerType.Horizontal}
            startDate={fromDate}
            endDate={untilDate}
            setStartDate={setFromDate}
            setEndDate={setUntilDate}
            header={textConstants.DESKTOP_CALENDAR_TITLE_RANGE}
            className="b2b-experiences"
          />
          <Button
            onClick={handleClose}
            disabled={!fromDate || !untilDate}
            className="select-dates-button"
            variant="contained"
          >
            {textConstants.DONE_BUTTON_TEXT}
          </Button>
        </DesktopPopupModal>
      )}
      onClick={handleOpen}
      dateFormat="ddd, MMM D"
      hideEndDate
      hideSeparator
      hasMissingSearchInfoError={hasMissingSearchInfoError}
    />
  );
};
