import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { Box, Link, Typography, useTheme } from "@material-ui/core";
import { ReportProblemOutlined } from "@material-ui/icons";
import {
  B2BTextField,
  BannerSeverity,
  Icon,
  IconName,
  NotificationBanner,
} from "halifax";
import { AMADEUS, getHotelLoyaltyProgram } from "@capone/common";
import { IPerson } from "redmond";
import { getHotelShopChosenProductHotelLoyaltyProgramCode } from "../../../../shop/reducer/selectors";
import { LOYALTY_HYATT_CHIP_LABELS } from "./constants";
import { getHotelBookChosenProduct } from "../../../reducer/selectors";
import "./styles.scss";

export interface LoyaltyInformationFormProps {
  isMobile?: boolean;
  onChange: (value: string) => void;
  hasSaved?: boolean;
  disabled?: boolean;
  traveler: IPerson | undefined;
  setShowLoyaltyError?: (isError: boolean) => void;
}

export const getLoyaltyNumberForChosenProduct = (
  loyaltyProgram: string | undefined,
  traveler: IPerson | undefined
) => {
  if (!loyaltyProgram || !traveler) {
    return null;
  }

  return traveler?.hotelLoyalty[loyaltyProgram]?.value ?? null;
};

export const LoyaltyInformationForm = ({
  isMobile,
  onChange,
  hasSaved,
  disabled,
  traveler,
  setShowLoyaltyError,
}: LoyaltyInformationFormProps) => {
  const [value, setValue] = useState("");
  const theme = useTheme();

  const loyaltyProgramCode = useSelector(
    getHotelShopChosenProductHotelLoyaltyProgramCode
  );

  const chosenProduct = useSelector(getHotelBookChosenProduct);
  const isAmadeusProvider = chosenProduct?.providerName === AMADEUS;
  const selectedTravelerLoyaltyNumber = getLoyaltyNumberForChosenProduct(
    loyaltyProgramCode,
    traveler
  );

  const worldOfHyattLoyaltyProgramCode = "HYA";
  const loyaltyProgram = getHotelLoyaltyProgram(loyaltyProgramCode);
  const isHyattLoyaltyAmadeusProgram =
    loyaltyProgram?.loyaltyProgramCode === worldOfHyattLoyaltyProgramCode &&
    isAmadeusProvider;
  const hasEmptyError = isHyattLoyaltyAmadeusProgram && !value;

  useEffect(() => {
    setValue(selectedTravelerLoyaltyNumber || "");
  }, [selectedTravelerLoyaltyNumber]);

  const prevErrorRef = useRef<boolean>(false);

  useEffect(() => {
    if (setShowLoyaltyError && prevErrorRef.current !== hasEmptyError) {
      setShowLoyaltyError(hasEmptyError);
      prevErrorRef.current = hasEmptyError;
    }
  }, [hasEmptyError, setShowLoyaltyError]);

  const handleChange = (value: string) => {
    setValue(value);
    onChange(value);
  };

  return (
    <>
      <Box
        className={clsx("loyalty-info-form-container", {
          mobile: isMobile,
        })}
      >
        <Box className="loyalty-info-form-description">
          <label htmlFor="loyalty-email-input-field">
            <Typography className="title" component="span">
              Hotel loyalty information
              <Icon className="icon" name={IconName.StarCircledIcon} />
            </Typography>
          </label>
          <Typography className="subtitle" variant="body2">
            Please enter your loyalty program number carefully.
          </Typography>
        </Box>
        <div className="loyalty-info-form">
          <B2BTextField
            value={value}
            disabled={disabled}
            width="100%"
            errorHelper={
              hasEmptyError ? "Enter a valid loyalty program number" : ""
            }
            className="loyalty-input-field"
            label={
              <Typography variant="body2">
                <strong>{loyaltyProgram?.loyaltyProgramName}</strong> Loyalty
                program number
                {!isHyattLoyaltyAmadeusProgram && "  (Optional)"}
              </Typography>
            }
            required={isHyattLoyaltyAmadeusProgram}
            onChange={handleChange}
            type="text"
            id="loyalty-email-input-field"
            endAdornment={
              hasSaved && (
                <Typography
                  variant="inherit"
                  className="loyalty-input-field-saved"
                >
                  <Icon name={IconName.CheckMarkGreen} />
                  Saved
                </Typography>
              )
            }
          />
        </div>
      </Box>
      {isHyattLoyaltyAmadeusProgram && (
        <NotificationBanner
          icon={
            <ReportProblemOutlined
              style={{
                color: theme.palette.warning.main,
              }}
            />
          }
          content={
            <Box style={{ marginLeft: "10px" }}>
              <Typography
                variant="body2"
                className="label"
                style={{
                  fontWeight: "bold",
                  fontSize: "14px",
                  color: "black",
                }}
              >
                {LOYALTY_HYATT_CHIP_LABELS.title}
              </Typography>
              <Typography
                variant="body2"
                className="label"
                style={{
                  color: "black",
                }}
              >
                {LOYALTY_HYATT_CHIP_LABELS.description}{" "}
                <Link
                  href={LOYALTY_HYATT_CHIP_LABELS.enroll_url}
                  underline="always"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    fontWeight: "bold",
                    color: theme.palette.primary.main,
                  }}
                >
                  {LOYALTY_HYATT_CHIP_LABELS.enroll_label}
                </Link>
              </Typography>
            </Box>
          }
          severity={BannerSeverity.WARNING}
        />
      )}
    </>
  );
};
