import dayjs from "dayjs";
import { TripDetails, FareDetails, TripSlice, FareSliceDetails } from "redmond";

const OUTBOUND = "Outbound";
const RETURN = "Return";

export const getStopsString = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

export const getReviewCardHeaderWithType = (
  isDeparture: boolean,
  location: string,
  date: Date | string
) => ({
  type: isDeparture ? OUTBOUND : RETURN,
  description: `<strong> to ${location}
    </strong> on ${dayjs(date).format("ddd, MMM D")}`,
});

export const getSliceIndex = (
  departure: boolean,
  details: TripDetails | FareDetails
): number => {
  return details.slices.findIndex((slice: TripSlice | FareSliceDetails) =>
    departure ? slice.outgoing : !slice.outgoing
  );
};

export const OPERATING_AIRLINES_MODAL_TITLE = "Operating airlines";

export const OPERATING_AIRLINES_TOOLTIP_TRIGGER_TEXT = (airlineCount: number) =>
  `+${airlineCount} more`;
