import { ParentState, PassengerInformationState } from "@capone/checkout";
import {
  CART_UPDATE_TEXT,
  LOADING_GENERIC,
  LOADING_TRAVELERS,
} from "./textConstants";

export const getLoadingMessage = (
  parentState: ParentState,
  childState?: string
) => {
  switch (true) {
    case parentState === ParentState.passengerInformation &&
      childState === PassengerInformationState.loading:
      return LOADING_TRAVELERS;
    case parentState === ParentState.loading:
      return LOADING_GENERIC;
    case parentState === ParentState.cartUpdate:
    case parentState === ParentState.cartUpdateForSeats:
      return CART_UPDATE_TEXT;
    default:
      return undefined;
  }
};
