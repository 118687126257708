// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lj8B4j\\+2GY74zNkYSvB6EQ\\=\\={top:50px}.eBISuVl9c\\+nbMCZz1Wyhuw\\=\\={background:var(--white);border-radius:0;height:50px;display:flex;flex-direction:row;align-items:center;padding:0 16px;border-bottom:.5px solid var(--grey-10)}.eBISuVl9c\\+nbMCZz1Wyhuw\\=\\= ._44j34BpQVAujebyWiZm6Dw\\=\\={color:var(--blue-9)}.eBISuVl9c\\+nbMCZz1Wyhuw\\=\\= .w\\+byuqXSOLpR85MInKkpjg\\=\\={flex-grow:1;display:flex;flex-direction:column;align-items:center}.eBISuVl9c\\+nbMCZz1Wyhuw\\=\\= .fa0HsGn5uMOsq8cXdgKKxA\\=\\={font-weight:400;font-size:14px}", "",{"version":3,"sources":["webpack://./../../cap1-modules/trips-module/src/pages/ChangeForAnyReason/components/ChangeForAnyReasonHeader/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,QAAA,CAGF,6BACE,uBAAA,CACA,eAAA,CACA,WAAA,CACA,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,cAAA,CACA,uCAAA,CAEA,0DACE,mBAAA,CAEF,0DACE,WAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CAEF,yDACE,eAAA,CACA,cAAA","sourcesContent":[".desktop-chfar-page-header {\n  top: 50px;\n}\n\n.mobile-chfar-page-header {\n  background: var(--white);\n  border-radius: 0;\n  height: 50px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  padding: 0 16px;\n  border-bottom: 0.5px solid var(--grey-10);\n\n  .go-back-button {\n    color: var(--blue-9);\n  }\n  .info-section {\n    flex-grow: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n  }\n  .title-copy {\n    font-weight: 400;\n    font-size: 14px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"desktop-chfar-page-header": "lj8B4j+2GY74zNkYSvB6EQ==",
	"mobile-chfar-page-header": "eBISuVl9c+nbMCZz1Wyhuw==",
	"go-back-button": "_44j34BpQVAujebyWiZm6Dw==",
	"info-section": "w+byuqXSOLpR85MInKkpjg==",
	"title-copy": "fa0HsGn5uMOsq8cXdgKKxA=="
};
export default ___CSS_LOADER_EXPORT___;
