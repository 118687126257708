import { combineReducers } from "@reduxjs/toolkit";
import { reducer as premierCollectionRewards } from "../modules/rewards/reducer";
import { reducer as premierCollectionSearch } from "../modules/search/reducer";
import { reducer as premierCollectionAvailability } from "../modules/availability/reducer";
import { reducer as premierCollectionTravelWallet } from "../modules/travel-wallet/reducer";
import { reducer as premierCollectionShop } from "../modules/shop/reducer";
// TODO: refactor it as a package, see https://hopper-jira.atlassian.net/browse/BF-1514
import { reducer as hotelAncillary } from "../modules/ancillary/reducer";
import { reducer as premierCollectionBook } from "../modules/book/reducer";
import { reducer as vacationRentalShop } from "../modules/vacation-rental-shop/reducer";
import { reducer as premierCollectionShopDetails } from "../modules/shop-details/reducer";

export const rootReducer = combineReducers({
  premierCollectionRewards,
  premierCollectionSearch,
  premierCollectionAvailability,
  premierCollectionTravelWallet,
  premierCollectionShop,
  premierCollectionShopDetails,
  premierCollectionBook,
  hotelAncillary,
  vacationRentalShop,
});
