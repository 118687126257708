import axios from "axios";
import { TrackEventRequest } from "redmond";

import { config } from "../../config";
import { postAnalyticEventPath } from "../paths";
import { store } from "../../../../../../cap1-application/b2b-base/src/app/store";
import { getGlobalTrackingProperties } from "@capone/common";

export const trackEvent = (req: TrackEventRequest): Promise<boolean> =>
  new Promise(async (resolve, reject) => {
    try {
      req.properties = {
        ...req.properties,
        ...getGlobalTrackingProperties(
          config.TENANT,
          store.getState().rootReducer
        ),
      };
      const res = await axios.post(postAnalyticEventPath, req, {
        baseURL: config.baseURL,
      });

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
