import {
  DurationRefinement,
  ExperiencesAvailabilityAutocompleteResponse,
  ExperiencesAvailabilityAutocompleteResult,
  ExperiencesAvailabilityRequestEnum,
  ExperiencesAvailabilityResponse,
  ExperiencesAvailabilitySort,
  ExperienceTag,
  PriceRefinement,
  StartTimeRefinement,
  TripadvisorRatingRefinement,
} from "redmond";
import * as H from "history";
import * as actionTypes from "./constants";

export interface IFetchExperiencesAvailability {
  type:
    | actionTypes.FETCH_INITIAL_EXPERIENCES_AVAILABILITY
    | actionTypes.FETCH_MORE_EXPERIENCES_AVAILABILITY
    | actionTypes.FETCH_FILTERED_EXPERIENCES_AVAILABILITY
    | actionTypes.FETCH_EXPERIENCES_AVAILABILITY_REQUEST_PARAMS;
  requestType: ExperiencesAvailabilityRequestEnum;
  history: H.History;
}

export const fetchInitialExperiencesAvailability = (
  history: H.History
): IFetchExperiencesAvailability => ({
  type: actionTypes.FETCH_INITIAL_EXPERIENCES_AVAILABILITY,
  requestType: ExperiencesAvailabilityRequestEnum.InitialSearch,
  history,
});

export const fetchFilteredExperiencesAvailability = (
  history: H.History
): IFetchExperiencesAvailability => ({
  type: actionTypes.FETCH_FILTERED_EXPERIENCES_AVAILABILITY,
  requestType: ExperiencesAvailabilityRequestEnum.InitialSearch,
  history,
});

export const fetchMoreExperiencesAvailability = (
  history: H.History
): IFetchExperiencesAvailability => ({
  type: actionTypes.FETCH_MORE_EXPERIENCES_AVAILABILITY,
  requestType: ExperiencesAvailabilityRequestEnum.FollowupSearch,
  history,
});

export const getAvailabilityRequestParameters = (
  history: H.History
): IFetchExperiencesAvailability => ({
  type: actionTypes.FETCH_EXPERIENCES_AVAILABILITY_REQUEST_PARAMS,
  requestType: ExperiencesAvailabilityRequestEnum.RequestParams,
  history,
});

export interface IStopFetchMoreExperiencesAvailability {
  type: actionTypes.STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY;
}

export const stopFetchMoreExperiencesAvailability =
  (): IStopFetchMoreExperiencesAvailability => ({
    type: actionTypes.STOP_FETCH_MORE_EXPERIENCES_AVAILABILITY,
  });

export interface ISetExperiencesAvailabilityResults {
  type: actionTypes.SET_EXPERIENCES_AVAILABILITY_RESULTS;
  payload: ExperiencesAvailabilityResponse;
  responseType: ExperiencesAvailabilityRequestEnum;
}

export const setExperiencesAvailabilityResults = (args: {
  payload: ExperiencesAvailabilityResponse;
  responseType: ExperiencesAvailabilityRequestEnum;
}): ISetExperiencesAvailabilityResults => ({
  type: actionTypes.SET_EXPERIENCES_AVAILABILITY_RESULTS,
  ...args,
});

export interface ISetExperiencesAvailabilityCallStateFailed {
  type: actionTypes.SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED;
}

export const setExperiencesAvailabilityCallStateFailed =
  (): ISetExperiencesAvailabilityCallStateFailed => ({
    type: actionTypes.SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED,
  });

export interface ISetKeyword {
  type: actionTypes.SET_KEYWORD;
  keyword: ExperiencesAvailabilityAutocompleteResult | null;
}

export const setKeyword = (
  keyword: ExperiencesAvailabilityAutocompleteResult | null
): ISetKeyword => ({
  type: actionTypes.SET_KEYWORD,
  keyword,
});

export interface ISetFilterTripadvisorRating {
  type: actionTypes.SET_FILTER_TRIPADVISOR_RATING;
  rating: TripadvisorRatingRefinement;
}

export const setFilterTripadvisorRating = (
  rating: TripadvisorRatingRefinement
): ISetFilterTripadvisorRating => ({
  type: actionTypes.SET_FILTER_TRIPADVISOR_RATING,
  rating,
});

export interface ISetFilterStartTimes {
  type: actionTypes.SET_FILTER_START_TIMES;
  startTimes: StartTimeRefinement[];
}

export const setFilterStartTimes = (
  startTimes: StartTimeRefinement[]
): ISetFilterStartTimes => ({
  type: actionTypes.SET_FILTER_START_TIMES,
  startTimes,
});

export interface ISetFilterDurationTimes {
  type: actionTypes.SET_FILTER_DURATION;
  durationTimes: DurationRefinement[];
}

export const setFilterDurationTimes = (
  durationTimes: DurationRefinement[]
): ISetFilterDurationTimes => ({
  type: actionTypes.SET_FILTER_DURATION,
  durationTimes,
});

export interface ISetFilterPrice {
  type: actionTypes.SET_FILTER_PRICE;
  price: PriceRefinement;
}

export const setFilterPrice = (price: PriceRefinement): ISetFilterPrice => ({
  type: actionTypes.SET_FILTER_PRICE,
  price,
});

export interface ISetFilterTags {
  type: actionTypes.SET_FILTER_TAGS;
  tags: ExperienceTag[];
}

export const setFilterTags = (tags: ExperienceTag[]): ISetFilterTags => ({
  type: actionTypes.SET_FILTER_TAGS,
  tags,
});

export interface ISetSortOption {
  type: actionTypes.SET_SORT_OPTION;
  sortOption: ExperiencesAvailabilitySort;
}

export const setSortOption = (
  sortOption: ExperiencesAvailabilitySort
): ISetSortOption => ({
  type: actionTypes.SET_SORT_OPTION,
  sortOption,
});

export interface IFetchExperiencesAvailabilityAutocomplete {
  type: actionTypes.FETCH_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE;
  queryString: string;
}

export const fetchExperiencesAvailabilityAutocomplete = (
  queryString: string
): IFetchExperiencesAvailabilityAutocomplete => ({
  type: actionTypes.FETCH_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE,
  queryString,
});

export interface ISetExperiencesAvailabilityAutocomplete {
  type: actionTypes.SET_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE_RESULTS;
  autocompleteResults: ExperiencesAvailabilityAutocompleteResponse;
}

export const setExperiencesAvailabilityAutocomplete = (
  autocompleteResults: ExperiencesAvailabilityAutocompleteResponse
): ISetExperiencesAvailabilityAutocomplete => ({
  type: actionTypes.SET_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE_RESULTS,
  autocompleteResults,
});

export type ExperiencesAvailabilityActions =
  | IFetchExperiencesAvailability
  | IStopFetchMoreExperiencesAvailability
  | ISetExperiencesAvailabilityResults
  | ISetExperiencesAvailabilityCallStateFailed
  | ISetKeyword
  | ISetFilterTripadvisorRating
  | ISetFilterStartTimes
  | ISetFilterDurationTimes
  | ISetFilterPrice
  | ISetFilterTags
  | ISetSortOption
  | IFetchExperiencesAvailabilityAutocomplete
  | ISetExperiencesAvailabilityAutocomplete;
