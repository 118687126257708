import {
  FiatPrice,
  HotelPriceQuoteData,
  PaymentV2Enum,
  RewardsPrice,
} from "redmond";
import { State } from "xstate";
import { PackageOpaqueProductValue, PackagesMachineContext } from "../types";
import { ReactElement } from "react";
import {
  getCurrencySymbol,
  IconName,
  IHotelPriceLineItem,
  roundToTwoDecimals,
} from "halifax";
import {
  CardPaymentSelectors,
  CartSelectors,
  ParentState,
  RewardsPaymentSelectors,
  SeatSelectors,
  WalletSelectors,
} from "@capone/checkout";
import { IHotelSummaryLineItem } from "halifax/build/HotelPriceBreakdown";
import { Product } from "@b2bportal/purchase-api";
import { PaymentSplitRequestEnum } from "redmond/build";
import { PaymentOpaqueValue, Payment } from "@b2bportal/purchase-api";
import { DO_NOT_APPLY_REWARDS_KEY } from "@capone/common";
import {
  formatCurrency,
  formatRewards,
  getRewardsAmountFromFiat,
} from "../utils";
import { PriceQuoteData } from "@b2bportal/air-booking-api";

const fallbackAmount: FiatPrice = {
  currencyCode: "USD",
  currencySymbol: "$",
  value: 0,
};

const fallbackRewards: RewardsPrice = {
  value: 0,
  currency: "",
};

export interface IPricingLineItem {
  icon?: ReactElement;
  key: string;
  value: string[] | FiatPrice;
  classNames?: string[];
  showPointsPrice?: boolean;
}

export interface TravelerLineItem {
  title: string;
  subtitleKey?: string;
  pricingLineItems: IPricingLineItem[];
}

type CheckoutState = State<PackagesMachineContext>;
type CheckoutStateWithoutValue = Pick<CheckoutState, "context">;
type CheckoutStateWithAndWithoutValue =
  | CheckoutState
  | CheckoutStateWithoutValue;

export const getPackagesShopPricing = (
  state: CheckoutStateWithAndWithoutValue
) => state.context.packagePricing;

export const getQuotedPackageDiscount = (
  state: CheckoutStateWithAndWithoutValue
) => {
  const quoteBreakdown = CartSelectors.getQuoteBreakdown(state);

  const quotePackageDiscountPayment = quoteBreakdown?.payments.find(
    (payment) =>
      payment.payment.type === Payment.Wallet &&
      payment.payment.value.offerName === "PackageSyntheticDiscount"
  );

  const quotePackageDiscount = quotePackageDiscountPayment?.payment.value as
    | {
        amount: {
          amount: number;
          currency: string;
        };
        offerName: "PackageSyntheticDiscount";
      }
    | undefined;

  return quotePackageDiscount;
};

export const getPriceBreakdownPricingLineItems = (
  state: CheckoutStateWithAndWithoutValue
): IHotelPriceLineItem[] => {
  const packagesShopPricing = getPackagesShopPricing(state);
  const quoteBreakdown = CartSelectors.getQuoteBreakdown(state);

  const quotedPackageProduct = quoteBreakdown?.products.find(
    (product) => product.product.type === Product.Package
  );

  const quotedPackageProductValue = quotedPackageProduct?.product.value as
    | PackageOpaqueProductValue
    | undefined;

  const travelerCount =
    state.context[ParentState.passengerInformation].selectedPassengerIds
      .length ||
    (state.context[ParentState.lodgingShop].guests?.adults || 0) +
      (state.context[ParentState.lodgingShop].guests?.children.length || 0);

  const quotePackageDiscount = getQuotedPackageDiscount(state);

  const shopPackageSavings = Math.abs(
    packagesShopPricing?.totalPackageSavings.fiat.value ?? 0
  );
  const shopPerTravelerDiscount = shopPackageSavings / travelerCount;

  const lineItems: IHotelPriceLineItem[] = [];

  const shopPayLaterFeePerTraveler =
    (packagesShopPricing?.dueAtHotelPrice?.fiat.value || 0) / travelerCount;

  const startingPricePerTraveler: FiatPrice =
    quotedPackageProductValue?.perTravelerPricing.subtotal.fiat ||
    (packagesShopPricing?.pricePerTraveler.fiat
      ? {
          ...packagesShopPricing?.pricePerTraveler.fiat,
          value:
            packagesShopPricing.pricePerTraveler.fiat.value -
            shopPayLaterFeePerTraveler + // shop pricing includes pay later
            shopPerTravelerDiscount, // add discount to show pre-discount pricing
        }
      : undefined) ||
    fallbackAmount;
  const subtotal: FiatPrice =
    quotedPackageProductValue?.totalPackagePricing.subtotal.fiat ||
    (packagesShopPricing?.totalPrice.fiat
      ? {
          ...packagesShopPricing?.totalPrice.fiat,
          value:
            packagesShopPricing?.totalPrice.fiat.value -
            (packagesShopPricing?.dueAtHotelPrice?.fiat.value || 0) +
            shopPackageSavings,
        }
      : undefined) ||
    fallbackAmount;
  const taxesAndFees: FiatPrice =
    quotedPackageProductValue?.totalPackagePricing.taxesAndFeesTotal.fiat ||
    fallbackAmount;
  const packageSavings: FiatPrice = quotePackageDiscount
    ? {
        value: quotePackageDiscount.amount.amount,
        currencyCode: quotePackageDiscount.amount.currency,
        currencySymbol: getCurrencySymbol(quotePackageDiscount.amount.currency),
      }
    : packagesShopPricing?.totalPackageSavings.fiat || fallbackAmount;

  const seatPricing = SeatSelectors.getSeatTotalPricing(state);

  lineItems.push(
    {
      title: "Starting price per traveler",
      value: formatCurrency(startingPricePerTraveler),
    },
    {
      title: `Subtotal (${travelerCount} traveler${
        travelerCount === 1 ? "" : "s"
      })`,
      value: formatCurrency(subtotal),
    }
  );

  if (taxesAndFees.value > 0) {
    lineItems.push({
      title: "Taxes and fees",
      value: formatCurrency(taxesAndFees),
    });
  }

  if (packageSavings.value) {
    lineItems.push({
      title: "Package savings",
      value: formatCurrency(packageSavings, true),
      tooltip:
        "This is an exclusive package rate. This amount reflects the total additional cost you would pay for your stay and flight if booked separately through Capital One Travel. If you select a different flight, you’ll still save this total amount.",
      className: "package-savings",
    });
  }

  if (seatPricing) {
    lineItems.push({
      title: "Seat selection",
      value: formatCurrency({ ...fallbackAmount, value: seatPricing }),
    });
  }

  return lineItems;
};

export const getPriceBreakdownTotalLineItems = (
  state: CheckoutStateWithAndWithoutValue
): IHotelSummaryLineItem[] => {
  const breakdownTotal = getBreakdownTotal(state);

  if (!breakdownTotal) return [];

  const { fiat, rewards, isDueToday } = breakdownTotal;

  const lineItems: IHotelSummaryLineItem[] = [];

  lineItems.push({
    title: isDueToday ? "Total due today" : "Total",
    value: formatCurrency(fiat),
    rewardsValue: rewards ? formatRewards(rewards) : undefined,
    boldLabel: true,
  });

  const packagesShopPricing = getPackagesShopPricing(state);
  const quoteBreakdown = CartSelectors.getQuoteBreakdown(state);

  const packageQuoteProduct = quoteBreakdown?.products.find(
    (product) => product.product.type === Product.Package
  );

  const packageQuoteProductValue = packageQuoteProduct?.product.value as
    | PackageOpaqueProductValue
    | undefined;

  const serviceFee: FiatPrice =
    packageQuoteProductValue?.totalPackagePricing.payLaterTotal ||
    packagesShopPricing?.dueAtHotelPrice?.fiat ||
    fallbackAmount;

  if (!!serviceFee.value) {
    lineItems.push({
      title: "Hotel service fee",
      value: `${formatCurrency(serviceFee)} (due at hotel)`,
      className: "hotel-service-fee",
    });
  }

  return lineItems;
};

export const getPriceBreakdownSummaryLineItems = (
  state: CheckoutStateWithAndWithoutValue
): IHotelSummaryLineItem[] => {
  const creditsToApply = WalletSelectors.getCreditAmountToApply(state);
  const offerToApply = WalletSelectors.getSelectedOffer(state);
  const selectedRewardsPaymentAccount =
    RewardsPaymentSelectors.getSelectedAccount(state);
  const rewardsAmountToApply =
    RewardsPaymentSelectors.getRewardsAmountToApply(state);
  const rewardsFiatAmountToApply =
    RewardsPaymentSelectors.getRewardsFiatAmountToApply(state);
  const selectedPaymentCard =
    CardPaymentSelectors.getSelectedPaymentMethod(state);
  const cardPaymentAmountRequired =
    CardPaymentSelectors.getTotalCardPaymentRequired(state);
  const packagesShopPricing = getPackagesShopPricing(state);

  const quotePackageDiscount = getQuotedPackageDiscount(state);
  const shopPackageDiscount = packagesShopPricing?.totalPackageSavings.fiat;

  // display card total minus shop discount if we haven't run quote yet
  const cardPaymentDiscount = Math.abs(
    quotePackageDiscount ? 0 : shopPackageDiscount?.value || 0
  );

  const lineItems: IHotelSummaryLineItem[] = [];

  if (creditsToApply) {
    lineItems.push({
      title: "Travel credits applied",
      value: formatCurrency(
        {
          value: creditsToApply,
          currencyCode: "USD",
          currencySymbol: "$",
        },
        true
      ),
      isTravelCredit: true,
      className: "travel-credits",
      icon: IconName.PiggyBank,
    });
  }

  if (offerToApply?.amount.amount) {
    lineItems.push({
      title: "Travel offer applied",
      value: formatCurrency(
        {
          value: offerToApply.amount.amount,
          currencyCode: offerToApply.amount.currency,
          currencySymbol: getCurrencySymbol(offerToApply.amount.currency),
        },
        true
      ),
      isTravelOffer: true,
      className: "travel-offer",
      icon: IconName.OfferTag,
    });
  }

  if (
    selectedRewardsPaymentAccount &&
    rewardsFiatAmountToApply &&
    rewardsAmountToApply
  ) {
    lineItems.push({
      title: `${selectedRewardsPaymentAccount.productDisplayName} rewards applied`,
      value: formatCurrency(rewardsFiatAmountToApply, true),
      rewardsValue: formatRewards(rewardsAmountToApply, true),
      className: "rewards-applied",
    });
  }

  if (selectedPaymentCard && !!cardPaymentAmountRequired) {
    lineItems.push({
      title: `Card ending in ${selectedPaymentCard.last4}`,
      value: formatCurrency({
        value: cardPaymentAmountRequired - cardPaymentDiscount,
        currencyCode: "USD",
        currencySymbol: "$",
      }),
      className: "card-payment",
      icon: IconName.Payment,
    });
  }

  return lineItems;
};

export const getPaymentRequestType = (
  state: CheckoutStateWithAndWithoutValue
) => {
  const selectedRewardsAccountId =
    RewardsPaymentSelectors.getSelectedAccountId(state);
  const selectedCardPaymentId =
    CardPaymentSelectors.getSelectedPaymentMethodId(state);

  const rewardsAmountToApply =
    RewardsPaymentSelectors.getRewardsAmountToApply(state);
  const cardAmountToApply =
    CardPaymentSelectors.getTotalCardPaymentRequired(state);

  const rewardsSelected = selectedRewardsAccountId
    ? selectedRewardsAccountId !== DO_NOT_APPLY_REWARDS_KEY
    : false;

  switch (true) {
    case !!selectedCardPaymentId && !rewardsSelected:
      return PaymentSplitRequestEnum.PaymentCardRequest;
    case rewardsSelected &&
      !!rewardsAmountToApply?.value &&
      cardAmountToApply === 0:
      return PaymentSplitRequestEnum.PaymentRewardsRequest;
    case !!rewardsAmountToApply?.value && rewardsSelected:
      return PaymentSplitRequestEnum.PaymentCardRewardsRequest;
    default:
      return undefined;
  }
};

export const getOpaquePayments = (state: CheckoutStateWithAndWithoutValue) => {
  let payments: PaymentOpaqueValue[] = [];

  const creditToApply = WalletSelectors.getTravelWalletCreditToApply(state);

  const paymentRequestType = getPaymentRequestType(state);

  const selectedRewardsAccount =
    RewardsPaymentSelectors.getSelectedAccount(state);
  const selectedCardPaymentId =
    CardPaymentSelectors.getSelectedPaymentMethodId(state);
  const selectedCardPaymentRewardsAccountId =
    CardPaymentSelectors.getSelectedPaymentMethodRewardsAccountId(state);

  const rewardsAmountToApply =
    RewardsPaymentSelectors.getRewardsAmountToApply(state);
  const rewardsFiatAmountToApply =
    RewardsPaymentSelectors.getRewardsFiatAmountToApply(state);
  const cardAmountToApply =
    CardPaymentSelectors.getTotalCardPaymentRequired(state);

  if (creditToApply) {
    if (Math.abs(creditToApply.amount.amount) > 0) {
      const credit: PaymentOpaqueValue = {
        type: Payment.TravelWalletCredit,
        value: {
          offerId: creditToApply.id,
          description: "TravelWalletCredit", // creditToApply doesn't have a description
          paymentAmount: {
            fiatValue: {
              amount: roundToTwoDecimals(Math.abs(creditToApply.amount.amount)),
              currency: creditToApply.amount.currency,
            },
          },
          PaymentV2: PaymentV2Enum.TravelWalletCredit,
        },
      };
      payments.push(credit);
    }
  }
  switch (paymentRequestType) {
    case PaymentSplitRequestEnum.PaymentCardRequest:
      const userCardPayment: PaymentOpaqueValue = {
        type: Payment.Card,
        value: {
          paymentId: selectedCardPaymentId || "",
          accountReferenceId: selectedCardPaymentRewardsAccountId,
          paymentAmount: {
            currency: "USD",
            amount: roundToTwoDecimals(cardAmountToApply),
          },
          PaymentV2: PaymentV2Enum.UserCard,
        },
      };
      payments.push(userCardPayment);
      break;
    case PaymentSplitRequestEnum.PaymentCardRewardsRequest:
      const splitUserCardPayment: PaymentOpaqueValue = {
        type: Payment.Card,
        value: {
          paymentId: selectedCardPaymentId || "",
          accountReferenceId: selectedCardPaymentRewardsAccountId,
          paymentAmount: {
            currency: "USD",
            amount: roundToTwoDecimals(cardAmountToApply),
          },
          PaymentV2: PaymentV2Enum.UserCard,
        },
      };

      const splitRewardsPayment: PaymentOpaqueValue = {
        type: Payment.Rewards,
        value: {
          paymentAmount: {
            rewardsAccountId: selectedRewardsAccount?.accountReferenceId,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatAmountToApply?.value || 0),
              currency: rewardsFiatAmountToApply?.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsAmountToApply?.value || 0),
              currency: rewardsAmountToApply?.currency,
            },
          },
          PaymentV2: PaymentV2Enum.Rewards,
        },
      };

      payments.push(splitUserCardPayment, splitRewardsPayment);
      break;
    case PaymentSplitRequestEnum.PaymentRewardsRequest:
      const rewardsPaymentType: PaymentOpaqueValue = {
        type: Payment.Rewards,
        value: {
          paymentAmount: {
            rewardsAccountId: selectedRewardsAccount?.accountReferenceId,
            fiatValue: {
              amount: roundToTwoDecimals(rewardsFiatAmountToApply?.value || 0),
              currency: rewardsFiatAmountToApply?.currencyCode,
            },
            rewardsPrice: {
              value: roundToTwoDecimals(rewardsAmountToApply?.value || 0),
              currency: rewardsAmountToApply?.currency,
            },
          },
          PaymentV2: PaymentV2Enum.Rewards,
        },
      };
      payments.push(rewardsPaymentType);
      break;
    default:
      break;
  }

  return payments;
};

export const getBreakdownTotal = (
  state: CheckoutStateWithAndWithoutValue
):
  | { fiat: FiatPrice; rewards?: RewardsPrice; isDueToday: boolean }
  | undefined => {
  const packagesShopPricing = getPackagesShopPricing(state);
  const quoteBreakdown = CartSelectors.getQuoteBreakdown(state);

  const rewardsAccountToUse =
    RewardsPaymentSelectors.getSelectedAccount(state) ||
    RewardsPaymentSelectors.getHighestEarnAccount(state);

  const packageQuoteProduct = quoteBreakdown?.products.find(
    (product) => product.product.type === Product.Package
  );

  const packageQuoteProductValue = packageQuoteProduct?.product.value as
    | PackageOpaqueProductValue
    | undefined;

  const seatPricing = SeatSelectors.getSeatTotalPricing(state);
  const postQuoteFiatTotalWithSeats: FiatPrice | undefined =
    packageQuoteProductValue?.totalPackagePricing.payNowTotal.fiat
      ? {
          ...packageQuoteProductValue.totalPackagePricing.payNowTotal.fiat,
          value:
            packageQuoteProductValue.totalPackagePricing.payNowTotal.fiat
              .value + seatPricing,
        }
      : undefined;

  const totalDueToday: FiatPrice =
    postQuoteFiatTotalWithSeats ||
    packagesShopPricing?.totalDueTodayPrice?.fiat ||
    fallbackAmount;

  const quotePackageDiscount = getQuotedPackageDiscount(state);
  const shopPackageDiscount = packagesShopPricing?.totalPackageSavings.fiat;

  const dueTodayWithoutDiscountFiat: FiatPrice = {
    ...totalDueToday,
    value: !!packageQuoteProductValue
      ? totalDueToday.value -
        (quotePackageDiscount?.amount.amount || shopPackageDiscount?.value || 0)
      : totalDueToday.value,
  };

  const rewardsAmountToUse = rewardsAccountToUse
    ? getRewardsAmountFromFiat(
        dueTodayWithoutDiscountFiat.value,
        rewardsAccountToUse
      )
    : fallbackRewards;

  if (dueTodayWithoutDiscountFiat.value > 0) {
    return {
      fiat: dueTodayWithoutDiscountFiat,
      rewards: rewardsAmountToUse,
      isDueToday: true,
    };
  } else if (packagesShopPricing) {
    // fallback to total if due today isn't populated in shop pricing
    return {
      fiat: packagesShopPricing.totalPrice.fiat,
      rewards:
        packagesShopPricing.totalPrice.rewards[
          rewardsAccountToUse?.accountReferenceId || ""
        ],
      isDueToday: false,
    };
  }

  return;
};

export const getTotalPackageDiscountAmountWithOffers = (
  state: CheckoutStateWithAndWithoutValue
) => {
  const packageDiscount = getQuotedPackageDiscount(state);
  const selectedOffer = WalletSelectors.getSelectedOffer(state);

  const packageDiscountAmount = Math.abs(packageDiscount?.amount.amount || 0);
  const offerAmount = Math.abs(selectedOffer?.amount.amount || 0);

  return packageDiscountAmount + offerAmount;
};

// used for calculating earn
export const getPackageFlightCardTotal = (
  state: CheckoutStateWithAndWithoutValue
): number | undefined => {
  const breakdown = state.context[ParentState.cartQuote].quoteBreakdown;

  const flightProduct = breakdown?.products.find(
    (product) => product.product.type === Product.Flight
  );

  const flightProductQuoteValue: PriceQuoteData | undefined =
    flightProduct?.product.value;

  if (!flightProductQuoteValue) return;

  const cardPaymentAmount =
    CardPaymentSelectors.getTotalCardPaymentRequired(state);

  const discountAmount = getTotalPackageDiscountAmountWithOffers(state);

  const flightTotal =
    flightProductQuoteValue.itinerary.sellingPricing.totalPricing.total.fiat;

  const packageProduct = breakdown?.products.find(
    (product) => product.product.type === Product.Package
  );
  const packageProductValue = packageProduct?.product.value as
    | PackageOpaqueProductValue
    | undefined;

  if (!packageProductValue) return undefined;

  // mirrored from https://github.com/hopper-org/Tysons/blob/master/shared/src/main/scala/com/hopper/tysons/service/purchase/coordinator/paymentassignment/PackagePaymentAssignmentStrategy.scala
  const flightProductProportion =
    flightTotal.value / // no discounts apply to flight portion currently
    (packageProductValue.totalPackagePricing.payNowTotal.fiat.value -
      discountAmount);

  const amountAssignedToCard = flightProductProportion * cardPaymentAmount;

  return amountAssignedToCard;
};

export const getPackageHotelCardTotal = (
  state: CheckoutStateWithAndWithoutValue
): number | undefined => {
  const breakdown = state.context[ParentState.cartQuote].quoteBreakdown;

  const hotelProduct = breakdown?.products.find(
    (product) => product.product.type === Product.Hotel
  );

  const hotelProductQuoteValue: HotelPriceQuoteData | undefined =
    hotelProduct?.product.value;

  if (!hotelProductQuoteValue) return undefined;

  const cardPaymentAmount =
    CardPaymentSelectors.getTotalCardPaymentRequired(state);

  const discountAmount = getTotalPackageDiscountAmountWithOffers(state);

  const hotelTotal =
    hotelProductQuoteValue.hotelQuoteData.pricing.payNowTotal.fiat;

  const packageProduct = breakdown?.products.find(
    (product) => product.product.type === Product.Package
  );
  const packageProductValue = packageProduct?.product.value as
    | PackageOpaqueProductValue
    | undefined;

  if (!packageProductValue) return undefined;

  const hotelProductProportion =
    (hotelTotal.value - discountAmount) /
    (packageProductValue.totalPackagePricing.payNowTotal.fiat.value -
      discountAmount);

  const amountAssignedToCard = hotelProductProportion * cardPaymentAmount;

  return amountAssignedToCard;
};

export const getUserSelectedRecommendedFlight = (
  state: CheckoutStateWithAndWithoutValue
) => {
  const selectedTrip = state.context[ParentState.flightShop]?.selectedTrip;

  const recommendedFlights =
    state.context[ParentState.flightShop]?.benchmarkSliceIds;

  return !!recommendedFlights.find(
    (flight) =>
      flight.value === selectedTrip?.outgoingSliceId ||
      flight.value === selectedTrip?.returnSliceId
  );
};
