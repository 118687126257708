import { CorpSessionInfo, RewardsAccount, SessionInfo } from "redmond";

export const getCurrentCorpAccount = (
  rewardsAccouonts: RewardsAccount[],
  sessionInfo: CorpSessionInfo | SessionInfo | undefined
) => {
  if (sessionInfo && "corporateInfo" in sessionInfo) {
    const currentAccountId = (sessionInfo as CorpSessionInfo)?.corporateInfo
      ?.accountReferenceId;

    return rewardsAccouonts.find(
      (account) => account.accountReferenceId === currentAccountId
    );
  }

  return undefined;
};
