import { Box, Typography } from "@material-ui/core";
import {
  HotelName,
  HotelCarousel,
  HotelOverview,
  HotelAmenities,
  HotelTripAdvisorReviews,
  HotelDescription,
  BackToTopButton,
  HotelPriceCompareChart,
  HotelShopRoomTypePickerVariant,
  savingsAmountBasedOnCheapestProduct,
  TrackingEventControlType,
  getCancellationPolicyInfo,
  VoidWindowNotice,
} from "halifax";
import React, { useState, useEffect } from "react";
import { RewardsAccountSelection } from "../../../rewards/components";
import { RouteComponentProps } from "react-router-dom";
import { CALIFORNIA_BILL_644_CANCELLATION_TEXT } from "../DesktopShop/textConstants";
import { HotelShopExpandedMap } from "../HotelShopExpandedMap";
import { HotelShopSmallMap } from "../HotelShopSmallMap/component";
import { MobileShopConnectorProps } from "./container";
import clsx from "clsx";
import { MobileHotelPhotoModal } from "../MobileHotelPhotoModal";
import * as textConstants from "./textConstants";
import { config } from "../../../../api/config";
import {
  PackageHotelShopCallState,
  PackageHotelShopStep,
} from "../../reducer/state";
import { MobileRoomPicker } from "../MobileRoomPicker";
import { MobileHotelShopRoomTypePickerPanel } from "../MobileHotelShopRoomTypePickerPanel";
import { MobileShopHeader } from "../MobileShopHeader";
import { PackagesShopProgressBar } from "../../../book/components";
import {
  CancellationPolicyEnum,
  CancellationReason,
  SelectedTravelOfferScreen,
} from "redmond";
import { goToAvailability } from "../../utils/queryStringHelpers";
import { ShopErrorModal } from "../ShopErrorModal";
import "./styles.scss";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components";
import Skeleton from "react-loading-skeleton";
export interface IMobileShopProps
  extends MobileShopConnectorProps,
    RouteComponentProps {
  roomInfoProductsType?: HotelShopRoomTypePickerVariant;
  handleReadyToRedirect?: (
    trackingEventControl?: TrackingEventControlType
  ) => void;
}

export const MobileShop = (props: IMobileShopProps) => {
  const {
    lodging,
    hotelShopCallInProgress,
    cheapestProduct,
    chosenProduct,
    hasFailed,
    hotelShopStep,
    fromDate,
    untilDate,
    history,
    largestValueAccount,
    adultsCount,
    children,
    hotelShopCallState,
    nightCount,
    roomInfoProductsType,
    handleReadyToRedirect,
    packagesByRateId,
    travelersCount,
    rewardsKey,
    cancellationSummary,
    origin,
    infants,
    stopsOption,
    fareClassOptionFilter,
    searchDistanceFromShopProperties,
    packagesByLodgingId,
    showOfferBasedOnSelectedLodging,
  } = props;
  const [showExpandedHotelMap, setShowExpandedHotelMap] = useState(false);
  const [openPhotoModal, setOpenPhotoModal] = useState(false);
  const [successShopSearchFromDate, setSuccessShopSearchFromDate] =
    useState<Date | null>(null);
  const [successShopSearchUntilDate, setSuccessShopSearchUntilDate] =
    useState<Date | null>(null);

  useEffect(() => {
    if (hotelShopCallState === PackageHotelShopCallState.Success) {
      setSuccessShopSearchFromDate(fromDate);
      setSuccessShopSearchUntilDate(untilDate);
    }
  }, [hotelShopCallState]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 100);
  }, [hotelShopStep]);

  const returnToAvailability = (
    prevSuccessHotelFromDate?: Date | null,
    prevSuccessHotelUntilDate?: Date | null
  ) => {
    goToAvailability({
      history,
      origin,
      lodging,
      fromDate,
      untilDate,
      adultsCount,
      children,
      infants,
      stopsOption,
      prevSuccessHotelFromDate,
      prevSuccessHotelUntilDate,
      fareClassOptionFilter,
    });
  };

  const renderSkeleton = () => (
    <Box className={"mobile-hotel-shop-skeleton"}>
      {!lodging && (
        <>
          <Box>
            <HotelName isSkeleton />
          </Box>
          <HotelCarousel isSkeleton />
          <Box className={"section-wrapper"}>
            <HotelOverview isSkeleton />
            <HotelAmenities isSkeleton />
            <HotelOverview isSkeleton />
          </Box>
          <Box className={"section-wrapper"}>
            <HotelAmenities isSkeleton />
            <HotelAmenities isSkeleton />
            <HotelOverview isSkeleton />
          </Box>
          <Box className={"section-wrapper"}>
            <HotelName isSkeleton />
            <HotelCarousel isSkeleton />
          </Box>
        </>
      )}

      <Box className={clsx("mobile-hotel-shop-price-panel", "skeleton")}>
        <Skeleton className="hotel-shop-choose-room-cta-skeleton" />
      </Box>
    </Box>
  );
  const renderRewardsAccount = () => {
    return (
      <Box className="mobile-rewards-account-contents">
        <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
      </Box>
    );
  };

  const cancellationPolicyInfo = cheapestProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(
        cheapestProduct?.cancellationPolicy,
        textConstants.getCfarSecondaryText
      )
    : null;

  const renderAvailability = (hotelShopStep: PackageHotelShopStep) => {
    const { lodging: lodgingData, price } = lodging || {};
    const savings =
      price && cheapestProduct
        ? savingsAmountBasedOnCheapestProduct(price, cheapestProduct)
        : null;

    return lodging && hotelShopStep === PackageHotelShopStep.HotelInfo ? (
      <Box className={"mobile-hotel-shop-container"}>
        <MobileShopHeader />
        <PackagesShopProgressBar isMobile />
        {renderRewardsAccount()}

        <MobileHotelPhotoModal
          openModal={openPhotoModal}
          setOpenModal={setOpenPhotoModal}
        />

        <div
          onClick={() => {
            setOpenPhotoModal(true);
          }}
        >
          <HotelCarousel media={lodgingData?.media} />
        </div>

        {showOfferBasedOnSelectedLodging && (
          <TravelWalletDetailsBanner
            offer={lodging.bestOfferThisLodging}
            className="mobile-pkg-hotel-shop-offer"
            screen={SelectedTravelOfferScreen.PACKAGES_HOTEL_SHOP}
            bannerTextType="shop"
          />
        )}

        {cancellationSummary?.reasons.includes(
          CancellationReason.CaliforniaBill644
        ) && <VoidWindowNotice />}
        <Box className={clsx("section-wrapper", "overview")}>
          <HotelOverview
            lodging={lodging}
            includePricing
            chosenProduct={chosenProduct ?? cheapestProduct}
            nightCount={nightCount ?? 0}
            rewardsKey={rewardsKey ?? undefined}
            packagesByRateId={packagesByRateId}
            isPackageHotel
            travelersCount={travelersCount}
          />
        </Box>

        <Box className={clsx("section-wrapper", "amenities")}>
          <Typography variant="h2">Amenities</Typography>
          <HotelAmenities
            amenities={lodgingData?.amenities}
            displayCount={4}
            showAmenitiesText={`${textConstants.SHOW_ALL_AMENITIES_TEXT} (${
              lodgingData?.amenities && lodgingData?.amenities?.length - 4
            })`}
            hideAmenityIconsInTooltip
          />
        </Box>
        {price &&
          price.comparePrices.length > 0 &&
          !!price.comparePrices.find((price) => price.source !== "Expedia") &&
          cheapestProduct &&
          savings && (
            <Box className={clsx("section-wrapper", "compare")}>
              <Typography variant="h2">Compare Prices</Typography>
              <HotelPriceCompareChart
                lodgingPrice={price}
                siteName="Capital One"
                cheapestProduct={cheapestProduct}
                earnText={textConstants.getEarnText(
                  largestValueAccount.earn.hotelsMultiplier,
                  largestValueAccount.productDisplayName,
                  largestValueAccount.rewardsBalance.currencyDescription ??
                    largestValueAccount.rewardsBalance.currency
                )}
              />
            </Box>
          )}

        <Box className={clsx("section-wrapper", "property")}>
          <Typography variant="h2">About the Property</Typography>
          <HotelDescription
            description={lodging?.lodging?.description}
            onMapClick={() => {
              setShowExpandedHotelMap(true);
            }}
            map={
              <HotelShopSmallMap
                lodging={lodging}
                setShowExpandedHotelMap={setShowExpandedHotelMap}
                searchDistanceFromShopProperties={
                  searchDistanceFromShopProperties
                }
                lodgingPackage={packagesByLodgingId?.[lodging.lodging.id]}
              />
            }
          />
          <HotelShopExpandedMap
            isMobile
            lodging={lodging}
            showExpandedHotelMap={showExpandedHotelMap}
            setShowExpandedHotelMap={setShowExpandedHotelMap}
          />
        </Box>

        {lodgingData?.tripAdvisorReviews &&
          lodgingData?.tripAdvisorReviews?.comments?.length > 0 && (
            <Box className={clsx("section-wrapper", "customer")}>
              <Typography variant="h2">Customer Reviews</Typography>
              <HotelTripAdvisorReviews
                lodgingData={lodgingData}
                trim={500}
                isMobile={true}
              />
            </Box>
          )}

        {cancellationPolicyInfo && (
          <Box className={clsx("section-wrapper", "cancellation")}>
            <Typography variant="h2">
              {cancellationPolicyInfo.primaryText}
            </Typography>
            <Typography variant="subtitle2">
              {cancellationSummary?.reasons.includes(
                CancellationReason.CaliforniaBill644
              ) &&
              cheapestProduct?.cancellationPolicy.CancellationPolicy ===
                CancellationPolicyEnum.NonRefundable
                ? CALIFORNIA_BILL_644_CANCELLATION_TEXT
                : cancellationPolicyInfo.secondaryText}
            </Typography>
          </Box>
        )}

        {lodgingData?.checkInInstructions && fromDate && untilDate && (
          <Box className={clsx("section-wrapper", "check-in-instructions")}>
            <Typography variant="h2">
              {textConstants.CHECK_IN_INSTRUCTIONS_TITLE}
            </Typography>
            <Typography
              variant="subtitle2"
              dangerouslySetInnerHTML={{
                __html: textConstants.formattedCheckIn(
                  fromDate,
                  lodgingData.checkInInstructions?.checkInTime
                ),
              }}
            />
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: textConstants.formattedCheckOut(
                  untilDate,
                  lodgingData.checkInInstructions?.checkOutTime
                ),
              }}
            />
          </Box>
        )}

        <BackToTopButton bottom={110} />

        <MobileHotelShopRoomTypePickerPanel />
      </Box>
    ) : (
      <></>
    );
  };

  const renderShop = (hotelShopStep: PackageHotelShopStep) => {
    return (
      <Box className={"mobile-hotel-shop-container"}>
        {lodging && hotelShopStep === PackageHotelShopStep.HotelInfo ? (
          <>
            <BackToTopButton bottom={110} />
          </>
        ) : (
          <MobileRoomPicker
            handleReadyToRedirect={handleReadyToRedirect}
            roomInfoProductsType={roomInfoProductsType}
            nonRefundablePolicyOverrideText={
              cancellationSummary?.reasons.includes(
                CancellationReason.CaliforniaBill644
              )
                ? CALIFORNIA_BILL_644_CANCELLATION_TEXT
                : undefined
            }
          />
        )}
      </Box>
    );
  };

  return !hasFailed ? (
    <Box
      className={clsx(
        "mobile-package-hotel-shop-wrapper",
        config.TENANT,
        "global-mobile-nav"
      )}
    >
      {renderAvailability(hotelShopStep)}
      {hotelShopCallInProgress &&
      hotelShopStep === PackageHotelShopStep.HotelInfo
        ? renderSkeleton()
        : renderShop(hotelShopStep)}
    </Box>
  ) : (
    <ShopErrorModal
      returnToAvailability={() =>
        returnToAvailability(
          successShopSearchFromDate,
          successShopSearchUntilDate
        )
      }
    />
  );
};
