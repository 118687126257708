import React, { useCallback, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import {
  B2BLoadingPopup,
  BannerSeverity,
  FlightSearchLoadingImage,
  CorpFlightSearchLoadingImage,
  NotificationBanner,
  PageBanner,
  PageBannerDesktopImage,
  PageBannerMobileImage,
  useDeviceTypes,
  CorporatePageBannerImage,
} from "halifax";
import {useDispatch, useSelector} from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ExchangeScenario, FlightShopStep, TripCategory } from "redmond";
import { isCorpTenant } from "@capone/common";

import { FareClassToggles } from "./components/FareClassToggles";
import { FlightReshopSearchFilter } from "./components/ShopSearchControl/components/FlightReshopSearchFilter";
import { FlightComparatorRow } from "./components/FlightComparatorRow";
import { FlightList } from "./components/FlightList";
import { ReshopBreadcrumbs } from "./components/ReshopBreadcrumbs";
import { ReviewItinerary } from "./components/ReviewItinerary";
import { ShopSearchControl } from "./components/ShopSearchControl";
import { SortOptionSelect } from "./components/SortOptionSelect";
import { flightShopCopy, headerSubtitles } from "../../constants";
import { ActiveExperiments, useExperiment } from "../../context/experiments";
import {
  getExchangeScenario,
  getFlightBooking,
  getFlightShopStep,
  getIsTripSummariesLoading,
  getTripType,
  getBannerCopy,
  getPrevAirlineName,
} from "../../selectors";
import { PATH_FLIGHT_EXCHANGE } from "../../utils/paths";
import { config } from "../../api/config";
import "./styles.scss";
import {initialPriceQuote, setPriceQuote, setSessionId} from "../../reducers/flightBook";

export interface IFlightReshopProps extends RouteComponentProps {
  isMobile: boolean;
}

const defaultProps: Partial<IFlightReshopProps> = {
  isMobile: false,
};

const FlightReshop = (props: IFlightReshopProps): JSX.Element | null => {
  const { history, isMobile } = props;
  const { matchesDesktop, matchesLargeDesktop } = useDeviceTypes();
  const redesignExp = useExperiment(ActiveExperiments.ShopPageRedesign);
  const airlineName = useSelector(getPrevAirlineName);
  const bannerCopy = useSelector(getBannerCopy);
  const booking = useSelector(getFlightBooking);
  const scenario = useSelector(getExchangeScenario);
  const shopStep = useSelector(getFlightShopStep);
  const tripSummariesLoading = useSelector(getIsTripSummariesLoading);
  const tripType = useSelector(getTripType);
  const matchesMedDesktopOnly = matchesDesktop && !matchesLargeDesktop;
  const onReturnStep = shopStep === FlightShopStep.ChooseReturn;
  const isOneWay = tripType === TripCategory.ONE_WAY;
  const isFTC = scenario === ExchangeScenario.ftc;

  const dispatch = useDispatch();
  const resetFlightBookState = () => {
    dispatch(setSessionId(""));
    dispatch(setPriceQuote(initialPriceQuote));
  };

  const renderDesktopFlightShop = useCallback(
    () => (
      <Box className="desktop-reshop-container">
        {redesignExp && (
          <PageBanner
            image={isCorporate ? CorporatePageBannerImage : PageBannerDesktopImage}
            subtitle={flightShopCopy.BANNER_SUBTITLE(airlineName)}
            title={
              isFTC
                ? flightShopCopy.BANNER_TITLE_FTC
                : flightShopCopy.BANNER_TITLE
            }
          />
        )}
        {!redesignExp && shopStep !== FlightShopStep.ReviewItinerary && (
          <ShopSearchControl isMedDesktop={matchesMedDesktopOnly} />
        )}
        <Box className="reshop-search-results">
          <ReshopBreadcrumbs />
          {shopStep !== FlightShopStep.ReviewItinerary ? (
            <>
              {isFTC && bannerCopy?.body.length ? (
                <NotificationBanner
                  className="travel-credit-notice"
                  html={bannerCopy.body.join(". ")}
                  severity={BannerSeverity.NOTICE}
                />
              ) : (
                <FlightComparatorRow />
              )}
              {redesignExp && (
                <ShopSearchControl isMedDesktop={matchesMedDesktopOnly} />
              )}
              <Box
                className={clsx("flight-list-view-controls", {
                  redesign: redesignExp,
                })}
              >
                <SortOptionSelect />
                {!redesignExp && <FareClassToggles />}
              </Box>
              <FlightList />
            </>
          ) : (
            <ReviewItinerary />
          )}
        </Box>
      </Box>
    ),
    [airlineName, bannerCopy, isFTC, matchesMedDesktopOnly, shopStep]
  );

  const isCorporate = isCorpTenant(config.TENANT);

  const renderMobileFlightShop = useCallback(
    () => (
      <Box className="mobile-reshop-container">
        {shopStep !== FlightShopStep.ReviewItinerary ? (
          <>
            {redesignExp && (
              <PageBanner
                image={isCorporate ? CorporatePageBannerImage : PageBannerMobileImage}
                subtitle={flightShopCopy.BANNER_SUBTITLE(airlineName)}
                title={
                  isFTC
                    ? flightShopCopy.BANNER_TITLE_FTC
                    : flightShopCopy.BANNER_TITLE
                }
              />
            )}
            <Box className="choose-flights-container">
              <Box className="reshop-controls-container">
                <Box className="filter-sort-row">
                  {!redesignExp && <FlightReshopSearchFilter isMobile />}
                  <SortOptionSelect isMobile />
                </Box>
                {!redesignExp && <FareClassToggles />}
              </Box>
              <Box className="reshop-results-container">
                <FlightComparatorRow isMobile />
                <Box className="reshop-instructions">
                  <Typography className="select-flight-copy">
                    {flightShopCopy.SELECT_NEW_FLIGHT_TITLE(onReturnStep)}
                  </Typography>
                  <Typography className="prices-are-copy">
                    {headerSubtitles.PRICES_ARE(isOneWay)}
                  </Typography>
                </Box>
                <FlightList />
              </Box>
            </Box>
          </>
        ) : (
          <ReviewItinerary isMobile />
        )}
      </Box>
    ),
    [airlineName, isFTC, isOneWay, onReturnStep, shopStep]
  );

  // reroute back to landing page if booking isn't set
  useEffect(() => {
    resetFlightBookState();
    if (!booking) {
      history.push({
        pathname: PATH_FLIGHT_EXCHANGE,
        search: history.location.search,
      });
    }
  }, [booking, history]);

  const isCorp = isCorpTenant(config.TENANT);

  return booking ? (
    <Box className={clsx("flight-reshop", { mobile: isMobile })}>
      {isMobile ? renderMobileFlightShop() : renderDesktopFlightShop()}
      {tripSummariesLoading && (
        <B2BLoadingPopup
          className="flight-reshop-search-loading-popup"
          image={
            isCorp ? CorpFlightSearchLoadingImage : FlightSearchLoadingImage
          }
          open={tripSummariesLoading}
          message={flightShopCopy.SEARCHING_FOR_FLIGHTS_TITLE}
          popupSize={isMobile ? "mobile" : "desktop"}
          secondaryMessage={redesignExp ? flightShopCopy.CHOOSE_SAME_OPTS : ""}
        />
      )}
    </Box>
  ) : null;
};

FlightReshop.defaultProps = defaultProps;

export default withRouter(FlightReshop);
