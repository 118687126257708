/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useState } from "react";
import { useInView } from "react-intersection-observer";
import { RoomContentConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import {
  RoomInfoProducts,
} from "redmond";
import {
  ActionButton,
  ActionLink,
  Carousel,
  getImageUrlsArray,
  RoomPhotoPlaceholder,
  RoomPhotoPlaceholderType,
  RoomName,
  RoomCapacity,
  RoomBedDescription,
  Tag,
} from "halifax"
import "./styles.scss";

export interface IRoomContentProps
  extends RoomContentConnectorProps,
    RouteComponentProps{
  roomInfoIndex: number;
  roomInfoProduct: RoomInfoProducts;
  className?: string;
  isMobile?: boolean;
  chosenRoomInfoIndex: number | null;
  openAmenitiesModal: (roomInfoProduct: RoomInfoProducts, productIdx: number) => void;
  openDatesModal: () => void;
  datesButtonClassName?: string;
  earnTagContent?: JSX.Element;
  earnTagClassName?: string;
  columnSize?: "column-430px" | "column-default";
  isGlobalMobileNavExperiment?: boolean;
  roomNameSuffixText?: string;
}
export const RoomContent = (props: IRoomContentProps) => {
  const {
    roomInfoIndex,
    roomInfoProduct,
    className,
    isMobile,
    chosenRoomInfoIndex,
    openAmenitiesModal,
    openDatesModal,
    datesButtonClassName,
    earnTagContent,
    earnTagClassName,
    columnSize,
    isGlobalMobileNavExperiment,
    roomNameSuffixText,
  } = props;

  const { ref, inView } = useInView({
    /* Optional options */
    root: document.getElementById("hotel-shop-room-picker"),
    threshold: 0.01,
    rootMargin: "0px 0px 0px 350px",
  });
  const [currentProductIndex, ] = useState(0);

  const useGlobalMobileNavUI =
    isGlobalMobileNavExperiment;


  const handleClick = () => {
    openDatesModal();
  };

  return (
    <>
      <div
        key={roomInfoIndex}
        className={clsx("hotel-shop-room-type-wrapper", {
          "global-mobile-nav-experiment": isGlobalMobileNavExperiment,
        })}
        ref={ref}
        id={chosenRoomInfoIndex === roomInfoIndex ? "chosen-room" : undefined}
      >
        <Box
          className={clsx("hotel-shop-room-type-info-wrapper", columnSize, {
            mobile: isMobile,
          })}
        >
          <Box className="hotel-shop-room-type-room-details">
            <Box className="hotel-shop-room-type-room-details-row">
              {earnTagContent && (
                <Box className="tag-container">
                  <Tag
                    className={clsx("earn-tag", earnTagClassName)}
                    label={earnTagContent}
                  />
                </Box>
              )}
              <Carousel
                imageUrlsArray={getImageUrlsArray(
                  roomInfoProduct.roomInfo.media
                )}
                placeholder={
                  <RoomPhotoPlaceholder
                    type={
                      roomInfoProduct.roomInfo.beds.bedTypes.length >= 2
                        ? RoomPhotoPlaceholderType.Double
                        : RoomPhotoPlaceholderType.Single
                    }
                  />
                }
              />

              <Box
                className={clsx(
                  "hotel-shop-room-type-room-details-list",
                  className
                )}
              >
                <RoomName
                  roomInfoProduct={roomInfoProduct}
                  roomNameSuffixText={roomNameSuffixText}
                />
                <Box className="room-capacity-bed-desc-wrapper">
                  <RoomCapacity
                    roomInfoProduct={roomInfoProduct}
                    index={currentProductIndex}
                    isRedesign
                  />
                  <RoomBedDescription roomInfoProduct={roomInfoProduct} />
                </Box>

                <Box className="amenities-modal-button-container">
                  {(roomInfoProduct.roomInfo.amenities.length > 0 ||
                    (roomInfoProduct.roomInfo.description &&
                        roomInfoProduct.roomInfo.description.length > 0)) && (
                    <ActionLink
                      className="amenities-modal-button"
                      onClick={() =>
                        openAmenitiesModal(roomInfoProduct, currentProductIndex)
                      }
                      content={
                        <Typography
                          className="amenities-modal-button-text"
                          variant="body1"
                        >
                          View Details and Amenities
                        </Typography>
                      }
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>

          {isMobile && useGlobalMobileNavUI && (
            <Box className="room-cta-container">
              <ActionButton
                className={clsx(className, datesButtonClassName)}
                message={
                  <Box className="cta-select-dates-text">
                    Select dates to see room rates
                  </Box>
                }
                onClick={handleClick}
              />
            </Box>
          )}
        </Box>

        {inView && !isMobile && (
          <Box className="room-cta-container">
            <ActionButton
              className={clsx(className, datesButtonClassName)}
              onClick={handleClick}
              message={
                <Box className="cta-select-dates-text">
                  Select dates to see room rates
                </Box>
              }
            />
          </Box>
        )}
      </div>
    </>
  );
};
