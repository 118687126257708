import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";

import { PopularAmenities } from "./component";
import { getPremierCollectionShopSelectedAvailability } from "../../reducer";
import { getPremierCollectionShopDetailsSelectedLodgingData } from "../../../shop-details/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    lodging: getPremierCollectionShopSelectedAvailability(state),
    shopDetailsLodgingData: getPremierCollectionShopDetailsSelectedLodgingData(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PopularAmenitiesConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedPopularAmenities = withRouter(connector(PopularAmenities));
