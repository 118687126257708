import { History } from "history";
import queryStringParser from "query-string";
import {
  BookedFlightItineraryWithDepartureTime,
  ExerciseEligibilityV1,
  Cap1DpExerciseFactsProperties,
  SELECTED_DISRUPTION,
  DISRUPTION_ITINERARY_ID_QUERY_PARAM,
  DisruptionProtectionQueryParam,
  FLIGHT_SHOP_TYPE,
  FlightShopType,
  MyTripsFilter,
  getDisruptionProductTypeFromAncillaries,
  getPartialEligibilityDpExerciseFactsPropertiesFromEligibility,
  PRICE_FREEZE_ID_QUERY_PARAM,
} from "redmond";
import {
  PATH_DISRUPTION_PROTECTION_OVERVIEW,
  PATH_DISRUPTION_PROTECTION_REFUND,
  PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT,
  PATH_DISRUPTION_PROTECTION_REBOOK_CONNECTION,
  PATH_HOME,
  PATH_FLIGHTS_SHOP,
  PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT,
  PATH_DISRUPTION_PROTECTION_CONTACT_SUPPORT,
} from "./paths";
import { trackEvent } from "../api/v1/analytics/trackEvent";

export const pushToDisruptionOverview = (props: {
  history: History;
  itineraryId: string | null | undefined;
  tracking?: {
    flights: BookedFlightItineraryWithDepartureTime[];
    eligibilities: { [key: string]: ExerciseEligibilityV1 };
  };
  path?:
    | "main"
    | "refund"
    | "rebook-flight"
    | "rebook-connection"
    | "contact-support"
    | "vi-rebook-connection";
}) => {
  const { history, itineraryId, path = "main", tracking } = props;
  if (!itineraryId) {
    return;
  }

  if (tracking) {
    const { flights, eligibilities } = tracking;
    const ancillaries = flights.find(
      (flight) => flight.bookedItinerary.id === itineraryId
    )?.ancillaries;
    const eligibility = eligibilities[itineraryId];
    if (ancillaries && eligibility) {
      const partialEligibilityDpExerciseFactsPropertiesFromEligibility =
        getPartialEligibilityDpExerciseFactsPropertiesFromEligibility(
          eligibility
        );

      trackEvent({
        eventName: SELECTED_DISRUPTION,
        properties: {
          ...partialEligibilityDpExerciseFactsPropertiesFromEligibility,
          disruption_product:
            getDisruptionProductTypeFromAncillaries(ancillaries),
        } as Cap1DpExerciseFactsProperties,
      });
    }
  }

  const pathname = (() => {
    switch (path) {
      case "refund":
        return PATH_DISRUPTION_PROTECTION_REFUND;
      case "rebook-flight":
        return PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT;
      case "rebook-connection":
        return PATH_DISRUPTION_PROTECTION_REBOOK_CONNECTION;
      case "vi-rebook-connection":
        return PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT;
      case "contact-support":
        return PATH_DISRUPTION_PROTECTION_CONTACT_SUPPORT;
      case "main":
      default:
        return PATH_DISRUPTION_PROTECTION_OVERVIEW;
    }
  })();

  const params = {
    [DISRUPTION_ITINERARY_ID_QUERY_PARAM]: itineraryId,
  };
  history.push({
    pathname,
    search: queryStringParser.stringify(params),
  });
};

export const pushToTripsHome = (props: {
  history: History;
  itineraryId?: string | null;
  tripsFilter?: MyTripsFilter;
}) => {
  const {
    history,
    itineraryId,
    tripsFilter = MyTripsFilter.UPCOMING_TRIPS,
  } = props;
  const params = {
    tripId: itineraryId,
    tripsFilter,
  };
  history.push({
    pathname: PATH_HOME,
    search: queryStringParser.stringify(params),
  });
};

export const pushToRebookFlightList = (props: {
  history: History;
  origin?: string | null;
  destination?: string | null;
  itineraryId?: string | null;
  sliceIndex?: number | null;
  segmentIndex?: number | null;
  productRedeemChoice?: string | null;
  activeDisruption?: string | null;
  hasEmbeddedRoundTrip?: string | null;
}) => {
  const {
    history,
    origin,
    destination,
    itineraryId,
    sliceIndex,
    segmentIndex,
    productRedeemChoice,
    activeDisruption,
    hasEmbeddedRoundTrip,
  } = props;
  const params = {
    [DisruptionProtectionQueryParam.Origin]: origin,
    [DisruptionProtectionQueryParam.Destination]: destination,
    [DisruptionProtectionQueryParam.ItineraryId]: itineraryId,
    [DisruptionProtectionQueryParam.SliceIndex]: sliceIndex,
    [DisruptionProtectionQueryParam.SegmentIndex]: segmentIndex,
    [DisruptionProtectionQueryParam.ProductRedeemChoice]: productRedeemChoice,
    [DisruptionProtectionQueryParam.ActiveDisruption]: activeDisruption,
    [DisruptionProtectionQueryParam.EmbeddedRoundTrip]: hasEmbeddedRoundTrip,
    [FLIGHT_SHOP_TYPE]: FlightShopType.DISRUPTION_PROTECTION_EXERCISE,
  };
  history.push({
    pathname: PATH_FLIGHTS_SHOP,
    search: queryStringParser.stringify(params),
  });
};

export interface IHotelPriceFreezeParsedQuery {
  priceFreezeId: string;
}

export type TripsParsedQuery = IHotelPriceFreezeParsedQuery;

export const parseQueryString = (history: History): TripsParsedQuery => {
  const queryString = history?.location?.search || "";
  return parseQueryFromString(queryString);
};

export const parseQueryFromString = (queryString: string): TripsParsedQuery => {
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);
  return {
    priceFreezeId: parsedQueryStringPrimitive[
      PRICE_FREEZE_ID_QUERY_PARAM
    ] as string,
  };
};
