import React, { useContext } from "react";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import { FAQConnectorProps } from "./container";
import "./styles.scss";
import { FAQs } from "../../textConstants";
import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { AccordionCollection, Icon } from "halifax";
import {
  VIEWED_OFFER_FAQ,
  SelectedTravelOfferScreen,
  CorpSessionInfo,
} from "redmond";
import { config } from "../../../../api/config";
import { useExperimentIsVariant } from "@capone/experiments";
import { AVAILABLE } from "../../../../context/experiments";
import { ClientContext } from "../../../../App";

export interface IFAQProps extends FAQConnectorProps, RouteComponentProps {}

export const FAQ = ({}: IFAQProps) => {
  const [trackEventFired, setTrackEventFired] = React.useState(false);

  const isCorpHideTravelOffers = useExperimentIsVariant(
    "corp-hide-travel-wallet-offers",
    AVAILABLE
  );

  const { sessionInfo } = useContext(ClientContext);
  const isNonFinancialUser =
    (sessionInfo as CorpSessionInfo)?.corporateInfo?.cap1Role === "NFU";

  const trackFaqExpanded = (_: string, isExpanded: boolean) => {
    if (!trackEventFired && isExpanded) {
      trackEvent({
        eventName: VIEWED_OFFER_FAQ,
        properties: {
          screen: SelectedTravelOfferScreen.TRAVEL_WALLET_LANDING,
        },
      });
      setTrackEventFired(true);
    }
  };

  return (
    <Box className="faq-container">
      <Typography variant="h2" className="faq-title">
        Have questions? We’ve got answers.
      </Typography>
      <AccordionCollection
        accordionContents={FAQs(
          config.TENANT,
          !isCorpHideTravelOffers,
          isNonFinancialUser
        )}
        expandIcon={<Icon name="plus" />}
        collapseIcon={<Icon name="minus" />}
        onChange={trackFaqExpanded}
        className="travel-wallet-credits-how-accordion"
      />
    </Box>
  );
};
