import React from "react";
import styles from "./styles.module.scss";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Input,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { ShopComponentSkeleton } from "../../component";
import {
  ExperienceBadge,
  ExperienceBookableItem,
  ExperienceLocation,
  ExperienceLogistics,
  ExperiencePricingCategory,
  ExperiencePricingCategoryEnum,
  Prices,
  ReviewSummary,
} from "redmond";
import {
  AvailabilityReviewRating,
  AvailabilityDuration,
  AvailabilityLanguage,
  Icon,
  IconName,
  truncateString,
  B2BButton,
  ActionButton,
  ExperienceTag,
  getPriceString,
  ActionLink,
  DesktopPopupModal,
} from "halifax";
import dayjs from "dayjs";
import clsx from "clsx";
import * as textConstants from "../../textConstants";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../common/MonthAndDatePicker";

export interface IShopOverview {
  isSkeleton?: boolean;
  title?: string;
  overview?: string;
  rating?: ReviewSummary;
  duration?: number;
  locationLogistics?: ExperienceLogistics;
  language?: string;
  provider?: string;
  badges?: ExperienceBadge[];
  bookableItems?: ExperienceBookableItem[];
  pricingCategory?: ExperiencePricingCategory;
}

export interface IShopOverviewProps extends IShopOverview {
  setFromDate: (date: Date | null) => void;
  setUntilDate: (date: Date | null) => void;
  fromDate: Date | null;
  untilDate: Date | null;
  fetchExperiencesShop: () => void;
}

const Skeleton = () => (
  <Box className={styles["experiences-shop-skeleton"]}>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
    </Box>
    <Box className={styles["section-wrapper"]}>
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="medium" />
    </Box>
  </Box>
);

export const ShopOverview = (props: IShopOverviewProps) => {
  const {
    isSkeleton = false,
    title,
    overview,
    rating,
    duration,
    locationLogistics,
    language,
    provider,
    badges,
    bookableItems,
    pricingCategory,
    setFromDate,
    setUntilDate,
    fromDate,
    untilDate,
    fetchExperiencesShop,
  } = props;

  const [showDescription, setShowDescription] = React.useState(false);
  const [isBadgeExcellenceModalOpen, setBadgeExcellenceModalOpen] =
    React.useState(false);
  const [openCalendarModal, setOpenCalendarModal] = React.useState(false);

  const [localFromDate, setLocalFromDate] = React.useState<Date | null>(null);
  const [localUntilDate, setLocalUntilDate] = React.useState<Date | null>(null);

  React.useEffect(() => {
    setLocalFromDate(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setLocalUntilDate(untilDate);
  }, [untilDate]);

  const handleClickDone = () => {
    setOpenCalendarModal(false);
    let refetchExperiencesShop = false;
    if (localFromDate !== fromDate || localUntilDate != untilDate) {
      refetchExperiencesShop = true;
    }
    setFromDate(localFromDate);
    setUntilDate(localUntilDate);
    if (refetchExperiencesShop) {
      fetchExperiencesShop();
    }
  };

  if (
    !title ||
    !overview ||
    !rating ||
    duration === undefined ||
    !locationLogistics ||
    !provider ||
    !language
  ) {
    return null;
  }

  const truncatedOverview = truncateString(overview, 300);
  const sameAsStartLocation =
    locationLogistics.startLocations[0].address ===
    locationLogistics.endLocations[0].address;

  const renderAddress = (
    location: ExperienceLocation,
    sameAsStart?: boolean
  ) => {
    const [line1, ...rest] = location.address.split(",");
    const line2 = rest.join(",");

    return (
      <Box className={styles["experiences-shop-overview-map-address"]}>
        {sameAsStart ? (
          <Typography variant="body2">
            {textConstants.END_SAME_AS_START}
          </Typography>
        ) : (
          <>
            {location.name && (
              <Typography variant="body2">{location.name}</Typography>
            )}
            <Typography variant="body2">{line1}</Typography>
            <Typography variant="body2">{line2}</Typography>
          </>
        )}
      </Box>
    );
  };

  return (
    <Box className={styles["experiences-shop-overview-container"]}>
      {badges && badges.length > 0 && (
        <Box className={styles["experience-shop-overview-tags-container"]}>
          {badges.map((badge) => (
            <ExperienceTag
              key={badge}
              badge={badge}
              onBadgeClick={
                badge === ExperienceBadge.BadgeOfExcellence
                  ? () => {
                      if (!isBadgeExcellenceModalOpen)
                        setBadgeExcellenceModalOpen(true);
                    }
                  : undefined
              }
            />
          ))}
        </Box>
      )}
      {/* TOP SECTION */}
      <Box className={styles["experiences-shop-overview-subtext"]}>
        <AvailabilityReviewRating
          reviewCount={rating.numberOfReviews}
          scaledScore={4.5} //// TODO: Remove this it's to deal with mock data
          // scaledScore={props.rating.reviewAverage}
          shortReviews
        />
        <Divider
          className={styles["experiences-shop-overview-subtext-dividers"]}
          orientation="vertical"
        />
        <AvailabilityDuration duration={duration} isSkeleton={isSkeleton} />
        <Divider
          className={styles["experiences-shop-overview-subtext-dividers"]}
          orientation="vertical"
        />
        <AvailabilityLanguage language={language} isSkeleton={isSkeleton} />
        <Divider
          className={styles["experiences-shop-overview-subtext-dividers"]}
          orientation="vertical"
        />
        <Typography className={styles["experiences-shop-overview-title"]}>
          {truncateString(provider, 50)}
        </Typography>
      </Box>
      {/* MIDDLE SECTION */}
      <Box className={styles["experiences-shop-overview-middle-section"]}>
        <Box className={styles["experiences-shop-overview-availability"]}>
          <Typography variant="h6">{"Availability"}</Typography>
          <Box
            className={
              styles["experiences-shop-overview-availability-selections"]
            }
          >
            {bookableItems?.slice(0, 4).map((_item, index) => {
              // TODO: Remove
              const date = "2024-11-17";
              const price: Prices = {
                fiat: {
                  currencyCode: "USD",
                  currencySymbol: "$",
                  value: 71,
                },
                accountSpecific: {},
                rewards: {},
              };
              return (
                <ActionButton
                  className={clsx(
                    styles["experiences-shop-overview-bookable-options-button"]
                  )}
                  defaultStyle={index === 0 ? "h4r-primary" : "h4r-secondary"}
                  onClick={() => {}}
                  message={
                    <Box
                      className={
                        styles[
                          "experiences-shop-overview-bookable-options-button-label"
                        ]
                      }
                    >
                      <span
                        className={
                          styles[
                            "experiences-shop-overview-bookable-options-button-date"
                          ]
                        }
                      >
                        {dayjs(date).format("ddd, MMM D")}
                      </span>
                      <span
                        className={
                          styles[
                            "experiences-shop-overview-bookable-options-button-price"
                          ]
                        }
                      >
                        {`${getPriceString({
                          price: price.fiat.value,
                          currencySymbol: price.fiat.currencySymbol,
                        })} ${
                          pricingCategory?.ExperiencePricingCategory ===
                          ExperiencePricingCategoryEnum.PerGroup
                            ? textConstants.PER_GROUP
                            : textConstants.PER_PERSON
                        }`}
                      </span>
                    </Box>
                  }
                />
              );
            })}
            <ActionLink
              content={
                <Box className={styles["view-all-calendar-link-content"]}>
                  <Icon name={IconName.CalendarWithBoxIcon} />
                  <Typography className={styles["view-all-calendar-link-text"]}>
                    {textConstants.VIEW_ALL}
                  </Typography>
                </Box>
              }
              onClick={() => setOpenCalendarModal(true)}
              className={styles["view-all-calendar-link"]}
            />
          </Box>
        </Box>
        <Box className={styles["experiences-shop-overview-bookables"]}>
          <Typography variant="h6">
            {textConstants.CONFIRM_TRAVELERS}
          </Typography>
          <Box
            className={styles["experiences-shop-overview-bookable-selections"]}
          >
            <B2BButton
              aria-label={"2 Travelers"} // TODO: Make dynamic
              className="num-travelers-input b2b"
              variant="traveler-selector"
              onClick={() => {}} // TODO: Make dynamic
              // onClick={() => setOpenPassengerCountPicker(true)}
            >
              <Box className={styles["num-traveler-content"]}>
                <Box className={styles["num-traveler-with-icon"]}>
                  <Icon
                    aria-hidden={true}
                    className={styles["num-traveler-icon-start"]}
                    name={IconName.B2BUser}
                    ariaLabel=""
                  />
                  <Box className={styles["num-traveler-text"]}>
                    {"2 Travelers"}
                  </Box>
                </Box>
                <Icon
                  aria-hidden={true}
                  className={styles["num-traveler-icon-end"]}
                  name={IconName.B2BEditPencil}
                  ariaLabel=""
                />
              </Box>
            </B2BButton>
            <FormControl className={styles["time-input-container"]}>
              <InputLabel id="time-select-label">
                {textConstants.START_TIME}
              </InputLabel>
              <Select
                labelId="time-select-label"
                id="time-select"
                className={styles["time-selection-menu"]}
                classes={{
                  root: styles["time-select-root"],
                  icon: styles["time-select-arrow-icon"],
                }}
                label={textConstants.START_TIME}
                // onChange={(event) => setTime(event.target.value)}
                value={"12:30 p.m."} // TODO: Make dynamic
                MenuProps={{
                  classes: { paper: "time-selector-menu" },
                }}
                input={
                  <Input
                    className={styles["input-root"]}
                    disableUnderline={true}
                    startAdornment={
                      <Icon
                        className={styles["clock-icon"]}
                        name="clock-icon"
                      />
                    }
                  />
                }
              >
                <MenuItem key={"12:30 p.m."} value={"12:30 p.m."}>
                  {"12:30 p.m."}
                </MenuItem>
                {/* {getTimeOptions(minTime, increment).map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))} */}
              </Select>
            </FormControl>
          </Box>
          <ActionButton
            className="experiences-shop-overview-checkout-button"
            message={
              <span className="experiences-shop-overview-checkout-button-text">
                {textConstants.CONTINUE_TO_CHECKOUT}
              </span>
            }
            defaultStyle="h4r-primary"
            // disabled={(totalAmountRemaining ?? 0) > 0}
            onClick={() => {}}
          />
        </Box>
      </Box>

      {/* BOTTOM SECTION */}
      <Box className={styles["experiences-shop-overview-bottom-section"]}>
        <Typography variant="h6">{"Overview"}</Typography>
        <Typography
          variant="body2"
          className={styles["experiences-shop-overview-text"]}
        >
          {!showDescription ? truncatedOverview : overview}
        </Typography>
        <Link
          component="button"
          onClick={() => setShowDescription(!showDescription)}
          className={styles["experiences-shop-overview-button"]}
        >
          {showDescription ? textConstants.SHOW_LESS : textConstants.READ_MORE}
        </Link>
        <Box className={styles["experiences-shop-overview-map-section"]}>
          <Box
            className={styles["experiences-shop-overview-map-starting-point"]}
          >
            <Typography
              variant="h6"
              className={styles["experiences-shop-overview-map-titles"]}
            >
              <Icon name={IconName.B2BMapPin} />
              {textConstants.STARTING_POINT}
            </Typography>
            {renderAddress(locationLogistics.startLocations[0])}
          </Box>
          <Box className={styles["experiences-shop-overview-map-end-point"]}>
            <Typography
              variant="h6"
              className={styles["experiences-shop-overview-map-titles"]}
            >
              <Icon name={IconName.FlagIcon} />
              {textConstants.END_POINT}
            </Typography>
            {renderAddress(
              locationLogistics.endLocations[0],
              sameAsStartLocation
            )}
          </Box>
          <Box
            className={styles["experiences-shop-overview-map"]}
            onClick={() => {
              // if (onMapClick) onMapClick();
            }}
          ></Box>
        </Box>
      </Box>
      <DesktopPopupModal
        open={openCalendarModal}
        onClose={(event: React.MouseEvent) => {
          event.stopPropagation();
          setOpenCalendarModal(false);
        }}
        className={clsx(
          "desktop-calendar-picker-popup-root",
          "desktop-experiences-calendar-picker-popup-root",
          "experiences-module"
        )}
        contentClassName="desktop-calendar-picker-wrapper"
        invisibleBackdrop={false}
      >
        {/* dailyPrices: Array<ExperienceDailyPrice>; */}
        <MonthAndDatePicker
          viewType={MonthAndDatePickerType.Horizontal}
          startDate={localFromDate}
          endDate={localUntilDate}
          setStartDate={(val: Date | null) => {
            setLocalFromDate(val);
          }}
          setEndDate={(val: Date | null) => {
            setLocalUntilDate(val);
          }}
          header={textConstants.SELECT_DATE}
          subheader={
            pricingCategory?.ExperiencePricingCategory ===
            ExperiencePricingCategoryEnum.PerGroup
              ? textConstants.PRICE_PER_GROUP
              : textConstants.PRICE_PER_PERSON
          }
          className="b2b-flights"
        />
        <Button
          onClick={handleClickDone}
          disabled={!localFromDate || !localUntilDate}
          className="select-dates-button"
          variant="contained"
        >
          {textConstants.SELECT_DATE}
        </Button>
      </DesktopPopupModal>
      {isSkeleton ? <Skeleton /> : null}
    </Box>
  );
};
