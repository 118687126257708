export const MULTI_ACCOUNT_MODAL_TITLE =
  "Which account would you like to book for?";
export const FINTECH_SUBSCRIPTION_V3_SNACKBAR_TITLE = "Subscribe and save!";
export const FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_ONE =
  "Add flight disruption assistance to your business’s next 3 flights and save $10 per booking.";
export const FINTECH_SUBSCRIPTION_V3_SNACKBAR_CHIP_LABEL =
  "Save $30 on 3 bookings";
export const FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_TWO_PREFIX =
  "If your flight is disrupted, ";
export const FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_TWO_MIDDLE =
  "rebook on any airline for no additional cost or get a refund and keep your flight.";
export const FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_TWO_SUFFIX =
  " Automatically add this flexibility to the next 3 flights across your business and get $10 off per booking. No expiration date. Cancel online anytime.";
export const FINTECH_SUBSCRIPTION_V3_SNACKBAR_OK_CTA_LABEL = "Learn more";
export const FINTECH_SUBSCRIPTION_V3_SNACKBAR_CANCEL_CTA_LABEL = "No thanks";

// Value prop modal
export const FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_TITLE =
  "Subscribe and save!";
export const FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_SUBTITLE =
  "Add flight disruption assistance to your business’s next 3 flights and save $10 per booking.\n\n Pricing varies based on flight details, but costs on average 12% of the total flight price. The discount will automatically be applied at booking. No expiration date. Cancel online anytime.";
export const FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_FDA_TITLE =
  "Flight disruption assistance";
export const FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_FDA_SUBTITLE =
  "Add the option to rebook a delayed or canceled flight on any airline we offer or get a refund.";
export const FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_FDA_NOTICE =
  "Protect your work-life balance—add the option to rebook a delayed or canceled flight on any airline we offer or get a refund.";
export const FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_OK_CTA_LABEL =
  "Yes, add Flight Disruption Assistance to my next 3 flights";
export const FINTECH_SUBSCRIPTION_V3_VALUE_PROP_MODAL_CANCEL_CTA_LABEL =
  "No thanks";

export const INFO_BOX_PRIMARY_TITLE = "If you experience:";
export const INFO_BOX_SECONDARY_TITLE = "You can:";
