import {
  CallState,
  DurationRefinement,
  ExperiencesAvailabilityAutocompleteResponse,
  ExperiencesAvailabilityAutocompleteResult,
  ExperiencesAvailabilityRequestEnum,
  ExperiencesAvailabilityResponse,
  ExperiencesAvailabilitySort,
  ExperienceTag,
  ICategorizedResponse,
  PriceRefinement,
  StartTimeRefinement,
  TripadvisorRatingRefinement,
} from "redmond";
import { actions, actionTypes } from "../actions";

export enum ExperiencesAvailabilityCallState {
  NotCalled = "NotCalled",
  InitialSearchCallInProcess = "InitialSearchCallInProcess",
  FollowUpSearchCallInProcess = "FollowUpSearchCallInProcess",
  InitialSearchCallSuccess = "InitialSearchCallSuccess",
  FollowUpSearchCallSuccess = "FollowUpSearchCallSuccess",
  // when a FollowUpSearch returns empty
  Complete = "Complete",
  Failed = "Failed",
}

export interface IExperiencesAvailabilityFilterState {
  tripAdvisorRating?: TripadvisorRatingRefinement;
  duration?: DurationRefinement[];
  startTime?: StartTimeRefinement[];
  price?: PriceRefinement;
  tags?: ExperienceTag[];
  sort: ExperiencesAvailabilitySort;
}

export interface IExperiencesAvailabilityState
  extends IExperiencesAvailabilityFilterState {
  availabilityResponse?: ExperiencesAvailabilityResponse;
  experiencesAvailabilityCallState: ExperiencesAvailabilityCallState;
  keyword: ExperiencesAvailabilityAutocompleteResult | null;
  keywordCategories: ICategorizedResponse[];
  filtersChangedSinceLastSearch: boolean;
  autocompleteCallState: CallState;
  autocompleteResults?: ExperiencesAvailabilityAutocompleteResponse;
}

export const initialState: IExperiencesAvailabilityState = {
  availabilityResponse: undefined,
  experiencesAvailabilityCallState: ExperiencesAvailabilityCallState.NotCalled,
  keyword: null,
  keywordCategories: [],
  filtersChangedSinceLastSearch: false,
  sort: ExperiencesAvailabilitySort.Recommended,
  autocompleteCallState: CallState.NotCalled,
};

export const reducer = (
  state: IExperiencesAvailabilityState = initialState,
  action: actions.ExperiencesAvailabilityActions
): IExperiencesAvailabilityState => {
  switch (action.type) {
    case actionTypes.FETCH_INITIAL_EXPERIENCES_AVAILABILITY:
      return {
        ...state,
        availabilityResponse: undefined,
        experiencesAvailabilityCallState:
          ExperiencesAvailabilityCallState.InitialSearchCallInProcess,
        filtersChangedSinceLastSearch: false,
      };
    case actionTypes.FETCH_FILTERED_EXPERIENCES_AVAILABILITY:
    case actionTypes.FETCH_MORE_EXPERIENCES_AVAILABILITY:
      return {
        ...state,
        experiencesAvailabilityCallState:
          ExperiencesAvailabilityCallState.FollowUpSearchCallInProcess,
        filtersChangedSinceLastSearch: false,
      };
    case actionTypes.SET_EXPERIENCES_AVAILABILITY_RESULTS:
      const isInitialRequest =
        action.responseType ===
        ExperiencesAvailabilityRequestEnum.InitialSearch;
      const hasCompletedRequest =
        !action.payload.nextPageToken ||
        action.payload.experiences.length === 0;
      if (isInitialRequest) {
        return {
          ...state,
          availabilityResponse: action.payload,
          experiencesAvailabilityCallState: hasCompletedRequest
            ? ExperiencesAvailabilityCallState.Complete
            : ExperiencesAvailabilityCallState.InitialSearchCallSuccess,
        };
      }

      return {
        ...state,
        availabilityResponse: {
          ...action.payload,
          // each FollowUpSearch fetches more experiences based on the specified page size;
          // the new experiences result continues from where the previous call ends.
          experiences: [
            ...(state.availabilityResponse?.experiences || []),
            ...action.payload.experiences,
          ],
        },
        experiencesAvailabilityCallState: hasCompletedRequest
          ? ExperiencesAvailabilityCallState.Complete
          : ExperiencesAvailabilityCallState.FollowUpSearchCallSuccess,
      };

    case actionTypes.SET_EXPERIENCES_AVAILABILITY_CALL_STATE_FAILED:
      return {
        ...state,
        experiencesAvailabilityCallState:
          ExperiencesAvailabilityCallState.Failed,
      };

    case actionTypes.SET_KEYWORD:
      const { keyword } = action;
      return {
        ...state,
        keyword: keyword ? { ...keyword } : null,
      };

    case actionTypes.SET_FILTER_TRIPADVISOR_RATING: {
      return {
        ...state,
        tripAdvisorRating: action.rating,
        filtersChangedSinceLastSearch: true,
      };
    }

    case actionTypes.SET_FILTER_START_TIMES: {
      return {
        ...state,
        startTime: action.startTimes,
        filtersChangedSinceLastSearch: true,
      };
    }

    case actionTypes.SET_FILTER_DURATION: {
      return {
        ...state,
        duration: action.durationTimes,
        filtersChangedSinceLastSearch: true,
      };
    }

    case actionTypes.SET_FILTER_PRICE: {
      return {
        ...state,
        price: action.price,
        filtersChangedSinceLastSearch: true,
      };
    }

    case actionTypes.SET_FILTER_TAGS: {
      return {
        ...state,
        tags: action.tags,
        filtersChangedSinceLastSearch: true,
      };
    }

    case actionTypes.SET_SORT_OPTION: {
      return {
        ...state,
        sort: action.sortOption,
        filtersChangedSinceLastSearch: true,
      };
    }

    case actionTypes.FETCH_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE: {
      return {
        ...state,
        autocompleteCallState: CallState.InProcess,
      };
    }

    case actionTypes.SET_EXPERIENCES_AVAILABILITY_AUTOCOMPLETE_RESULTS: {
      return {
        ...state,
        autocompleteResults: action.autocompleteResults,
        autocompleteCallState: CallState.Success,
      };
    }
    default:
      return { ...state };
  }
};

export * from "./selectors";
