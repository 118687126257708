import React from "react";
import { PopularAmenitiesConnectorProps } from "./container";
import { Box, Typography } from "@material-ui/core";
import { RouteComponentProps } from "react-router";
import "./styles.scss";
import { HotelAmenities, Icon, IconName} from "halifax";
import * as textConstants from "./textConstants";
import { useInView } from "react-intersection-observer";
import clsx from "clsx";

export interface PopularAmenitiesProps
  extends PopularAmenitiesConnectorProps,
    RouteComponentProps {
    variant?: "default" | "lifestyle-collection";
    isMobile?: boolean;
}

export const PopularAmenities = (props: PopularAmenitiesProps) => {
  const {
    variant,
    isMobile,
    lodging,
    shopDetailsLodgingData,
  } = props;
  // TODO change once BE is updated to return lodging
  const { lodging: lodgingData } = lodging?.lodging ? lodging : { lodging: shopDetailsLodgingData };
  const [amenitiesRef, ] = useInView({ threshold: 0.8 });

    return (
      <div
        className={clsx("section-wrapper selected-lodging-amenities-section", { mobile: isMobile })}
        id="amenities-section"
        ref={amenitiesRef}
      >
        <Box className="left-section">
          <Typography variant="h2" className="amenities-title">
            {textConstants.AMENITIES_TITLE}
          </Typography>
          <Typography variant="body1" className="amenities-subtitle">
            {textConstants.getAmenitiesSubtitle(variant !== "default")}
          </Typography>
          <HotelAmenities
            amenities={lodgingData?.amenities}
            displayCount={6}
            showAmenitiesText={`View More (${
              lodgingData?.amenities && lodgingData?.amenities?.length - 6
            })`}
            hideAmenityIconsInTooltip
          />
          {
            isMobile && (
            <Box className="additional-benefits">
              <Icon name={IconName.GiftOutline} />
              <Typography className="additional-benefits-text">
                {textConstants.ADDITIONAL_BENEFITS}
              </Typography>
            </Box>
            )
          }
        </Box>
        {
          !isMobile && (
            <Box
              className="right-section"
              style={
                !!lodgingData?.media?.[1]
                    ? { backgroundImage: `url("${lodgingData.media[1].url}")` }
                    : undefined
              }
            ></Box>
          )
        }
      </div>
  );
};
