import {
  CallState,
  FetchFlightTripDetailsResponse,
  FlightShopStep,
  FlightSortOption,
  ITimeRange,
  ShopFlightsResponse,
  AirlineCode,
  IFlightNumberFilter,
} from "redmond";
import * as actionTypes from "./constants";
import * as H from "history";
import { generateFilterTrackingEvent } from "../../search/actions/actions";
import { trackEvent } from "../../../api/v0/trackEvent";

export interface IFetchPackagesFlightShop {
  type: actionTypes.FETCH_PACKAGE_FLIGHT_SHOP;
  history: H.History;
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallPackageFlightAvailability?: boolean;
  };
}

export const fetchPackagesFlightShop = (
  history: H.History,
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallPackageFlightAvailability?: boolean;
  }
): IFetchPackagesFlightShop => ({
  type: actionTypes.FETCH_PACKAGE_FLIGHT_SHOP,
  history,
  options,
});

export interface ISetPackagesFlightShopResults {
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_RESULTS;
  flightShopCallState: CallState;
  // TODO: Change this to use the correct model from airShopSummary
  flightShopResults: ShopFlightsResponse;
}

export const setPackagesFlightShopResults = (args: {
  flightShopCallState: CallState;
  // TODO: Change this to use the correct model from airShopSummary
  flightShopResults: ShopFlightsResponse;
}): ISetPackagesFlightShopResults => ({
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_RESULTS,
  flightShopCallState: args.flightShopCallState,
  flightShopResults: args.flightShopResults,
});

export interface ISetPackagesFlightShopCallStateFailed {
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED;
  flightShopCallError: any; // Change this to correct error type
}

export const setPackagesFlightShopCallStateFailed = (
  flightShopCallError: any // Change this to correct error type
): ISetPackagesFlightShopCallStateFailed => ({
  type: actionTypes.SET_PACKAGE_FLIGHT_SHOP_CALL_STATE_FAILED,
  flightShopCallError,
});

export interface IFetchPackagesTripDetails {
  type: actionTypes.FETCH_PACKAGES_TRIP_DETAILS;
  history: H.History;
  tripId: string;
}

export const fetchPackagesTripDetails = (
  history: H.History,
  tripId: string
): IFetchPackagesTripDetails => ({
  type: actionTypes.FETCH_PACKAGES_TRIP_DETAILS,
  history,
  tripId,
});

export interface ISetPackagesTripDetails {
  type: actionTypes.SET_PACKAGES_TRIP_DETAILS;
  tripDetailsResponse: FetchFlightTripDetailsResponse;
}

export const setPackagesTripDetails = (
  tripDetailsResponse: FetchFlightTripDetailsResponse
): ISetPackagesTripDetails => ({
  type: actionTypes.SET_PACKAGES_TRIP_DETAILS,
  tripDetailsResponse: tripDetailsResponse,
});

export interface ISetPackagesTripDetailsCallStateFailed {
  type: actionTypes.SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED;
  tripDetailsCallError: any; // Change this to correct error type
}

export const setPackagesTripDetailsCallStateFailed = (
  tripDetailsCallError: any // Change this to correct error type
): ISetPackagesTripDetailsCallStateFailed => ({
  type: actionTypes.SET_PACKAGES_TRIP_DETAILS_CALL_STATE_FAILED,
  tripDetailsCallError,
});

export interface ISetPackagesChosenOutgoingSlice {
  type: actionTypes.SET_PACKAGES_CHOSEN_OUTGOING_SLICE;
  outgoingSliceId: string;
  outgoingFareId: string;
  outgoingFareSliceId: string;
  tripId: string;
  outgoingFareRating?: number;
  resetReturnIds?: boolean;
}

export const setPackagesChosenOutgoingSlice = (
  outgoingSliceId: string,
  outgoingFareId: string,
  outgoingFareSliceId: string,
  tripId: string,
  outgoingFareRating?: number,
  resetReturnIds?: boolean
): ISetPackagesChosenOutgoingSlice => ({
  type: actionTypes.SET_PACKAGES_CHOSEN_OUTGOING_SLICE,
  outgoingSliceId,
  outgoingFareId,
  outgoingFareSliceId,
  tripId,
  outgoingFareRating,
  resetReturnIds,
});

export interface ISetPackagesChosenReturnSlice {
  type: actionTypes.SET_PACKAGES_CHOSEN_RETURN_SLICE;
  tripId: string;
  returnFareId: string;
  returnSliceId: string;
  returnFareSliceId: string;
  returnFareRating?: number;
}

export const setPackagesChosenReturnSlice = (
  tripId: string,
  returnSliceId: string,
  returnFareId: string,
  returnFareSliceId: string,
  returnFareRating?: number
): ISetPackagesChosenReturnSlice => ({
  type: actionTypes.SET_PACKAGES_CHOSEN_RETURN_SLICE,
  returnFareId,
  returnSliceId,
  returnFareSliceId,
  returnFareRating,
  tripId,
});

export interface ISetPackagesFlightShopProgress {
  type: actionTypes.SET_PACKAGES_FLIGHT_SHOP_PROGRESS;
  progress: FlightShopStep;
}

export const setPackagesFlightShopProgress = (
  progress: FlightShopStep
): ISetPackagesFlightShopProgress => ({
  type: actionTypes.SET_PACKAGES_FLIGHT_SHOP_PROGRESS,
  progress,
});

export interface ISetSortOption {
  type: actionTypes.SET_SORT_OPTION;
  sortOption: FlightSortOption;
}
export const setSortOption = (
  sortOption: FlightSortOption
): ISetSortOption => ({
  type: actionTypes.SET_SORT_OPTION,
  sortOption,
});

export interface ISetMaxPriceFilter {
  type: actionTypes.SET_MAX_PRICE_FILTER;
  maxPriceFilter: number;
}

export const setMaxPriceFilter = (
  maxPriceFilter: number
): ISetMaxPriceFilter => {
  return {
    type: actionTypes.SET_MAX_PRICE_FILTER,
    maxPriceFilter,
  };
};

export interface ISetAirlineFilter {
  type: actionTypes.SET_AIRLINE_FILTER;
  airlineFilter: AirlineCode[];
}

export const setAirlineFilter = (
  airlineFilter: AirlineCode[]
): ISetAirlineFilter => {
  trackEvent(generateFilterTrackingEvent("airline", "list"));
  return {
    type: actionTypes.SET_AIRLINE_FILTER,
    airlineFilter,
  };
};

export interface ISetOutboundDepartureTimeRange {
  type: actionTypes.SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER;
  outboundDepartureTimeRange: ITimeRange;
}

export const setOutboundDepartureTimeRange = (
  outboundDepartureTimeRange: ITimeRange
): ISetOutboundDepartureTimeRange => {
  trackEvent(generateFilterTrackingEvent("time", "list"));
  return {
    type: actionTypes.SET_OUTBOUND_DEPARTURE_TIME_RANGE_FILTER,
    outboundDepartureTimeRange,
  };
};

export interface ISetOutboundArrivalTimeRange {
  type: actionTypes.SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER;
  outboundArrivalTimeRange: ITimeRange;
}

export const setOutboundArrivalTimeRange = (
  outboundArrivalTimeRange: ITimeRange
): ISetOutboundArrivalTimeRange => {
  trackEvent(generateFilterTrackingEvent("time", "list"));
  return {
    type: actionTypes.SET_OUTBOUND_ARRIVAL_TIME_RANGE_FILTER,
    outboundArrivalTimeRange,
  };
};

export interface ISetReturnDepartureTimeRange {
  type: actionTypes.SET_RETURN_DEPARTURE_TIME_RANGE_FILTER;
  returnDepartureTimeRange: ITimeRange;
}

export const setReturnDepartureTimeRange = (
  returnDepartureTimeRange: ITimeRange
): ISetReturnDepartureTimeRange => {
  trackEvent(generateFilterTrackingEvent("time", "list"));
  return {
    type: actionTypes.SET_RETURN_DEPARTURE_TIME_RANGE_FILTER,
    returnDepartureTimeRange,
  };
};

export interface ISetReturnArrivalTimeRange {
  type: actionTypes.SET_RETURN_ARRIVAL_TIME_RANGE_FILTER;
  returnArrivalTimeRange: ITimeRange;
}

export const setReturnArrivalTimeRange = (
  returnArrivalTimeRange: ITimeRange
): ISetReturnArrivalTimeRange => {
  trackEvent(generateFilterTrackingEvent("time", "list"));
  return {
    type: actionTypes.SET_RETURN_ARRIVAL_TIME_RANGE_FILTER,
    returnArrivalTimeRange,
  };
};

export interface ISetDurationFilter {
  type: actionTypes.SET_DURATION_FILTER;
  duration: number;
}

export const setDurationFilter = (duration: number): ISetDurationFilter => {
  return {
    type: actionTypes.SET_DURATION_FILTER,
    duration,
  };
};
export interface ISetAirportFilter {
  type: actionTypes.SET_AIRPORT_FILTER;
  airportFilter: string[];
}

export const setAirportFilter = (
  airportFilter: string[]
): ISetAirportFilter => {
  return {
    type: actionTypes.SET_AIRPORT_FILTER,
    airportFilter,
  };
};

export interface ISetFlightNumberFilter {
  type: actionTypes.SET_FLIGHT_NUMBER_FILTER;
  flightNumberFilter: IFlightNumberFilter[];
}

export const setFlightNumberFilter = (
  flightNumberFilter: IFlightNumberFilter[]
): ISetFlightNumberFilter => {
  return {
    type: actionTypes.SET_FLIGHT_NUMBER_FILTER,
    flightNumberFilter,
  };
};

export type PackagesFlightShopActions =
  | IFetchPackagesFlightShop
  | ISetPackagesFlightShopResults
  | ISetPackagesFlightShopCallStateFailed
  | IFetchPackagesTripDetails
  | ISetPackagesTripDetails
  | ISetPackagesTripDetailsCallStateFailed
  | ISetPackagesChosenOutgoingSlice
  | ISetPackagesChosenReturnSlice
  | ISetPackagesFlightShopProgress
  | ISetSortOption
  | ISetMaxPriceFilter
  | ISetAirlineFilter
  | ISetOutboundDepartureTimeRange
  | ISetOutboundArrivalTimeRange
  | ISetReturnDepartureTimeRange
  | ISetReturnArrivalTimeRange
  | ISetDurationFilter
  | ISetAirportFilter
  | ISetFlightNumberFilter;
