import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Chip, Divider, Typography } from "@material-ui/core";
import {
  CallState,
  CorpSessionInfo,
  CreditCard,
  FiatPrice,
  Rewards,
  RewardsAccount,
  SessionInfo,
  TokenizeCardErrors,
  TravelProductEnum,
  TravelWalletCredit,
  TravelWalletCreditPayment,
  UserCard,
} from "redmond";
import {
  GenericInfoPopup,
  B2BSpinner,
  LoadingIndicator,
  useDeviceTypes,
  LoadingPopup,
  PoweredByHopper,
  CheckoutPaymentForm,
  B2BPaymentMethodSelectWorkflow,
  Icon,
  IconName,
  createElementId,
  TEST_CARD_LAST_FOURS,
  NotificationBanner,
  BannerSeverity,
  getTotalPriceText,
  twoDecimalFormatter,
} from "halifax";
import clsx from "clsx";
import { useExperimentIsVariant } from "@capone/experiments";
import { OUT_OF_POLICY_REWARDS_BANNER } from "@capone/common";

import { RewardsSelection } from "../RewardsSelection";
import {
  ADD_PAYMENT_AGAIN,
  EDIT_PAYMENT_METHOD,
  UNABLED_TO_ADD_PAYMENT,
  PAYMENT_STEP_SUBTITLE,
  REWARDS_ACCOUNT_TITLE,
  REWARDS_ACCOUNT_SUBTITLE,
  CAP_ONE_INVALID_CREDIT_CARD_TITLE,
  CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE,
  PAYMENT_METHOD_TITLE,
  PAYMENT_METHOD_SUBTITLE,
  CTA_SINGLE_ACCOUNT_ADD_YOUR_TEXT,
  CTA_SINGLE_ACCOUNT_CREDIT_CARD_TEXT,
  ADD_PAYMENT_METHOD_CTA_MULTIPLE_ACCOUNTS,
  ADD_PAYMENT_METHOD_MODAL_TITLE,
  ADD_ADDITIONAL_PAYMENT_METHOD_CTA,
  BACK_TO_CARD_SELECTION_CTA,
  CARD_ENDING_IN_TEXT,
  ADD_PAYMENT_FORM_HEADER_TEXT,
  MOBILE_PAYMENT_STEP_TITLE,
  MOBILE_PAYMENT_STEP_SUBTITLE,
  PAYMENT_METHOD_SUBTITLE_AMOUNT,
  TRY_AGAIN,
  INELIGIBLE_ACCOUNTS_NOTICE,
  INELIGIBLE_ACCOUNTS_TOOLTIP,
  ADD_PAYMENT_FORM_SUBTITLE_TEXT,
  PAYMENT_CARD_SUBTITLE_WITH_CREDITS_AND_OFFERS,
  PAYMENT_TITLE,
} from "./textConstants";
import { config } from "../../../api/config";
import { RouteComponentProps } from "react-router";
import {
  ANNUAL_TRAVEL_CREDITS,
  AVAILABLE,
  CONTROL,
  CREDIT_OFFER_STACKING_V1,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  TRAVEL_SALE,
  TRAVEL_SALE_VARIANTS,
  useExperiments,
  VCN_ENABLEMENT,
} from "../../../context/experiments";
import "./styles.scss";
import { IVerifyPaymentMethodArgs } from "../../book/actions/actions";
import { RewardsSelectionProps } from "../RewardsSelection/component";
import { TravelWalletSelection } from "../TravelWalletSelection";
import { Prices } from "@b2bportal/air-shopping-api";
import { ITravelWalletSelectionProps } from "../TravelWalletSelection/component";
import { ClientContext } from "../../../App";

type OmitValues =
  | "totalCreditCardPaymentRequiredNumber"
  | "totalCreditCardPaymentRequired"
  | "isCreditCardPaymentRequired"
  | "maxApplicableTravelWalletCredit";

type RewardsSelectionPropsOmitted = Omit<RewardsSelectionProps, OmitValues>;

type ITravelWalletSelectionPropsOmitted = Omit<
  ITravelWalletSelectionProps,
  OmitValues
>;

export type PaymentTypes = {
  creditCard?: {
    payment: UserCard;
    card: CreditCard;
  };
  rewards?: {
    payment: Rewards;
    account: RewardsAccount;
  };
  travelCredit?: {
    payment: TravelWalletCreditPayment;
    account: TravelWalletCredit;
  };
};

export interface IPaymentCardProps
  extends RewardsSelectionPropsOmitted,
    ITravelWalletSelectionPropsOmitted,
    RouteComponentProps {
  disabled?: boolean;
  // note: this prop is used to overwrite the copy in step-title
  paymentStepTitle?: string;
  className?: string;
  paymentMethods: CreditCard[];
  rewardsAccounts: RewardsAccount[];
  selectedPaymentMethodId: string;
  listPaymentMethodCallState: CallState;
  verifyPaymentMethodCallState: CallState;
  deletePaymentMethodCallState: CallState;
  selectedRewardsPaymentAccount: RewardsAccount | undefined;
  selectedRewardsPaymentAccountId: string | null | undefined;
  hasError: boolean;
  canRedeemRewards: boolean;
  userIsPrimary: boolean;
  verifyPaymentMethod: (
    payload: IVerifyPaymentMethodArgs,
    accountId: string,
    isVCN: boolean
  ) => any;
  listPaymentMethods: () => any;
  deletePaymentMethod: (args: { paymentId: string }) => any;
  setSelectedPaymentMethodId: (args: {
    paymentMethodId?: string | undefined;
    accountId?: string | undefined;
  }) => any;
  fetchRewardsAccounts: (
    refreshCache?: boolean | undefined,
    sessionInfo?: SessionInfo | CorpSessionInfo
  ) => any;
  fetchProductToEarn: () => any;
}

export const PaymentCard = (props: IPaymentCardProps) => {
  const {
    listPaymentMethods,
    verifyPaymentMethod,
    deletePaymentMethod,
    setSelectedPaymentMethodId,
    fetchRewardsAccounts,
    fetchProductToEarn,
    selectedRewardsPaymentAccount,
    selectedRewardsPaymentAccountId,
    paymentMethods,
    rewardsAccounts,
    selectedPaymentMethodId,
    listPaymentMethodCallState,
    verifyPaymentMethodCallState,
    deletePaymentMethodCallState,
    paymentStepTitle,
    disabled = false,
    hasError,
    className,
    canRedeemRewards,
    userIsPrimary,
    total,
    rewardsPaymentInFiatCurrency,
    creditToApply,
    type = "flight",
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const { sessionInfo } = useContext(ClientContext);
  const [openErrorPaymentModal, setOpenErrorPaymentModal] = useState(false);
  const [isNotCapOneAccount, setIsNotCapOneAccount] = useState(false);
  const [tokenizeErrors, setTokenizeErrors] = useState<TokenizeCardErrors[]>(
    []
  );

  const corpSessionInfo = sessionInfo && (sessionInfo as CorpSessionInfo);

  function twoDecimalsMinZero(value?: number) {
    return Math.round(Math.max(value ?? 0, 0) * 100) / 100;
  }

  const totalAmount = total.fiat.value;

  const balanceRemaingAfterCredit = twoDecimalsMinZero(
    totalAmount + (creditToApply?.amount.amount ?? 0)
  );

  const isTravelCreditPaymentOnly = balanceRemaingAfterCredit <= 0;
  const isStackedTravelWalletPaymentOnly = false;
  const isTravelWalletOfferPaymentOnly = false;

  const totalCreditCardPaymentRequired = twoDecimalsMinZero(
    balanceRemaingAfterCredit - (rewardsPaymentInFiatCurrency?.value ?? 0)
  );

  const totalCreditCardPaymentRequiredString =
    getTotalCreditCardPaymentRequiredLogic({
      ...total.fiat,
      value: totalCreditCardPaymentRequired,
    });

  const isCreditCardPaymentRequired = totalCreditCardPaymentRequired > 0;

  const expState = useExperiments();

  const allowAnyCards = useExperimentIsVariant(
    "c1-corporate-credit-card-validation",
    "any-spark"
  );

  const vcnEnablement = getExperimentVariant(
    expState.experiments,
    VCN_ENABLEMENT
  );
  const isVCNEnabled = React.useMemo(
    () => vcnEnablement === AVAILABLE,
    [vcnEnablement]
  );

  const creditAndOfferStackingExperimentV1 = getExperimentVariant(
    expState.experiments,
    CREDIT_OFFER_STACKING_V1
  );
  const isCreditAndOfferStackingExperimentV1 = useMemo(() => {
    return creditAndOfferStackingExperimentV1 === AVAILABLE;
  }, [creditAndOfferStackingExperimentV1]);

  const isAnnualTravelCreditsExperiment =
    getExperimentVariant(expState.experiments, ANNUAL_TRAVEL_CREDITS) ===
    AVAILABLE;

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );
  const isTravelSaleEnabled = travelSaleVariant !== CONTROL;

  const isCorpHideTravelOffers = useExperimentIsVariant(
    "corp-hide-travel-wallet-offers",
    AVAILABLE
  );

  useEffect(() => {
    listPaymentMethods();
    fetchRewardsAccounts(true);
  }, []);

  const ineligibleRewardsAccounts = useMemo(
    () =>
      rewardsAccounts.filter(
        (account) => !(account.allowRewardsRedemption ?? true)
      ),
    [rewardsAccounts]
  );

  const handleOnAddPaymentMethod = (token: string, last4: string) => {
    // note: cap1 specific logic:
    // A card should be deemed ineligible if the last four digits do not match one of the cards associated with their accounts.
    const account = rewardsAccounts.find(
      (account) =>
        account.lastFour === last4 ||
        account.lastFourVirtualCardNumbers?.includes(last4)
    );
    let matchingRewardsAccount = account;

    const isAddingVCNPaymentMethod = !!(
      matchingRewardsAccount?.lastFourVirtualCardNumbers &&
      matchingRewardsAccount?.lastFourVirtualCardNumbers?.includes(last4)
    );

    // TODO: bad practice, remove this in favor of real test accounts when we have them
    const isTestCard =
      window.__mclean_env__.ENV !== "production" &&
      TEST_CARD_LAST_FOURS.includes(last4);
    if (isTestCard) {
      matchingRewardsAccount = rewardsAccounts[0];
    }

    if (!!matchingRewardsAccount || isTestCard || allowAnyCards) {
      verifyPaymentMethod(
        { token },
        matchingRewardsAccount?.accountReferenceId!,
        isAddingVCNPaymentMethod
      );
    } else {
      setIsNotCapOneAccount(true);
      setOpenErrorPaymentModal(true);
    }
  };

  const handleCloseErrorPopup = () => {
    isNotCapOneAccount && setIsNotCapOneAccount(false);
    setOpenErrorPaymentModal(false);
  };

  const PaymentAddingElement = () => (
    <LoadingPopup
      indicatorSize={"small"}
      indicator={B2BSpinner}
      open={true}
      popupSize={"small"}
      message={"Adding your payment method"}
      footer={PoweredByHopper}
    />
  );

  const renderCheckoutPaymentForm = () => {
    return (
      <CheckoutPaymentForm
        loading={verifyPaymentMethodCallState === CallState.InProcess}
        loadingEl={<PaymentAddingElement />}
        onSubmit={(token, last4) => {
          handleOnAddPaymentMethod(token, last4);
        }}
        saveLabel={"Save"}
        onError={(errors) => {
          setTokenizeErrors(errors);
          setOpenErrorPaymentModal(true);
        }}
        spreedlyEnvironmentKey={config.spreedlyEnvironmentKey}
        isMobile={matchesMobile}
        className="b2b"
        buttonClassName="b2b"
      />
    );
  };

  const isPaymentMethodSelectDisabled =
    selectedRewardsPaymentAccount?.isTiered ||
    !isCreditCardPaymentRequired ||
    !totalCreditCardPaymentRequired ||
    selectedRewardsPaymentAccountId === undefined;

  const paymentMethodWorkflowTitles = () => ({
    addPaymentCta:
      rewardsAccounts.length === 1 ? (
        <Box
          className={clsx("add-payment-cta-container", {
            mobile: matchesMobile,
          })}
        >
          <Typography variant="body1">
            {CTA_SINGLE_ACCOUNT_ADD_YOUR_TEXT}
            <b className="card-name">{rewardsAccounts[0].productDisplayName}</b>
            {CTA_SINGLE_ACCOUNT_CREDIT_CARD_TEXT}
          </Typography>
          {rewardsAccounts[0].earn.flightsMultiplier &&
          rewardsAccounts[0].earn.flightsMultiplier > 0 ? (
            <Chip
              label={`Earn ${rewardsAccounts[0].earn.flightsMultiplier}X on flights`}
              className={clsx("earn-chip", {
                enabled: !isPaymentMethodSelectDisabled,
              })}
            />
          ) : null}
        </Box>
      ) : (
        ADD_PAYMENT_METHOD_CTA_MULTIPLE_ACCOUNTS
      ),
    addPaymentModalTitle: ADD_PAYMENT_METHOD_MODAL_TITLE,
    addAdditionalPaymentCta: ADD_ADDITIONAL_PAYMENT_METHOD_CTA,
    backToCardSelectionCta: BACK_TO_CARD_SELECTION_CTA,
    paymentFormHeader: (cardName: string) => (
      <Typography
        variant={"h4"}
        dangerouslySetInnerHTML={{
          __html: ADD_PAYMENT_FORM_HEADER_TEXT(cardName),
        }}
      ></Typography>
    ),
    paymentFormSubtitle: isVCNEnabled
      ? ADD_PAYMENT_FORM_SUBTITLE_TEXT
      : undefined,
    cardEndingIn: CARD_ENDING_IN_TEXT,
  });

  const getPaymentCardTitle = (): string => {
    if (paymentStepTitle) {
      return paymentStepTitle;
    } else if (matchesMobile) {
      return MOBILE_PAYMENT_STEP_TITLE(canRedeemRewards);
    } else {
      return PAYMENT_TITLE(canRedeemRewards);
    }
  };

  const rewardsTitleId = createElementId("rewardsTitle");
  const rewardsSubtitleId = createElementId("rewardsSubtitle");

  const creditAmount = -(props.creditToApply?.amount.amount ?? 0);
  const rewardsTotal: Prices = {
    ...props.total,
    fiat: {
      ...props.total.fiat,
      value: props.total.fiat.value - creditAmount,
    },
  };

  for (var key in props.total.accountSpecific) {
    const accountObj = props.total.accountSpecific[key];
    const creditAmountConversion = accountObj.currency === "Cash" ? 1 : 100;
    rewardsTotal.accountSpecific[key] = {
      ...accountObj,
      value: accountObj.value - creditAmount * creditAmountConversion,
    };
  }
  const getParadiseCardMessage = () =>
    rewardsAccounts.find((acct) =>
      acct.productDisplayName.toLowerCase().includes("paradise")
    ) ? (
      <NotificationBanner
        className={clsx("paradise-banner-notification", {
          mobile: matchesMobile,
        })}
        label="Bookings made with your Paradise card through Capital One Travel cannot be serviced by concierge services. If you’d like concierge to manage your booking, book in the Capital One concierge app."
        severity={BannerSeverity.NOTICE}
      />
    ) : null;
  return (
    <>
      {listPaymentMethodCallState === CallState.InProcess ||
      deletePaymentMethodCallState === CallState.InProcess ? (
        <LoadingIndicator
          indicatorSize={"small"}
          indicator={B2BSpinner}
          message={
            listPaymentMethodCallState === CallState.InProcess
              ? `Fetching`
              : `Deleting`
          }
        />
      ) : (
        <Box
          className={clsx(
            "payment-methods-container",
            {
              mobile: matchesMobile,
              disabled: disabled,
            },
            className
          )}
        >
          <Typography className="step-title" variant="h2">
            {getPaymentCardTitle()}
          </Typography>
          <Typography variant="body2" className="payment-step-subtitle">
            {isCreditAndOfferStackingExperimentV1
              ? PAYMENT_CARD_SUBTITLE_WITH_CREDITS_AND_OFFERS(
                  !isCorpHideTravelOffers
                )
              : matchesMobile
              ? MOBILE_PAYMENT_STEP_SUBTITLE(canRedeemRewards)
              : PAYMENT_STEP_SUBTITLE(canRedeemRewards)}
          </Typography>
          {canRedeemRewards && (
            <>
              {ineligibleRewardsAccounts.length > 0 && (
                <NotificationBanner
                  className="authorized-users-rewards-banner"
                  label={INELIGIBLE_ACCOUNTS_NOTICE(rewardsAccounts)}
                  severity={BannerSeverity.NOTICE}
                  tooltip={
                    rewardsAccounts.length === ineligibleRewardsAccounts.length
                      ? {
                          label: INELIGIBLE_ACCOUNTS_TOOLTIP(
                            ineligibleRewardsAccounts
                          ),
                          icon: IconName.InfoCircle,
                        }
                      : undefined
                  }
                />
              )}

              <Divider className={"payment-methods-container-divider"} />
              {isCreditAndOfferStackingExperimentV1 && (
                <TravelWalletSelection
                  {...props}
                  disabled={disabled}
                  isMobile={matchesMobile}
                  isAnnualTravelCreditsExperiment={
                    isAnnualTravelCreditsExperiment
                  }
                  isCreditAndOfferStackingExperimentV1={
                    isCreditAndOfferStackingExperimentV1
                  }
                  isTravelSale={isTravelSaleEnabled}
                  maxApplicableTravelWalletCredit={maxApplicableCreditAmount(
                    total,
                    0,
                    props.credits
                  )}
                />
              )}
              {getParadiseCardMessage()}
              <Typography
                variant="h3"
                className="rewards-account-title"
                id={rewardsTitleId}
              >
                {REWARDS_ACCOUNT_TITLE}
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{ __html: REWARDS_ACCOUNT_SUBTITLE }}
                id={rewardsSubtitleId}
                className="rewards-accounts-subtitle"
              ></Typography>
              <RewardsSelection
                {...{
                  disabled:
                    disabled ||
                    (isCreditAndOfferStackingExperimentV1 &&
                      (isTravelCreditPaymentOnly ||
                        isStackedTravelWalletPaymentOnly ||
                        isTravelWalletOfferPaymentOnly)),
                  ...props,
                  rewardsTitleId,
                  rewardsSubtitleId,
                  totalCreditCardPaymentRequired,
                  isCreditCardPaymentRequired,
                  totalCreditCardPaymentRequiredNumber:
                    totalCreditCardPaymentRequired,
                  total: rewardsTotal,
                  type,
                }}
              />
            </>
          )}

          {!canRedeemRewards && userIsPrimary && (
            <NotificationBanner
              className="out-of-policy-rewards-banner"
              severity={BannerSeverity.NOTICE}
              label={OUT_OF_POLICY_REWARDS_BANNER}
            />
          )}
          <Divider className={"payment-methods-container-divider"} />
          <Typography className="payment-method-title" variant="h3">
            {PAYMENT_METHOD_TITLE}
          </Typography>
          {!isPaymentMethodSelectDisabled && (
            <Typography variant="body2" className="payment-method-subtitle">
              {!selectedPaymentMethodId && PAYMENT_METHOD_SUBTITLE}
              <b>
                {PAYMENT_METHOD_SUBTITLE_AMOUNT(
                  totalCreditCardPaymentRequiredString
                )}
              </b>
            </Typography>
          )}
          <B2BPaymentMethodSelectWorkflow
            errorModalOpen={openErrorPaymentModal}
            rewardsAccounts={rewardsAccounts ?? []}
            savedPayments={paymentMethods ?? []}
            selectedPaymentHopperId={selectedPaymentMethodId}
            disabled={
              isPaymentMethodSelectDisabled ||
              disabled ||
              hasError ||
              (isCreditAndOfferStackingExperimentV1 &&
                (isTravelCreditPaymentOnly ||
                  isStackedTravelWalletPaymentOnly ||
                  isTravelWalletOfferPaymentOnly))
            }
            selectPaymentMethod={(paymentId, rewardsAccount) => {
              setSelectedPaymentMethodId({
                paymentMethodId: paymentId,
                accountId: rewardsAccount?.accountReferenceId,
              });
              fetchProductToEarn();
            }}
            removePaymentMethod={(paymentId: string) => {
              deletePaymentMethod({ paymentId });
            }}
            titles={paymentMethodWorkflowTitles()}
            renderCheckoutPaymentForm={renderCheckoutPaymentForm}
            isMobile={matchesMobile}
            product={TravelProductEnum.Flights}
            loading={verifyPaymentMethodCallState === CallState.InProcess}
            buttonClassName="b2b"
            fullScreenWithBanner={matchesMobile}
            paymentMethodDisabled={(_r) => false}
            isVCNEnabled={isVCNEnabled}
            allowAnyCards={allowAnyCards}
            corporateInfo={corpSessionInfo?.corporateInfo}
          />
        </Box>
      )}
      <GenericInfoPopup
        open={openErrorPaymentModal}
        image={
          <Icon
            className="error-icon"
            name={
              isNotCapOneAccount
                ? IconName.ErrorState
                : IconName.UnableToProcess
            }
          />
        }
        title={
          isNotCapOneAccount
            ? CAP_ONE_INVALID_CREDIT_CARD_TITLE
            : UNABLED_TO_ADD_PAYMENT
        }
        subtitle={
          isNotCapOneAccount
            ? CAP_ONE_INVALID_CREDIT_CARD_SUBTITLE
            : tokenizeErrors.length > 0
            ? tokenizeErrors[0].message
            : ADD_PAYMENT_AGAIN
        }
        buttons={[
          {
            buttonText: EDIT_PAYMENT_METHOD,
            onClick: () => {
              handleCloseErrorPopup();
            },
            defaultStyle: "h4r-secondary",
          },
          {
            buttonText: TRY_AGAIN,
            onClick: () => {
              handleCloseErrorPopup();
            },
            defaultStyle: "h4r-primary",
            buttonWrapperClassName: "b2b",
          },
        ]}
        isMobile={matchesMobile}
      ></GenericInfoPopup>
    </>
  );
};

export function getTotalCreditCardPaymentRequiredInFiatPriceLogic(
  total: FiatPrice,
  rewardsPaymentAmount?: FiatPrice | null,
  travelWalletCredit?: TravelWalletCredit
): FiatPrice {
  const totalRemaining =
    total.value +
    (travelWalletCredit?.amount.amount ?? 0) -
    (rewardsPaymentAmount?.value ?? 0);

  return {
    ...total,
    value: Math.max(totalRemaining, 0),
  };
}

export function getTotalCreditCardPaymentRequiredLogic(
  totalCreditCardPaymentRequired: FiatPrice
): string {
  if (totalCreditCardPaymentRequired.value === 0) return "";

  return getTotalPriceText({
    price: totalCreditCardPaymentRequired,
    priceFormatter: twoDecimalFormatter,
  });
}

export function getTotalCreditCardPaymentRequiredNumberLogic(
  totalCreditCardPaymentRequired: FiatPrice
): number | null {
  if (totalCreditCardPaymentRequired.value === 0) return null;

  return totalCreditCardPaymentRequired.value;
}

const maxApplicableCreditAmount = (
  total: Prices,
  offerAppliedAmount: number,
  credit?: TravelWalletCredit
) => {
  const totalBeforeWalletItem = total.fiat.value;
  if (credit && totalBeforeWalletItem) {
    let totalAfterOffer =
      totalBeforeWalletItem + credit?.amount.amount - offerAppliedAmount;
    return totalAfterOffer < 0
      ? (totalBeforeWalletItem - offerAppliedAmount) * -1
      : credit.amount.amount;
  }
  return 0;
};
