// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".bo2kaHu65vkBc\\+2mHT6G8Q\\=\\={font-size:12px;color:var(--blue-9)}", "",{"version":3,"sources":["webpack://./../../cap1-modules/experiences-module/src/modules/common/EarnTagText/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,cAAA,CACA,mBAAA","sourcesContent":[".earn-tag-text {\n  font-size: 12px;\n  color: var(--blue-9);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"earn-tag-text": "bo2kaHu65vkBc+2mHT6G8Q=="
};
export default ___CSS_LOADER_EXPORT___;
