import React, { useEffect, useState, useMemo, useRef, useContext } from "react";
import { RouteComponentProps } from "react-router";
import { Box, Typography } from "@material-ui/core";
import {
  Icon,
  IconName,
  ActionButton,
  GenericAddOnItem as AddOnItem,
  ErrorOutsideComponentType,
  useDeviceTypes,
  getRewardText,
  CorpPolicyBanner,
  getCfarAirDetailsRefundString,
} from "halifax";
import {
  TripCategory,
  CallState,
  CFAR_ADD_ON_CHOICE,
  DISRUPTION_ADD_ON_CHOICE,
  Cap1DisruptionAddOnChoiceProperties,
  CAP1_VIEW_BOOKING_ADD_ON,
  CFAR_HEADER,
  DISRUPTION_PROTECTION_HEADER,
  CorporateTravel,
  CorpFareDetails,
  ChfarAirOrchestratorEvent,
} from "redmond";
import clsx from "clsx";
import { useExperimentsById } from "@capone/experiments";
import { getPriceFreezeRewardsString as getRewardsString } from "../../../utils/helpers";
import { ClientContext } from "../../../../../App";
import { Progress, CheckoutSteps } from "../../../../book/reducer/selectors";
import { AGENT_FEE } from "../../../../book/components";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";
import {
  CfarDetails,
  CfarAttachedContent,
  constants as CfarConstants,
} from "../cfar";
import {
  ChfarDetails,
  ChfarAttachedContent,
  DECLINED_COLLAPSED_COPY as CHFAR_DECLINED_COLLAPSED_COPY,
} from "../chfar";
import {
  DisruptionProtectionDetails,
  constants as DisruptionConstants,
} from "../disruptionProtection";
import { AddOnPricingBreakdown } from "../AddOnPricingBreakdown";
import { AddOnCustomizeConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";
import {
  AVAILABLE,
  SEAT_SELECTION,
  getExperimentVariant,
  useExperiments,
  FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT,
  CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY,
  CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY_CFAR_FDA,
} from "../../../../../context/experiments";
import { AddOnTestimonyCarousel } from "../AddOnTestimonyCarousel";
import { CardWrapper } from "../AddOnCardWrapper";
import {
  CORP_FINTECH_SUBSCRIPTION_KEY,
  DO_NOT_APPLY_OPTION_KEY,
} from "../../../reducer";

import { CollapsedContent } from "../CollapsedContent";
import {
  DO_NOT_APPLY_CFAR_OPTION,
  DO_NOT_APPLY_CHFAR_OPTION,
  getFormattedTotalPriceText,
} from "../helpers";
import { onChfarViewedEvent } from "../common";
import {
  corpFintechSmoketestMap,
  CorpFintechSubscriptionModal,
} from "b2b-base/src/components/capone-corporate";
import optInSubscription from "b2b-base/src/api/v1/cotbFintech/optInSubscription";
import { CorpFintechSubscriptionBanner } from "../CorpFintechSmoketest";

export interface IAddOnCustomizeProps
  extends AddOnCustomizeConnectorProps,
    RouteComponentProps {
  isPriceFreezeExercise?: boolean;
  tripCategory: TripCategory;
  // note: this callback function is used to overwrite the default (successful) on-continue behaviour
  onContinueSuccess?: () => void;
  goBackToPreviousPage?: () => void;
  isDisruptionProtectionEnabled: boolean;
  hideGoToCheckoutButton?: boolean;
  hidePricingBreakdown?: boolean;
  useFlightCheckoutVariant?: boolean;
  useMultiPageVariant?: boolean;
  disableCollapse?: boolean;
}

enum AncillaryType {
  Cfar,
  Chfar,
  DisruptionProtection,
}

export const AddOnCustomize = (props: IAddOnCustomizeProps) => {
  const {
    isCfarAvailable,
    selectedCfarId,
    cfarOfferPrices,
    isChfarAvailable,
    isChfarVariantDeclineAll,
    setSelectedCfarId,
    setSelectedChfarId,
    selectedChfarId,
    chfarOfferPrices,
    chfarTrackingProps,
    isDisruptionProtectionAvailable,
    selectedDisruptionProtectionId,
    disruptionProtectionOfferPrices,
    rewardsKey,
    isOptionSelectionComplete,
    isDpOptionSelectionComplete,
    isCfarOptionSelectionComplete,
    populateFlightBookQueryParams,
    fetchAncillaryOffer,
    onContinueSuccess = () => {
      populateFlightBookQueryParams({ history });
    },
    goBackToPreviousPage,
    getDpOptionSelectionProgress,
    getCfarOptionSelectionProgress,
    getCheckoutStepNumber,
    scheduleQuote,
    fetchAncillaryOfferCallState,
    history,
    isPriceFreezeExercise,
    cfarAddOnChoiceProperties,
    dpAddOnChoiceProperties,
    viewBookingAddOnProperties,
    dpOfferPropertiesForViewBookingAddOn,
    cfarOfferPropertiesForViewBookingAddOn,
    hideGoToCheckoutButton,
    useFlightCheckoutVariant,
    hidePricingBreakdown = useFlightCheckoutVariant,
    useMultiPageVariant,
    disableCollapse = useMultiPageVariant,
    hasActiveRefundableFare,
    cfarSocialVariant,
    cfarDiscountPromoCopy,
    discountedCfarOffer,
    cfarOffer,
    cfarDiscountProperties,
    isAirOfferRedesignEnabled,
    isCfarCoMerchEnabled,
    selectedCfarOfferCoverage,
    selectedFare,
    pfFare,
    priceFreezeTaxAmount,
    useGroupedAncillaries,
    isCorpFintechEligible,
    setSelectedDisruptionProtectionId,
    hasTravelFusionFareBrand,
  } = props;
  const { matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;
  const [isCfarCollapsed, setIsCfarCollapsed] = useState<boolean>(false);
  const [isChfarCollapsed, setIsChfarCollapsed] = useState<boolean>(false);
  const [cfarError, setCfarError] = useState<ErrorOutsideComponentType>();
  const [chfarError, setChfarError] = useState<ErrorOutsideComponentType>();
  const [isDisruptionProtectionCollapsed, setIsDisruptionProtectionCollapsed] =
    useState<boolean>(false);
  const [disruptionProtectionError, setDisruptionProtectionError] =
    useState<ErrorOutsideComponentType>();
  const [currentAncillaryIndex, setCurrentAncillaryIndex] = useState<number>(0);
  const [openCfarModal, setOpenCfarModal] = useState<boolean>(false);
  const [
    openCorpFintechSubscriptionModal,
    setOpenCorpFintechSubscriptionModal,
  ] = useState<boolean>(false);
  const [hasSelectedCorpFintech, setHasSelectedCorpFintech] =
    useState<boolean>(false);

  const continueButtonRef = useRef<HTMLDivElement | null>(null);

  const isCorpFintechSubscription =
    selectedDisruptionProtectionId?.policyId ===
      CORP_FINTECH_SUBSCRIPTION_KEY && disruptionProtectionOfferPrices;

  const expState = useExperiments();

  const seatSelectionGroup = getExperimentVariant(
    expState.experiments,
    SEAT_SELECTION
  );
  const isSeatSelectionGroupEnabled = seatSelectionGroup === AVAILABLE;

  const hideIconColumn = useFlightCheckoutVariant || useGroupedAncillaries;

  const isFlightListOptimizationExperiment = useMemo(
    () =>
      !matchesMobile &&
      getExperimentVariant(
        expState.experiments,
        FLIGHT_LIST_OPTIMIZATION_V1_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );

  const corpFintechVariant =
    useExperimentsById("corp-fintech-subscription-smoketest")?.variant ||
    "control";

  const corpSmoketestVariant = corpFintechSmoketestMap[corpFintechVariant];

  const showCorpFintechSmoketestBanner =
    corpFintechVariant === "available_variant2" && isCorpFintechEligible;

  const isDisruptionProtectionDisabled =
    useFlightCheckoutVariant &&
    getDpOptionSelectionProgress(isSeatSelectionGroupEnabled)?.status ===
      Progress.NOT_STARTED;
  const isCfarDisabled =
    useFlightCheckoutVariant &&
    getCfarOptionSelectionProgress(isSeatSelectionGroupEnabled)?.status ===
      Progress.NOT_STARTED;

  const cfarDiscount =
    cfarOffer && cfarOffer.length > 0 ? cfarOffer[0].discount : undefined;
  const cfarDiscountEnabled = cfarDiscount !== undefined;

  const hasError = useMemo(
    () =>
      cfarError === ErrorOutsideComponentType.NoOptionSelected ||
      disruptionProtectionError === ErrorOutsideComponentType.NoOptionSelected,
    [cfarError, disruptionProtectionError]
  );

  const handleCfarAddOnChoiceEvent = () => {
    trackEvent({
      eventName: CFAR_ADD_ON_CHOICE,
      properties: {
        ...cfarAddOnChoiceProperties,
        ...{
          cfar_discount_original_premium:
            cfarDiscountProperties.original_premium,
          cfar_discount_percentage: cfarDiscountProperties.discount_percentage,
        },
        success: true,
      },
    });
  };

  const handleDpAddOnChoiceEvent = () => {
    trackEvent({
      eventName: DISRUPTION_ADD_ON_CHOICE,
      properties: {
        ...dpAddOnChoiceProperties,
      } as Cap1DisruptionAddOnChoiceProperties,
    });
  };

  const handleChfarChoiceEvent = () => {
    const properties = chfarTrackingProps?.properties ?? {};

    const chfarChoice =
      selectedChfarId !== null
        ? selectedChfarId.quoteId !== DO_NOT_APPLY_CHFAR_OPTION.quoteId
          ? true
          : false
        : undefined;
    trackEvent({
      eventName: ChfarAirOrchestratorEvent.CHOOSE_CHFAR,
      properties: {
        ...properties,
        chfar_choice: chfarChoice,
      },
      encryptedProperties: chfarTrackingProps?.encryptedProperties,
    });
  };

  const handleOnContinue = () => {
    let choice = "declined";
    if (
      selectedDisruptionProtectionId?.policyId === CORP_FINTECH_SUBSCRIPTION_KEY
    ) {
      choice = "subscription";
      !hasSelectedCorpFintech && optInSubscription({ optIn: true });
      setHasSelectedCorpFintech(true);
      setOpenCorpFintechSubscriptionModal(true);
      setIsDisruptionProtectionCollapsed(false);
    } else {
      if (isCorpFintechEligible && !hasSelectedCorpFintech) {
        optInSubscription({ optIn: false });
      }
      if (isOptionSelectionComplete) {
        handleCfarAddOnChoiceEvent();
        handleDpAddOnChoiceEvent();
        handleChfarChoiceEvent();
        onContinueSuccess();

        if (
          selectedDisruptionProtectionId?.policyId !== DO_NOT_APPLY_OPTION_KEY
        ) {
          choice = "full_price";
        }
      } else {
        if (!selectedCfarId) {
          setCfarError(ErrorOutsideComponentType.NoOptionSelected);
        }
        if (!selectedChfarId) {
          setChfarError(ErrorOutsideComponentType.NoOptionSelected);
        }
        if (!selectedDisruptionProtectionId) {
          setDisruptionProtectionError(
            ErrorOutsideComponentType.NoOptionSelected
          );
        }
      }
    }

    if (isCorpFintechEligible && !hasSelectedCorpFintech) {
      trackEvent({
        eventName: "fintech_subscription_choice",
        properties: {
          test_type: corpSmoketestVariant,
          subscription_type: "fda_10dollarsoff3bookings",
          choice,
          disruption_total:
            dpOfferPropertiesForViewBookingAddOn.disruption_total,
        },
      });
    }
  };

  const scrollToElement = (
    divRef: React.MutableRefObject<HTMLDivElement | null>
  ) => {
    if (divRef.current) {
      divRef.current.style.scrollMarginBottom = "20px";
      divRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  };

  const isAncillaryDisplayed = (type: AncillaryType): boolean => {
    const isAncillaryAvailable = (() => {
      switch (type) {
        case AncillaryType.DisruptionProtection:
          return isDisruptionProtectionAvailable;
        case AncillaryType.Cfar:
          return isCfarAvailable;
        case AncillaryType.Chfar:
          return isChfarAvailable;
        default:
          return false;
      }
    })();

    if (useMultiPageVariant) {
      return (
        isAncillaryAvailable &&
        availableAncillariesList[currentAncillaryIndex] === type
      );
    } else {
      return isAncillaryAvailable;
    }
  };

  const handleMultiPageGoBack = () => {
    if (currentAncillaryIndex === 0) {
      goBackToPreviousPage && goBackToPreviousPage();
    } else {
      setCurrentAncillaryIndex((index) => index - 1);
    }
  };

  const handleMultiPageContinue = () => {
    if (availableAncillariesList.length === 0) return;
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    // first of all, check for completion
    const currentAncillaryType =
      availableAncillariesList[currentAncillaryIndex];
    const isCurrentPageComplete = (() => {
      switch (currentAncillaryType) {
        case AncillaryType.DisruptionProtection: {
          if (isDpOptionSelectionComplete) {
            handleDpAddOnChoiceEvent();
          } else {
            setDisruptionProtectionError(
              ErrorOutsideComponentType.NoOptionSelected
            );
          }
          return isDpOptionSelectionComplete;
        }
        case AncillaryType.Cfar: {
          if (isCfarOptionSelectionComplete) {
            handleCfarAddOnChoiceEvent();
          } else {
            setCfarError(ErrorOutsideComponentType.NoOptionSelected);
          }
          return isCfarOptionSelectionComplete;
        }
        default:
          return false;
      }
    })();

    if (!isCurrentPageComplete) {
      return;
    }

    // if it's complete, either go to checkout or go to the next page
    if (currentAncillaryIndex === availableAncillariesList.length - 1) {
      onContinueSuccess();
    } else {
      setCurrentAncillaryIndex((index) => index + 1);
    }
  };

  useEffect(() => {
    /*
                  note: after the user refreshes the page on flight checkout, and clicks on "customize" in the progress bar,
                  it needs to re-fetch the CFAR offer
                */
    if (fetchAncillaryOfferCallState === CallState.NotCalled) {
      fetchAncillaryOffer({
        preserveCfarId: hasActiveRefundableFare,
      });
    }
  }, [fetchAncillaryOfferCallState, hasActiveRefundableFare]);

  const availableAncillariesList: AncillaryType[] = [
    isDisruptionProtectionAvailable ? [AncillaryType.DisruptionProtection] : [],
    isCfarAvailable ? [AncillaryType.Cfar] : [],
    isChfarAvailable ? [AncillaryType.Chfar] : [],
  ].flat();

  useEffect(() => {
    setCurrentAncillaryIndex(0);
  }, [isDisruptionProtectionAvailable, isCfarAvailable, isChfarAvailable]);

  // todo add chfar tracking. will be done in https://hopper-jira.atlassian.net/browse/FLEX-2968
  useEffect(() => {
    if (
      fetchAncillaryOfferCallState === CallState.Success &&
      (isCfarAvailable || isDisruptionProtectionAvailable)
    ) {
      trackEvent({
        eventName: CAP1_VIEW_BOOKING_ADD_ON,
        properties: {
          is_fintech_subscription_eligible: isCorpFintechEligible,
          ...viewBookingAddOnProperties,
          ...(isDisruptionProtectionAvailable &&
            dpOfferPropertiesForViewBookingAddOn),
          ...(isCfarAvailable && cfarOfferPropertiesForViewBookingAddOn),
          ...{
            cfar_discount_original_premium:
              cfarDiscount?.originalPremiumAmount.fiat.value,
            cfar_discount_percentage: cfarDiscount?.discountPercentage,
          },
        },
      });
    }
  }, [
    fetchAncillaryOfferCallState,
    isCfarAvailable,
    isDisruptionProtectionAvailable,
  ]);

  useEffect(() => {
    if (
      useFlightCheckoutVariant &&
      ((isDisruptionProtectionAvailable && isDpOptionSelectionComplete) ||
        (isCfarAvailable && isCfarOptionSelectionComplete))
    ) {
      scheduleQuote({
        agentFee: isAgentPortal ? AGENT_FEE : 0,
        pollQuoteOnly: true,
        preserveQuote: true,
      });
    }
  }, [
    useFlightCheckoutVariant,
    isDisruptionProtectionAvailable,
    isDpOptionSelectionComplete,
    isCfarAvailable,
    isCfarOptionSelectionComplete,
    selectedDisruptionProtectionId,
    selectedCfarId,
  ]);

  const getCfarBannerContent = () => {
    if (cfarDiscountEnabled && !isAirOfferRedesignEnabled) {
      return `${cfarDiscountPromoCopy} limited-time promotion`;
    }
    return undefined;
  };

  const renderCfarAddOnItem = () => {
    const cfarHeaderStyle = useFlightCheckoutVariant ? "checkout" : "default";
    const Header = () => (
      <Typography
        variant="inherit"
        dangerouslySetInnerHTML={{
          __html: useFlightCheckoutVariant
            ? constants.getStepCopy(
                getCheckoutStepNumber(CheckoutSteps.CFAR),
                CFAR_HEADER({ textVersion: "short" })
              )
            : CFAR_HEADER({ textVersion: "short" }),
        }}
      />
    );

    const getHeader = () => {
      return (
        <>
          {useFlightCheckoutVariant ? (
            <>
              <Icon
                name={IconName.CheckShieldNoCircle}
                className={clsx("header-icon", "cfar")}
              />
              <Header />
            </>
          ) : (
            <Header />
          )}
        </>
      );
    };

    const getCollapsedCopy = () => {
      const price =
        isPriceFreezeExercise && pfFare
          ? pfFare.fiat
          : selectedFare?.paxPricings?.[0].pricing.baseAmount.fiat ?? undefined;
      const tax = isPriceFreezeExercise
        ? priceFreezeTaxAmount?.fiat
        : selectedFare?.paxPricings?.[0].pricing.taxAmount.fiat;
      if (!price) {
        return undefined;
      }
      const refundAmount = getCfarAirDetailsRefundString({
        price,
        tax,
        coverage: selectedCfarOfferCoverage?.cashCoveragePercentage!,
      });
      return isCfarCoMerchEnabled && cfarOfferPrices
        ? `Added ${
            selectedCfarOfferCoverage?.cashCoveragePercentage
          }% (${refundAmount} refund per traveler) coverage for ${constants.getPriceAndRewardsCopy(
            {
              price: getFormattedTotalPriceText(cfarOfferPrices.fiat),
              rewards: getRewardsString(cfarOfferPrices.rewards, rewardsKey),
            }
          )} per traveler.`
        : undefined;
    };

    return (
      <>
        <AddOnItem
          key="cfar"
          type="cancel-for-any-reason"
          header={getHeader()}
          headerStyle={cfarHeaderStyle}
          subtitle={
            useGroupedAncillaries ? undefined : constants.CFAR_SUBTITLE_COPY
          }
          iconName={IconName.CheckShieldNoCircle}
          headerBannerContent={getCfarBannerContent()}
          onHeaderBannerClick={() => setOpenCfarModal(true)}
          content={
            <CfarDetails
              isAgentPortal={isAgentPortal}
              contentOnly={true}
              cardContentProps={{
                hideTopContent: true,
                hideSubtitle: true,
                hideContinueCta: true,
                disablePartialScroll: true,
                readTermsAndConditionsLinkPosition: "left",
                onSelectValueCallback: () => {
                  setCfarError(undefined);
                  if (useMultiPageVariant) {
                    scrollToElement(continueButtonRef);
                  }
                },
                onConfirmSelectedValueCallback: () => {
                  setTimeout(() => {
                    setIsCfarCollapsed(true);
                  }, 800);
                },
                error: cfarError,
              }}
              disabled={isCfarDisabled}
              hasTravelFusionFareBrand={hasTravelFusionFareBrand}
            />
          }
          fetchAncillaryOfferCallState={fetchAncillaryOfferCallState}
          error={cfarError}
          isCollapsed={isCfarCollapsed}
          setIsCollapsed={setIsCfarCollapsed}
          hasSelectedOption={!!selectedCfarId}
          priceCopy={
            cfarOfferPrices
              ? cfarDiscountEnabled && discountedCfarOffer
                ? constants.getDiscountedPriceAndRewardsCopy({
                    origPrice: getFormattedTotalPriceText(
                      discountedCfarOffer.originalPremiumAmount.fiat
                    ),
                    discountedPrice: getFormattedTotalPriceText(
                      cfarOfferPrices.fiat
                    ),
                    rewards: rewardsKey
                      ? getRewardText({
                          reward: cfarOfferPrices.rewards[rewardsKey],
                        })
                      : undefined,
                  })
                : constants.getPriceAndRewardsCopy({
                    price: getFormattedTotalPriceText(cfarOfferPrices.fiat),
                    rewards: getRewardsString(
                      cfarOfferPrices.rewards,
                      rewardsKey
                    ),
                  })
              : undefined
          }
          collapsedCopy={getCollapsedCopy()}
          disableCollapse={disableCollapse}
          hideIconColumn={hideIconColumn}
          isInlineIconHeader={useGroupedAncillaries}
          disabled={isCfarDisabled}
          redesignClassName={
            isAirOfferRedesignEnabled
              ? constants.AIR_OFFER_REDESIGN_CLASSNAME
              : undefined
          }
        />
        <CfarDetails
          isAgentPortal={isAgentPortal}
          openCfarDetails={openCfarModal}
          onClose={() => setOpenCfarModal(false)}
          contentOnly={false}
          modalType="refundable-fare"
          topBannerEnabled={true}
          cardContentProps={{ hideContinueCta: true, hideRadioButtons: true }}
          hasTravelFusionFareBrand={hasTravelFusionFareBrand}
        />
      </>
    );
  };

  const renderChfarAddOnItem = () => {
    const headerStyle = "default";
    const Header = () => (
      <Typography
        variant="inherit"
        dangerouslySetInnerHTML={{
          __html: "Change for any reason",
        }}
      />
    );

    const getCollapsedCopy = (): string | undefined => {
      return chfarOfferPrices
        ? `<strong>Change your flight for any reason</strong> added for <nobr>${constants.getPriceAndRewardsCopy(
            {
              price: getFormattedTotalPriceText(chfarOfferPrices.fiat),
              rewards: getRewardsString(
                chfarOfferPrices.accountSpecific,
                rewardsKey
              ),
            }
          )}</nobr> per traveler.`
        : undefined;
    };

    const chfarViewedEventHandler = () =>
      onChfarViewedEvent(chfarTrackingProps);

    return (
      <AddOnItem
        key="chfar"
        type="change-for-any-reason"
        header={<Header />}
        iconName={IconName.ChfarLogo}
        fetchAncillaryOfferCallState={fetchAncillaryOfferCallState}
        isCollapsed={isChfarCollapsed}
        setIsCollapsed={setIsChfarCollapsed}
        hasSelectedOption={!!selectedChfarId}
        headerStyle={headerStyle}
        error={chfarError}
        disabled={false}
        collapsedCopy={getCollapsedCopy()}
        hideIconColumn={hideIconColumn}
        isInlineIconHeader={useGroupedAncillaries}
        collapsedDeclineCopy={CHFAR_DECLINED_COLLAPSED_COPY}
        redesignClassName={
          isAirOfferRedesignEnabled
            ? constants.AIR_OFFER_REDESIGN_CLASSNAME
            : undefined
        }
        priceCopy={
          chfarOfferPrices
            ? constants.getPriceAndRewardsCopy({
                price: getFormattedTotalPriceText(chfarOfferPrices.fiat),
                rewards: getRewardsString(
                  chfarOfferPrices.accountSpecific,
                  rewardsKey
                ),
              })
            : undefined
        }
        content={
          <ChfarDetails
            isAgentPortal={isAgentPortal}
            contentOnly={true}
            cardContentProps={{
              disablePartialScroll: true,
              onConfirmSelectedValueCallback: () => {
                setTimeout(() => {
                  setIsChfarCollapsed(true);
                }, 800);
              },
              onSelectValueCallback: () => {
                setChfarError(undefined);
                if (useMultiPageVariant) {
                  scrollToElement(continueButtonRef);
                }
              },
              error: chfarError,
              readTermsAndConditionsLinkPosition: "left",
              hideTopContent: true,
              hideContinueCta: true,
            }}
            onChfarViewedEvent={chfarViewedEventHandler}
          />
        }
      />
    );
  };

  const renderDisruptionProtectionAddOnItem = () => {
    const disruptionProtectionHeaderStyle = useFlightCheckoutVariant
      ? "checkout"
      : "contrast";
    const Header = () => (
      <Typography
        variant="inherit"
        dangerouslySetInnerHTML={{
          __html: useFlightCheckoutVariant
            ? constants.getStepCopy(
                getCheckoutStepNumber(CheckoutSteps.DISRUPTION_SUPPORT),
                DISRUPTION_PROTECTION_HEADER()
              )
            : DISRUPTION_PROTECTION_HEADER(),
        }}
      />
    );

    const getHeader = () => {
      return (
        <>
          {useFlightCheckoutVariant ? (
            <>
              <Icon
                name={IconName.DisruptionProtectionNoCircle}
                className={clsx("header-icon", "disruption-protection")}
              />
              <Header />
            </>
          ) : (
            <Header />
          )}
        </>
      );
    };

    const priceCopy = disruptionProtectionOfferPrices
      ? constants.getPriceAndRewardsCopy({
          price: getFormattedTotalPriceText(
            disruptionProtectionOfferPrices.fiat
          ),
          rewards: getRewardsString(
            disruptionProtectionOfferPrices.rewards,
            rewardsKey
          ),
        })
      : undefined;

    const subtitle = (
      <>
        <Typography variant="body2" className="card-subtitle-copy">
          {constants.DISRUPTION_PROTECTION_SUBTITLE_COPY(
            showCorpFintechSmoketestBanner
          )}
        </Typography>
        {showCorpFintechSmoketestBanner && <CorpFintechSubscriptionBanner />}
      </>
    );

    return (
      <AddOnItem
        key="disruption-protection"
        type="flight disruption assistance"
        header={getHeader()}
        headerStyle={disruptionProtectionHeaderStyle}
        subtitle={
          matchesMobile || isAirOfferRedesignEnabled ? subtitle : undefined
        }
        iconName={IconName.DisruptionProtectionNoCircle}
        content={
          <DisruptionProtectionDetails
            contentOnly={true}
            cardContentProps={{
              hideTopContent: true,
              hideSubtitle: true,
              hideContinueCta: true,
              disablePartialScroll: true,
              readTermsAndConditionsLinkPosition: "left",
              onSelectValueCallback: () => {
                setDisruptionProtectionError(undefined);
                if (useMultiPageVariant) {
                  scrollToElement(continueButtonRef);
                }
              },
              onConfirmSelectedValueCallback: () => {
                setTimeout(() => {
                  setIsDisruptionProtectionCollapsed(true);
                }, 800);
              },
              error: disruptionProtectionError,
            }}
            disabled={isDisruptionProtectionDisabled}
            hasSelectedCorpFintech={hasSelectedCorpFintech}
          />
        }
        fetchAncillaryOfferCallState={fetchAncillaryOfferCallState}
        error={disruptionProtectionError}
        isCollapsed={isDisruptionProtectionCollapsed}
        setIsCollapsed={setIsDisruptionProtectionCollapsed}
        hasSelectedOption={!!selectedDisruptionProtectionId}
        priceCopy={isCorpFintechSubscription ? "placeholder" : priceCopy}
        {...(isCorpFintechSubscription && {
          collapsedCopy: `${constants.CORP_FINTECH_SUBSCRIPTION_COPY(
            disruptionProtectionOfferPrices.fiat,
            disruptionProtectionOfferPrices.rewards[rewardsKey ?? 0]
          )}<span class="fintech-subscription-chip">${
            constants.CORP_SUBSCRIPTION_CHIP_COLLAPSED
          }</span>`,
        })}
        disableCollapse={disableCollapse}
        hideIconColumn={hideIconColumn}
        isInlineIconHeader={useGroupedAncillaries}
        disabled={isDisruptionProtectionDisabled}
        redesignClassName={
          isAirOfferRedesignEnabled
            ? constants.AIR_OFFER_REDESIGN_CLASSNAME
            : undefined
        }
      />
    );
  };

  const addOnItems: (JSX.Element | null)[] = availableAncillariesList.map(
    (ancillaryType) => {
      if (!isAncillaryDisplayed(ancillaryType)) {
        return null;
      }

      switch (ancillaryType) {
        case AncillaryType.Cfar:
          return renderCfarAddOnItem();
        case AncillaryType.DisruptionProtection:
          return renderDisruptionProtectionAddOnItem();
        case AncillaryType.Chfar:
          return renderChfarAddOnItem();
        default:
          return null;
      }
    }
  );

  const GroupedAncillaries = () => {
    return (
      <>
        {isDisruptionProtectionAvailable && (
          <CardWrapper
            title="1. In-transit assistance"
            displayEdit={isDisruptionProtectionCollapsed}
            onEdit={() => {
              setIsDisruptionProtectionCollapsed(false);
            }}
            disabled={false}
          >
            {!isDisruptionProtectionCollapsed ? (
              renderDisruptionProtectionAddOnItem()
            ) : (
              <CollapsedContent
                attached={!!disruptionProtectionOfferPrices}
                attachedContent={
                  <span>{DisruptionConstants.ATTACHED_COPY}</span>
                }
                declinedContent={
                  <span>{DisruptionConstants.DECLINED_COPY}</span>
                }
              />
            )}
          </CardWrapper>
        )}
        {(isCfarAvailable || isChfarAvailable) && (
          <CardWrapper
            title="2. Extra flexibility"
            displayEdit={isCfarCollapsed || isChfarCollapsed}
            onEdit={() => {
              setIsCfarCollapsed(false);
              setIsChfarCollapsed(false);
            }}
            disabled={
              isDisruptionProtectionAvailable
                ? !selectedDisruptionProtectionId
                : false
            }
            subtitle="Add flexibility to cancel or change flights when travel doesn’t go as planned."
          >
            {isCfarAvailable ? (
              !isCfarCollapsed ? (
                renderCfarAddOnItem()
              ) : (
                <div
                  className={clsx("collapsed-content-container", {
                    isNotLastItem: true,
                  })}
                >
                  <CollapsedContent
                    attached={!!cfarOfferPrices}
                    attachedContent={
                      <CfarAttachedContent
                        cfarOfferPrices={cfarOfferPrices}
                        rewardsKey={rewardsKey}
                      />
                    }
                    declinedContent={<span>{CfarConstants.DECLINED_COPY}</span>}
                  />
                </div>
              )
            ) : null}
            {isChfarAvailable ? (
              !isChfarCollapsed ? (
                renderChfarAddOnItem()
              ) : (
                <div className="collapsed-content-container">
                  <CollapsedContent
                    attached={!!chfarOfferPrices}
                    attachedContent={
                      <ChfarAttachedContent
                        chfarOfferPrices={chfarOfferPrices}
                        rewardsKey={rewardsKey}
                      />
                    }
                    declinedContent={
                      <span>{CHFAR_DECLINED_COLLAPSED_COPY}</span>
                    }
                  />
                </div>
              )
            ) : null}
            {isChfarVariantDeclineAll &&
            (!isCfarCollapsed || !isChfarCollapsed) ? (
              <div className="decline-all-button-row">
                <ActionButton
                  defaultStyle="h4r-primary"
                  message="Decline all extra flexibility options"
                  onClick={() => {
                    setSelectedCfarId(DO_NOT_APPLY_CFAR_OPTION);
                    setIsCfarCollapsed(true);
                    setSelectedChfarId(DO_NOT_APPLY_CHFAR_OPTION);
                    setIsChfarCollapsed(true);
                  }}
                />
              </div>
            ) : null}
          </CardWrapper>
        )}
      </>
    );
  };

  const hasDisruptionProtectionAddOnToShow = isAncillaryDisplayed(
    AncillaryType.DisruptionProtection
  );
  const hasCfarAddOnToShow = isAncillaryDisplayed(AncillaryType.Cfar);

  return (
    <Box className="add-on-customize-root">
      <Box
        className={clsx("add-on-customize-container", {
          "full-size": useFlightCheckoutVariant,
          "flight-list-optimization-experiment":
            isFlightListOptimizationExperiment,
        })}
      >
        <Box className="add-on-items-section">
          {useGroupedAncillaries ? <GroupedAncillaries /> : <>{addOnItems}</>}
          {useMultiPageVariant && (
            <Box className="multi-page-buttons-section">
              <Box className={clsx("multi-page-button-wrapper", "go-back")}>
                <ActionButton
                  className="navigation-button"
                  defaultStyle="h4r-secondary"
                  onClick={handleMultiPageGoBack}
                  ariaLabelText={constants.BACK}
                  message={
                    <Box className="copy-wrapper">
                      <Icon
                        name={IconName.NavigationForward}
                        className={clsx(
                          "chevron-icon",
                          "blue",
                          "white-on-hover",
                          "backwards"
                        )}
                      />
                      <Typography variant="inherit" className="button-copy">
                        {constants.BACK}
                      </Typography>
                    </Box>
                  }
                />
              </Box>
              <div
                className={clsx("multi-page-button-wrapper", "continue")}
                ref={continueButtonRef}
              >
                <ActionButton
                  className="navigation-button"
                  defaultStyle="h4r-primary"
                  onClick={handleMultiPageContinue}
                  ariaLabelText={constants.CONTINUE}
                  message={
                    <Box className="copy-wrapper">
                      <Typography variant="inherit" className="button-copy">
                        {constants.CONTINUE}
                      </Typography>
                      <Icon
                        name={IconName.NavigationForward}
                        className={clsx("chevron-icon", "white")}
                      />
                    </Box>
                  }
                />
              </div>
            </Box>
          )}
        </Box>
        {!hidePricingBreakdown && (
          <Box className="checkout-breakdown-section">
            <AddOnCheckoutBreakdown
              isPriceFreezeExercise={isPriceFreezeExercise}
              disabled={!isOptionSelectionComplete}
              onContinue={handleOnContinue}
              hideGoToCheckoutButton={hideGoToCheckoutButton}
              isMobile={matchesMobile}
              hasError={hasError}
              corporateTravel={
                (selectedFare as CorpFareDetails)?.corporateTravel
              }
            />
          </Box>
        )}
      </Box>
      {cfarSocialVariant === CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY_CFAR_FDA && (
        <AddOnTestimonyCarousel
          hasCfarAddOnToShow={hasCfarAddOnToShow}
          hasDisruptionProtectionAddOnToShow={
            hasDisruptionProtectionAddOnToShow
          }
          showArrows
        />
      )}

      {cfarSocialVariant === CFAR_SOCIAL_SOCIAL_PROOF_TESTIMONY && (
        <AddOnTestimonyCarousel
          // For the DP only variant, this carousel element behaves as if CFAR is not offered (thus no CFAR testimonies).
          hasCfarAddOnToShow={false}
          hasDisruptionProtectionAddOnToShow={
            hasDisruptionProtectionAddOnToShow
          }
          showArrows
        />
      )}
      <CorpFintechSubscriptionModal
        open={openCorpFintechSubscriptionModal}
        onClose={() => setOpenCorpFintechSubscriptionModal(false)}
        onConfirm={() => {
          setOpenCorpFintechSubscriptionModal(false);
          setSelectedDisruptionProtectionId(null);
        }}
      />
    </Box>
  );
};

interface IAddOnCheckoutBreakdownProps {
  isPriceFreezeExercise?: boolean;
  disabled?: boolean;
  hideGoToCheckoutButton?: boolean;
  onContinue: () => void;
  isMobile?: boolean;
  hasError: boolean;
  corporateTravel: CorporateTravel | undefined;
}

const AddOnCheckoutBreakdown = (props: IAddOnCheckoutBreakdownProps) => {
  const {
    isPriceFreezeExercise,
    disabled,
    hideGoToCheckoutButton,
    onContinue,
    isMobile,
    hasError,
    corporateTravel,
  } = props;

  const onClickWhenDisabled = () => {
    onContinue();
    const errorSection =
      document.querySelector(".disruption-protection-details-error-message") ??
      document.querySelector(".cfar-details-error-message");
    if (errorSection) errorSection.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Box className="add-on-checkout-breakdown-root">
      <CorpPolicyBanner
        variant="base"
        corporateTravel={corporateTravel}
        productType="flight"
      />
      <Box className="add-on-checkout-breakdown-container">
        <AddOnPricingBreakdown
          isPriceFreezeExercise={isPriceFreezeExercise}
          actionButtonProps={
            !hideGoToCheckoutButton
              ? {
                  message: constants.CONTINUE_TO_CHECKOUT_COPY,
                  disabled,
                  onContinue,
                  onClickWhenDisabled,
                }
              : undefined
          }
          isMobile={isMobile}
          hasError={hasError}
        />
      </Box>
    </Box>
  );
};
