import React from "react";
import clsx from "clsx";
import { Box } from "@material-ui/core";
import { ChangeForAnyReasonHeader } from "./components/ChangeForAnyReasonHeader";
import styles from "./styles.module.scss";
import { useDeviceTypes } from "halifax";

export const ChangeForAnyReason = () => {
  const { matchesMobile } = useDeviceTypes();

  return (
    <Box
      data-test-id="change-for-any-reason-page-root"
      className={clsx(styles["change-for-any-reason-root"], {
        [styles["mobile"]]: matchesMobile,
      })}
    >
      <ChangeForAnyReasonHeader />
      <Box className={styles["change-for-any-reason-body"]}>
        <h1>Change for any reason!</h1>
      </Box>
    </Box>
  );
};
