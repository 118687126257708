import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import {
  disruptionOverviewEligibilityDpExerciseFactsPropertiesSelector,
} from "../../reducer/selectors";
import { setProductRedeemChoice } from "../../actions/actions";
import { DisruptionProtectionContactSupport } from "./component";

const mapStateToProps = (state: IStoreState) => ({
  disruptionOverviewEligibilityDpExerciseFactsProperties:
    disruptionOverviewEligibilityDpExerciseFactsPropertiesSelector(state),
});

const mapDispatchToProps = {
  setProductRedeemChoice,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DisruptionProtectionConctactSupportConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedDisruptionProtectionContactSupport = connector(
  withRouter(DisruptionProtectionContactSupport)
);
