import React from "react";
import { isCaponeTenant } from "@capone/common";
import { config } from "../../../../api/config";

export const SEARCH = "Search";
export const BENEFITS_TITLE = "Your Premier Collection Benefits:";
export const LC_BENEFITS_TITLE = "Your Lifestyle Collection benefits:";
export const SELECT_DATES = "Select dates to view availability";
export const SELECT_DATES_ERROR = "Select new dates to view availability"
export const getBenefitsList = (
  hotelsMultiplier: number,
  variant?: "default" | "lifestyle-collection",
  isUserPrimary?: boolean
) => {
  const isLifestyleCollection = variant === "lifestyle-collection";
  return isUserPrimary || isCaponeTenant(config.TENANT) ? (
    <ul>
      <li>
        Earn <strong>{hotelsMultiplier}X miles</strong> on any{" "}
        {isLifestyleCollection ? "Lifestyle" : "Premier"} Collection booking
      </li>
      <li>
        Enjoy a{" "}
        <strong>
          {isLifestyleCollection ? "$50" : "$100"} experience credit
        </strong>
      </li>
      {isLifestyleCollection ? (
        <li>
          <strong>Room upgrades</strong> when available
        </li>
      ) : (
        <li>
          Daily <strong>breakfast</strong> for 2 and complimentary{" "}
          <strong>Wifi</strong>
        </li>
      )}
    </ul>
  ) : (
    <ul>
      <li>
        Enjoy a{" "}
        <strong>
          {isLifestyleCollection ? "$50" : "$100"} experience credit
        </strong>
      </li>
      {isLifestyleCollection ? (
        <li>
          <strong>Room upgrades</strong> when available
        </li>
      ) : (
        <li>
          Daily <strong>breakfast</strong> for 2 and complimentary{" "}
          <strong>Wifi</strong>
        </li>
      )}
    </ul>
  );
};

export const ADDITIONAL_BENEFITS = (
  <>
    Plus, enjoy other premium benefits like{" "}
    <strong>room upgrades, early check-in and late checkout</strong> when
    available.
  </>
);

export const LC_ADDITIONAL_BENEFITS = (
  <>
    Plus, enjoy other benefits like <strong>complimentary Wi-Fi</strong> and,
    when available, <strong>early check-in/late checkout.</strong>
  </>
);
