import React from "react";
import "./styles.scss";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ShopComponentSkeleton } from "../../component";
import { ActionButton } from "halifax";
import {
  CONTINUE_TO_CHECKOUT_COPY,
  getPriceAndRewardsCopy,
} from "./textConstants";

export interface IShopCheckoutBreakdownProps {
  isSkeleton: boolean;
}

export const ShopCheckoutBreakdown = (props: IShopCheckoutBreakdownProps) => {
  const { isSkeleton } = props;

  // TODO: replace with real prices
  const price = "$125 per adult";
  const rewards = "12,500 miles";
  const subtitle = "Up to 10 people";

  const renderSkeleton = () => (
    <Box className="experiences-shop-skeleton">
      <Box className={clsx("section-wrapper")}>
        <ShopComponentSkeleton name="medium" />
        <ShopComponentSkeleton name="large" />
        <ShopComponentSkeleton name="small" />
        <ShopComponentSkeleton name="large" />
        <ShopComponentSkeleton name="large" />
      </Box>
    </Box>
  );

  return (
    <Box className="experiences-shop-checkout-breakdown-container">
      <Box className="checkout-breakdown-section">
        <Typography
          className="experiences-shop-checkout-breakdown-title"
          dangerouslySetInnerHTML={{
            __html: getPriceAndRewardsCopy({
              price: price,
              rewards: rewards,
            }),
          }}
        />
        <Typography className="experiences-shop-checkout-breakdown-subtitle">
          {subtitle}
        </Typography>
        {isSkeleton ? renderSkeleton() : null}
        <ActionButton
          disabled={true}
          className="continue-button"
          message={CONTINUE_TO_CHECKOUT_COPY}
          onClick={() => {}}
        />
      </Box>
    </Box>
  );
};
