import React from "react";
import { Box, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import clsx from "clsx";
import {
  ActionLink,
  Icon,
  IconName,
} from "halifax";
import { RouteComponentProps } from "react-router";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileShopDetailsHeaderConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import { PATH_HOME } from "../../../../utils/paths";
import {MobilePopoverCalendarButton} from "../MobilePopoverCalendarButton";

export interface IMobileShopDetailsHeaderProps
  extends RouteComponentProps,
    MobileShopDetailsHeaderConnectorProps {
  handleShopCall: (queryParams: string) => void;
  isUnavailable?: boolean,
}

export const MobileShopDetailsHeader = (props: IMobileShopDetailsHeaderProps) => {
  const {
    fromDate,
    untilDate,
    adultsCount,
    childrenCount,
    history,
    handleShopCall,
    isUnavailable,
    setOpenDatesModal,
    shopDetailsLodgingData,
  } = props;

  const defaultOnClickBackButton = () => {
    history.push(PATH_HOME);
  };

  const MiddleSection = () => {
    return (
      <>
        <Box
          className={clsx(
            "locations-and-dates-section",
            "header-middle-section"
          )}
        >
          <>
            <Typography variant="body1" className="locations-section">
              {shopDetailsLodgingData?.name}
            </Typography>
            <Typography variant="body2" className="select-dates-banner-section">
              {isUnavailable ? textConstants.SELECT_DATES_ERROR : textConstants.SELECT_DATES}
            </Typography>
            <Box className="dates-and-occupancy-count-section">
              <Typography variant="body2" className="dates-section">
                {fromDate &&
                  untilDate &&
                  `${dayjs(fromDate).format("MMM DD")} - ${dayjs(
                    untilDate
                  ).format("MMM DD")}, `}
                {adultsCount + childrenCount > 1
                  ? `${adultsCount + childrenCount} Travelers`
                  : `${adultsCount + childrenCount} Traveler`}
              </Typography>
            </Box>
          </>
        </Box>
          <Box className="edit-location-and-date-button-section">
            <ActionLink
              className="edit-location-and-date-button"
              onClick={() => {
                setOpenDatesModal(true);
              }}
              content={
                <Icon name={IconName.B2BEditPencil} className="edit-icon" />
              }
            />
          </Box>
      </>
    );
};

  return (
    <>
      <Box className="pc-shop-progress-header-root">
        <Box className="pc-shop-progress-header-container">
          <Box className="go-back-button-section">
            <ActionLink
              className={clsx("go-back-button")}
              onClick={defaultOnClickBackButton}
              content={<FontAwesomeIcon icon={faChevronLeft} />}
            />
          </Box>
          <MiddleSection />
        </Box>
      </Box>
      <MobilePopoverCalendarButton handleShopCall={handleShopCall}/>
    </>
  );
};
