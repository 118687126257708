import { Box, Button, Typography } from "@material-ui/core";
import React, { useContext, useState, useEffect, useMemo } from "react";
import {
  Slice,
  IFlightListData,
  FareclassOptionFilter,
  AlgomerchTag,
  mapAlgomerchTexts,
  FiatPrice,
  FlightShopStep,
  TripDetails,
  MODAL_ALERT,
  ModalScreens,
  ModalCategoryType,
  SELECTED_FLIGHT,
} from "redmond";
import { FlightListInfo } from "./components/FlightListInfo";
import { FlightCardType } from "./components/FlightListInfo/component";
import {
  B2BSpinner,
  BackButton,
  FareDetailsCardCtaTitles,
  FareDetailsCardCtaType,
  Header,
  LoadingIndicator,
  MobilePopoverCard,
  NoResults,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";
import { IFlightListProps } from "./container";
import { ClientContext } from "../../../../App";
import {
  INITIAL_RESULT_SET_SIZE,
  LOADING_FLIGHT_DETAILS_STRING,
  RECOMMENDED_FLIGHT_LIST_SEPARATOR_PRIMARY_TEXT,
  RECOMMENDED_FLIGHT_LIST_SEPARATOR_SECONDARY_TEXT,
  SHOW_MORE_NUM,
} from "./components/FlightListInfo/textConstants";
import ReactList from "react-list";
import InfiniteScroll from "react-infinite-scroll-component";
import { FlightDetails } from "./components/FlightDetails";
import { FlightAlgomerchModal } from "../FlightAlgomerchModal";
import { ITripDetailsByTripId } from "../../reducer";
import { SelectedPackagePricingCard } from "../FlightShopHeader/SelectedPackagePricingCard";
import { FlightSummaryPanel } from "../FlightShopHeader/FlightSummaryPanel";
import { goToReview } from "../../../hotel-shop/utils/queryStringHelpers";
import * as constants from "../../../book/actions/constants";
import { trackEvent } from "../../../../api/v0/trackEvent";
import { FlightShopHeader } from "../FlightShopHeader";
import {
  CLEAR_FILTERS_CTA_TEXT,
  FLIGHTS_NOT_FOUND_SUBTITLE,
  FLIGHTS_NOT_FOUND_TITLE,
} from "./constants";
import { VIEWED_FLIGHT_LIST } from "redmond/build/apis/tysons";

const DESKTOP_OFFSET_SCROLL = 250;

enum ModalTypes {
  AlgomerchModal,
}

export const getSelectCtaCopy = (
  input: string,
  usePriceText?: boolean,
  isMobile?: boolean
) =>
  usePriceText ? (
    <>
      {!isMobile ? "Continue for " : ""}
      <strong>{input} per traveler</strong>
    </>
  ) : (
    `Select ${input}`
  );

const ctaTitles: FareDetailsCardCtaTitles = {
  primary: {
    getContent: getSelectCtaCopy,
    type: FareDetailsCardCtaType.Function,
  },
  secondary: undefined,
};

type IOpenModal = ModalTypes | false;

export const getSliceFareDetails = (args: {
  tripDetailsById: ITripDetailsByTripId;
  fareTrips: any;
}): TripDetails | null => {
  const { tripDetailsById, fareTrips } = args;
  if (!fareTrips.length) return null;
  let sliceDetails: TripDetails | null = null;
  sliceDetails = { ...tripDetailsById[fareTrips[0].trip] } as TripDetails;
  sliceDetails.fareDetails = [];
  fareTrips.forEach((fareTrip: any) => {
    const fareDetailByFareId = tripDetailsById[fareTrip.trip].fareDetails.find(
      (fareDetail) => fareDetail.id === fareTrip.fare
    );
    if (fareDetailByFareId && sliceDetails)
      sliceDetails.fareDetails.push(fareDetailByFareId);
  });
  sliceDetails.fareDetails.sort(
    (fare1, fare2) =>
      (fare1?.paxPricings?.[0].pricing.baseAmount.fiat.value || 0) -
      (fare2?.paxPricings?.[0].pricing.baseAmount.fiat.value || 0)
  );

  return sliceDetails as TripDetails;
};

export const FlightList = (props: IFlightListProps) => {
  const {
    flights,
    rewardsKey,
    packagesByOutboundFareSlice,
    packagesByReturnFareSlice,
    isFlightShopLoading,
    largestValueAccount,
    isTripDetailsLoading,
    tripDetailsById,
    fetchPackagesTripDetails,
    setPackagesChosenOutgoingSlice,
    setPackagesChosenReturnSlice,
    setPackagesFlightShopProgress,
    isOutgoing,
    isReturn,
    history,
    sortedFlights,
    maxPriceFilter,
    outgoingRecommendedFlight,
    returnRecommendedFlight,
    openMobileFlightDetailsModal,
    setOpenMobileFlightDetailsModal,
    hasFlightShopCallFailed,
    isFlightShopCallNotCalled,
    departureDate,
    returnDate,
    airports,
    hasAppliedNonFareclassFilter,
    resetFilters,
    viewedFlightListProperties,
    selectedOutgoingSliceProperties,
    selectedReturnSliceProperties,
  } = props;
  const { matchesDesktop, matchesLargeDesktop, matchesMobile } =
    useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;

  const [faresToShow, setFaresToShow] = useState<any[]>([]);
  const [expandedFlight, setExpandedFlight] = useState("");
  const [selectedFareId, setClickedFareId] = useState("");
  const [fareTrips, setFareTrips] = useState([]);

  const [openModal, setOpenModal] = React.useState<IOpenModal>(false);
  const [selectedAlgomerchTag, setSelectedAlgomerchTag] =
    React.useState<AlgomerchTag>(AlgomerchTag.Cheapest);

  const handleClickAlgomerchTag = (tagText: string) => {
    const allTags = Object.keys(AlgomerchTag);
    const selectedTag = allTags.find((tag) =>
      tagText.includes(mapAlgomerchTexts[tag])
    );

    setSelectedAlgomerchTag(
      (selectedTag as AlgomerchTag) ?? AlgomerchTag.Cheapest
    );
  };

  const expandedFareDetails = useMemo(() => {
    const fetchedAllFareDetails = !!fareTrips.length
      ? fareTrips.reduce((hasFetchedFareTrip: boolean, fareTrip: any) => {
          return hasFetchedFareTrip && !!tripDetailsById[fareTrip.trip];
        }, true)
      : false;

    return fetchedAllFareDetails
      ? getSliceFareDetails({
          tripDetailsById,
          fareTrips,
        })
      : null;
  }, [fareTrips, tripDetailsById]);

  useEffect(() => {
    if (isFlightShopLoading) setFaresToShow([]);
  }, [isFlightShopLoading]);

  const setFetchMoreData = () => {
    const newPageSize = faresToShow.length + SHOW_MORE_NUM;
    return setTimeout(
      () => setFaresToShow(sortedFlights.slice(0, newPageSize)),
      500
    );
  };

  useEffect(() => {
    if (
      !isFlightShopLoading &&
      sortedFlights.length > 0 &&
      (isOutgoing || isReturn)
    ) {
      trackEvent({
        eventName: VIEWED_FLIGHT_LIST,
        properties: {
          ...viewedFlightListProperties,
        },
        encryptedProperties: [],
      });
    }
  }, [isFlightShopLoading, isOutgoing]);

  useEffect(() => {
    if (sortedFlights.length > 0) {
      if (!matchesMobile) {
        setFaresToShow(sortedFlights.slice(0, INITIAL_RESULT_SET_SIZE));
      } else {
        setFaresToShow(sortedFlights);
      }

      setExpandedFlight("");
    } else {
      setFaresToShow([]);
    }
    return clearTimeout(setFetchMoreData());
  }, [sortedFlights]);

  // TODO: this should be replaced with actual filters
  const fareClassFilter: FareclassOptionFilter = {
    basic: false,
    standard: false,
    enhanced: false,
    premium: false,
    luxury: false,
  };

  const renderRecommendedFlightInfo = (flight: any) => {
    if (!flight) {
      return null;
    }
    const flightSliceId = flight.slice;
    const flightSlice = flights?.slices[flightSliceId];
    if (!flightSlice) {
      return null;
    }

    const selectedFare =
      flight.fares.find((fare: any) => fare.example?.fare === selectedFareId) ||
      flight.fares[0];

    return (
      <>
        {renderFlightListInfo(selectedFare, true, flightSlice, flight, 0, true)}
        {/* TODO: Move this to components, make common */}
        <Box className={clsx("recommended-flight-list-separator")}>
          <Box className="recommended-flight-list-separator-top-row">
            <Box className="dashed-line" />
            <Typography className="recommended-flight-list-separator-primary-text">
              {RECOMMENDED_FLIGHT_LIST_SEPARATOR_PRIMARY_TEXT}
            </Typography>
            <Box className="dashed-line" />
          </Box>
          <Typography className="recommended-flight-list-separator-secondary-text">
            {RECOMMENDED_FLIGHT_LIST_SEPARATOR_SECONDARY_TEXT}
          </Typography>
        </Box>
      </>
    );
  };

  const handleFlightSelect = (fareTrips: any) => {
    const uniqueTripIds: string[] = Array.from(
      new Set(fareTrips.map((fareTrip: any) => fareTrip.trip))
    );
    uniqueTripIds.map((fareTrip: string) => {
      fetchPackagesTripDetails(history, fareTrip);
    });

    setFareTrips(fareTrips);
  };

  const handleSliceSelect = (sliceId: string, flight: IFlightListData) => {
    if (sliceId === expandedFlight) {
      setExpandedFlight("");
    } else {
      setExpandedFlight(sliceId);
      isOutgoing
        ? handleFlightSelect(flight.fares.map((fare: any) => fare.example))
        : handleFlightSelect(
            flight.fares.map((fare: any) => {
              return { trip: fare.tripId, fare: fare.id };
            })
          );
    }
  };

  const handleFareSelect = (
    flight: any,
    fareId: string,
    _idx: number,
    _limit?: FiatPrice | null
  ) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (flights && expandedFareDetails) {
      const tripId = expandedFareDetails?.id;

      if (isOutgoing) {
        setPackagesChosenOutgoingSlice(
          flight.slice,
          fareId,
          flights.fares[fareId].outbound,
          tripId,
          flights.fareSlices[flights.fares[fareId].outbound].fareShelf.value,
          // whenever selecting a different departure flight, reset return ids
          true
        );
        trackEvent({
          eventName: SELECTED_FLIGHT,
          properties: {
            ...selectedOutgoingSliceProperties,
            recommended_flight_selected:
              outgoingRecommendedFlight?.slice == flight.slice,
          },
          encryptedProperties: [],
        });
        setPackagesFlightShopProgress(FlightShopStep.ChooseReturn);
      } else {
        // TODO: Send user to review itinerary after they choose a return flight
        setPackagesChosenReturnSlice(
          tripId,
          flight.slice,
          fareId,
          flights.fares[fareId].return,
          flights.fareSlices[flights.fares[fareId].return].fareShelf.value
        );
        setPackagesFlightShopProgress(FlightShopStep.ReviewItinerary);
        goToReview({ history });
        trackEvent({
          eventName: SELECTED_FLIGHT,
          properties: {
            ...selectedReturnSliceProperties,
            recommended_flight_selected:
              returnRecommendedFlight?.slice == flight.slice,
          },
          encryptedProperties: [],
        });
      }
    }
  };

  const renderFlightListInfo = (
    selectedFare: any,
    showListView: boolean,
    slice: Slice,
    flight: IFlightListData,
    index: number,
    recommendedFlight?: boolean
  ) => {
    // first fare of the outbound flight
    const fareId = selectedFare.example?.fare || selectedFare.id;
    return (
      <Box
        id={slice.id}
        className={clsx(
          "flight-list-item",
          `flight-list-item-${index}`,
          "flight-row",
          {
            "row-view-desktop":
              matchesDesktop || (matchesLargeDesktop && showListView),
            expanded: slice.id === expandedFlight,
            recommended: recommendedFlight,
          },
          "b2b"
        )}
        key={slice.id}
      >
        <FlightListInfo
          type={FlightCardType.content}
          selectedFare={selectedFare}
          slice={slice}
          flights={flights}
          flight={flight}
          rewardsKey={rewardsKey || ""}
          fareClassFilter={fareClassFilter}
          onAlgomerchClick={(label: string) => {
            handleClickAlgomerchTag(label);
            setOpenModal(ModalTypes.AlgomerchModal);
          }}
          // TODO: use real params here
          maxFlightPriceFilter={maxPriceFilter}
          isRoundTrip={true}
          isOutgoing={isOutgoing}
          isAgentPortal={isAgentPortal}
          packagesByFareSlice={
            isOutgoing ? packagesByOutboundFareSlice : packagesByReturnFareSlice
          }
          recommendedFlight={recommendedFlight}
          largestValueAccount={largestValueAccount}
          onClick={() => {
            handleSliceSelect(slice.id, flight);
            setTimeout(() => {
              const OFFSET = DESKTOP_OFFSET_SCROLL;
              const cardTop =
                document?.getElementById(slice.id)?.getBoundingClientRect()
                  .top || 0;
              window.scrollBy({
                top: (cardTop as number) - OFFSET,
                behavior: "smooth",
              });
            }, 100);
          }}
          onFareClick={(fareId: string) => {
            setClickedFareId(fareId);
          }}
        />
        {matchesMobile && slice.id === expandedFlight && (
          <MobilePopoverCard
            headerElement={
              <Header
                className="mobile-flight-details-header-container"
                left={
                  <BackButton
                    className="back-button-icon"
                    onClick={() => {
                      setExpandedFlight("");
                    }}
                  />
                }
                center={
                  <Typography
                    variant="body1"
                    className="mobile-flight-details-header"
                  >
                    {"Choose Fare"}
                  </Typography>
                }
                isMobile={true}
                fullWidth={true}
              />
            }
            open={true}
            fullScreen={true}
            className="mobile-flight-details-container"
            contentClassName="mobile-flight-details-container-content"
            centered={true}
          >
            {(!expandedFareDetails || isTripDetailsLoading) && (
              <LoadingIndicator
                className="packages-flight-shop-details-loading-indicator"
                indicatorSize={"small"}
                indicator={B2BSpinner}
                message={LOADING_FLIGHT_DETAILS_STRING}
              />
            )}
            {expandedFareDetails && !isTripDetailsLoading && (
              <FlightDetails
                isOutgoing={isOutgoing}
                selectedFareId={fareId}
                onFareClick={(fareId, limit) => {
                  handleFareSelect(flight, fareId, index, limit);
                }}
                tripDetails={expandedFareDetails}
                rewardsKey={undefined}
                departureDate={undefined}
                returnDate={undefined}
                airports={{}}
                openMobileFlightDetailsModal={openMobileFlightDetailsModal}
                setOpenMobileFlightDetailsModal={
                  setOpenMobileFlightDetailsModal
                }
                isSpiritOrFrontierAirlinesSelected={false}
                onAlgomerchClick={(label: string) => {
                  handleClickAlgomerchTag(label);
                  setOpenModal(ModalTypes.AlgomerchModal);
                }}
                ctaTitles={ctaTitles}
                showFareDetailsTitle={true}
                isMobile={matchesMobile}
              />
            )}
          </MobilePopoverCard>
        )}
        {slice.id === expandedFlight &&
          expandedFareDetails &&
          !isTripDetailsLoading && (
            <FlightDetails
              isOutgoing={isOutgoing}
              selectedFareId={fareId}
              onFareClick={(fareId, limit) => {
                handleFareSelect(flight, fareId, index, limit);
              }}
              tripDetails={expandedFareDetails}
              openMobileFlightDetailsModal={openMobileFlightDetailsModal}
              setOpenMobileFlightDetailsModal={setOpenMobileFlightDetailsModal}
              isSpiritOrFrontierAirlinesSelected={false}
              onAlgomerchClick={(label: string) => {
                handleClickAlgomerchTag(label);
                setOpenModal(ModalTypes.AlgomerchModal);
              }}
              ctaTitles={ctaTitles}
              showFareDetailsTitle={true}
              isMobile={matchesMobile}
              {...{ rewardsKey, departureDate, returnDate, airports }}
            />
          )}
        {slice.id === expandedFlight &&
          (!expandedFareDetails || isTripDetailsLoading) && (
            <LoadingIndicator
              className="packages-flight-shop-details-loading-indicator"
              indicatorSize={"small"}
              indicator={B2BSpinner}
              message={LOADING_FLIGHT_DETAILS_STRING}
            />
          )}
      </Box>
    );
  };

  const listRef = React.useRef<ReactList | null>(null);
  const divRef = React.useRef<HTMLDivElement | null>(null);

  const renderDesktopFlights = () => (
    <InfiniteScroll
      dataLength={faresToShow.length}
      next={setFetchMoreData}
      hasMore={faresToShow.length < sortedFlights.length}
      loader={
        <Box className="loading-flights">
          <B2BSpinner classes={["loading-flights-bunny"]} />
        </Box>
      }
    >
      {renderRecommendedFlightInfo(
        isOutgoing ? outgoingRecommendedFlight : returnRecommendedFlight
      )}
      {faresToShow.map((flight: any, index: number) => {
        // TODO: Filter for not recommended flight
        const flightSliceId = flight.slice;
        const flightSlice = flights.slices[flightSliceId];
        if (
          !flightSlice ||
          flightSliceId === outgoingRecommendedFlight?.slice ||
          flightSliceId === returnRecommendedFlight?.slice
        ) {
          return null;
        }
        const isListView = true;
        const selectedFare =
          flight.fares.find(
            (fare: any) => fare.example?.fare === selectedFareId
          ) || flight.fares[0];
        return renderFlightListInfo(
          selectedFare,
          isListView,
          flightSlice,
          flight,
          index
        );
      })}
    </InfiniteScroll>
  );

  const renderMobileFLights = () => (
    <div ref={divRef} className="availability-list">
      <FlightSummaryPanel />
      <Box className="selected-package-pricing-card-wrapper">
        <SelectedPackagePricingCard isMobile />
      </Box>
      <FlightShopHeader isMobile />
      {renderRecommendedFlightInfo(
        isOutgoing ? outgoingRecommendedFlight : returnRecommendedFlight
      )}
      <ReactList
        ref={listRef}
        itemRenderer={(index: number) => {
          const currentFare = faresToShow[index];

          const { slice: flightSliceId } = currentFare;
          const flightSlice = flights?.slices[flightSliceId];
          const isListView = !flightSlice?.domestic;
          if (
            !flightSlice ||
            flightSliceId === outgoingRecommendedFlight?.slice ||
            flightSliceId === returnRecommendedFlight?.slice
          ) {
            // itemRenderer does not allow for returning of undefined/null
            return (
              <Box
                display="none"
                // key={`${fare.example?.fare || fare.id}-none`}
              />
            );
          }
          const selectedFare =
            currentFare.fares.find(
              (fare: any) => fare.example?.fare === selectedFareId
            ) || currentFare.fares[0];

          return renderFlightListInfo(
            selectedFare,
            isListView,
            flightSlice,
            currentFare,
            index
          );
        }}
        length={faresToShow.length}
        type="variable"
      />
    </div>
  );

  const renderFlights = () => {
    return matchesMobile ? renderMobileFLights() : renderDesktopFlights();
  };

  const renderNoFlightsMessaging = () => {
    if (hasFlightShopCallFailed) {
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          type: "packages_flight_list_error",
          primary_button: constants.RELOAD,
          screen: ModalScreens.FLIGHTS_SHOP,
          category: ModalCategoryType.TROUBLE,
          modal_subtitle: constants.ERROR_SUBTITLE,
          modal_title: constants.ERROR_TITLE,
          agent_title: constants.ERROR_TITLE,
          agent_subtitle: constants.ERROR_TITLE,
          funnel: "packages",
          step: "flight_list",
        },
      });
    }
    return hasFlightShopCallFailed ? (
      <Box className="no-results-container">
        <NoResults
          className="flight-list-no-results"
          title={constants.ERROR_TITLE}
          subtitle={constants.ERROR_SUBTITLE}
        />
        <button
          onClick={() => window.location.reload()}
          className={"reload-button"}
        >
          {constants.RELOAD}
        </button>
      </Box>
    ) : hasAppliedNonFareclassFilter ? (
      <Box className={clsx("no-results-container", "air-cx-v3")}>
        <NoResults
          className="flight-list-no-results"
          title={FLIGHTS_NOT_FOUND_TITLE}
          subtitle={FLIGHTS_NOT_FOUND_SUBTITLE}
        />

        <Box className="no-results-buttons-container">
          <Button
            onClick={resetFilters}
            className={clsx("reset-filters-dates-button", "b2b")}
          >
            {CLEAR_FILTERS_CTA_TEXT}
          </Button>
        </Box>
      </Box>
    ) : null;
  };

  return (
    <Box className={clsx("packages-flight-list-root")}>
      {!isFlightShopLoading &&
      !isFlightShopCallNotCalled &&
      flights &&
      packagesByOutboundFareSlice ? (
        <>
          {faresToShow.length > 0
            ? renderFlights()
            : renderNoFlightsMessaging()}
        </>
      ) : null}
      <FlightAlgomerchModal
        selectedCategory={selectedAlgomerchTag}
        setSelectedCategory={setSelectedAlgomerchTag}
        openModal={openModal === ModalTypes.AlgomerchModal}
        onClose={() => setOpenModal(false)}
      />
    </Box>
  );
};
