export const BASE_PATH_HOME = "/";
export const PATH_TRIPS = `${BASE_PATH_HOME}trips/`;
export const PATH_HOME = "/premium-stays/";
export const PATH_HOME_SEARCH = PATH_HOME + "search/";
export const PATH_AVAILABILITY = PATH_HOME + "availability/";
export const PATH_SHOP = PATH_HOME + "shop/";
export const PATH_SHOP_DETAILS = PATH_HOME + "shop-details/";
export const PATH_ANCILLARY = PATH_HOME + "ancillary/";
export const PATH_ANCILLARY_CUSTOMIZE = PATH_ANCILLARY + "customize/";
export const PATH_BOOK = PATH_HOME + "book/";
export const PATH_BOOK_CONFIRMATION = PATH_BOOK + "confirm/";
export const PATH_TERMS = `${BASE_PATH_HOME}terms/`;
export const PATH_TERMS_HOTEL_CFAR = `${PATH_TERMS}hotel-cancel-for-any-reason/`;
export const PATH_BEST_PRICE_GUARANTEE = `${PATH_TERMS}best-price-guarantee`;
export const PATH_HOTEL = "/hotels";
export const PATH_TRAVEL_SALE = `/travelsale/`;
export const PATH_VACATION_RENTALS = PATH_HOME + "vacation-rentals/";
export const PATH_VACATION_RENTALS_SEARCH = `${PATH_VACATION_RENTALS}search/`;
export const PATH_VACATION_RENTALS_SHOP = `${PATH_VACATION_RENTALS}shop/`;
export const PATH_VACATION_RENTALS_BOOK = PATH_VACATION_RENTALS + "book/";
export const PATH_VACATION_RENTALS_BOOK_CONFIRMATION =
  PATH_VACATION_RENTALS_BOOK + "confirm/";
export const HOTELS_PATH_AVAILABILITY = "/hotels/availability/";
export const PATH_VACATION_RENTALS_AVAILABILITY = `${PATH_VACATION_RENTALS}availability/`;
