import React, { useState } from "react";
import {
  FINTECH_SUBSCRIPTION_V3_SNACKBAR_CANCEL_CTA_LABEL,
  FINTECH_SUBSCRIPTION_V3_SNACKBAR_CHIP_LABEL,
  FINTECH_SUBSCRIPTION_V3_SNACKBAR_OK_CTA_LABEL,
  FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_ONE,
  FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_TWO_MIDDLE,
  FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_TWO_PREFIX,
  FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_TWO_SUFFIX,
  FINTECH_SUBSCRIPTION_V3_SNACKBAR_TITLE,
} from "../Header/components/constants";
import { Typography } from "@material-ui/core";
import { SnackbarNotice } from "../SnackbarNotice";
import { ValuePropModal } from "../ValuePropModal/ValuePropModal";
import { CorpFintechSubscriptionModal } from "../CorpFintechSubscriptionModal/component";
import optInSubscription from "../../../api/v1/cotbFintech/optInSubscription";
import { trackEvent } from "../../../api/v1/trackEvent";
import { MODAL_ALERT_CHOICE } from "redmond";

enum FlowSteps {
  Snackbar = "Snackbar",
  ValueProp = "ValueProp",
  InterestThankYou = "InterestThankYou",
}

export const FintechSubscriptionV3Flow: React.FC = () => {
  const [currentStep, setCurrentStep] = useState<FlowSteps | null>(
    FlowSteps.Snackbar
  );

  const handleNext = () => {
    switch (currentStep) {
      case FlowSteps.Snackbar:
        trackEvent({
          eventName: MODAL_ALERT_CHOICE,
          properties: {
            type: "fintech_subscription_placement_booking",
            primary_button: "learn_more",
            secondary_button: "no_thanks",
            choice: "primary",
          },
        });
        trackEvent({
          eventName: "viewed_fintech_subscription",
          properties: {
            test_type: "placement_booking",
            subscription_type: "fda_10dollarsoff3bookings",
          },
        });
        setCurrentStep(FlowSteps.ValueProp);
        break;
      case FlowSteps.ValueProp:
        trackEvent({
          eventName: "fintech_subscription_choice",
          properties: {
            test_type: "placement_booking",
            subscription_type: "fda_10dollarsoff3bookings",
            choice: "subscription",
          },
        });
        optInSubscription({ optIn: true });
        setCurrentStep(FlowSteps.InterestThankYou);
        break;
      case FlowSteps.InterestThankYou:
        setCurrentStep(null);
        break;
      default:
        setCurrentStep(null);
    }
  };

  const handleClose = () => {
    switch (currentStep) {
      case FlowSteps.Snackbar:
        trackEvent({
          eventName: MODAL_ALERT_CHOICE,
          properties: {
            type: "fintech_subscription_placement_booking",
            primary_button: "learn_more",
            secondary_button: "no_thanks",
            choice: "secondary",
          },
        });
        optInSubscription({ optIn: false });
        break;
      case FlowSteps.ValueProp:
        trackEvent({
          eventName: "fintech_subscription_choice",
          properties: {
            test_type: "placement_booking",
            subscription_type: "fda_10dollarsoff3bookings",
            choice: "no_thanks",
          },
        });
        optInSubscription({ optIn: false });
        break;
    }
    setCurrentStep(null);
  };

  return (
    <>
      {currentStep === FlowSteps.Snackbar && (
        <SnackbarNotice
          open
          onClose={handleClose}
          title={FINTECH_SUBSCRIPTION_V3_SNACKBAR_TITLE}
          mainSubtitle={FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_ONE}
          secondarySubtitle={
            <Typography>
              <span style={{ color: "#FFFFFFBF" }}>
                {FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_TWO_PREFIX}
              </span>
              <span style={{ color: "#FFFFFF" }}>
                {FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_TWO_MIDDLE}
              </span>
              <span style={{ color: "#FFFFFFBF" }}>
                {FINTECH_SUBSCRIPTION_V3_SNACKBAR_SUBTITLE_TWO_SUFFIX}
              </span>
            </Typography>
          }
          chipLabel={FINTECH_SUBSCRIPTION_V3_SNACKBAR_CHIP_LABEL}
          ctaOkText={FINTECH_SUBSCRIPTION_V3_SNACKBAR_OK_CTA_LABEL}
          ctaCancelText={FINTECH_SUBSCRIPTION_V3_SNACKBAR_CANCEL_CTA_LABEL}
          onCtaOkClick={handleNext}
          onCtaCancelClick={handleClose}
        />
      )}
      {currentStep === FlowSteps.ValueProp && (
        <ValuePropModal
          open
          onClose={handleClose}
          onConfirmCtaClick={handleNext}
          onCancelCtaClick={handleClose}
        />
      )}
      {currentStep === FlowSteps.InterestThankYou && (
        <CorpFintechSubscriptionModal
          open
          onClose={handleClose}
          onConfirm={handleClose}
        />
      )}
    </>
  );
};

export default FintechSubscriptionV3Flow;
