import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../../reducer";
import { RewardsBanner } from "./component";

// TODO: Update banner content with user data
const mapStateToProps = (state: IStoreState) => {
  return {
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    rewardsAccounts: getRewardsAccounts(state),
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RewardsBannerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRewardsBanner = withRouter(connector(RewardsBanner));
