export const BACK_TO_RESULTS = "Back to results";

// common shared
export const CONTINUE_TO_CHECKOUT = "Continue to checkout";
export const FREE_CANCELLATION = "Free cancellation";
export const VIEW_DETAILS = "View details";
export const HIDE_DETAILS = "Hide details";
export const PER_GROUP = "per group";
export const PER_PERSON = "per person";

export const WHAT_TO_EXPECT = "What to expect";
export const CANCELLATION_POLICY = "Cancellation policy";
export const WHATS_INCLUDED = "What's included";
export const CUSTOMER_REVIEWS = "Customer Reviews";
export const TRAVELERS_PHOTOS = "Traveler Photos";
export const CONTACT_INFORMATION = "Contact Information";
export const COMPARE_OTHER_SIMILAR_EXPERIENCES =
  "Compare other similar experiences";

export const SHOP_DETAIL_HEADER_TEXT = (
  experienceName: string,
  locationName: string
) => `${experienceName} in ${locationName}`;

export const PARSE_TO_MINUTES = (minutes: number) => `${minutes} minutes`;

export const PASS_BY = " (Pass by)";
export const READ_MORE = "Read more";
export const READ_LESS = "Read less";

export const END_SAME_AS_START = "This activity ends at the starting point.";
export const VIEW_ALL = "View all";
export const CONFIRM_TRAVELERS = "Confirm travelers and start time";
export const SHOW_LESS = "Show less";
export const STARTING_POINT = "Starting point";
export const END_POINT = "End point";
export const PRICE_PER_GROUP = "Prices shown are per group";
export const PRICE_PER_PERSON = "Prices shown are per person";
export const START_TIME = "Start time";
export const SELECT_DATE = "Select date";

// photo gallery
export const SHOW_ALL_PHOTOS_BUTTON_LABEL = (numPhotos: number) =>
  `See all photos (${numPhotos})`;
export const BOOK_NOW = "Book now";
export const ALL_PHOTOS = "All photos";
export const REVIEWS_POWERED_BY = "Reviews powered by";
export const TRIPADVISOR = "TripAdvisor";
export const PROVIDER_PHOTOS = "Provider photos";
export const TRAVELER_PHOTOS = "Traveler photos";

// shop similar experiences
export const RATING = "Rating";
export const DURATION = "Duration";
export const CANCELLATION = "Cancellation";
export const PRICE = "Price";
export const PAID_CANCELLATION = "Paid cancellation";
export const CURRENT = "Current";
export const AVG_REVIEWS = ({
  avg,
  numberOfReviews,
}: {
  avg: string;
  numberOfReviews: number;
}) => `${avg} avg (${numberOfReviews} reviews)`;
export const COMPARE_ITINERARY = "Compare itinerary";
export const SEE_MORE_SIMILAR_EXPERIENCES = "See more similar experiences";
