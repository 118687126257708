import React, {useEffect, useState} from "react";
import { RouteComponentProps } from "react-router";
import { RoomInfoPickerConnectorProps } from "./container";
import "./styles.scss";
import { Box } from "@material-ui/core";
import { RoomContent } from "../RoomContent";
import clsx from "clsx";
import {
  BackButton,
  HotelShopRoomAmenitiesModal
} from "halifax";
import {
  RoomInfo,
  RoomInfoProducts
} from "redmond";
import {
  DesktopCalendarPicker
} from "../../../search/components/PremierCollectionSearchControl/components/DesktopCalendarPicker";
import { transformToStringifiedQuery } from "../../../shop/utils/queryStringHelpers";

export interface IRoomInfoPickerProps
  extends RoomInfoPickerConnectorProps,
    RouteComponentProps {
  handleReadyToRedirect?: () => void;
  isMobile?: boolean;
  handleShopCall: (queryParams: string) => void;
}

export const RoomInfoPicker = ({
  handleReadyToRedirect,
  isMobile,
  handleShopCall,
  chosenRoomInfoIndex,
  roomsInfo,
  lodgingData,
  fromDate,
  untilDate,
}: IRoomInfoPickerProps) => {
  const roomInfoProducts: RoomInfoProducts[] = (roomsInfo as unknown as RoomInfo[]).map(
      info => ({ roomInfo: info, products: [] }) as RoomInfoProducts
  );
  const [openAmenitiesModal, setOpenAmenitiesModal] = useState(false);
  const [openDatesModal, setOpenDatesModal] = useState(false);
  const [isOverFlow, setIsOverFlow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [datesSelected, setDatesSelected] = useState(false);

  const [modalRoomProduct, setModalRoomProduct] = useState<RoomInfoProducts>(
    roomInfoProducts[0]
  );
  const [modalProductIndex, setModalProductIndex] = useState(0);

  const openAmenities = (product: RoomInfoProducts, productIdx: number) => {
    setModalRoomProduct(product);
    setModalProductIndex(productIdx);
    setOpenAmenitiesModal(true);
  };
  const handleAmenitiesClose = () => {
    setOpenAmenitiesModal(false);
  };
  const openDates = () => {
    setOpenDatesModal(true);
  }

  // the state change for dates can be delayed so we only search when the dates are ready
  const isReadyToSearch = !!fromDate && !!untilDate && datesSelected;

  React.useEffect(() => {
    if (isReadyToSearch) {
      searchShop();
    }
  }, [fromDate, untilDate,]);

  const onSearchDates = () => {
    setDatesSelected(true);
    if (handleReadyToRedirect) {
      handleReadyToRedirect();
    }
    if (isReadyToSearch) {
      searchShop();
    }
  }

  const searchShop = () => {
    const queryParams = transformToStringifiedQuery({
      lodgingId: lodgingData?.id || "",
      fromDate: fromDate,
      untilDate: untilDate,
      selectedLodgingIndex: 0, // defaulting to 0 since not from availability list
      adultsCount: 2,
      childrenCount: 0,
      selectedAccountIndex: 0,
    });
    handleShopCall(queryParams);
  }

  const leftScrollButton = () => {
    const overFlowedElement = document.getElementById("hotel-shop-room-picker");
    if (overFlowedElement) {
      overFlowedElement.scrollLeft -= 350;
    }
  };
  const rightScrollButton = () => {
    const overFlowedElement = document.getElementById("hotel-shop-room-picker");
    if (overFlowedElement) {
      overFlowedElement.scrollLeft += 350;
    }
  };

  const determineOverFlow = () => {
    const overFlowedElement = document.getElementById("hotel-shop-room-picker");
    if (overFlowedElement) {
      return overFlowedElement.clientWidth < overFlowedElement.scrollWidth;
    }
    return false;
  };

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    const overFlow = determineOverFlow();
    setIsOverFlow(overFlow);
  }, [windowWidth]);

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
      <Box
        className={clsx("hotel-shop-room-picker-root-redesign", {
          mobile: isMobile,
          overflow: isOverFlow,
        })}
      >
        {!isMobile && isOverFlow && (
          <BackButton
            aria-label="See more rooms"
            onClick={leftScrollButton}
            className="left-scroll-button"
          />
        )}
        <Box
          className="hotel-shop-room-type-section-wrapper"
          id="hotel-shop-room-picker"
        >
          {roomInfoProducts.map((roomInfoProduct, roomInfoProductIndex) => (
            <RoomContent
              key={roomInfoProductIndex}
              roomInfoIndex={roomInfoProductIndex}
              roomInfoProduct={roomInfoProduct}
              chosenRoomInfoIndex={chosenRoomInfoIndex}
              datesButtonClassName="select-dates-cta"
              openAmenitiesModal={openAmenities}
              openDatesModal={openDates}
              isMobile={isMobile}
            />
          ))}
        </Box>
        <HotelShopRoomAmenitiesModal
          roomInfoProduct={modalRoomProduct}
          roomInfoIndex={modalProductIndex}
          openModal={openAmenitiesModal}
          onClose={handleAmenitiesClose}
          isMobile={isMobile}
        />
        <DesktopCalendarPicker
          open={openDatesModal}
          closePopup={() => {
            setOpenDatesModal(false);
          }}
          onSearchDates={onSearchDates}
          isSearchButton={true}
        />
        {!isMobile && isOverFlow && (
          <BackButton
            aria-label="See more rooms"
            onClick={rightScrollButton}
            className="right-scroll-button"
          />
        )}
      </Box>
  );
};
