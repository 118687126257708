import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { RewardsBanner } from "./component";
import { fetchRewardsAccounts } from "../../actions/actions";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
} from "../../reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    rewardsAccounts: getRewardsAccounts(state),
  };
};

const mapDispatchToProps = { fetchRewardsAccounts };

const connector = connect(mapStateToProps, mapDispatchToProps);

export type RewardsBannerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedRewardsBanner = withRouter(connector(RewardsBanner));
