import React, { useContext, useState, useMemo } from "react";
import {
  ITravelOfferCardContent,
  TravelWalletSingleSelectionWorkflow,
  TravelWalletSingleSelectionStep,
  MobilePopoverCard,
  BackButton,
  MobileFloatingButton,
  getTravelOfferCardContent,
  getTravelCreditCardContent,
  B2BSpinner,
  LoadingIndicator,
  getTravelCreditHistoryModalProps,
  IconName,
} from "halifax";
import {
  CallState,
  SelectedTravelOfferScreen,
  SELECTED_TRAVEL_OFFER,
  VIEWED_OFFER_FAQ,
  TravelWalletOffer,
  TravelWalletCredit, CorpSessionInfo,
} from "redmond";
import {
  CONTINUE,
  CREDITS_CTA,
  getSelectedCreditDescription,
  getSelectedOfferDescription,
  getWalletModalTitle,
  LOADING_OFFERS,
  LOADING_WALLET,
  MODAL_FLIGHTS_HEADER_TEXT_CREDITS,
  VIEW_OFFER_DETAILS_CTA_COPY,
} from "./textConstants";
import { TravelOfferSelectionConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import "./styles.scss";
import { Box } from "@material-ui/core";
import clsx from "clsx";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { ClientContext } from "../../../../App";
import {
  AVAILABLE,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  TRAVEL_SALE,
  TRAVEL_SALE_VARIANTS,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";

interface ITravelOfferSelectionProps
  extends TravelOfferSelectionConnectorProps,
    RouteComponentProps {
  className?: string;
  isMobile?: boolean;
  progressStep?: TravelWalletSingleSelectionStep;
  mobileHeaderElement?: JSX.Element;
  onGoBack?: () => void;
  onContinueClick?: () => void;
}

export const TravelOfferSelection = (props: ITravelOfferSelectionProps) => {
  const {
    className,
    offers,
    bestOverallOffer,
    offerToApply,
    setOfferToApply,
    isMobile,
    progressStep,
    mobileHeaderElement,
    onGoBack,
    onContinueClick,
    fetchApplicableTravelWalletItemsCallState,
    selectedPaymentMethodId,
    selectedRewardsPaymentAccountId,
    setSelectedRewardsPaymentTotal,
    resetPaymentCardSelectedAccounts,
    credit,
    creditBreakdown,
    creditToApply,
    setCreditToApply,
    maxApplicableTravelWalletCreditOnly,
    isTravelWalletPaymentOnly,
    creditsTransactionHistory,
  } = props;
  const [travelOfferCardContent, setTravelOfferCardContent] = useState<
    ITravelOfferCardContent | undefined
  >(undefined);
  const [currentSelectedOffer, setCurrentSelectedOffer] = useState<
    TravelWalletOffer | undefined
  >(undefined);
  const [currentSelectedCredit, setCurrentSelectedCredit] = useState<
    TravelWalletCredit | undefined
  >(undefined);
  const [openModal, setOpenModal] = useState(false);

  const expState = useExperiments();
  const { sessionInfo } = useContext(ClientContext);
  const travelWalletCreditsExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_CREDITS_EXPERIMENT
  );
  const isTravelWalletCreditsExperiment = useMemo(
    () => travelWalletCreditsExperiment === AVAILABLE,
    [travelWalletCreditsExperiment]
  );

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );
  const isTravelSaleEnabled = travelSaleVariant !== CONTROL;

  const firstName =
    useContext(ClientContext).sessionInfo?.userInfo?.firstName || "";

  const isLoading =
    fetchApplicableTravelWalletItemsCallState === CallState.InProcess;

  const isNonFinancialUser =
      (sessionInfo as CorpSessionInfo)?.corporateInfo?.cap1Role === "NFU";

  const trackViewedOffer = () => {
    trackEvent({
      eventName: VIEWED_OFFER_FAQ,
      properties: {
        screen: SelectedTravelOfferScreen.FLIGHT_CHECKOUT,
      },
    });
  };

  React.useEffect(() => {
    switch (progressStep) {
      case TravelWalletSingleSelectionStep.Main:
        setOpenModal(false);
        break;
      case TravelWalletSingleSelectionStep.TravelWalletSelection:
        setOpenModal(true);
        break;
      default:
        break;
    }
  }, [progressStep]);

  React.useEffect(() => {
    if (
      (!isTravelWalletCreditsExperiment && bestOverallOffer) ||
      Math.abs(bestOverallOffer?.amount.amount || 0) >
        Math.abs(credit?.amount.amount || 0)
    ) {
      setOfferToApply(bestOverallOffer);
      setCurrentSelectedOffer(bestOverallOffer);
      setCreditToApply(undefined);
      setCurrentSelectedCredit(undefined);
    } else if (credit) {
      setCreditToApply(credit);
      setCurrentSelectedCredit(credit);
      setOfferToApply(undefined);
      setCurrentSelectedOffer(undefined);
    }
  }, [bestOverallOffer, credit]);

  React.useEffect(() => {
    if (currentSelectedOffer) {
      const content = getTravelOfferCardContent(currentSelectedOffer);
      setTravelOfferCardContent(content);
    }
  }, [currentSelectedOffer]);

  React.useEffect(() => {
    if (selectedPaymentMethodId || selectedRewardsPaymentAccountId) {
      resetPaymentCardSelectedAccounts();
      setSelectedRewardsPaymentTotal("", null, null);
    }
  }, [offerToApply, creditToApply]);

  const creditDetailsCardProps = React.useMemo(
    () =>
      getTravelCreditCardContent(
        creditBreakdown,
        config.TENANT,
        firstName,
        credit?.amount,
        false,
        isNonFinancialUser,
      ),
    [creditBreakdown, firstName, credit]
  );

  if ((!offers || !bestOverallOffer) && !credit && !isLoading) return null;

  return isMobile ? (
    <MobilePopoverCard
      className={clsx("mobile-select-offers-popup", "b2b")}
      open={openModal}
      onClose={() => setOpenModal(false)}
      fullScreen
      headerElement={mobileHeaderElement}
      topLeftButton={
        onGoBack && (
          <BackButton
            className="flight-offers-popup-back-button"
            onClick={onGoBack}
          />
        )
      }
    >
      <TravelWalletSingleSelectionWorkflow
        travelOfferCardContent={travelOfferCardContent}
        modalCTACopy={
          currentSelectedCredit ? CREDITS_CTA : VIEW_OFFER_DETAILS_CTA_COPY
        }
        offers={offers || []}
        className={className}
        offerToApply={offerToApply}
        setOfferToApply={setOfferToApply}
        currentSelectedOffer={currentSelectedOffer}
        setCurrentSelectedOffer={setCurrentSelectedOffer}
        getOfferDescription={getSelectedOfferDescription}
        modalTitle={getWalletModalTitle(offers || [], credit, false)}
        isMobile={isMobile}
        onSetOpenModal={(open) => {
          if (open && offerToApply) {
            trackEvent({
              eventName: SELECTED_TRAVEL_OFFER,
              properties: {
                screen: SelectedTravelOfferScreen.FLIGHT_CHECKOUT,
                offer_count: offers?.length,
                ...offerToApply?.trackingPropertiesV2?.properties,
              },
              encryptedProperties: [
                offerToApply?.trackingPropertiesV2?.encryptedProperties ?? "",
              ],
            });
          }
        }}
        onFirstTravelOfferCardChange={trackViewedOffer}
        credit={isTravelWalletCreditsExperiment ? credit : undefined}
        creditToApply={creditToApply}
        setCreditToApply={setCreditToApply}
        currentSelectedCredit={currentSelectedCredit}
        setCurrentSelectedCredit={setCurrentSelectedCredit}
        getCreditDescription={getSelectedCreditDescription}
        creditAmountToApply={maxApplicableTravelWalletCreditOnly}
        creditDetailsCardProps={{ cardContent: creditDetailsCardProps }}
        isTravelWalletPaymentOnly={isTravelWalletPaymentOnly}
        showCreditHistory={
          isTravelCreditHistoryExperiment && !!creditsTransactionHistory?.length
        }
        travelCreditHistoryProps={
          isTravelCreditHistoryExperiment && !!creditsTransactionHistory?.length
            ? getTravelCreditHistoryModalProps(
                firstName,
                creditsTransactionHistory,
                credit,
                isMobile ? IconName.PiggyBankCircleOutline : undefined
              )
            : undefined
        }
        isTravelSale={isTravelSaleEnabled}
      />
      {onContinueClick && (
        <MobileFloatingButton
          className="offers-continue-button"
          onClick={onContinueClick}
          wrapperClassName="b2b"
        >
          {CONTINUE}
        </MobileFloatingButton>
      )}
    </MobilePopoverCard>
  ) : (
    <Box className="checkout-travel-offer-container">
      {isLoading ? (
        <LoadingIndicator
          indicator={B2BSpinner}
          message={
            isTravelWalletCreditsExperiment ? LOADING_WALLET : LOADING_OFFERS
          }
          indicatorSize="medium"
          textAlign="center"
        />
      ) : (
        <TravelWalletSingleSelectionWorkflow
          travelOfferCardContent={travelOfferCardContent}
          modalCTACopy={
            currentSelectedCredit ? CREDITS_CTA : VIEW_OFFER_DETAILS_CTA_COPY
          }
          modalHeaderText={
            currentSelectedCredit
              ? MODAL_FLIGHTS_HEADER_TEXT_CREDITS(firstName)
              : undefined
          }
          offers={offers || []}
          className={className}
          offerToApply={offerToApply}
          setOfferToApply={setOfferToApply}
          currentSelectedOffer={currentSelectedOffer}
          setCurrentSelectedOffer={setCurrentSelectedOffer}
          getOfferDescription={getSelectedOfferDescription}
          modalTitle={getWalletModalTitle(offers || [], credit, false)}
          isMobile={isMobile}
          onSetOpenModal={(open) => {
            if (open) {
              trackEvent({
                eventName: SELECTED_TRAVEL_OFFER,
                properties: {
                  screen: SelectedTravelOfferScreen.FLIGHT_CHECKOUT,
                  offer_count: offers?.length,
                  ...offerToApply?.trackingPropertiesV2?.properties,
                },
                encryptedProperties: [
                  offerToApply?.trackingPropertiesV2?.encryptedProperties ?? "",
                ],
              });
            }
          }}
          onFirstTravelOfferCardChange={trackViewedOffer}
          credit={isTravelWalletCreditsExperiment ? credit : undefined}
          creditToApply={creditToApply}
          setCreditToApply={setCreditToApply}
          currentSelectedCredit={currentSelectedCredit}
          setCurrentSelectedCredit={setCurrentSelectedCredit}
          getCreditDescription={getSelectedCreditDescription}
          creditAmountToApply={maxApplicableTravelWalletCreditOnly}
          creditDetailsCardProps={{ cardContent: creditDetailsCardProps }}
          isTravelWalletPaymentOnly={isTravelWalletPaymentOnly}
          showCreditHistory={
            isTravelCreditHistoryExperiment &&
            !!creditsTransactionHistory?.length
          }
          travelCreditHistoryProps={
            isTravelCreditHistoryExperiment &&
            !!creditsTransactionHistory?.length
              ? getTravelCreditHistoryModalProps(
                  firstName,
                  creditsTransactionHistory,
                  credit,
                  isMobile ? IconName.PiggyBankCircleOutline : undefined
                )
              : undefined
          }
          isTravelSale={isTravelSaleEnabled}
        />
      )}
    </Box>
  );
};
