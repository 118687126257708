import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";

import {
  fetchTravelWalletDetails,
  IFetchTravelOffersDetails,
} from "../../../travel-wallet/actions/actions";
import { FlightList } from "./component";
import {
  fetchPackagesFlightShop,
  fetchPackagesTripDetails,
  IFetchPackagesFlightShop,
  IFetchPackagesTripDetails,
  ISetPackagesChosenOutgoingSlice,
  ISetPackagesChosenReturnSlice,
  ISetPackagesFlightShopProgress,
  setAirlineFilter,
  setAirportFilter,
  setDurationFilter,
  setFlightNumberFilter,
  setMaxPriceFilter,
  setOutboundArrivalTimeRange,
  setOutboundDepartureTimeRange,
  setPackagesChosenOutgoingSlice,
  setPackagesChosenReturnSlice,
  setPackagesFlightShopProgress,
  setReturnArrivalTimeRange,
  setReturnDepartureTimeRange,
} from "../../actions/actions";
import { getRecommendedFlights } from "../../../availability/reducer";
import {
  getAirShopSummary,
  getFareTripDetailsById,
  getFlightShopCallResults,
  flightsSelector,
  getIsFlightShopLoading,
  getIsOutgoing,
  getIsTripDetailsLoading,
  getOutboundFlightsToRender,
  getPackagesByReturnFareSlice,
  getParsedPackagesByOutboundFareSlice,
  getRecommendedOutgoingFlight,
  getRecommendedReturnFlight,
  getReturnFlightsToRender,
  getSelectedTrip,
  getSortedAndFilteredFlights,
  getMaxPriceFilter,
  getFlightShopCallState,
  getAirportMap,
  hasSetNonFareclassFiltersSelector,
  initialFilterOptions,
  getViewedFlightListProperties,
  getSelectedReturnSliceProperties,
  getSelectedOutgoingSliceProperties,
  getIsReturn,
} from "../../reducer";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import {
  getDepartureDate,
  getDestination,
  getOrigin,
  getReturnDate,
} from "../../../search/reducer";
import { RouteComponentProps } from "react-router-dom";
import { CallState, FlightShopStep } from "redmond";
import { Dispatch } from "@reduxjs/toolkit";

import * as H from "history";
import { setStopsOption } from "../../../search/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    recommendedFlights: getRecommendedFlights(state),
    flightShopCallResults: getFlightShopCallResults(state),
    isFlightShopLoading: getIsFlightShopLoading(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    airShopSummary: getAirShopSummary(state),
    flights: flightsSelector(state),
    packagesByOutboundFareSlice: getParsedPackagesByOutboundFareSlice(state),
    packagesByReturnFareSlice: getPackagesByReturnFareSlice(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    isTripDetailsLoading: getIsTripDetailsLoading(state),
    tripDetailsById: getFareTripDetailsById(state),
    isOutgoing: getIsOutgoing(state),
    isReturn: getIsReturn(state),
    outboundFlights: getOutboundFlightsToRender(state),
    returnFlights: getReturnFlightsToRender(state),
    selectedTrip: getSelectedTrip(state),
    sortedFlights: getSortedAndFilteredFlights(state),
    maxPriceFilter: getMaxPriceFilter(state),
    outgoingRecommendedFlight: getRecommendedOutgoingFlight(state),
    returnRecommendedFlight: getRecommendedReturnFlight(state),
    hasFlightShopCallFailed: getFlightShopCallState(state) === CallState.Failed,
    isFlightShopCallNotCalled:
      getFlightShopCallState(state) === CallState.NotCalled,
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    airports: getAirportMap(state),
    hasAppliedNonFareclassFilter: hasSetNonFareclassFiltersSelector(state),
    viewedFlightListProperties: getViewedFlightListProperties(state),
    selectedReturnSliceProperties: getSelectedReturnSliceProperties(state),
    selectedOutgoingSliceProperties: getSelectedOutgoingSliceProperties(state),
  };
};

interface IDispatchProps {
  fetchTravelWalletDetails: () => IFetchTravelOffersDetails;
  fetchPackagesFlightShop: (
    history: H.History,
    options?: {
      overrideStateByQueryParams?: boolean;
      forceCallPackageFlightAvailability?: boolean;
    }
  ) => IFetchPackagesFlightShop;
  fetchPackagesTripDetails: (
    history: H.History,
    tripId: string
  ) => IFetchPackagesTripDetails;
  setPackagesChosenOutgoingSlice: (
    outgoingSliceId: string,
    outgoingFareId: string,
    outgoingFareSliceId: string,
    tripId: string,
    outgoingFareRating?: number,
    resetReturnIds?: boolean
  ) => ISetPackagesChosenOutgoingSlice;
  setPackagesChosenReturnSlice: (
    tripId: string,
    returnSliceId: string,
    returnFareId: string,
    returnFareSliceId: string,
    returnFareRating?: number
  ) => ISetPackagesChosenReturnSlice;

  setPackagesFlightShopProgress: (
    progress: FlightShopStep
  ) => ISetPackagesFlightShopProgress;
  resetFilters: () => void;
}

export const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    fetchTravelWalletDetails: () => dispatch(fetchTravelWalletDetails()),
    fetchPackagesFlightShop: (
      history: H.History,
      options?: {
        overrideStateByQueryParams?: boolean;
        forceCallPackageFlightAvailability?: boolean;
      }
    ) => dispatch(fetchPackagesFlightShop(history, options)),
    fetchPackagesTripDetails: (history: H.History, tripId: string) =>
      dispatch(fetchPackagesTripDetails(history, tripId)),
    setPackagesChosenOutgoingSlice: (
      outgoingSliceId: string,
      outgoingFareId: string,
      outgoingFareSliceId: string,
      tripId: string,
      outgoingFareRating?: number,
      resetReturnIds?: boolean
    ) =>
      dispatch(
        setPackagesChosenOutgoingSlice(
          outgoingSliceId,
          outgoingFareId,
          outgoingFareSliceId,
          tripId,
          outgoingFareRating,
          resetReturnIds
        )
      ),
    setPackagesChosenReturnSlice: (
      tripId: string,
      returnSliceId: string,
      returnFareId: string,
      returnFareSliceId: string,
      returnFareRating?: number
    ) =>
      dispatch(
        setPackagesChosenReturnSlice(
          tripId,
          returnSliceId,
          returnFareId,
          returnFareSliceId,
          returnFareRating
        )
      ),
    setPackagesFlightShopProgress: (progress: FlightShopStep) =>
      dispatch(setPackagesFlightShopProgress(progress)),
    resetFilters: () => {
      dispatch(setAirlineFilter(initialFilterOptions.airlineFilter));
      dispatch(setFlightNumberFilter(initialFilterOptions.flightNumberFilter));
      dispatch(setAirportFilter(initialFilterOptions.airportFilter));
      dispatch(setMaxPriceFilter(initialFilterOptions.maxPriceFilter));
      dispatch(
        setOutboundDepartureTimeRange(
          initialFilterOptions.outboundDepartureTimeRange
        )
      );
      dispatch(
        setOutboundArrivalTimeRange(
          initialFilterOptions.outboundArrivalTimeRange
        )
      );
      dispatch(
        setReturnDepartureTimeRange(
          initialFilterOptions.returnDepartureTimeRange
        )
      );
      dispatch(
        setReturnArrivalTimeRange(initialFilterOptions.returnArrivalTimeRange)
      );
      dispatch(setDurationFilter(initialFilterOptions.durationFilter));
      dispatch(setStopsOption(initialFilterOptions.stopsOption));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightListConnectorProps = ConnectedProps<typeof connector>;

export interface IFlightListProps
  extends FlightListConnectorProps,
    RouteComponentProps {
  openMobileFlightDetailsModal?: boolean;
  setOpenMobileFlightDetailsModal?: (val: boolean) => void;
}

export const ConnectedFlightList = withRouter(connector(FlightList));
