/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import clsx from "clsx";
import {
  Box,
  TextField,
  ListSubheader,
  Divider,
  ListItem,
  Popper,
  PopperProps,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import Autocomplete, {
  AutocompleteRenderInputParams,
} from "@material-ui/lab/Autocomplete";
import styles from "./styles.module.scss";
import { AvailabilityReviewRating, Icon, IconName } from "halifax";
import {
  Category,
  ExperiencesAvailabilityAutocompleteGroup,
  ExperiencesAvailabilityAutocompleteResult,
  GroupedExperienceIds,
} from "redmond";
import { PATH_SHOP } from "../../../../utils/paths";
import { transformToStringifiedShopQuery } from "../../../../utils/queryStringHelpers";

export type ParsedExperiencesAvailabilityAutocompleteResult =
  ExperiencesAvailabilityAutocompleteResult & {
    categoryLabel: string;
  };

export interface IAvailabilityAutocompleteInputProps
  extends AutocompleteRenderInputParams {
  autoFocus?: boolean;
  hideFloatingLabel?: boolean;
  label: string;
  ariaExpanded?: boolean;
}

class AvailabilityAutocompleteInput extends React.PureComponent<IAvailabilityAutocompleteInputProps> {
  public render() {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      InputProps: { startAdornment, endAdornment, ...defaultInputProps },
      autoFocus,
      hideFloatingLabel,
      label,
      ariaExpanded,

      ...defaultProps
    } = this.props;

    const {
      inputProps: { value },
    }: { inputProps: any } = defaultProps;
    defaultProps.inputProps = {
      ...defaultProps.inputProps,
      role: "combobox",
      "aria-autocomplete": "list",
      "aria-expanded": ariaExpanded,
      "aria-haspopup": true,
    };

    return (
      <Box
        className={styles["experiences-availability-autocomplete-input"]}
        tabIndex={-1}
      >
        <Icon
          className={styles["experiences-availability-autocomplete-icon"]}
          name={IconName.MagnifyingGlass}
          ariaLabel=""
          aria-hidden={true}
        />
        <TextField
          {...defaultProps}
          fullWidth
          id={label}
          name="terminus-input"
          label={hideFloatingLabel ? null : label}
          autoFocus={autoFocus}
          InputProps={{
            disableUnderline: true,
          }}
          value={value || ""}
          {...defaultInputProps}
          className={styles["experiences-availability-autocomplete-text"]}
        />
      </Box>
    );
  }
}

export interface IAvailabilityAutocompleteProps {
  value: ExperiencesAvailabilityAutocompleteResult | null;
  valueCategories: ExperiencesAvailabilityAutocompleteGroup[] | null;
  setValue: (value: ExperiencesAvailabilityAutocompleteResult | null) => void;
  fetchValueCategories: (queryString: string) => void;
  loading?: boolean;
  loadingText?: string;
  className?: string;
  autoFocus?: boolean;
  openOnFocus?: boolean;
  afterSetValue?: () => void;
  afterSetValidValue?: () => void;
  initialInputValue?: string;
  overrideInputValue?: string;
  getOptionSelected: (
    option: ParsedExperiencesAvailabilityAutocompleteResult,
    value: ExperiencesAvailabilityAutocompleteResult
  ) => boolean;
  hideFloatingLabel?: boolean;
  label: string;
  popperClassName?: string;
  disabled?: boolean;
  noOptionsText?: JSX.Element;
  hasMissingSearchInfoError?: boolean;
  callbackOnEmptyValue?: boolean;
  groupHeadingText?: string;
  fromDate: Date | null;
  untilDate: Date | null;
  setSelectedGroupedExperience: (option: GroupedExperienceIds) => void;
  experiencesByCategory: GroupedExperienceIds[];
}

export const AvailabilityAutocomplete = (
  props: IAvailabilityAutocompleteProps
): JSX.Element => {
  const {
    loading,
    loadingText,
    value,
    valueCategories,
    autoFocus,
    openOnFocus,
    getOptionSelected,
    fetchValueCategories,
    initialInputValue,
    overrideInputValue,
    hideFloatingLabel,
    label,
    popperClassName,
    disabled,
    noOptionsText,
    hasMissingSearchInfoError,
    callbackOnEmptyValue,
    groupHeadingText,
    fromDate,
    untilDate,
    setSelectedGroupedExperience,
    experiencesByCategory,
  } = props;

  const getGroupedExperience = (groupedExperienceId: string) => {
    return experiencesByCategory.filter((category) => {
      return category.tag.id.toString() === groupedExperienceId;
    })[0];
  };

  const AutocompleteCustomPopper = (prps: PopperProps) => (
    <Popper
      {...prps}
      className={clsx("autocomplete-popper", popperClassName)}
      placement="bottom-start"
      id="autocomplete-popper"
    />
  );

  const [inputValue, setInputValue] = React.useState<string>(
    value ? value.label : initialInputValue || ""
  );

  const [focused, setFocused] = React.useState(false);
  const [inputFocused, setInputFocused] = React.useState(false);

  React.useEffect(() => {
    // close recent search popper if focus has been lost
    if (!inputFocused) {
      setFocused(false);
    } else if (inputFocused) {
      setFocused(true);
    }
  }, [inputFocused]);

  const autocompleteRef = React.useRef();

  const handleInputChange = (
    e: React.ChangeEvent<unknown>,
    inptValue: string
  ) => {
    if (!disabled && e && e.type === "change") {
      if (callbackOnEmptyValue || inptValue.length > 0) {
        setFocused(true);
        fetchValueCategories(inptValue);
      }

      if (e) setInputValue(inptValue);
    }
  };

  const generateOptions: () => ParsedExperiencesAvailabilityAutocompleteResult[] =
    () => {
      if (valueCategories && valueCategories.length > 0) {
        return valueCategories.flatMap((category) => {
          const { results, label: categoryLabel } = category;

          return results.map((result: any) => ({
            ...result,
            categoryLabel,
          }));
        });
      }
      return [];
    };

  // Updates the inputValue when value (e.g.: origin / destination) is parsed and set in the consumer
  React.useEffect(() => {
    if (overrideInputValue) setInputValue(overrideInputValue);
    else if (value) {
      setInputValue(value.label);
    } else {
      setInputValue("");
    }
  }, [value, overrideInputValue]);

  const options = generateOptions();
  const chosenOption =
    (value && options.find((option) => getOptionSelected(option, value))) ||
    null;

  const expanded =
    (!disabled &&
      !overrideInputValue &&
      inputValue.length > 0 &&
      (!value || value?.label !== inputValue)) ||
    (options.length > 1 && focused) ||
    (!!overrideInputValue && inputValue.length > 0 && focused);
  const renderGroup = (params: any) => {
    return [
      <div key={params.group}>
        <ListSubheader
          className={styles["group-header"]}
          key={params.key}
          component="div"
        >
          {groupHeadingText || params.group}
        </ListSubheader>
      </div>,
      params.children,
    ];
  };

  return (
    <Box
      tabIndex={-1}
      className={clsx(styles["experiences-availability-keyword-container"], {
        error: hasMissingSearchInfoError,
      })}
    >
      <Autocomplete
        tabIndex={-1}
        value={chosenOption}
        inputValue={inputValue}
        open={
          focused &&
          ((!disabled &&
            !overrideInputValue &&
            inputValue.length > 0 &&
            (!value || value?.label !== inputValue)) ||
            options.length > 1 ||
            (!!overrideInputValue && inputValue.length > 0))
        }
        PopperComponent={AutocompleteCustomPopper}
        role="combobox"
        onInputChange={handleInputChange}
        onFocus={() => {
          if (!disabled) {
            setFocused(true);
            setTimeout(() => {
              setInputFocused(true);
            }, 100);
          }
        }}
        onBlur={() => {
          // timeout to prevent clicking on recent searches from prematurely closing search popper
          setTimeout(() => {
            setInputFocused(false);
          }, 500);
        }}
        onClose={() => {
          if (!disabled) setFocused(false);
        }}
        openOnFocus={openOnFocus}
        filterOptions={(opts) => opts}
        className={styles["experiences-availability-keyword-autocomplete"]}
        loading={loading}
        loadingText={loadingText ?? "Searching..."}
        autoComplete
        options={options}
        groupBy={(option) => option.categoryLabel}
        getOptionLabel={(
          option: ParsedExperiencesAvailabilityAutocompleteResult
        ) => option.label}
        renderOption={(props, _option) => {
          // const boldString = (str: string, target: string) => {
          //   return str.replaceAll(target, `<b>${target}</b>`);
          // };

          const selectionIsCategory =
            props.categoryLabel === Category.Categories.toString();

          return (
            <div key={props.id}>
              <ListItem
                className={styles["group-item-container"]}
                key={props.id}
                onClick={() => {
                  if (selectionIsCategory) {
                    //get category tags
                    setSelectedGroupedExperience(
                      getGroupedExperience(props.id)
                    );
                  } else {
                    window.open(
                      `${PATH_SHOP}${transformToStringifiedShopQuery(
                        { value: props.id.toString() },
                        fromDate,
                        untilDate
                      )}`,
                      "_blank"
                    );
                  }
                }}
              >
                <ListItemIcon className={styles["group-item-icon"]}>
                  <Icon
                    name={
                      selectionIsCategory
                        ? "navigation-icon"
                        : "experiences-icon"
                    }
                  />
                </ListItemIcon>
                <ListItemText
                  className={styles["group-item-text"]}
                  primary={props.label}
                />
                {props.rating && (
                  <>
                    <Divider
                      orientation="vertical"
                      className={styles["group-item-divider"]}
                    />
                    <AvailabilityReviewRating
                      reviewCount={props.rating.numberOfReviews}
                      scaledScore={4.5} //// TODO: Remove this it's to deal with mock data
                      // scaledScore={props.rating.reviewAverage}
                      shortReviews
                    />
                  </>
                )}
              </ListItem>
            </div>
          );
        }}
        renderGroup={renderGroup}
        renderInput={(params) => (
          <AvailabilityAutocompleteInput
            {...{
              ...params,
              autoFocus,
              label,
              hideFloatingLabel,
              disabled: !!disabled,
              ariaExpanded: expanded,
            }}
          />
        )}
        disabled={disabled}
        noOptionsText={noOptionsText}
        aria-expanded={expanded}
        aria-controls="autocomplete-popper"
        aria-owns={undefined}
        disablePortal // note: this was added so the list always renders under the input field
        ref={autocompleteRef}
      />
      <span id="initInstr" style={{ display: "none" }}>
        When autocomplete results are available use up and down arrows to review
        and enter to select. Touch device users, explore by touch or with swipe
        gestures.
      </span>
      <div aria-live="assertive" className="screen-reader-text" />
    </Box>
  );
};
