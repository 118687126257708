import { Box } from "@material-ui/core";
import * as H from "history";
import React from "react";

import clsx from "clsx";
import { ExperiencesSearchButton } from "../../search/components/ExperiencesSearchControl/components/SearchButton";
import "./styles.scss";
import { SEARCH_EXPERIENCES_BUTTON } from "../../search/textConstants";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../MonthAndDatePicker";
import { DatePickerButton } from "halifax";

export interface IMobileCalendarPickerProps {
  fromDate: Date | null;
  untilDate: Date | null;
  setFromDate: (from: Date | null) => void;
  setUntilDate: (until: Date | null) => void;
  focusedMonthIndex?: number;
  handleSearch: (history: H.History) => void;
}

export const MobileCalendarPicker = (props: IMobileCalendarPickerProps) => {
  const {
    fromDate,
    untilDate,
    setFromDate,
    setUntilDate,
    focusedMonthIndex,
    handleSearch,
  } = props;

  const [from, setFrom] = React.useState<Date | null>(null);
  const [until, setUntil] = React.useState<Date | null>(null);

  React.useEffect(() => {
    setFrom(fromDate);
  }, [fromDate]);

  React.useEffect(() => {
    setUntil(untilDate);
  }, [untilDate]);

  const handleSearchClick = (history: H.History) => {
    handleSearch(history);
  };

  return (
    <Box className="mobile-calendar-picker-root">
      <DatePickerButton
        startDate={from}
        startLabel={"When?"}
        endDate={until}
        endLabel={""}
        classes={[]}
        variant="combinedDates"
        dateFormat={"ddd, MMM D"}
        hideEndDate={true}
        hideSeparator={true}
      />
      <MonthAndDatePicker
        className="b2b"
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setStartDate={setFromDate}
        setEndDate={setUntilDate}
        startDate={from}
        endDate={until}
      />
      {from && until && (
        <ExperiencesSearchButton
          className={clsx("mobile-calendar-picker-search-button", "b2b")}
          message={SEARCH_EXPERIENCES_BUTTON}
          onClick={(history) => {
            handleSearchClick(history);
          }}
        />
      )}
    </Box>
  );
};
