import { TripDetails } from "redmond";
import { flyerOptions } from "halifax";

export const getFrequentFlyerProgramFromTrip = (tripDetails: TripDetails) => {
  // note: we are building with the assumption that one slice can accept one Frequent Flyer
  const airlineCodes = tripDetails.slices.map(
    (slice) => slice.segmentDetails[0].marketingAirline.code
  );
  const flyerPrograms = flyerOptions.filter((option) =>
    airlineCodes.includes(option.value)
  );
  return flyerPrograms.length > 0 ? flyerPrograms : null;
};
