import React, { useState } from "react";
import "./styles.scss";
import { RouteComponentProps } from "react-router-dom";
import {
  ActionButton,
  ContactInfoForm,
  ContactInfoStep,
  ContactInfoWorkflow,
  IContactInfo,
} from "halifax";
import { ITravelerStepErrors } from "redmond";
import { PackagesContactInfoConnectorProps } from "./container";
import {
  ContactSelectors,
  FlightPassengerEventTypes,
  FlightPassengerSelectors,
  GenericChildState,
  getChildState,
  getParentState,
  LodgingSelectors,
  ParentState,
  PassengerErrorModalTypes,
  useCheckoutState,
  useCheckoutStateSelector,
  useChildMachineSelector,
  useChildMachineState,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import {
  CONTACT_INFO_NOTICE,
  CONTACT_INFO_SUBTITLE,
  CONTACT_INFO_TITLE,
  CONTACT_INFO_TITLE_MOBILE,
  CONTINUE_TEXT,
  SAVING_TEXT,
} from "./textConstants";
import { Box, Typography } from "@material-ui/core";
import { PackagesPriceBreakdown } from "../PriceBreakdown";
import clsx from "clsx";
import { DesktopPackageBookValidationError } from "../DesktopPackagesBookWorkflow";

export interface PackagesContactInfoProps
  extends PackagesContactInfoConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  validationErrorTypes?: DesktopPackageBookValidationError[];
  setValidationErrorTypes?: (
    types: DesktopPackageBookValidationError[]
  ) => void;
  contactFormOnly?: boolean;
}

export const PackagesContactInfo = ({
  isMobile,
  validationErrorTypes,
  setValidationErrorTypes,
  contactFormOnly,
}: PackagesContactInfoProps) => {
  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    phone: false,
    email: false,
    travelerSelect: false,
  });

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const [, passengerChildMachineSend] = useChildMachineState<
    TEvent,
    PackagesMachineContext
  >(ParentState.passengerInformation);

  const parentState = getParentState(state.value) as ParentState;
  const childState = getChildState(state.value);

  const contactInfo = useCheckoutStateSelector(
    ContactSelectors.getContactInformation
  );

  const contactInfoLoading = useCheckoutStateSelector(
    ContactSelectors.getContactInformationLoading
  );

  const passengersValidated = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getPassengerStateValidated
  );
  const selectedPassengerCount = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getAllSelectedPassengerIds
  ).length;

  // uses maxAdults or occupancy, it is what we're using in `RoomCapacity` on shop page
  const selectedRoomCapacity =
    useCheckoutStateSelector(LodgingSelectors.getSelectedRoom)?.roomInfo
      .maxAdults ||
    useCheckoutStateSelector(LodgingSelectors.getSelectedRoomProduct)?.bedTypes
      .occupancy ||
    0;

  const searchedGuestCount = useCheckoutStateSelector(
    LodgingSelectors.getGuestCount
  );

  const contactInfoValidated = useCheckoutStateSelector(
    ContactSelectors.getContactInformationValidated
  );

  const numPassengersAlertDismissed = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getNumPassengerAlertDismissed
  );

  const selectedTravelerCountExceedsRoomCapacity =
    selectedPassengerCount > 0 &&
    selectedRoomCapacity > 0 &&
    selectedPassengerCount > selectedRoomCapacity;
  const selectedTravelerCountLessThanSearched =
    selectedPassengerCount > 0 &&
    !numPassengersAlertDismissed &&
    selectedPassengerCount < searchedGuestCount;

  const canContinue =
    isMobile ||
    (!(
      selectedTravelerCountExceedsRoomCapacity ||
      selectedTravelerCountLessThanSearched
    ) &&
      passengersValidated &&
      contactInfoValidated);

  const handleContinue = () => {
    if (canContinue) {
      setValidationErrorTypes?.([]);
      /*
      desktop is on flight passengers step here, which expects the "next" event
      to be sent to the passengers child machine
    */
      isMobile ? send(Event.NEXT) : passengerChildMachineSend(Event.NEXT);
    } else {
      if (selectedTravelerCountExceedsRoomCapacity) {
        passengerChildMachineSend({
          type: FlightPassengerEventTypes.SET_PASSENGER_INFORMATION_ERROR,
          error: {
            type: PassengerErrorModalTypes.SelectedPassengersExceedRoomCapacity,
            data: {},
          },
        });
      } else if (selectedTravelerCountLessThanSearched) {
        passengerChildMachineSend({
          type: FlightPassengerEventTypes.SET_PASSENGER_INFORMATION_ERROR,
          error: {
            type: PassengerErrorModalTypes.SearchPassengerNumNotReached,
            data: {},
          },
        });
      } else {
        if (!passengersValidated && !contactInfoValidated) {
          setValidationErrorTypes?.(["flight-travelers", "contact-info"]);
        } else if (!passengersValidated) {
          setValidationErrorTypes?.(["flight-travelers"]);
        } else if (!contactInfoValidated) {
          setValidationErrorTypes?.(["contact-info"]);
        }
      }
    }
  };

  const handleBack = () => {
    send(Event.PREVIOUS);
  };

  const onContactInfoChange = (contactInfo: IContactInfo) => {
    send({ type: Event.CHANGE, contactInfo });
  };

  const goToContactInfo = () => [
    send({ type: Event.GO_TO_CONTACT_INFORMATION }),
  ];

  React.useEffect(() => {
    if (canContinue) {
      setValidationErrorTypes?.([]);
    }
  }, [canContinue]);

  React.useEffect(() => {
    if (passengersValidated) {
      setValidationErrorTypes?.(
        validationErrorTypes?.filter((error) => error !== "flight-travelers") ||
          []
      );
    }
  }, [passengersValidated]);

  React.useEffect(() => {
    if (contactInfoValidated) {
      setValidationErrorTypes?.(
        validationErrorTypes?.filter((error) => error !== "contact-info") || []
      );
    }
  }, [contactInfoValidated]);

  if (isMobile)
    return (
      <ContactInfoWorkflow
        titles={{
          contactInfoTitle: CONTACT_INFO_TITLE_MOBILE,
          contactInfoSubtitle: CONTACT_INFO_SUBTITLE,
          buttonTitle: CONTINUE_TEXT,
        }}
        progressStep={
          parentState === ParentState.review
            ? ContactInfoStep.Main
            : ContactInfoStep.ContactInfoForm
        }
        setProgressStep={(step) => {
          if (parentState === ParentState.review) {
            switch (step) {
              case ContactInfoStep.ContactInfoForm:
                goToContactInfo();
                break;
              case ContactInfoStep.Main:
              default:
                break;
            }
          }
        }}
        contactInfo={contactInfo}
        onContactInfoChange={onContactInfoChange}
        isMobile
        onContinueClick={handleContinue}
        onGoBack={handleBack}
        className="packages-contact-info-workflow-root"
        mobileHeaderElement={<PackagesPriceBreakdown isMobile dropdown />}
        customHeader={
          <Box className="packages-book-mobile-contact-info-header">
            <Typography
              className="mobile-contact-info-header-title"
              variant="h3"
            >
              {CONTACT_INFO_TITLE_MOBILE}
            </Typography>
            <Typography className="mobile-contact-info-header-subtitle">
              {CONTACT_INFO_SUBTITLE}
            </Typography>
            <hr className="heading-rule" />
          </Box>
        }
        showMobilePopoverTransition={false}
        contactFormOnly={contactFormOnly}
      />
    );

  return (
    <>
      <ContactInfoForm
        disabled={
          ![
            ParentState.contactInformation,
            ParentState.passengerInformation,
          ].includes(parentState)
        }
        title={CONTACT_INFO_TITLE}
        subtitle={CONTACT_INFO_SUBTITLE}
        contactInfo={contactInfo}
        onContactInfoChange={onContactInfoChange}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
        loading={contactInfoLoading}
        className={clsx("packages-contact-info-form", {
          error: validationErrorTypes?.includes("contact-info"),
        })}
      />
      <Typography
        className={clsx("packages-contact-info-notice", {
          error: validationErrorTypes?.some((error) =>
            ["contact-info", "flight-travelers"].includes(error)
          ),
        })}
      >
        {CONTACT_INFO_NOTICE}
      </Typography>
      <ActionButton
        onClick={handleContinue}
        message={
          childState === GenericChildState.saving ? SAVING_TEXT : CONTINUE_TEXT
        }
        className="packages-continue-button"
        disabled={childState === GenericChildState.saving}
      />
    </>
  );
};
