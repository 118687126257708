import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";

import { PackageItineraryReview } from "./component";
import { selectedFareDetailsSelector } from "../flight-shop/reducer";
import { finalizePackage } from "./actions/actions";
import { getSelectedPackageByLodgingId } from "../hotel-shop/reducer";
import {
  getDepartureDate,
  getNightCount,
  getReturnDate,
} from "../search/reducer";
import { getTravelersCount } from "../availability/reducer";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../rewards/reducer";
import { setPackagesFlightShopProgress } from "../flight-shop/actions/actions";
import { getFinalizePackageCallState } from "./reducer/selectors";
import { PackageFareDetails } from "redmond";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedPackageByLodging: getSelectedPackageByLodgingId(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    travelersCount: getTravelersCount(state),
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    finalizePackageCallState: getFinalizePackageCallState(state),
    selectedFareDetails: selectedFareDetailsSelector(state) as
      | PackageFareDetails
      | undefined,
  };
};

export const mapDispatchToProps = {
  finalizePackage,
  setPackagesFlightShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PackageItineraryReviewConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackageItineraryReview = withRouter(
  connector(PackageItineraryReview)
);
