import React from "react";
import "./styles.scss";
import { RouteComponentProps } from "react-router-dom";
import {
  ActionButton,
  B2BSpinner,
  HotelPriceBreakdown,
  Icon,
  IconName,
  LoadingIndicator,
} from "halifax";
import { PackagesPriceBreakdownConnectorProps } from "./container";
import {
  CardPaymentSelectors,
  getParentState,
  ParentState,
  RewardsPaymentSelectors,
  useCheckoutState,
  useCheckoutStateSelector,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import {
  getBreakdownTotal,
  getPriceBreakdownPricingLineItems,
  getPriceBreakdownSummaryLineItems,
  getPriceBreakdownTotalLineItems,
} from "../../state/selectors/common";
import { Box, Link, Popover, Typography } from "@material-ui/core";
import {
  CHECKOUT_BREAKDOWN_HEADING,
  TRIP_TOTAL_BREAKDOWN_TEXT,
} from "./textConstants";
import { CONFIRM_AND_BOOK_CTA_TEXT } from "../textConstants";
import { formatCurrency, formatRewards } from "../../state/utils";
import { DesktopPackageBookValidationError } from "../DesktopPackagesBookWorkflow";
import { CART_UPDATE_TEXT } from "../MobilePackagesBookWorkflow/textConstants";

export interface PackagesPriceBreakdownProps
  extends PackagesPriceBreakdownConnectorProps,
    RouteComponentProps {
  isMobile?: boolean;
  dropdown?: boolean;
  setValidationErrorTypes?: (
    types: DesktopPackageBookValidationError[]
  ) => void;
}

export const PackagesPriceBreakdown = ({
  isMobile,
  dropdown,
  setValidationErrorTypes,
}: PackagesPriceBreakdownProps) => {
  const [openPopover, setOpenPopover] = React.useState<boolean>(false);

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const pricingLineItems = useCheckoutStateSelector(
    getPriceBreakdownPricingLineItems
  );
  const totalLineItems = useCheckoutStateSelector(
    getPriceBreakdownTotalLineItems
  );
  const summaryLineItems = useCheckoutStateSelector(
    getPriceBreakdownSummaryLineItems
  );
  const isCostCoveredBySelectedPaymentMethods = useCheckoutStateSelector(
    CardPaymentSelectors.getIsCostCoveredBySelectedPaymentMethods
  );
  const breakdownTotal = useCheckoutStateSelector(getBreakdownTotal);
  const selectedRewardsAccountId = useCheckoutStateSelector(
    RewardsPaymentSelectors.getSelectedAccountId
  );
  const selectedPaymentMethodId = useCheckoutStateSelector(
    CardPaymentSelectors.getSelectedPaymentMethodId
  );
  const isCreditCardPaymentRequired = useCheckoutStateSelector(
    CardPaymentSelectors.getIsCreditCardPaymentRequired
  );

  const canContinue =
    isMobile ||
    (isCostCoveredBySelectedPaymentMethods && !!selectedRewardsAccountId);

  const handleContinue = () => {
    if (canContinue) {
      setValidationErrorTypes?.([]);
      send(Event.NEXT);
    } else {
      setValidationErrorTypes?.(
        (() => {
          switch (true) {
            case !selectedRewardsAccountId:
              return ["rewards"];
            case isCreditCardPaymentRequired && !selectedPaymentMethodId:
              return ["card"];
            default:
              return [];
          }
        })()
      );
    }
  };

  const togglePopover = () => {
    setOpenPopover((open) => !open);
  };

  const handleClose = () => {
    setOpenPopover(false);
  };

  if (isMobile) {
    if (!breakdownTotal) return null;

    const mobileContent = (
      <Box className="packages-price-breakdown-wrapper mobile">
        <Typography variant="h3" className="price-breakdown-heading">
          {CHECKOUT_BREAKDOWN_HEADING}
        </Typography>
        <HotelPriceBreakdown
          pricingItems={pricingLineItems}
          totalItems={totalLineItems}
          summaryItems={summaryLineItems}
          isMobile
        />
      </Box>
    );

    return dropdown ? (
      <>
        <Link
          className="packages-price-breakdown-dropdown-root"
          onClick={togglePopover}
          component={"button"}
        >
          <Box className="total-price-label-container">
            <Typography
              className="trip-total"
              variant="subtitle2"
            >{`${TRIP_TOTAL_BREAKDOWN_TEXT}:`}</Typography>
            <Typography className="value fiat" variant="subtitle1">
              {formatCurrency(breakdownTotal.fiat)}
            </Typography>
            {breakdownTotal.rewards && (
              <>
                <Typography className="separator" variant="subtitle2">
                  /
                </Typography>
                <Typography className="value rewards" variant="subtitle2">
                  {formatRewards(breakdownTotal.rewards)}
                </Typography>
              </>
            )}
          </Box>

          <Icon name={IconName.Dropdown} className="total-price-label-icon" />
        </Link>
        <Popover
          className="packages-checkout-mobile-breakdown-popover"
          open={openPopover}
          PaperProps={{
            className: "price-breakdown-popover-paper",
          }}
          hideBackdrop
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          disableEnforceFocus
        >
          {mobileContent}
        </Popover>
      </>
    ) : (
      mobileContent
    );
  }
  return (
    <Box className="packages-price-breakdown-wrapper">
      <Typography variant="h3" className="price-breakdown-heading">
        {CHECKOUT_BREAKDOWN_HEADING}
      </Typography>

      {[ParentState.cartUpdate, ParentState.cartUpdateForSeats].includes(
        parentState
      ) && (
        <Box className="packages-price-breakdown-loading-wrapper">
          <LoadingIndicator
            className="packages-checkout-loading-indicator"
            indicatorSize="small"
            indicator={B2BSpinner}
            message={CART_UPDATE_TEXT}
          />
        </Box>
      )}

      <HotelPriceBreakdown
        pricingItems={pricingLineItems}
        totalItems={totalLineItems}
        summaryItems={summaryLineItems}
        tooltipClassName="packages-price-breakdown-tooltip"
      />

      {[ParentState.cardPayment, ParentState.cartFulfill].includes(
        parentState
      ) && (
        <ActionButton
          onClick={handleContinue}
          message={CONFIRM_AND_BOOK_CTA_TEXT}
          className="packages-continue-button book price-breakdown"
          disabled={parentState === ParentState.cartFulfill}
        />
      )}
    </Box>
  );
};
