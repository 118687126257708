import { FrequentFlyerMap, ICorpPerson, IPerson } from "redmond";
import { IHotelLoyaltyProgram } from "./hotelLoyaltyProgramList";

const getNewHotelLoyalty = (
  traveler: IPerson,
  loyaltyProgram: IHotelLoyaltyProgram | null,
  newLoyaltyNumber?: string
): { [key: string]: any } | undefined => {
  if (newLoyaltyNumber === undefined || !loyaltyProgram) {
    return undefined;
  }

  const newHotelLoyalty = { ...traveler.hotelLoyalty };

  if (newLoyaltyNumber === "") {
    const { [loyaltyProgram.loyaltyProgramCode]: _, ...rest } = newHotelLoyalty;
    return rest;
  } else {
    return {
      ...newHotelLoyalty,
      [loyaltyProgram.loyaltyProgramCode]: { value: newLoyaltyNumber },
    };
  }
};

const getNewFrequentFlyerNumbers = (
  traveler: IPerson,
  newFlyerMap?: FrequentFlyerMap
): FrequentFlyerMap | undefined => {
  if (!newFlyerMap) {
    return undefined;
  }
  return Object.keys(newFlyerMap).reduce(
    (acc, airline) => {
      if (newFlyerMap[airline].value === "") {
        const { [airline]: _, ...rest } = acc;
        return rest;
      } else {
        return {
          ...acc,
          [airline]: { value: newFlyerMap[airline].value },
        };
      }
    },
    { ...traveler.frequentFlyer }
  );
};

export const getUpdatedTraveler = (
  traveler: IPerson,
  loyaltyProgram: IHotelLoyaltyProgram | null,
  newLoyaltyNumber?: string,
  newEmail?: string,
  newFlyerMap?: FrequentFlyerMap
) => {
  const newHotelLoyalty = getNewHotelLoyalty(
    traveler,
    loyaltyProgram,
    newLoyaltyNumber
  );

  const newFrequentFlyerNumbers = getNewFrequentFlyerNumbers(
    traveler,
    newFlyerMap
  );

  const updatedTraveler = {
    ...traveler,
    ...(newHotelLoyalty && { hotelLoyalty: newHotelLoyalty }),
    ...(newEmail && { emailAddress: newEmail }),
    ...(newFrequentFlyerNumbers && { frequentFlyer: newFrequentFlyerNumbers }),
  } satisfies IPerson | ICorpPerson;

  return updatedTraveler;
};
