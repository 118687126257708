import React, { useState, useEffect } from "react";
import {
  ActionLink,
  CloseButtonIcon,
  MobilePopoverCard,
  PassengerCountPickerType,
} from "halifax";
import { LocationSearch } from "../../../search/components/MobilePackagesSearchControl/components";
import { MobileEditLocationModalConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import {
  FareclassOptionFilter,
  IIdLodgings,
  IPassengerCounts,
  IResult,
  ITripTerminus,
  SliceStopCountFilter,
} from "redmond";
import * as textConstants from "./textConstants";
import { Box, Typography } from "@material-ui/core";
import { PATH_HOTELS_AVAILABILITY } from "../../../../utils/paths";
import { transformToStringifiedAvailabilityQuery } from "../../utils/queryStringHelpers";
import {
  setFareclassOptionFilter,
  setStopsOption,
} from "../../../search/actions/actions";

export interface IMobileEditLocationModalProps
  extends MobileEditLocationModalConnectorProps,
    RouteComponentProps {
  openLocationModal: boolean;
  setOpenLocationModal: (arg: boolean) => void;
  setOpenCalendarModal: (arg: boolean) => void;
}

export const MobileEditLocationsModal = (
  props: IMobileEditLocationModalProps
) => {
  const {
    openLocationModal,
    setOpenLocationModal,
    history,
    origin,
    destination,
    travelers,
    adultsCount,
    children,
    infants,
    departureDate,
    returnDate,
    setOrigin,
    setDestination,
    setOpenCalendarModal,
    resetPackagesAvailabilityCallState,
    setTravelers,
    stopsOption,
    fareClassFilter,
  } = props;

  const [currOrigin, setCurrOrigin] = useState<ITripTerminus | null>(origin);
  const [currDestination, setCurrDestination] = useState<IResult | null>(
    destination
  );
  const [currTravelersCount, setCurrTravelersCount] =
    useState<PassengerCountPickerType>({
      ...travelers,
      childrenCount: travelers.children.length,

      infantsInSeatCount: travelers.infants.filter(({ inSeat }) => inSeat)
        .length,
      infantsOnLapCount: travelers.infants.filter(({ inSeat }) => !inSeat)
        .length,
    });

  const [currFareClassFilter, setCurrFareClassFilter] =
    useState<FareclassOptionFilter>(fareClassFilter);
  const [currStopsOption, setCurrStopsOptions] =
    useState<SliceStopCountFilter>(stopsOption);

  useEffect(() => {
    setCurrOrigin(origin);
  }, [origin]);

  useEffect(() => {
    setCurrDestination(destination);
  }, [destination]);

  useEffect(() => {
    setCurrTravelersCount({
      ...travelers,
      childrenCount: travelers.children.length,

      infantsInSeatCount: travelers.infants.filter(({ inSeat }) => inSeat)
        .length,
      infantsOnLapCount: travelers.infants.filter(({ inSeat }) => !inSeat)
        .length,
    });
  }, [adultsCount, children, infants]);

  useEffect(() => {
    setCurrFareClassFilter(fareClassFilter);
  }, [fareClassFilter]);

  useEffect(() => {
    setCurrStopsOptions(stopsOption);
  }, [stopsOption]);

  const handleContinue = (passCountsToUse?: IPassengerCounts) => {
    const isMissingDates = !departureDate || !returnDate;

    setOpenLocationModal(false);
    setOrigin(currOrigin);
    setDestination(currDestination);
    if (isMissingDates) {
      setOpenCalendarModal(true);
    }
    setFareclassOptionFilter(currFareClassFilter);
    setStopsOption(currStopsOption);

    const passengerCountChanged = // If user updated the num of travelers in the passenger count picker modal, do a new search on the modal CTA click
      passCountsToUse &&
      (adultsCount !== passCountsToUse?.adultsCount ||
        children.length !== passCountsToUse.childrenCount ||
        infants.filter(({ inSeat }) => inSeat).length !==
          passCountsToUse.infantsInSeatCount ||
        infants.filter(({ inSeat }) => !inSeat).length !==
          passCountsToUse.infantsOnLapCount);

    passengerCountChanged &&
      setTravelers({
        adultsCount:
          passCountsToUse?.adultsCount ??
          (currTravelersCount as IPassengerCounts).adultsCount,
        children:
          passCountsToUse?.childrenAges ??
          ((currTravelersCount as IPassengerCounts).childrenAges || []),
        infants:
          passCountsToUse?.infants ??
          ((currTravelersCount as IPassengerCounts).infants || []),
      });

    const isReadyToSearch =
      !!currOrigin &&
      !!currDestination &&
      !!departureDate &&
      !!returnDate &&
      !!(currTravelersCount as IPassengerCounts).adultsCount;

    resetPackagesAvailabilityCallState();
    isReadyToSearch &&
      history.push(
        `${PATH_HOTELS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
          currOrigin?.id?.code?.code,
          (currDestination?.id as IIdLodgings).lodgingSelection.searchTerm,
          (currDestination?.id as IIdLodgings).lodgingSelection.placeId,
          departureDate,
          returnDate,
          passCountsToUse?.adultsCount ??
            (currTravelersCount as IPassengerCounts).adultsCount,
          passCountsToUse?.childrenAges ??
            ((currTravelersCount as IPassengerCounts).childrenAges || []),
          passCountsToUse?.infants ??
            ((currTravelersCount as IPassengerCounts).infants || []),
          currStopsOption,
          currFareClassFilter
        )}`
      );
  };

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">Packages</Typography>
        <ActionLink
          className="modal-close-button"
          onClick={() => {
            setOpenLocationModal(false);
          }}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };

  return openLocationModal ? (
    <MobilePopoverCard
      open={openLocationModal}
      onClose={() => {
        setOpenLocationModal(false);
      }}
      headerElement={<MobileSearchPopoverHeader />}
      className={"mobile-flight-shop-edit-location-popup"}
      fullScreen
    >
      <LocationSearch
        history={history}
        localOrigin={currOrigin}
        setLocalOrigin={setCurrOrigin}
        setLocalDestination={setCurrDestination}
        localDestination={currDestination}
        onComplete={handleContinue}
        searchOnContinue
        paxCountApplyButtonText={textConstants.SEARCH_AGAIN}
        onPaxCountButtonClick={handleContinue}
        localFareClassFilter={currFareClassFilter}
        setLocalFareClassFilter={setCurrFareClassFilter}
        localStopsOption={currStopsOption}
        setLocalStopsOption={setCurrStopsOptions}
      />
    </MobilePopoverCard>
  ) : null;
};
