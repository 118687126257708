import React, { useContext, useMemo } from "react";
import "./styles.scss";
import {
  HotelAvailabilityFilter,
  ActionButton,
  GenericDropdown,
  StarRatingsSelection,
  getCurrencySymbol,
  useDeviceTypes,
  Icon,
  IconName,
  AmenitiesSelection,
  FreeCancelFilter,
  HotelPriceSelection,
  PolicyStatusSelection,
  LoyaltyProgramsSelection,
  SwitchButton,
  mapAmenitiesToTopAmenities,
  NotificationBanner,
  BannerSeverity,
  StayTypeFilter,
} from "halifax";
import { RouteComponentProps } from "react-router";
import { debounce, isEqual } from "lodash-es";
import clsx from "clsx";

import { initialFilterState, initialState } from "../../reducer/index";
import {
  HotelAvailabilityCallState,
  HotelAvailabilitySortOption,
} from "../../reducer/state";
import { AvailabilityFilterConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import {
  addTrackingProperties,
  AVAILABLE,
  boostPremierCollectionExperiment,
  CONTROL,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
  FREE_BREAKFAST_CANCEL,
  getExperimentDefVariant,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  LODGING_PROMOTIONS_AVAILABLE,
  LODGING_PROMOTIONS_VARIANTS,
  LODGING_PROMOTIONS,
  useExperiments,
  STAYS_STAY_TYPE_TOOLTIP_VARIANTS,
  STAYS_STAY_TYPE_TOOLTIP,
  STAYS_TOOLTIP_AVAILABLE_IF_HOMES,
  STAYS_TOOLTIP_AVAILABLE_IF_HOME_IN_TOP_10_RESULTS,
} from "../../../../context/experiments";
import { useFilterLabelValues } from "./useFilterLabelValues";
import { Box, Tooltip, Typography } from "@material-ui/core";
import { sortOptions } from "../MobileLocationSelection/component";
import {
  AppliedHotelFilterProperties,
  APPLIED_HOTEL_FILTER,
  CLEAR_ALL_FILTERS,
} from "redmond";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import config from "../../../../../../../cap1-application/b2b-base/src/utils/config";
import { isCorpTenant, useShowPolicyBanner } from "@capone/common";
import {
  getPremiumStaysToggleText,
  PREMIUM_STAYS_LABEL,
  PREMIUM_STAYS_TOOLTIP_TEXT,
} from "./textConstants";
import { shouldShowPremiumStaysFilter } from "../utilities";
import { StayTypeGenericDropdownPopper } from "../StayTypeGenericDropdownPopper/component";
import { ClientContext } from "../../../../App";

export interface IAvailabilityFilterProps
  extends AvailabilityFilterConnectorProps,
    RouteComponentProps {}

export const AvailabilityFilter = (props: IAvailabilityFilterProps) => {
  const {
    locationLabel,
    amenities,
    bannerMessage,
    setAmenitiesFilter,
    starRatings,
    setStarRatingsFilter,
    maxPrice,
    setMaxPriceFilter,
    minMaxPriceRange,
    currency,
    hotelName,
    stayTypes,
    setHotelNameFilter,
    hasChangedMaxPriceFilter,
    hasChangedAmenitiesFilter,
    hasChangedStarRatingsFilter,
    hasChangedHotelAvailabilityStayTypeFilter,
    setStayTypeFilter,
    sortOption,
    setHotelAvailabilitySortOption,
    hasFreeCancelFilter,
    setFreeCancelFilter,
    hasHotelsOnSaleFilter,
    setHotelsOnSaleFilter,
    isInPolicy,
    setPolicyFilter,
    loyaltyPrograms,
    setLoyaltyProgramsFilter,
    userHotelPreferencesCallState,
    hasUserSetHotelPreferences,
    shouldApplyUserHotelPreferences,
    setApplyUserHotelPreferences,
    userHotelPreferences,
    appliedFilterCount,
    premiumStaysOnly,
    setPremiumStaysOnly,
    premiumStaysLodgingCount,
    hasChangedPolicyFilter,
    hotelAvailabilityCallState,
    availabilityResultsHasHomesAndHotels,
    availabilityResultsFirstIndexOfHomeResult,
  } = props;
  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const stayTypeDropdownButtonRef = React.useRef<HTMLButtonElement | null>(
    null
  );
  const [localMaxPriceFilter, setLocalMaxPriceFilter] =
    React.useState<number>(maxPrice);

  const expState = useExperiments();

  const isFreeBreakfastCancelExperiment =
    getExperimentVariant(expState.experiments, FREE_BREAKFAST_CANCEL) ===
    AVAILABLE;

  const lodgingPromotions = getExperimentVariantCustomVariants(
    expState.experiments,
    LODGING_PROMOTIONS,
    LODGING_PROMOTIONS_VARIANTS
  );
  const isLodgingPromotionsExperiment = useMemo(
    () => lodgingPromotions === LODGING_PROMOTIONS_AVAILABLE,
    [lodgingPromotions]
  );

  const { policies } = useContext(ClientContext);
  const showPolicyFilter = useShowPolicyBanner(policies);

  const stayTypeFilterTooltipVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    STAYS_STAY_TYPE_TOOLTIP,
    STAYS_STAY_TYPE_TOOLTIP_VARIANTS
  );

  const shouldShowStayTypeTooltipBasedOnExperiment =
    stayTypeFilterTooltipVariant == STAYS_TOOLTIP_AVAILABLE_IF_HOMES
      ? availabilityResultsHasHomesAndHotels
      : stayTypeFilterTooltipVariant ==
        STAYS_TOOLTIP_AVAILABLE_IF_HOME_IN_TOP_10_RESULTS
      ? availabilityResultsFirstIndexOfHomeResult >= 0 &&
        availabilityResultsFirstIndexOfHomeResult < 10
      : false;

  const stayTypeModalPopperNotSeen =
    localStorage.getItem("stayTypeFilterAwarenessPopperSeen") == null;

  const [stayTypeModalPopperOpen, setStayTypeModalPopperOpen] =
    React.useState<boolean>(false);

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  const stayTypeToAbbreviatedTitle = {
    Hotels: "Hotels",
    Homes: "Homes",
  };

  const boostPremierCollectionVariant = getExperimentDefVariant(
    expState.experiments,
    boostPremierCollectionExperiment
  );

  const showPremiumStaysFilter = shouldShowPremiumStaysFilter(
    premiumStaysLodgingCount.all,
    boostPremierCollectionVariant
  );

  React.useEffect(() => {
    setLocalMaxPriceFilter(maxPrice);
  }, [maxPrice]);

  const isCorporateTenant = isCorpTenant(config.TENANT);
  // TODO(corp): temporarily hide hotel loyalty filter
  const showLoyaltyFilter = false;

  React.useEffect(() => {
    const showStayTypeFilterModal =
      shouldShowStayTypeTooltipBasedOnExperiment && stayTypeModalPopperNotSeen;
    setStayTypeModalPopperOpen(showStayTypeFilterModal);
  }, [shouldShowStayTypeTooltipBasedOnExperiment, stayTypeModalPopperNotSeen]);

  const closeStayTypeFilterAwarenessPopperHandler = () => {
    setStayTypeModalPopperOpen(false);
    localStorage.setItem("stayTypeFilterAwarenessPopperSeen", "true");
  };

  const readyToReset =
    !isEqual(amenities, initialFilterState.amenities) ||
    !isEqual(starRatings, initialFilterState.starRatings) ||
    !isEqual(isInPolicy, initialFilterState.isInPolicy) ||
    !isEqual(loyaltyPrograms, initialFilterState.loyaltyPrograms) ||
    premiumStaysOnly !== initialFilterState.showPremiumStaysOnly ||
    maxPrice !== initialFilterState.maxPrice ||
    !isEqual(hotelName, initialFilterState.hotelName) ||
    !isEqual(stayTypes, initialFilterState.stayTypes);

  const { ratingLabel, amenitiesLabel } = useFilterLabelValues({
    starRatings,
    amenities,
  });

  const setMaxPriceFilterHandler = (maxPrice: number) => {
    setMaxPriceFilter(maxPrice);
    const properties: AppliedHotelFilterProperties = {
      filter_type: "price",
    };
    trackEvent({
      eventName: APPLIED_HOTEL_FILTER,
      properties,
    });
  };

  const setMaxPriceFilterDebounced = React.useMemo(
    () => debounce(setMaxPriceFilterHandler, 150),
    []
  );

  React.useEffect(() => {
    if (
      ![
        HotelAvailabilityCallState.InitialSearchCallInProcess,
        HotelAvailabilityCallState.NotCalled,
      ].includes(hotelAvailabilityCallState) &&
      shouldApplyUserHotelPreferences &&
      userHotelPreferences &&
      setApplyUserHotelPreferences &&
      (!isEqual(
        userHotelPreferences.amenities,
        mapAmenitiesToTopAmenities(amenities).map(
          (topAmenity) => topAmenity.value
        )
      ) ||
        !isEqual(userHotelPreferences.starRatings, starRatings) ||
        userHotelPreferences.freeCancellationOnly !== hasFreeCancelFilter)
    ) {
      setApplyUserHotelPreferences(false);
    }
  }, [
    starRatings,
    amenities,
    hasFreeCancelFilter,
    shouldApplyUserHotelPreferences,
    userHotelPreferences,
    userHotelPreferencesCallState,
    hotelAvailabilityCallState,
  ]);

  const renderDropdownButtons = () => {
    return (
      <>
        <GenericDropdown
          buttonClassName={clsx(
            "hotel-availability-dropdown",
            "star-ratings",
            "b2b-shop-filter",
            "enhanced-hotel-filters",
            {
              "has-value": hasChangedStarRatingsFilter,
            }
          )}
          popoverClassName={clsx(
            "hotel-availability-star-ratings-dropdown-popover",
            "b2b",
            "enhanced-hotel-filters"
          )}
          ariaLabel={`${textConstants.RATING_TEXT} filter`}
          dropdownLabel={
            <>
              <strong>
                {textConstants.RATING_TEXT}
                {hasChangedStarRatingsFilter ? ": " : ""}
              </strong>
              {hasChangedStarRatingsFilter ? ratingLabel : ""}
            </>
          }
          dropdownIcon={
            hasChangedStarRatingsFilter ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setStarRatingsFilter([]);
                }}
              >
                <Icon name={IconName.XCircle} />
              </div>
            ) : undefined
          }
          dropdownContent={
            <StarRatingsSelection
              className="desktop-hotel-availability-star-ratings"
              starRatings={starRatings}
              setStarRatings={(starRatings) =>
                setStarRatingsFilter(starRatings)
              }
            />
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        />
        <GenericDropdown
          buttonClassName={clsx(
            "hotel-availability-dropdown",
            "amenities",
            "b2b-shop-filter",
            "enhanced-hotel-filters",
            {
              "has-value": hasChangedAmenitiesFilter,
            }
          )}
          popoverClassName={clsx(
            "hotel-availability-amenities-dropdown-popover",
            "b2b",
            "enhanced-hotel-filters"
          )}
          ariaLabel={`${textConstants.AMENITIES_FILTER_LABEL} filter`}
          dropdownLabel={
            <>
              <strong>
                {textConstants.AMENITIES_FILTER_LABEL}
                {hasChangedAmenitiesFilter ? ": " : ""}
              </strong>
              {hasChangedAmenitiesFilter ? amenitiesLabel : ""}
            </>
          }
          dropdownIcon={
            hasChangedAmenitiesFilter ? (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setAmenitiesFilter([]);
                }}
              >
                <Icon name={IconName.XCircle} />
              </div>
            ) : undefined
          }
          dropdownContent={
            <AmenitiesSelection
              amenities={amenities}
              setAmenities={setAmenitiesFilter}
              useTopAmenities
            />
          }
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        />
        {isFreeBreakfastCancelExperiment ? (
          <GenericDropdown
            buttonClassName={clsx(
              "hotel-availability-dropdown",
              "free-cancel",
              "b2b-shop-filter",
              "enhanced-hotel-filters",
              {
                "has-value": hasFreeCancelFilter,
              }
            )}
            popoverClassName={clsx(
              "hotel-availability-price-dropdown-popover",
              "b2b",
              "enhanced-hotel-filters"
            )}
            ariaLabel={`${textConstants.CANCELLATION} filter`}
            dropdownLabel={
              <>
                <strong>
                  {textConstants.CANCELLATION}
                  {hasFreeCancelFilter ? ":" : ""}
                </strong>
                {hasFreeCancelFilter ? ` Free` : ""}
              </>
            }
            dropdownIcon={
              hasFreeCancelFilter ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setFreeCancelFilter(false);
                  }}
                >
                  <Icon name={IconName.XCircle} />
                </div>
              ) : undefined
            }
            dropdownContent={
              <>
                <FreeCancelFilter
                  boldText
                  hasFreeCancelFilter={hasFreeCancelFilter}
                  setFreeCancelFilter={setFreeCancelFilter}
                />
              </>
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          />
        ) : (
          <GenericDropdown
            buttonClassName={clsx(
              "hotel-availability-dropdown",
              "price",
              "b2b-shop-filter",
              "enhanced-hotel-filters",
              {
                "has-value": hasChangedMaxPriceFilter,
              }
            )}
            popoverClassName={clsx(
              "hotel-availability-price-dropdown-popover",
              "b2b",
              "enhanced-hotel-filters"
            )}
            ariaLabel={`${textConstants.PRICE_PER_NIGHT_TEXT} filter`}
            dropdownLabel={
              <>
                <strong>
                  {textConstants.PRICE_FILTER_LABEL}
                  {hasChangedMaxPriceFilter ? ":" : ""}
                </strong>
                {hasChangedMaxPriceFilter
                  ? ` ${getCurrencySymbol(currency)}${maxPrice}`
                  : ""}
              </>
            }
            dropdownIcon={
              hasChangedMaxPriceFilter ? (
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setMaxPriceFilter(initialFilterState.maxPrice);
                  }}
                >
                  <Icon name={IconName.XCircle} />
                </div>
              ) : undefined
            }
            dropdownContent={
              <>
                <HotelPriceSelection
                  className="desktop-hotel-availability-price enhanced-hotel-filters"
                  title={textConstants.PRICE_FILTER_TITLE}
                  subtitle={textConstants.PRICE_FILTER_SUBTITLE}
                  setHotelMaxPrice={setMaxPriceFilter}
                  hotelPriceRange={minMaxPriceRange || { min: 0, max: 0 }}
                  hotelMaxPrice={Math.ceil(minMaxPriceRange?.max ?? 0)}
                  currencySymbol={getCurrencySymbol(currency)}
                  type="max"
                  alwaysShowTooltip
                  showMinLabel
                  showClearButton
                  reset={() => setMaxPriceFilter(initialFilterState.maxPrice)}
                />
              </>
            }
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
          />
        )}
        {availabilityResultsHasHomesAndHotels && (
          <>
            <GenericDropdown
              buttonClassName={clsx(
                "hotel-availability-dropdown",
                "stay-type",
                "b2b-shop-filter",
                "enhanced-hotel-filters",
                {
                  "has-value": hasChangedHotelAvailabilityStayTypeFilter,
                }
              )}
              popoverClassName={clsx(
                "hotel-availability-stay-type-dropdown-popover",
                "b2b",
                "enhanced-hotel-filters"
              )}
              ariaLabel={`${textConstants.PROPERTY_TYPE_TEXT} filter`}
              dropdownLabel={
                <>
                  <strong>
                    {textConstants.PROPERTY_TYPE_TEXT}
                    {hasChangedHotelAvailabilityStayTypeFilter ? ": " : ""}
                  </strong>
                  {hasChangedHotelAvailabilityStayTypeFilter
                    ? stayTypes
                        .map((st) => stayTypeToAbbreviatedTitle[st])
                        .join(", ")
                    : ""}
                </>
              }
              dropdownIcon={
                hasChangedHotelAvailabilityStayTypeFilter ? (
                  <div
                    role="image"
                    aria-label={`${textConstants.PROPERTY_TYPE_TEXT} filter clear button`}
                    onClick={(e) => {
                      e.stopPropagation();
                      setStayTypeFilter([]);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" || e.key === " ") {
                        e.preventDefault();
                        e.stopPropagation();
                        setStayTypeFilter([]);
                      }
                    }}
                    tabIndex={0}
                  >
                    <Icon name={IconName.XCircle} />
                  </div>
                ) : undefined
              }
              dropdownContent={
                <StayTypeFilter
                  className="desktop-hotel-availability-stay-type"
                  stayTypes={stayTypes}
                  setStayTypes={(stayTypes) => setStayTypeFilter(stayTypes)}
                  showStayTypeFilter={availabilityResultsHasHomesAndHotels}
                />
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              ref={stayTypeDropdownButtonRef}
            />
            {stayTypeDropdownButtonRef.current && (
              <StayTypeGenericDropdownPopper
                isOpen={stayTypeModalPopperOpen}
                handleClose={closeStayTypeFilterAwarenessPopperHandler}
                anchorRef={stayTypeDropdownButtonRef}
              />
            )}
          </>
        )}
        {hotelName && (
          <Box className={clsx("hotel-filter-pill", "hotel-name")}>
            <Box className="filter-label-wrapper">
              <span className="filter-label-text">
                {textConstants.HOTEL_NAME_FILTER_LABEL(hotelName)}
              </span>
            </Box>
            <button onClick={() => setHotelNameFilter("")}>
              <Icon name={IconName.XCircle} />
            </button>
          </Box>
        )}
        {hasHotelsOnSaleFilter && (
          <Box className={clsx("hotel-filter-pill", "hotels-on-sale")}>
            <Box className="filter-label-wrapper">
              <span className="filter-label-text">
                {textConstants.HOTELS_ON_SALE}
              </span>
            </Box>
            <button onClick={() => setHotelsOnSaleFilter(false)}>
              <Icon name={IconName.XCircle} />
            </button>
          </Box>
        )}
        {isCorporateTenant && (
          <>
            {showLoyaltyFilter && (
              <GenericDropdown
                buttonClassName={clsx(
                  "hotel-availability-dropdown",
                  "b2b-shop-filter",
                  "enhanced-hotel-filters"
                )}
                popoverClassName={clsx(
                  "hotel-availability-loyalty-programs-dropdown-popover",
                  "b2b"
                )}
                ariaLabel="Hotel loyalty program filter"
                dropdownLabel={
                  <strong>{textConstants.HOTELS_LOYALTY_PROGRAMS}</strong>
                }
                dropdownContent={
                  <LoyaltyProgramsSelection
                    className="desktop-hotel-availability-loyalty-programs"
                    loyaltyPrograms={loyaltyPrograms}
                    setLoyaltyPrograms={(loyaltyPrograms: string[]) =>
                      setLoyaltyProgramsFilter(loyaltyPrograms)
                    }
                  />
                }
              />
            )}
            {showPolicyFilter && (
              <GenericDropdown
                buttonClassName={clsx(
                  "hotel-availability-dropdown",
                  "b2b-shop-filter",
                  "enhanced-hotel-filters",
                  {
                    "has-value": hasChangedPolicyFilter,
                  }
                )}
                popoverClassName={clsx(
                  "hotel-availability-policy-dropdown-popover",
                  "b2b"
                )}
                ariaLabel="Policy status filter"
                dropdownLabel={
                  <strong>{textConstants.HOTELS_POLICY_STATUS}</strong>
                }
                dropdownContent={
                  <PolicyStatusSelection
                    isInPolicy={isInPolicy}
                    setIsInPolicy={setPolicyFilter}
                  />
                }
                dropdownIcon={
                  hasChangedPolicyFilter ? (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        setPolicyFilter(false);
                      }}
                    >
                      <Icon name={IconName.XCircle} />
                    </div>
                  ) : undefined
                }
              />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      <ActionButton
        className={clsx(
          "hotel-availability-filter-action-button",
          "enhanced-hotel-filters",
          {
            mobile: matchesMobile,
          }
        )}
        defaultStyle="h4r-secondary"
        message={textConstants.SORT_DROPDOWN_LABEL_EXPERIMENT(
          appliedFilterCount
        )}
        onClick={() => setOpenModal(true)}
        icon={<Icon name={IconName.Settings} />}
      />
      {matchesDesktop && showPremiumStaysFilter && (
        <SwitchButton
          checked={premiumStaysOnly}
          onClick={(enable) =>
            setPremiumStaysOnly(enable, {
              location: "list",
              searched_market: locationLabel,
            })
          }
          content={{
            label: PREMIUM_STAYS_LABEL,
            content: (
              <Box className="premium-stays-toggle-content">
                <Icon name={IconName.StarIconWithLines} />{" "}
                <Typography className="premium-stays-toggle-text">
                  {getPremiumStaysToggleText(premiumStaysLodgingCount.filtered)}
                </Typography>
                <Tooltip
                  title={
                    <Typography variant="subtitle2" className="tooltip-text">
                      {PREMIUM_STAYS_TOOLTIP_TEXT}
                    </Typography>
                  }
                  classes={{
                    tooltip: "premium-stays-tooltip-text",
                  }}
                  placement="bottom"
                  tabIndex={0}
                >
                  <Icon name={IconName.InfoCircle} />
                </Tooltip>
              </Box>
            ),
          }}
          switchAtFront
          className="premium-stays-toggle-switch"
        />
      )}
      {matchesDesktop && renderDropdownButtons()}
      {bannerMessage != null && (
        <NotificationBanner
          className="hotel-availability-filter-banner standard-style"
          content={<Typography>{bannerMessage}</Typography>}
          severity={BannerSeverity.NOTICE}
        />
      )}
      <HotelAvailabilityFilter
        openModal={openModal}
        onClose={() => setOpenModal(false)}
        isInPolicy={isInPolicy}
        setIsInPolicy={setPolicyFilter}
        isMobile={matchesMobile}
        amenities={amenities}
        setAmenities={setAmenitiesFilter}
        starRatings={starRatings}
        setStarRatings={setStarRatingsFilter}
        premiumStays={
          showPremiumStaysFilter
            ? {
                value: premiumStaysOnly,
                set: (enable) =>
                  setPremiumStaysOnly(enable, {
                    location: "all_filters_modal",
                    searched_market: locationLabel,
                  }),
                premiumHotelsCount: premiumStaysLodgingCount.filtered,
              }
            : undefined
        }
        hotelPriceRange={minMaxPriceRange || { min: 0, max: 0 }}
        setHotelMaxPrice={setMaxPriceFilterDebounced}
        hotelMaxPrice={localMaxPriceFilter}
        currencySymbol={getCurrencySymbol(currency)}
        hotelName={hotelName}
        setHotelName={setHotelNameFilter}
        readyToReset={readyToReset}
        sortOptions={sortOptions.map((value) => ({
          value,
          label: textConstants.sortOptionLabel[value],
        }))}
        selectedSortOption={sortOption}
        setSelectedSortOption={(value) =>
          setHotelAvailabilitySortOption(value as HotelAvailabilitySortOption)
        }
        isFreeCancelExperiment={isFreeBreakfastCancelExperiment}
        setFreeCancelFilter={setFreeCancelFilter}
        hasFreeCancelFilter={hasFreeCancelFilter}
        isLodgingPromotionsExperiment={isLodgingPromotionsExperiment}
        setHotelsOnSaleFilter={setHotelsOnSaleFilter}
        hasHotelsOnSaleFilter={hasHotelsOnSaleFilter}
        defaultSortOption={initialState.sortOption}
        titles={textConstants.FILTER_MODAL_TITLES}
        loyaltyPrograms={loyaltyPrograms}
        setLoyaltyPrograms={setLoyaltyProgramsFilter}
        tenant={config.TENANT}
        showHotelPreferencesBanner={isCustomerProfileExperiment}
        userHotelPreferencesCallState={userHotelPreferencesCallState}
        userHasSetHotelPreferences={hasUserSetHotelPreferences}
        shouldApplyUserHotelPreferences={shouldApplyUserHotelPreferences}
        setShouldApplyUserHotelPreferences={setApplyUserHotelPreferences}
        userHotelPreferences={userHotelPreferences}
        onReset={() => {
          trackEvent({
            eventName: CLEAR_ALL_FILTERS,
            properties: addTrackingProperties(expState.trackingProperties),
          });
        }}
        showStayTypeFilter={availabilityResultsHasHomesAndHotels}
        stayTypes={stayTypes}
        setStayTypes={setStayTypeFilter}
        showPolicyFilter={showPolicyFilter}
      />
    </>
  );
};
