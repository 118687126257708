import {
  IPerson,
  ISelectedTrip,
  PersonId,
  SessionInfo,
  TripDetails,
} from "redmond";
import {
  IContactInfo,
  ParentState,
  getCartInitialContext,
  getAirChfarInitialContext,
  getIntialWalletContext,
  getInitialRewardsPaymentContext,
} from "@capone/checkout";
import { PackagesMachineContext } from "./types";

export const getInitialPackagesMachineContext =
  function (): PackagesMachineContext {
    return {
      ...getCartInitialContext(),
      platform: "desktop",
      redeemedPackageDiscountAmount: undefined,
      sessionInfo: {} as SessionInfo,
      flightSearch: {
        tripCategory: undefined,
        destination: null,
        origin: null,
        departureDate: null,
        returnDate: null,
        originCountryCode: "",
        destinationCountryCode: "",
      },
      flightShop: {
        tripDetails: {} as TripDetails,
        selectedTrip: {} as ISelectedTrip,
        shopPricingInfo: {
          fare: undefined,
        },
        departureLabel: "",
        returnLabel: undefined,
        airports: undefined,
        benchmarkSliceIds: [],
      },
      lodgingShop: {
        fromDate: undefined,
        untilDate: undefined,
        selectedLodging: undefined,
        selectedRoom: undefined,
        selectedRoomRateId: undefined,
      },
      common: {
        tripPricing: undefined,
      },
      [ParentState.cancelForAnyReason]: {
        cfarQuoteId: undefined,
        cfarOffers: undefined,
      },
      [ParentState.changeForAnyReason]: { ...getAirChfarInitialContext() },
      [ParentState.passengerInformation]: {
        selectedPassengerIds: [] as Array<PersonId>,
        selectedLapInfantIds: [] as Array<PersonId>,
        userPassengers: [] as Array<IPerson>,
        addPassport: true,
        infantOnlyError: false,
        visited: false,
      },
      [ParentState.lodgingPassengerInformation]: {
        selectedPassengerId: undefined,
      },
      [ParentState.contactInformation]: {
        contactInfo: {} as IContactInfo,
        visited: false,
      },
      [ParentState.passport]: {
        error: undefined,
      },
      [ParentState.seatSelection]: {
        selectedSeats: [],
        selectedSeatSegments: [],
        cheapestSeat: undefined,
        seatMapHtml: undefined,
        seatMapRequest: undefined,
        seatsInfo: undefined,
        seatMapAvailable: undefined,
        visited: false,
        skipSeatSelection: false,
      },
      [ParentState.review]: {
        visited: false,
      },
      // [ParentState.awardsPayment]: {
      //   selectedAccount: null,
      //   awardsAccounts: [],
      //   awardsAmountToApply: 0,
      //   awardsCashAmountToApply: 0,
      //   visited: false,
      //   error: false,
      // },
      [ParentState.cardPayment]: {
        paymentMethods: [],
        selectedPaymentMethodId: undefined,
        spreedlyToken: undefined,
        error: undefined,
        visited: false,
      },
      [ParentState.bookingConfirmation]: {},
      [ParentState.disruption]: {
        selectedOffers: [],
        offersResponse: [],
      },
      [ParentState.priceDrop]: {
        candidateId: undefined,
      },
      [ParentState.changeForAnyReasonDiscount]: {},
      [ParentState.rewardsPayment]: { ...getInitialRewardsPaymentContext() },
      [ParentState.wallet]: { ...getIntialWalletContext() },
      // walletPayment: {
      //   creditPaymentAmount: {
      //     value: 0,
      //     currencyCode: undefined,
      //     currencySymbol: undefined,
      //   },
      //   creditBalance: {
      //     value: 0,
      //     currencyCode: undefined,
      //     currencySymbol: undefined,
      //   },
      // },
    };
  };
