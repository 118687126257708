import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPerson, PersonId } from "redmond";
import dayjs from "dayjs";
import { getDateTimeWithFormat } from "halifax";
import {
  getTripFinalFlightDate,
  getUserPassengers,
  getUserSelectedLapInfantIds,
  getUserSelectedPassengerIds,
} from "../../reducer";
import {
  setUserSelectedLapInfantIds,
  setUserSelectedPassengerIds,
} from "../../actions/actions";
import { getViewedTripSummaryProperties } from "../../../shop/reducer";

export const getIsLapInfant = (
  traveler: IPerson,
  tripFinalFlightDate: string
) => {
  const ageAtBoardingTime = dayjs(tripFinalFlightDate).diff(
    getDateTimeWithFormat(traveler.dateOfBirth, "YYYY-MM-DD"),
    "year"
  );
  return ageAtBoardingTime < 2;
};

export const useFlightBookPassengerSelect = () => {
  const selectedTravelerIds = useSelector(getUserSelectedPassengerIds);
  const selectedLapInfantIds = useSelector(getUserSelectedLapInfantIds);
  const travelers = useSelector(getUserPassengers);
  const tripFinalFlightDate = useSelector(getTripFinalFlightDate);
  const viewedTripSummaryProperties = useSelector(
    getViewedTripSummaryProperties
  );

  const [currentInfantToSelectSeat, setCurrentInfantToSelectSeat] = useState<
    string | boolean
  >(false);

  const dispatch = useDispatch();

  const updateUserSelectedTravelerIds = (
    passengerIds: PersonId[],
    lapInfantIds: PersonId[]
  ) => {
    dispatch(
      setUserSelectedPassengerIds({
        userSelectedPassengerIds: passengerIds,
        multiTicketType: viewedTripSummaryProperties?.multi_ticket_type,
      })
    );
    dispatch(
      setUserSelectedLapInfantIds({
        userSelectedLapInfantIds: lapInfantIds,
      })
    );
  };

  const handleSelectedTravelerIds = (travelerIds: string[]) => {
    // travelerIds -> selectedTravelerIds + selectedLapInfants + unknowns

    // Filter any removed ids from currentTravelerIds & currentLapInfantIds
    let userSelectedPassengerIdsToUpdate = selectedTravelerIds.filter((id) =>
      travelerIds.includes(id)
    );
    let userSelectedLapInfantIdsToUpdate = selectedLapInfantIds.filter((id) =>
      travelerIds.includes(id)
    );

    // If any travelers are selected, get the new travelers selected
    // - and check if there is an infant in the selected ids
    if (travelerIds.length > 0) {
      const newTravelersIds = travelerIds.filter(
        (id) =>
          !selectedTravelerIds.includes(id) &&
          !selectedLapInfantIds.includes(id)
      );

      const filteredTravelers = travelers.filter((traveler) =>
        newTravelersIds.includes(traveler.id)
      );

      const firstInfantNotInState = filteredTravelers.find((traveler) =>
        getIsLapInfant(traveler, tripFinalFlightDate)
      );

      if (firstInfantNotInState) {
        setCurrentInfantToSelectSeat(firstInfantNotInState.id);
        // only update passengers that are not infants, since infants are added to state in the InfantSeatPickerModal
        userSelectedPassengerIdsToUpdate = travelerIds.filter(
          (id) =>
            id !== firstInfantNotInState.id &&
            !selectedLapInfantIds.includes(id)
        );
        userSelectedLapInfantIdsToUpdate = [...selectedLapInfantIds];
      } else {
        userSelectedPassengerIdsToUpdate = [
          ...userSelectedPassengerIdsToUpdate,
          ...newTravelersIds,
        ];
      }
    } else if (travelerIds.length === 0) {
      userSelectedPassengerIdsToUpdate = [];
      userSelectedLapInfantIdsToUpdate = [];
    }

    updateUserSelectedTravelerIds(
      userSelectedPassengerIdsToUpdate,
      userSelectedLapInfantIdsToUpdate
    );
  };

  return {
    selectedTravelerIds,
    selectedLapInfantIds,
    travelers,
    tripFinalFlightDate,
    viewedTripSummaryProperties,
    currentInfantToSelectSeat,
    setCurrentInfantToSelectSeat,
    handleSelectedTravelerIds,
  };
};
