import React, { useEffect, useState } from "react";
import {
  HotelEntryTypeEnum,
  IIdLodgings,
  ProductFeedViewedProperties,
  PRODUCT_FEED_TILE_TAPPED,
  PRODUCT_FEED_VIEWED_EVENT,
  Lodging,
  CallState,
  AvailabilityResponse,
  PlatformEnum,
  AvailabilityRequestEnum,
  LodgingSelectionEnum,
} from "redmond";
import {
  PATH_HOTELS_AVAILABILITY,
  PATH_HOTELS_SHOP,
} from "../../utils/urlPaths";
import { transformToStringifiedAvailabilityQuery } from "../../utils/queryStringHelpers";
import {
  ActionButton,
  HotelCrossSellBanner,
  getBannerText,
  getCrossSellEarnText,
  IconName,
  Icon,
  useDeviceTypes,
  HotelCrossSell,
} from "halifax";
import { Divider, Typography, Box } from "@material-ui/core";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { trackEvent } from "../../api/v1/trackEvent";
import { CrossSellProps } from "../Body/cross-sell-helpers";
import clsx from "clsx";
import dayjs from "dayjs";
import { fetchHotelAvailability } from "../../api/v1/recently-viewed-and-searched/fetchHotelAvailability";
import {
  HOTEL_CROSS_SELL_V3,
  getExperimentVariantCustomVariants,
  HOTEL_CROSS_SELL_V3_VARIANTS,
  CONTROL,
  useExperiments,
  AVAILABLE,
  CACHE_HOTEL_TOKEN,
  getExperimentVariant,
} from "../../context/experiments";
import queryStringParser from "query-string";

const checkImage = (url: string, onLoad: (success: boolean) => void) => {
  var request = new XMLHttpRequest();
  request.open("GET", url, true);
  request.responseType = "blob";
  request.send();
  request.onload = () => {
    if (request.status == 200 && request.response.type == "image/jpeg") {
      onLoad(true);
    } else {
      onLoad(false);
    }
  };
};
export const HomepageHotelCrossSell = ({
  searchLocationResult,
  fromDate,
  untilDate,
  adultCount,
  children,
  largestValueAccount,
  latency,
  bannerText,
  tysonsOffer,
}: CrossSellProps) => {
  const { matchesMobile, matchesTablet } = useDeviceTypes();

  const history = useHistory();
  const expState = useExperiments();
  const [cityImageName, setCityImageName] = useState("");
  const [lodgings, setLodgings] = useState<Lodging[]>([]);
  const [nextPageToken, setNextPageToken] = useState<string | undefined>(
    undefined
  );
  const [availabilityCallState, setAvailabilityCallState] = useState<CallState>(
    CallState.NotCalled
  );
  const [showCrossSellV3UI, setShowCrossSellV3UI] = useState<boolean>(false);

  const isHotelCrossSellV3Enabled =
    getExperimentVariantCustomVariants(
      expState.experiments,
      HOTEL_CROSS_SELL_V3,
      HOTEL_CROSS_SELL_V3_VARIANTS
    ) !== CONTROL;

  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  const onViewAllHotelsCTAClick = () => {
    trackEvent({
      eventName: PRODUCT_FEED_TILE_TAPPED,
      properties: {
        feed_type: "cross_sell",
        tile_type: "cross_sell_hotel",
        feed_placement: "homepage",
      },
    });
    history.push(
      `${PATH_HOTELS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
        (searchLocationResult?.id as IIdLodgings).lodgingSelection.searchTerm,
        fromDate,
        untilDate,
        adultCount,
        children,
        undefined,
        HotelEntryTypeEnum.HOMEPAGE
      )}`
    );
  };

  useEffect(() => {
    setShowCrossSellV3UI(isHotelCrossSellV3Enabled && tysonsOffer);
  }, [tysonsOffer]);

  useEffect(() => {
    if (searchLocationResult) {
      const city =
        searchLocationResult.label.split(",").length > 0
          ? `${searchLocationResult.label.split(",")[0]}`
          : searchLocationResult.label;
      const imageName = `${city.replace(/ /g, "")}_${
        matchesMobile && !matchesTablet ? "mobile" : "desktop"
      }`;
      const imageUrl = `${window.location.origin}/email-assets/city-images/${imageName}.jpeg`;
      checkImage(imageUrl, (success: boolean) => {
        if (success) {
          setCityImageName(imageUrl);
        } else {
          setCityImageName(
            `${window.location.origin}/email-assets/city-images/generic_${
              matchesMobile ? "mobile" : "desktop"
            }.jpeg`
          );
        }
      });
      const xSellProps: ProductFeedViewedProperties = {
        feed_placement: "homepage",
        feed_type: "cross_sell_hotels",
        latency,
        tile_type: "cross_sell_hotel",
      };
      trackEvent({
        eventName: PRODUCT_FEED_VIEWED_EVENT,
        properties: { ...xSellProps },
      });
    }
  }, [searchLocationResult, matchesMobile, matchesTablet]);

  useEffect(() => {
    if (searchLocationResult) {
      const hotelCrossSellSearchLocationId =
        searchLocationResult.id as IIdLodgings;

      if (
        hotelCrossSellSearchLocationId.lodgingSelection.LodgingSelection ===
          LodgingSelectionEnum.Place &&
        !!hotelCrossSellSearchLocationId.lodgingSelection.placeId &&
        isHotelCrossSellV3Enabled &&
        availabilityCallState === CallState.NotCalled &&
        fromDate &&
        untilDate
      ) {
        const fetchInitialHotelAvailability = async () => {
          const availabilityResponse: AvailabilityResponse =
            await fetchHotelAvailability({
              lodgingSelection: hotelCrossSellSearchLocationId.lodgingSelection,
              stayDates: {
                from: dayjs(fromDate).format("YYYY-MM-DD"),
                until: dayjs(untilDate).format("YYYY-MM-DD"),
              },
              guests: {
                adults: adultCount,
                children: [],
              },
              platform: PlatformEnum.Desktop,
              progressiveConfig: {},
              AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
              rooms: { numberOfRooms: 1 },
            });
          setAvailabilityCallState(CallState.InProcess);
          setLodgings(
            availabilityResponse.lodgings.filter(
              (lodging) => !!lodging.available
            )
          );
          if (availabilityResponse.nextPageToken) {
            setNextPageToken(availabilityResponse.nextPageToken);
          } else {
            setAvailabilityCallState(CallState.Success);
          }
        };
        fetchInitialHotelAvailability();
      }
    }
  }, [searchLocationResult, availabilityCallState]);

  useEffect(() => {
    if (lodgings.length < 8 && nextPageToken) {
      const fetchFollowUpHotelAvailability = async () => {
        const availabilityResponse: AvailabilityResponse =
          await fetchHotelAvailability({
            moreToken: nextPageToken,
            progressiveConfig: {},
            AvailabilityRequest: AvailabilityRequestEnum.FollowUpSearch,
          });
        setLodgings(
          lodgings.concat(
            availabilityResponse.lodgings.filter(
              (lodging) => !!lodging.available
            )
          )
        );
        if (availabilityResponse.nextPageToken) {
          setNextPageToken(availabilityResponse.nextPageToken);
        } else {
          setNextPageToken(undefined);
        }
      };
      fetchFollowUpHotelAvailability();
    }
  }, [lodgings, nextPageToken]);

  return searchLocationResult && cityImageName ? (
    showCrossSellV3UI ? (
      lodgings.length > 0 ? (
        <HotelCrossSell
          isHotelCrossSellV3={isHotelCrossSellV3Enabled}
          isMobile={matchesMobile}
          lodgingsToDisplay={lodgings}
          onHotelCardClick={(lodgingData: Lodging, index: number) => {
            const lodgingId = lodgingData.lodging.id;
            const hotelQueryParams = {
              fromDate: fromDate,
              untilDate: untilDate,
              adultsCount: adultCount,
              children: children.length,
              selectedLodgingIndex: index,
              selectedAccountIndex: largestValueAccount,
              lodgingSelection: lodgingId,
              recommended: false,
              fromHotelAvailability: false,
              shopToken: cacheHotelTokenEnabled
                ? lodgingData?.price?.opaqueShopRequest
                : undefined,
            };

            const path = PATH_HOTELS_SHOP;
            window.open(
              `${path}?${queryStringParser.stringify({
                lodgingId,
                ...hotelQueryParams,
              })}`,
              "_blank"
            );
          }}
          onViewAllHotelsCTAClick={onViewAllHotelsCTAClick}
          largestValueAccount={largestValueAccount}
          nightCount={dayjs(untilDate).diff(dayjs(fromDate), "days")}
          fromDate={fromDate}
          untilDate={untilDate}
          searchLocationResult={searchLocationResult}
          bannerText={bannerText}
          showEarnEnhancement={
            !!largestValueAccount && !!largestValueAccount.earn.hotelsMultiplier
          }
        />
      ) : null
    ) : matchesMobile ? (
      <>
        <div className="mobile-homepage-hotel-cross-sell-container">
          <Typography
            className="hotel-cross-sell-banner-text"
            dangerouslySetInnerHTML={{
              __html: getBannerText(
                searchLocationResult,
                fromDate!,
                untilDate!,
                largestValueAccount!,
                true,
                false,
                bannerText
              ),
            }}
          />
          <Divider className="homepage-hotel-cross-sell-divider" />
          <Typography className="homepage-hotel-cross-sell-earn-text">
            {getCrossSellEarnText(largestValueAccount)}
          </Typography>
          <ActionButton
            defaultStyle={
              isHotelCrossSellV3Enabled ? "h4r-secondary" : "h4r-primary"
            }
            className={clsx("view-all-hotels-banner-button", "b2b")}
            onClick={onViewAllHotelsCTAClick}
            message={`View all hotels in ${
              searchLocationResult.label.split(",").length > 0
                ? `${searchLocationResult.label.split(",")[0]}`
                : searchLocationResult.label
            }`}
          />
        </div>
        <Box className="mobile-homepage-hotel-cross-sell-image-wrapper">
          <img
            className=" mobile-homepage-hotel-cross-sell-image"
            src={cityImageName}
            alt="cross-sell background"
          />

          {!cityImageName.includes("generic") && (
            <span className="location-tag">
              <Icon className="map-icon" name={IconName.B2BMapPin} />
              {searchLocationResult.label.split(",").length > 1
                ? `${searchLocationResult.label.split(",")[0]}, ${
                    searchLocationResult.label.split(",")[1]
                  }`
                : searchLocationResult.label}
            </span>
          )}
        </Box>
      </>
    ) : (
      <div
        className={clsx("homepage-hotel-cross-sell-container", {
          "hotel-cross-sell-v3": showCrossSellV3UI,
        })}
      >
        <div
          className={clsx("homepage-hotel-cross-sell", {
            "hotel-cross-sell-v3": showCrossSellV3UI,
          })}
        >
          {!showCrossSellV3UI && (
            <Typography variant="h3" className="homepage-cross-sell-header">
              View hotels for your upcoming trip to{" "}
              <strong>
                {searchLocationResult.label.split(",").length > 1
                  ? `${searchLocationResult.label.split(",")[0]}, ${
                      searchLocationResult.label.split(",")[1]
                    }`
                  : searchLocationResult.label}
              </strong>
            </Typography>
          )}
          <HotelCrossSellBanner
            largestValueAccount={largestValueAccount!}
            fromDate={fromDate}
            untilDate={untilDate}
            searchLocationResult={searchLocationResult}
            onViewAllHotelsCTAClick={onViewAllHotelsCTAClick}
            shortEarnText
            isHotelCrossSellV3={showCrossSellV3UI}
            bannerText={bannerText}
          />

          {!showCrossSellV3UI && (
            <div
              className="cross-sell-image"
              style={{
                backgroundImage: `url(${cityImageName})`,
              }}
            >
              {!cityImageName.includes("generic") && (
                <span className="location-tag">
                  <Icon className="map-icon" name={IconName.B2BMapPin} />
                  {searchLocationResult.label.split(",").length > 1
                    ? `${searchLocationResult.label.split(",")[0]}, ${
                        searchLocationResult.label.split(",")[1]
                      }`
                    : searchLocationResult.label}
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    )
  ) : null;
};
