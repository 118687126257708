import React, { ReactElement, useContext, useEffect } from "react";
import {
  GenericBanner,
  TravelSaleTakeoverBanner,
  useDeviceTypes,
} from "halifax";
import { RouterProps } from "react-router-dom";
import { B2B_PORTAL_BANNER_CONTAINER_ID, CustomerAccountRole } from "redmond";
import {
  isCorpTenant,
  REWARDS_BANNER_TEXT,
  getCurrentCorpAccount,
} from "@capone/common";

import { ClientContext } from "../../../../App";

import { RewardsBannerConnectorProps } from "./container";
import {
  PATH_HOME,
  PATH_DISRUPTION_PROTECTION_OVERVIEW,
  PATH_DISRUPTION_PROTECTION_REFUND,
  PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT,
  PATH_DISRUPTION_PROTECTION_REBOOK_CONNECTION,
  PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT,
  PATH_TRAVEL_SALE,
  PATH_DISRUPTION_PROTECTION_CONTACT_SUPPORT,
  PATH_CHANGE_FOR_ANY_REASON,
} from "../../../../utils/paths";
import {
  ActiveExperiments,
  CONTROL,
  TRAVEL_SALE_VARIANTS,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";

export interface IRewardsBannerProps
  extends RewardsBannerConnectorProps,
    RouterProps {}

export const RewardsBanner = (props: IRewardsBannerProps) => {
  const {
    history,
    largestValueAccount,
    fetchRewardsAccounts,
    rewardsAccounts,
  } = props;

  useEffect(() => {
    fetchRewardsAccounts();
  }, []);

  const { sessionInfo } = useContext(ClientContext);

  const { matchesMobile } = useDeviceTypes();
  const firstName = sessionInfo?.userInfo?.firstName || "";

  const currentAccount = getCurrentCorpAccount(rewardsAccounts, sessionInfo);

  const expState = useExperiments();

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    ActiveExperiments.TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  if (travelSaleVariant !== CONTROL)
    return (
      <TravelSaleTakeoverBanner
        variant={travelSaleVariant}
        onClickCTA={() => {
          const path = `${PATH_TRAVEL_SALE}?entryType=global_earn_banner`;
          matchesMobile ? history.push(path) : window.open(path, "_blank");
        }}
      />
    );

  let contentText: string | ReactElement = "";

  const account = isCorpTenant(config.TENANT)
    ? currentAccount || largestValueAccount
    : largestValueAccount;

  const {
    earn,
    lastFour = "",
    productDisplayName: productId = "",
    rewardsBalance,
  } = account || {};
  const { currency, currencyDescription } = rewardsBalance || {};
  const {
    hotelsMultiplier: earnRate = "",
    flightsMultiplier: flightEarnRate = "",
  } = earn || {};

  //if no earn multiplier do not display a banner or if its less than 0
  if (!account || !account?.earn?.hotelsMultiplier || Number(earnRate) <= 0) {
    return null;
  }

  // TODO: handle texts properly once the backend is ready
  switch (history.location.pathname) {
    case PATH_CHANGE_FOR_ANY_REASON:
    case PATH_DISRUPTION_PROTECTION_OVERVIEW:
    case PATH_DISRUPTION_PROTECTION_CONTACT_SUPPORT:
    case PATH_DISRUPTION_PROTECTION_REFUND:
    case PATH_DISRUPTION_PROTECTION_REBOOK_FLIGHT:
    case PATH_DISRUPTION_PROTECTION_REBOOK_CONNECTION:
    case PATH_DISRUPTION_PROTECTION_MCP_REBOOK_CONNECTING_FLIGHT:
    case PATH_HOME.slice(0, -1):
    case PATH_HOME:
      if (!matchesMobile) {
        contentText = REWARDS_BANNER_TEXT({
          firstName,
          earnRate,
          flightEarnRate,
          productId,
          lastFour,
          currency: currencyDescription || currency,
          isPrimary:
            account.customerAccountRole === CustomerAccountRole.Primary,
          tenant: config.TENANT,
        });
      }
      // hide the banner on mobile search workflow
      else {
        contentText = "";
      }
      break;
  }

  if (!contentText) {
    return null;
  }

  return (
    <GenericBanner
      className="rewards-banner-root"
      content={contentText}
      containerId={B2B_PORTAL_BANNER_CONTAINER_ID}
      isMobile={matchesMobile}
    />
  );
};
