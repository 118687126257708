import { Box, Tooltip, Typography } from "@material-ui/core";
import dayjs from "dayjs";
import {
  removeTimezone,
  AirlineIcon,
  formatInterval,
  Icon,
  IconName,
  useDeviceTypes,
  ButtonWrap,
  MobilePopoverCard,
  CloseButtonIcon,
} from "halifax";
import React from "react";

import {
  getReviewCardHeaderWithType,
  getSliceIndex,
  getStopsString,
  OPERATING_AIRLINES_MODAL_TITLE,
  OPERATING_AIRLINES_TOOLTIP_TRIGGER_TEXT,
} from "./constants";
import { uniqBy } from "lodash-es";
import { FlightSummaryPanelConnectorProps } from "./container";
import "./styles.scss";

interface IFlightSummaryPanelProps extends FlightSummaryPanelConnectorProps {
  departure?: boolean;
}
export const FlightSummaryPanel = (props: IFlightSummaryPanelProps) => {
  const { departure = true, tripDetails, airports } = props;

  const { matchesMobile } = useDeviceTypes();

  const [
    mobileOperatingAirlinesModalOpen,
    setMobileOperatingAirlinesModalOpen,
  ] = React.useState<undefined | "outbound" | "return">(undefined);

  if (!tripDetails) return null;

  const sliceIndex = getSliceIndex(departure, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  // get all operating airlines & add tooltips because only logos will be shown
  const airlines: any[] = tripSlice.segmentDetails.map((sd) => ({
    ...sd.operatingAirline,
    tooltip: `1 or more segments operated by ${sd.operatingAirline.name}`,
  }));

  // put marketing airline as first without tooltip
  airlines.unshift(tripSlice.segmentDetails[0].marketingAirline);

  // remove duplicates
  const uniqSegmentAirlines = uniqBy(airlines, "code");

  const additionalOperatingAirlines = uniqSegmentAirlines.slice(1);

  const operatingAirlineString = additionalOperatingAirlines.length
    ? additionalOperatingAirlines.reduce(
        (allAirlinesString, currentAirline, index) => {
          if (index !== 0) {
            if (index + 1 === additionalOperatingAirlines.length) {
              return `${allAirlinesString}${
                additionalOperatingAirlines.length > 2 ? "," : ""
              } and ${currentAirline.name}`;
            }
            return `${allAirlinesString}, ${currentAirline.name}`;
          }

          return `1 or more segments operated by ${currentAirline.name}`;
        },
        ""
      )
    : undefined;

  const { type, description } = getReviewCardHeaderWithType(
    departure,
    airports[tripSlice.destinationCode]
      ? airports[tripSlice.destinationCode].cityName
      : tripSlice.destinationName,
    removeTimezone(tripSlice.departureTime)
  );

  return (
    <Box className="flight-summary-panel-root">
      <Box className="airline-details-with-title">
        <Typography className="card-header">
          <span className="bold">{type}</span>
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></span>
        </Typography>
        <Box className="airline-details">
          <Box className="airline-details-left-container">
            <Box className="flight-times-wrapper">
              <Typography variant="body1" className="flight-timespan">
                {`${dayjs(removeTimezone(tripSlice.departureTime)).format(
                  "h:mm A"
                )}`}
              </Typography>
              <Box className="flight-time-icon">
                <Icon name={IconName.B2BAirplaneIcon} />
                <Box className="flight-line" />
                <Box className="flight-line-circle" />
              </Box>
              <Typography variant="body1" className="flight-timespan">
                {`${dayjs(removeTimezone(tripSlice.arrivalTime)).format(
                  "h:mm A"
                )}`}
              </Typography>
            </Box>

            <Box className="card-airline-container">
              <AirlineIcon airlineCode={uniqSegmentAirlines[0].code} />
              <Typography variant="body2">
                {uniqSegmentAirlines[0].name}
                {additionalOperatingAirlines.length > 0 && tripSlice.stops > 0
                  ? ","
                  : ""}
              </Typography>

              {additionalOperatingAirlines.length > 0 &&
                tripSlice.stops > 0 &&
                operatingAirlineString &&
                (matchesMobile ? (
                  <>
                    <ButtonWrap
                      className="operating-airlines-tooltip-label"
                      onClick={() =>
                        setMobileOperatingAirlinesModalOpen(
                          departure ? "outbound" : "return"
                        )
                      }
                    >
                      <Typography className="additional-operating-airlines-text">
                        {OPERATING_AIRLINES_TOOLTIP_TRIGGER_TEXT(
                          additionalOperatingAirlines.length
                        )}
                      </Typography>
                      <Icon
                        className="tooltip-icon"
                        name={IconName.InfoCircle}
                      />
                    </ButtonWrap>
                    <MobilePopoverCard
                      className="pkg-mobile-operating-airlines-popover"
                      open={
                        mobileOperatingAirlinesModalOpen ===
                        (departure ? "outbound" : "return")
                      }
                      onClose={() =>
                        setMobileOperatingAirlinesModalOpen(undefined)
                      }
                      centered
                      topRightButton={
                        <CloseButtonIcon
                          onClick={() =>
                            setMobileOperatingAirlinesModalOpen(undefined)
                          }
                        />
                      }
                      headerElement={
                        <Typography
                          variant="h2"
                          className="mobile-operating-airlines-popover-header-text"
                        >
                          {OPERATING_AIRLINES_MODAL_TITLE}
                        </Typography>
                      }
                    >
                      <Box className="mobile-operating-airlines-popover-content">
                        <Typography className="operating-airlines-text">
                          {operatingAirlineString}
                        </Typography>
                      </Box>
                    </MobilePopoverCard>
                  </>
                ) : (
                  <Tooltip
                    title={operatingAirlineString}
                    classes={{
                      popper:
                        "pkg-recommended-flight-operating-airlines-tooltip",
                    }}
                  >
                    <span className="operating-airlines-tooltip-label">
                      <Typography className="additional-operating-airlines-text">
                        {OPERATING_AIRLINES_TOOLTIP_TRIGGER_TEXT(
                          additionalOperatingAirlines.length
                        )}
                      </Typography>
                      <Icon
                        className="tooltip-icon"
                        name={IconName.InfoCircle}
                      />
                    </span>
                  </Tooltip>
                ))}
            </Box>
          </Box>
          <Box className="airline-details-right-container">
            <Typography variant="body1" className="flight-timespan">
              {formatInterval(
                dayjs(tripSlice.arrivalTime).diff(
                  dayjs(tripSlice.departureTime),
                  "minute",
                  true
                )
              )}
            </Typography>
            <Typography variant="body2">
              {getStopsString(tripSlice.stops)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
