import axios from "axios";
import { CorpOptInResponse } from "redmond";
import { config } from "../../config";
import { adminApiPrefix } from "../../paths";

const END_POINT: string = `${adminApiPrefix}/onboarding/optIn`;

export const optIn = (accountReferenceId: string): Promise<CorpOptInResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.put(
        END_POINT,
        { accountReferenceId },
        {
          baseURL: config.baseURL,
        }
      );
      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
