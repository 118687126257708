import React, { useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Box, Typography } from "@material-ui/core";
import {
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import clsx from "clsx";

import {
  VIEWED_DISRUPTION_PAGE,
} from "redmond";

import { trackEvent } from "../../../../api/v1/analytics/trackEvent";
import { DisruptionProtectionConctactSupportConnectorProps } from "./container";
import * as constants from "./constants";
import "./styles.scss";

export interface IDisruptionProtectionLandingProps
  extends DisruptionProtectionConctactSupportConnectorProps,
    RouteComponentProps {}

export const DisruptionProtectionContactSupport = (
  props: IDisruptionProtectionLandingProps
) => {
  const {
    disruptionOverviewEligibilityDpExerciseFactsProperties,
  } = props;
  const { matchesMobile } = useDeviceTypes();

  const hasFiredViewedDisruptionPageEvent = useRef<boolean>(false);

  useEffect(() => {
    if (
      disruptionOverviewEligibilityDpExerciseFactsProperties &&
      !hasFiredViewedDisruptionPageEvent.current
    ) {
      trackEvent({
        eventName: VIEWED_DISRUPTION_PAGE,
        properties: disruptionOverviewEligibilityDpExerciseFactsProperties,
      });
      hasFiredViewedDisruptionPageEvent.current = true;
    }
  }, [disruptionOverviewEligibilityDpExerciseFactsProperties]);

  return (
    <Box
    className={clsx("disruption-protection-contact-support-root", {
      mobile: matchesMobile,
    })}
  >
    <Box className="disruption-protection-contact-support-container">
      <Box className="icon-section">
        <Icon
          name={IconName.CallBellBlueCircle}
          className="contact-support-icon"
        />
      </Box>
      <Box className="details-section">
        <Typography className = "contact-support-title">
        {constants.CONTACT_US}
        </Typography>
        <Typography
          className="contact-support-copy"
          variant="body2"
          dangerouslySetInnerHTML={{
            __html: constants.CONTACT_SUPPORT_DETAILS_COPY
          }}
        />
      </Box>
    </Box>
  </Box>
  );
};
