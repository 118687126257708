import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { withRouter } from "react-router";
import {
  getRewardsAccountWithLargestEarnForBanner,
} from "../../../rewards/reducer";

import { DesktopEarnBanner } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    largestEarnAccount: getRewardsAccountWithLargestEarnForBanner(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopEarnBannerConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedDesktopEarnBanner = withRouter(connector(DesktopEarnBanner));
