import React, { useState, useEffect } from "react";
import { Box } from "@material-ui/core";
import { BackButton, DesktopPopupModal, useDeviceTypes } from "halifax";
import { ExperienceAvailability, RewardsAccount } from "redmond";

import styles from "./styles.module.scss";
import {
  ExperiencesSimilarAvailability,
  ExperiencesSimilarCard,
  ExperiencesCompareCard,
} from "./components";
import {
  COMPARE_ITINERARY,
  SEE_MORE_SIMILAR_EXPERIENCES,
} from "../../textConstants";

export interface IShopSimilar {
  isSkeleton: boolean;
  selectedExperienceInfo?: ExperiencesSimilarAvailability;
  similarExperiences?: Array<ExperienceAvailability>;
  rewardsAccount?: RewardsAccount;
}

export interface IShopSimilarProps extends IShopSimilar {
  fromDate: Date | null;
  untilDate: Date | null;
}

const SIMILAR_EXPERIENCES_SKELETON: Array<ExperienceAvailability> = Array.from({
  length: 3,
});

export const ShopSimilar = (props: IShopSimilarProps) => {
  const {
    isSkeleton,
    selectedExperienceInfo,
    similarExperiences = SIMILAR_EXPERIENCES_SKELETON,
    rewardsAccount,
    fromDate,
    untilDate,
  } = props;

  const { matchesMobile } = useDeviceTypes();
  const [isOverFlow, setIsOverFlow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selectedComparedExpId, setSelectedComparedExpId] = useState("");

  const shopSimilarId = "experiences-shop-similar";

  const determineOverFlow = () => {
    const overFlowedElement = document.getElementById(shopSimilarId);
    if (overFlowedElement) {
      return overFlowedElement.clientWidth < overFlowedElement.scrollWidth;
    }
    return false;
  };

  const resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    if (isSkeleton || !similarExperiences) return;

    const overFlow = determineOverFlow();
    setIsOverFlow(overFlow);
  }, [windowWidth, isSkeleton, similarExperiences]);

  const leftScrollButton = () => {
    const overFlowedElement = document.getElementById(shopSimilarId);
    if (overFlowedElement) {
      overFlowedElement.scrollLeft -= 350;
    }
  };

  const rightScrollButton = () => {
    const overFlowedElement = document.getElementById(shopSimilarId);
    if (overFlowedElement) {
      overFlowedElement.scrollLeft += 350;
    }
  };

  return (
    <>
      <Box className={styles["experiences-shop-similar-container"]}>
        <ExperiencesSimilarCard
          experiencesAvailabilityInfo={selectedExperienceInfo}
          rewardsAccount={rewardsAccount}
          selectedExperienceIdValue={selectedExperienceInfo?.id?.value}
          fromDate={fromDate}
          untilDate={untilDate}
          isSkeleton={isSkeleton}
        />
        <Box className={styles["experiences-shop-other-similar-container"]}>
          {!matchesMobile && isOverFlow && (
            <BackButton
              aria-label={SEE_MORE_SIMILAR_EXPERIENCES}
              onClick={leftScrollButton}
              className={styles["left-scroll-button"]}
            />
          )}
          <Box
            className={styles["experiences-shop-other-similar-wrapper"]}
            id={shopSimilarId}
          >
            {similarExperiences.map(
              (
                experiencesAvailabilityInfo: ExperienceAvailability,
                expIdx: number
              ) => (
                <ExperiencesSimilarCard
                  key={experiencesAvailabilityInfo?.id?.value || expIdx}
                  experiencesAvailabilityInfo={experiencesAvailabilityInfo}
                  rewardsAccount={rewardsAccount}
                  selectedExperienceIdValue={selectedExperienceInfo?.id?.value}
                  fromDate={fromDate}
                  untilDate={untilDate}
                  isSkeleton={isSkeleton}
                  onCompareClick={(comparedExpId) => {
                    setSelectedComparedExpId(comparedExpId);
                    setOpenModal(true);
                  }}
                />
              )
            )}
          </Box>
          {!matchesMobile && isOverFlow && (
            <BackButton
              aria-label={SEE_MORE_SIMILAR_EXPERIENCES}
              onClick={rightScrollButton}
              className={styles["right-scroll-button"]}
            />
          )}
        </Box>
      </Box>
      {selectedExperienceInfo?.id?.value && (
        <DesktopPopupModal
          className={styles["experiences-shop-similar-compare-modal-popup"]}
          open={openModal}
          invisibleBackdrop={false}
          onClose={(event: React.MouseEvent) => {
            event.stopPropagation();
            setOpenModal(false);
          }}
        >
          <div className={styles["experiences-shop-similar-modal-header"]}>
            {COMPARE_ITINERARY}
          </div>
          <div className={styles["experiences-shop-similar-modal-content"]}>
            <ExperiencesCompareCard
              experiencesAvailabilityInfo={selectedExperienceInfo}
              selectedExperienceIdValue={selectedExperienceInfo.id.value}
              fromDate={fromDate}
              untilDate={untilDate}
              closeModal={() => setOpenModal(false)}
            />
            <ExperiencesCompareCard
              experiencesAvailabilityInfo={
                similarExperiences.find(
                  (exp) => exp.id.value === selectedComparedExpId
                ) as ExperiencesSimilarAvailability
              }
              selectedExperienceIdValue={selectedExperienceInfo.id.value}
              fromDate={fromDate}
              untilDate={untilDate}
              closeModal={() => setOpenModal(false)}
            />
          </div>
        </DesktopPopupModal>
      )}
    </>
  );
};
