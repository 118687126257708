import axios from "axios";
import { config } from "../../config";

import { hotelDetailsApiPrefix } from "../paths";
import { LodgingId } from "redmond/build/hotels-module";
import { HotelDetailsResponse } from "redmond";

export const fetchHotelDetails = (
    id: LodgingId
): Promise<HotelDetailsResponse> =>
  new Promise(async (resolve, reject) => {
    try {
      const res = await axios.get(hotelDetailsApiPrefix + id, {
        baseURL: config.baseURL,
      });

      resolve(res.data);
    } catch (e) {
      reject(e);
    }
  });
