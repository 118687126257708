// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kidUquuStjbEMxFv0iV-GA\\=\\={background-color:var(--white)}.kidUquuStjbEMxFv0iV-GA\\=\\= .hE30Tkwv1iQXe1tjR7rSwQ\\=\\={margin-top:50px}.kidUquuStjbEMxFv0iV-GA\\=\\=.jI-ntIK1281t1Q5Pu\\+ngjQ\\=\\= .hE30Tkwv1iQXe1tjR7rSwQ\\=\\={margin-top:0}", "",{"version":3,"sources":["webpack://./../../cap1-modules/trips-module/src/pages/ChangeForAnyReason/styles.module.scss"],"names":[],"mappings":"AAAA,4BACE,6BAAA,CACA,wDACE,eAAA,CAGA,oFACE,YAAA","sourcesContent":[".change-for-any-reason-root {\n  background-color: var(--white);\n  .change-for-any-reason-body {\n    margin-top: 50px;\n  }\n  &.mobile {\n    .change-for-any-reason-body {\n      margin-top: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"change-for-any-reason-root": "kidUquuStjbEMxFv0iV-GA==",
	"change-for-any-reason-body": "hE30Tkwv1iQXe1tjR7rSwQ==",
	"mobile": "jI-ntIK1281t1Q5Pu+ngjQ=="
};
export default ___CSS_LOADER_EXPORT___;
