import { connect, ConnectedProps } from "react-redux";
import {
  updateUserPassenger,
  fetchCorpUserPassengers,
  fetchUserPassengers,
  setUserSelectedLapInfantIds,
  setUserSelectedPassengerIds,
} from "../../../actions/actions";
import { getUserPassengerCallState } from "../../../reducer";
import { IStoreState } from "../../../../../reducers/types";
import { FlightTravelerSelectDropdownForm } from "./component";
import {
  selectedTripSelector,
  tripDetailsSelector,
} from "../../../../shop/reducer";

const mapStateToProps = (state: IStoreState) => {
  const selectedTrip = selectedTripSelector(state);
  const tripId = selectedTrip.tripId || "";
  return {
    userPassengerCallState: getUserPassengerCallState(state),
    tripDetails: tripDetailsSelector(state, tripId),
  };
};

const mapDispatchToProps = {
  updateUserPassenger,
  fetchUserPassengers,
  fetchCorpUserPassengers,
  setUserSelectedPassengerIds,
  setUserSelectedLapInfantIds,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightTravelerSelectDropdownFormConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightTravelerSelectDropdownForm = connector(
  FlightTravelerSelectDropdownForm
);
