export const FETCH_EXPERIENCES_SHOP = "experiencesShop/FETCH_EXPERIENCES_SHOP";
export type FETCH_EXPERIENCES_SHOP = typeof FETCH_EXPERIENCES_SHOP;

export const SET_EXPERIENCES_SHOP_DETAILS =
  "experiencesShop/SET_EXPERIENCES_SHOP_DETAILS";
export type SET_EXPERIENCES_SHOP_DETAILS = typeof SET_EXPERIENCES_SHOP_DETAILS;

export const SET_EXPERIENCES_SHOP_CALL_FAILED =
  "experiencesShop/SET_EXPERIENCES_SHOP_CALL_FAILED";
export type SET_EXPERIENCES_SHOP_CALL_FAILED =
  typeof SET_EXPERIENCES_SHOP_CALL_FAILED;

export const SET_SELECTED_EXPERIENCE =
  "experiencesShop/SET_SELECTED_EXPERIENCE";
export type SET_SELECTED_EXPERIENCE = typeof SET_SELECTED_EXPERIENCE;

export const FETCH_EXPERIENCES_SHOP_OPTIONS =
  "experiencesShop/FETCH_EXPERIENCES_SHOP_OPTIONS";
export type FETCH_EXPERIENCES_SHOP_OPTIONS =
  typeof FETCH_EXPERIENCES_SHOP_OPTIONS;

export const SET_EXPERIENCES_SHOP_OPTIONS =
  "experiencesShop/SET_EXPERIENCES_SHOP_OPTIONS";
export type SET_EXPERIENCES_SHOP_OPTIONS = typeof SET_EXPERIENCES_SHOP_OPTIONS;

export const SET_EXPERIENCES_SHOP_OPTIONS_CALL_FAILED =
  "experiencesShop/SET_EXPERIENCES_SHOP_OPTIONS_CALL_FAILED";
export type SET_EXPERIENCES_SHOP_OPTIONS_CALL_FAILED =
  typeof SET_EXPERIENCES_SHOP_OPTIONS_CALL_FAILED;

export const FETCH_EXPERIENCES_SHOP_REVIEWS =
  "experiencesShop/FETCH_EXPERIENCES_SHOP_REVIEWS";
export type FETCH_EXPERIENCES_SHOP_REVIEWS =
  typeof FETCH_EXPERIENCES_SHOP_REVIEWS;

export const SET_EXPERIENCES_SHOP_REVIEWS =
  "experiencesShop/SET_EXPERIENCES_SHOP_REVIEWS";
export type SET_EXPERIENCES_SHOP_REVIEWS = typeof SET_EXPERIENCES_SHOP_REVIEWS;

export const SET_EXPERIENCES_SHOP_REVIEWS_CALL_STATE_FAILED =
  "experiencesShop/SET_EXPERIENCES_SHOP_REVIEWS_CALL_STATE_FAILED";
export type SET_EXPERIENCES_SHOP_REVIEWS_CALL_STATE_FAILED =
  typeof SET_EXPERIENCES_SHOP_REVIEWS_CALL_STATE_FAILED;
