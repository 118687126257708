import { Box, Typography } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import "./styles.scss";
import { KeywordSearchConnectorProps } from "./container";
import { AvailabilityAutocomplete } from "../AvailabilityAutocomplete";
import { ParsedExperiencesAvailabilityAutocompleteResult } from "../AvailabilityAutocomplete/component";
import {
  ExperiencesAvailabilityAutocompleteResult,
  GroupedExperienceIds,
} from "redmond";

export interface IKeywordSearchProps extends KeywordSearchConnectorProps {
  hideSearchBar?: boolean;
  displaySearchOnChange?: boolean;
  title: string;
  setSelectedGroupedExperience: (option: GroupedExperienceIds) => void;
}

const getSelectedOption = (
  option: ParsedExperiencesAvailabilityAutocompleteResult | null,
  value: ExperiencesAvailabilityAutocompleteResult | null
) => {
  const selection = value ? value.id : null;
  const opt = option ? option.id : null;
  return !!selection && !!opt && selection === opt;
};

export const KeywordSearch = (props: IKeywordSearchProps) => {
  const {
    hideSearchBar,
    title,
    overrideInputValue,
    value,
    valueCategories,
    loading,
    setValue,
    fetchValueCategories,
    setSelectedGroupedExperience,
    fromDate,
    untilDate,
    experiencesByCategory,
  } = props;

  return (
    <Box className="experiences-availability-keyword-search-container">
      <Box className={clsx("experiences-availability-keyword-search-control")}>
        <Box className="search-components-section">
          <Typography className="search-title">{title}</Typography>
          {!hideSearchBar && (
            <AvailabilityAutocomplete
              label={"Search by name or keyword"}
              getOptionSelected={getSelectedOption}
              overrideInputValue={overrideInputValue}
              loading={loading}
              value={value}
              valueCategories={valueCategories}
              setValue={setValue}
              fetchValueCategories={fetchValueCategories}
              fromDate={fromDate}
              untilDate={untilDate}
              setSelectedGroupedExperience={setSelectedGroupedExperience}
              experiencesByCategory={experiencesByCategory}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
