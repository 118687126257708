import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  Button,
} from "@material-ui/core";
import { MODAL_ALERT } from "redmond";
import { CloseButton, Icon, IconName } from "halifax";
import { useExperimentsById } from "@capone/experiments";
import "./styles.scss";
import { trackEvent } from "../../../api/v1/trackEvent";

interface CorpFintechSubscriptionModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const corpFintechSmoketestMap = {
  available_variant1: "smoketest",
  available_variant2: "value_prop",
  control: "control",
};

export const CorpFintechSubscriptionModal = ({
  open,
  onClose,
  onConfirm,
}: CorpFintechSubscriptionModalProps) => {
  const [feedback, setFeedback] = useState("");

  const corpFintechVariant =
    useExperimentsById("corp-fintech-subscription-smoketest")?.variant ||
    "control";

  const corpSmoketestVariant = corpFintechSmoketestMap[corpFintechVariant];

  useEffect(() => {
    if (open) {
      trackEvent({
        eventName: MODAL_ALERT,
        properties: {
          type: `fintech_subscription_${corpSmoketestVariant}`,
        },
      });
    }
  }, [open]);

  const handleConfirm = () => {
    trackEvent({
      eventName: "fintech_subscription_smoketest_feedback",
      properties: {
        feedback,
      },
    });
    onClose();
    onConfirm();
  };

  return (
    <Dialog
      className="corp-fintech-subscription-modal"
      open={open}
      onClose={onClose}
      aria-labelledby="corp-fintech-subscription-title"
      aria-describedby="corp-fintech-subscription-description"
    >
      <CloseButton onClose={onClose} />
      <DialogTitle
        className="corp-fintech-subscription-title"
        id="corp-fintech-subscription-title"
      >
        <Box
          className="corp-fintech-subscription-title-container"
          display="flex"
          flexDirection="column"
          alignItems="flex-start"
        >
          <Chip
            className="corp-fintech-subscription-chip"
            icon={<Icon name={IconName.CorpMagnifyingGlass} />}
            label={
              <Typography component="span">
                This subscription offering was a test
              </Typography>
            }
          />
          <Typography component="h1">
            Thanks for expressing interest in a subscription for this product
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent className="corp-fintech-subscription-content">
        <Typography variant="subtitle2" component="p">
          As a thanks for participating in our test,
          <strong> we’ve added a $30 travel credit to your wallet</strong> to
          use on this flight or future bookings.
          <br />
          <br />
          We’re working on subscription offerings that can best support you and
          your business. We value your feedback and would love your thoughts on
          how we can structure offers like this to meet your needs.
        </Typography>
        <TextField
          variant="outlined"
          placeholder="Add any additional information you would like us to know about your subscription needs."
          aria-label="Add any additional information you would like us to know about your subscription needs."
          multiline
          minRows={4}
          maxRows={4}
          fullWidth
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        />
      </DialogContent>
      <DialogActions className="corp-fintech-subscription-actions">
        <Button
          className="corp-fintech-subscription-button"
          variant="contained"
          color="primary"
          size="medium"
          onClick={handleConfirm}
        >
          Got it!
        </Button>
      </DialogActions>
    </Dialog>
  );
};
