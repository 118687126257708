import { useState } from "react";
import { CorpBusinessAccount } from "redmond";
import { onboardingPage, PATH_CORP_ACKNOWLEDGEMENT, VXB } from "@capone/common";
import { useHistory } from "react-router-dom";
import { changeBusinessInUse } from "../../../api/v1/multi-account/changeBusinessInUse";

export const useSwitchBusinessAccount = () => {
  const history = useHistory();
  const [isAccountSwitchLoading, setIsAccountSwitchLoading] = useState(false);

  const handleChangeBusinessInUse = (selectedAccount: CorpBusinessAccount) => {
    setIsAccountSwitchLoading(true);

    if (selectedAccount?.optedIn) {
      changeBusinessInUse({
        businessId: selectedAccount.optedIn.businessId,
      })
        .then((response) => {
          setIsAccountSwitchLoading(false);
          window.location.href = response.request.responseURL;
        })
        .catch(() => {
          setIsAccountSwitchLoading(false);
        });
    } else if (selectedAccount?.rewardsAccount.productDisplayName === VXB) {
      history.push(PATH_CORP_ACKNOWLEDGEMENT);
    } else {
      window.location.replace(onboardingPage);
    }
  };

  return {
    handleChangeBusinessInUse,
    isAccountSwitchLoading,
  };
};
