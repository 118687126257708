import { TrackEventRequest } from "redmond";
import { config } from "../config";

import { analyticsApiPrefix } from "../paths";
import { mcLeanAxiosInstance } from "../../components/AxiosInterceptors";
import { getGlobalTrackingProperties } from "@capone/common";
import { store } from "../../app/store";

const END_POINT: string = `${analyticsApiPrefix}/event`;

export const trackEvent = (req: TrackEventRequest): Promise<boolean> =>
  new Promise(async (resolve, reject) => {
    req.properties = {
      ...req.properties,
      ...getGlobalTrackingProperties(
        config.TENANT,
        store.getState().rootReducer
      ),
      has_travel_credit: req.properties.has_credits,
    };
    try {
      const res = await mcLeanAxiosInstance.post(END_POINT, req, {
        baseURL: config.baseURL,
      });

      const responseBody = res.data;
      resolve(responseBody);
    } catch (e) {
      console.error(e);
      reject(e);
    }
  });
