import React, { useLayoutEffect, useMemo } from "react";
import { useTheme } from "@material-ui/core";
import clsx from "clsx";
import { useDeviceTypes } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { ExperimentState, HotelsModuleProps } from "redmond";
import { useUserContext, isCorpTenant } from "@capone/common";

import useMicrofrontend from "../../hooks/useMicrofrontend";
import config from "../../utils/config";
import { colors as caponeColors } from "../../utils/capone/colors";
import { colors as corpColors } from "../../utils/capone-corporate/colors";
import { PAWTUCKET_HOTELS_MODULE_ID } from "../../utils/moduleIds";

import "./styles.scss";

interface IHotelsModuleProps extends RouteComponentProps {
  language: string;
  experiments?: ExperimentState;
  crossSellLoaded: boolean;
  recentlyViewedLoaded: boolean;
  annualTravelCreditLoaded: boolean;
}

const HotelsModule = (props: IHotelsModuleProps) => {
  const {
    language,
    experiments,
    crossSellLoaded,
    recentlyViewedLoaded,
    annualTravelCreditLoaded,
    ...routerProps
  } = props;
  const { history } = routerProps;
  const id = PAWTUCKET_HOTELS_MODULE_ID;
  const theme = useTheme();
  const { matchesDesktop, matchesSmallDesktop, matchesLargeDesktop } =
    useDeviceTypes();
  const { isLoaded, ptHotelsModule } = useMicrofrontend(
    id,
    (config.ptHotelsModuleUrl += "")
  );
  const { sessionInfo, policies, logo } = useUserContext(config.TENANT);

  const isCrossSell: boolean = useMemo(
    () => crossSellLoaded,
    [crossSellLoaded]
  );

  const isRecentlyViewed: boolean = useMemo(
    () => recentlyViewedLoaded,
    [recentlyViewedLoaded]
  );

  const moduleProps: HotelsModuleProps = useMemo(
    () => ({
      colors: isCorpTenant(config.TENANT) ? corpColors : caponeColors,
      experiments,
      language,
      theme,
      baseHistory: history,
      clientAssets: {
        sessionInfo,
        logo: <img src={logo?.src} alt={logo?.alt} />,
        policies,
      },
      isAgentPortal: config.ptIsAgentPortal,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useLayoutEffect(() => {
    if (!ptHotelsModule) return;
    const { render, unMount } = ptHotelsModule;

    render(id, moduleProps);

    return () => {
      unMount(id);
    };
  }, [isLoaded, moduleProps]); // eslint-disable-line

  if (!isLoaded) return null;
  if (!ptHotelsModule) return <h2>ERROR! Cannot find Module</h2>;

  return (
    <div
      className={clsx("hotels-module-container", {
        desktop: matchesDesktop || matchesSmallDesktop,
        largeDesktop: matchesLargeDesktop,
        "cross-sell": isCrossSell,
        "recently-viewed": isRecentlyViewed,
        "annual-travel-credit": annualTravelCreditLoaded,
      })}
      id={id}
    />
  );
};

export default HotelsModule;
