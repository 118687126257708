import React from "react";
import { Box } from "@material-ui/core";
import { ShopComponentSkeleton } from "../../component";

export interface IShopCustomerReviewsProps {
  isSkeleton: boolean;
}

const Skeleton = () => (
  <Box className="experiences-shop-skeleton">
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="large" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="small" />
      <ShopComponentSkeleton name="medium" />
    </Box>
    <Box className="section-wrapper">
      <ShopComponentSkeleton name="medium" />
      <ShopComponentSkeleton name="large" />
      <ShopComponentSkeleton name="medium" />
    </Box>
  </Box>
);

export const ShopCustomerReviews = (props: IShopCustomerReviewsProps) => {
  const { isSkeleton } = props;

  return isSkeleton ? <Skeleton /> : null;
};
