import { createSelector } from "reselect";
import {
  getImageUrlsArray,
} from "halifax";
import {
  ITrackingProperties,
  ViewedPremierCollectionDetailsProperties,
  RoomInfo,
} from "redmond";
import { PremierCollectionShopDetailsCallState } from "../index";
import { IStoreState } from "../../../../reducers/types";
import { getSelectedAccount } from "../../../rewards/reducer";
import {
  getIsFromHotelAvailability,
  getViewedPremierCollectionListProperties,
} from "../../../availability/reducer";

export const getPremierCollectionShopDetailsSelectedLodgingData = (
  state: IStoreState
) => state.premierCollectionShopDetails.lodgingData;

export const getPremierCollectionShopDetailsImageUrlsArray  = (state: IStoreState) =>
    getImageUrlsArray(state.premierCollectionShopDetails.lodgingData?.media ?? []);

export const getPremierCollectionShopDetailsSelectedLodgingId  = (state: IStoreState) =>
    state.premierCollectionShopDetails.lodgingData?.id;

export const getPremierCollectionShopDetailsRoomsInfo = (state: IStoreState) =>
  state.premierCollectionShopDetails.lodgingData?.roomsInfo ?? [];

export const getPremierCollectionShopDetailsCallState = (state: IStoreState) =>
  state.premierCollectionShopDetails.premierCollectionShopDetailsCallState;

export const getPremierCollectionShopDetailsCallError = (state: IStoreState) =>
  state.premierCollectionShopDetails.premierCollectionShopDetailsCallError;

export const getShopCallFromShopDetails  = (state: IStoreState) =>
    state.premierCollectionShopDetails.shopCallFromShopDetails;

export const getShopCallFailureUrl  = (state: IStoreState) =>
    state.premierCollectionShopDetails.shopCallFailureUrl;

export const getPremierCollectionShopDetailsChosenRoomInfoIndex = (
    state: IStoreState
) => state.premierCollectionShopDetails.chosenRoomInfoIndex;

export const getPremierCollectionShopDetailsChosenRoomInfo = createSelector(
    [
      getPremierCollectionShopDetailsRoomsInfo,
      getPremierCollectionShopDetailsChosenRoomInfoIndex,
    ],
    (
        roomsInfo,
        chosenRoomInfoIndex
    ): RoomInfo | null => {
      if (chosenRoomInfoIndex !== null) {
        return roomsInfo[chosenRoomInfoIndex] ?? null;
      }

      return null;
    }
);

export const isMissingProductInfo = createSelector(
  getPremierCollectionShopDetailsCallState,
  getPremierCollectionShopDetailsRoomsInfo,
  (premierCollectionShopDetailsCallState, roomInfo) => {
    return (
      premierCollectionShopDetailsCallState ===
        PremierCollectionShopDetailsCallState.Success && roomInfo.length === 0
    );
  }
);

export const hasPremierCollectionShopDetailsFailed = createSelector(
  getPremierCollectionShopDetailsCallState,
  isMissingProductInfo,
  (premierCollectionShopDetailsCallState, missingProductInfo) => {
    return (
      premierCollectionShopDetailsCallState ===
        PremierCollectionShopDetailsCallState.Failed || missingProductInfo
    );
  }
);

export const getPCHotelShopTrackingPropertiesV2 = (state: IStoreState) =>
  state.premierCollectionShopDetails.hotelShopTrackingPropertiesV2;

const getHotelShopRoomInfoRoomsImagesCount = createSelector(
  getPremierCollectionShopDetailsRoomsInfo,
  (
    roomsInfo
  ): {
    rooms_with_images_count: number;
    rooms_without_images_count: number;
  } => {
    let rooms_with_images_cnt = 0;
    let rooms_without_images_cnt = 0;
    for (var roomInfo of roomsInfo) {
      rooms_with_images_cnt += roomInfo.media.length ? 1 : 0;
      rooms_without_images_cnt += roomInfo.media.length ? 0 : 1;
    }
    return {
      rooms_with_images_count: rooms_with_images_cnt,
      rooms_without_images_count: rooms_without_images_cnt,
    };
  }
);

export const getViewedPremierCollectionDetailsTrackingProps = createSelector(
    getPremierCollectionShopDetailsChosenRoomInfo,
    (): ITrackingProperties | null => {
      return null; // TODO https://hopper-jira.atlassian.net/browse/CLUX-450
    }
);
// TODO clean up in https://hopper-jira.atlassian.net/browse/CLUX-450
export const getViewedPremierCollectionDetailsProperties = createSelector(
    getViewedPremierCollectionListProperties,
    getSelectedAccount,
    getViewedPremierCollectionDetailsTrackingProps,
    getPremierCollectionShopDetailsRoomsInfo,
    getIsFromHotelAvailability,
    getPCHotelShopTrackingPropertiesV2,
    getHotelShopRoomInfoRoomsImagesCount,
    (
        viewedPremierCollectionListProperties,
        account,
        trackingProps,
        roomInfo,
        isFromHotelFunnel,
        pcShopTrackingProperties,
        roomInfoRoomsImagesCount
    ): ITrackingProperties<ViewedPremierCollectionDetailsProperties> => {
      return {
        properties: {
          ...viewedPremierCollectionListProperties.properties,
          account_type_selected: account?.productDisplayName || "",
          rooms_shown: roomInfo.length,
          account_use_type: account?.accountUseType,
          account_allow_rewards_redemption: account?.allowRewardsRedemption,
          ...trackingProps?.properties,
          funnel: isFromHotelFunnel ? "hotel" : "premium_stays",
          ...pcShopTrackingProperties?.properties,
          rooms_with_images_count:
          roomInfoRoomsImagesCount.rooms_with_images_count,
          rooms_without_images_count:
          roomInfoRoomsImagesCount.rooms_without_images_count,
        },
        encryptedProperties: [
          ...viewedPremierCollectionListProperties.encryptedProperties,
          pcShopTrackingProperties?.encryptedProperties ?? "",
        ],
      };
    }
);
