import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";

import {
  getPremierCollectionShopDetailsSelectedLodgingData,
} from "../../reducer/selectors";
import { DesktopPremierCollectionShopDetailsChooseDates } from "./component";
import {
  getAdultsCount,
  getChildrenCount,
  getFromDate,
  getUntilDate
} from "../../../search/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    childrenCount: getChildrenCount(state),
    lodgingData: getPremierCollectionShopDetailsSelectedLodgingData(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    largestEarnAccount: getRewardsAccountWithLargestEarnForBanner(state),
  };
};

export const mapDispatchToProps = {
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type DesktopPremierCollectionShopDetailsChooseDatesConnectorProps =
  ConnectedProps<typeof connector>;

export const ConnectedDesktopPremierCollectionShopDetailsChooseDates = withRouter(
  connector(DesktopPremierCollectionShopDetailsChooseDates)
);
