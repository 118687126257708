import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import { MobileShopDetails } from "./component";
import {
  getPremierCollectionShopDetailsCallState,
  getPremierCollectionShopDetailsSelectedLodgingData,
  hasPremierCollectionShopDetailsFailed,
  PremierCollectionShopDetailsCallState
} from "../../reducer";
import { getRewardsAccountWithLargestEarnForBanner } from "../../../rewards/reducer";
import { actions } from "../../actions";
import { setOpenDatesModal } from "../../../availability/actions/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    lodgingData: getPremierCollectionShopDetailsSelectedLodgingData(state),
    hotelShopDetailsCallInProgress:
        getPremierCollectionShopDetailsCallState(state) ===
        PremierCollectionShopDetailsCallState.InProcess,
    hasFailed: hasPremierCollectionShopDetailsFailed(state),
    largestEarnAccount: getRewardsAccountWithLargestEarnForBanner(state),
  };
};

export const mapDispatchToProps = {
  shopCallFromShopDetails: actions.shopCallFromShopDetails,
  setOpenDatesModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopDetailsConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShopDetails = withRouter(connector(MobileShopDetails));
