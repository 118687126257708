import React, { useEffect } from "react";
import { ButtonWrap, HamburgerButton, Header as GenericHeader } from "halifax";

import CapOneLogo from "../../../assets/b2b/capone-logo.svg";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import {
  PATH_FLIGHTS,
  PATH_FLIGHTS_BOOK_CONFIRMATION,
  PATH_HOTELS,
  PATH_HOTELS_BOOK_CONFIRMATION,
  PATH_CARS,
  PATH_FLIGHTS_SEARCH,
  PATH_HOTELS_SEARCH,
  PATH_CARS_SEARCH,
  PATH_PREMIER_COLLECTION_SEARCH,
  PATH_CARS_BOOK_CONFIRMATION,
  PATH_HOME,
  PATH_TRAVEL_WALLET,
  PATH_PREMIER_VACATION_RENTALS_SEARCH,
  PATH_FLIGHTS_BOOK_WITH_PF_CONFIRMATION,
  PATH_FLIGHTS_BOOK_WITH_VI_CONFIRMATION,
  PATH_HOTELS_AVAILABILITY,
  PATH_HOTELS_SHOP,
  PATH_CARS_AVAILABILITY,
  PATH_CARS_SHOP,
  PATH_FLIGHTS_SHOP,
  PATH_HOTELS_ANCILLARY,
  PATH_HOTELS_ANCILLARY_CUSTOMIZE,
  PATH_PACKAGE_HOTEL_SHOP,
  PATH_PACKAGE_FLIGHT_SHOP,
  PATH_STAYS,
  PATH_STAYS_SEARCH,
  PATH_STAYS_AVAILABILITY,
  PATH_PACKAGE_REVIEW,
  PATH_PACKAGES_BOOK_CONFIRMATION,
  PATH_PACKAGES_HOTEL_AVAILABILITY,
  PATH_EXPERIENCES_AVAILABILITY,
} from "../../../utils/urlPaths";
import { CAP_ONE_LOGO_ALT } from "../../../utils/constants";

import MobileHamburgerMenu from "../../MobileHamburgerMenu";
import "./styles.scss";
import clsx from "clsx";
import { Badge, Box } from "@material-ui/core";
import { fetchTravelWalletOffers } from "../../../api/v1/takeovers/fetchTravelWalletOffers";
import {
  CustomerAccountRole,
  RewardsAccount,
  WalletSummaryResponse,
} from "redmond";
import {
  AVAILABLE,
  CONTROL,
  CORP_VXB_ELIGIBILITY,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
  EXPERIENCES_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  PREMIER_COLLECTION_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
} from "../../../context/experiments";
import { useUserContext, VXB } from "@capone/common";
import config from "../../../utils/config";
import fetchUserPreferencesStatus, {
  UserPreferencesStatusPayload,
} from "../../../api/v1/user/fetchUserPreferencesStatus";

export const HIDE_ON_SCROLL_PATHS = [
  PATH_HOTELS_AVAILABILITY,
  PATH_STAYS_AVAILABILITY,
  PATH_HOTELS_SHOP,
  PATH_HOTELS_ANCILLARY,
  PATH_HOTELS_ANCILLARY_CUSTOMIZE,
  PATH_CARS_AVAILABILITY,
  PATH_CARS_SHOP,
  PATH_FLIGHTS_SHOP,
  PATH_PACKAGES_HOTEL_AVAILABILITY,
  PATH_PACKAGE_HOTEL_SHOP,
  PATH_PACKAGE_FLIGHT_SHOP,
  PATH_PACKAGE_REVIEW,
  PATH_EXPERIENCES_AVAILABILITY,
];

interface IMobileHeaderProps {
  language: string;
  className?: string;
  rewardsAccounts: RewardsAccount[];
}

const MobileHeader = (props: IMobileHeaderProps) => {
  const { className, rewardsAccounts } = props;
  const [showMenu, setShowMenu] = React.useState(false);
  const [openSupportModal, setOpenSupportModal] = React.useState(false);
  const [offerCount, setOfferCount] = React.useState(0);
  const [userPreferencesStatus, setUserPreferencesStatus] =
    React.useState<UserPreferencesStatusPayload>();
  const { isBusinessEligible } = useUserContext("capone");

  const expState = useExperiments();
  const isTravelWalletOfferExperiment =
    getExperimentVariant(
      expState.experiments,
      TRAVEL_WALLET_OFFER_EXPERIMENT
    ) === AVAILABLE;

  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const isPremierCollectionEnabled =
    getExperimentVariant(
      expState.experiments,
      PREMIER_COLLECTION_EXPERIMENT
    ) === AVAILABLE;

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  const isGlobalMobileNavExperiment =
    getExperimentVariant(expState.experiments, GLOBAL_MOBILE_NAV_EXPERIMENT) ===
    AVAILABLE;

  const isExperiencesExperiment =
    getExperimentVariant(expState.experiments, EXPERIENCES_EXPERIMENT) ===
    AVAILABLE;

  const isVXBEligibleExperiment =
    getExperimentVariant(expState.experiments, CORP_VXB_ELIGIBILITY) ===
    AVAILABLE;

  useEffect(() => {
    const fetchPreferencesStatus = async () => {
      const prefsStatus = await fetchUserPreferencesStatus();
      setUserPreferencesStatus(prefsStatus);
    };
    if (isCustomerProfileExperiment) {
      fetchPreferencesStatus();
    }
  }, [isCustomerProfileExperiment]);

  useEffect(() => {
    fetchTravelWalletOffers().then((response) => {
      const hasCredit = !!(response as WalletSummaryResponse).credit;
      const offerCount = (response as WalletSummaryResponse).offerCount || 0;
      const totalCount = hasCredit ? offerCount + 1 : offerCount;
      setOfferCount(totalCount);
    });
  }, []);

  const hasEligibleVXBAccount = rewardsAccounts?.some(
    (account) =>
      account.productDisplayName === VXB &&
      account.customerAccountRole === CustomerAccountRole.Primary
  );

  const showVXBCorpExperience =
    isVXBEligibleExperiment && hasEligibleVXBAccount;

  const showCorpPortalMenu = isBusinessEligible || showVXBCorpExperience;

  const hamburger =
    isTravelWalletOfferExperiment && !isGlobalMobileNavExperiment ? (
      <Badge badgeContent={offerCount} className="offer-count">
        <HamburgerButton
          onOpen={() => setShowMenu(true)}
          onClose={() => setShowMenu(false)}
          open={showMenu}
          menu={
            <MobileHamburgerMenu
              onClose={() => setShowMenu(false)}
              offerCount={offerCount}
              openSupportModal={openSupportModal}
              setOpenSupportModal={setOpenSupportModal}
              isTravelWalletOfferExperiment={isTravelWalletOfferExperiment}
              isPremierCollectionEnabled={isPremierCollectionEnabled}
              isCustomerProfileExperiment={isCustomerProfileExperiment}
              showCustomerProfileNewBadge={
                userPreferencesStatus?.hasUsedPreferences === false
              }
              tenant="capone"
              corporatePortalUrl={
                showCorpPortalMenu ? config.corporatePortalUrl : ""
              }
              isExperiencesExperiment={isExperiencesExperiment}
            />
          }
        />
      </Badge>
    ) : (
      <HamburgerButton
        onOpen={() => setShowMenu(true)}
        onClose={() => setShowMenu(false)}
        open={showMenu}
        menu={
          <MobileHamburgerMenu
            onClose={() => setShowMenu(false)}
            offerCount={offerCount}
            openSupportModal={openSupportModal}
            setOpenSupportModal={setOpenSupportModal}
            isTravelWalletOfferExperiment={isTravelWalletOfferExperiment}
            isPremierCollectionEnabled={isPremierCollectionEnabled}
            isCustomerProfileExperiment={isCustomerProfileExperiment}
            showCustomerProfileNewBadge={
              userPreferencesStatus?.hasUsedPreferences === false
            }
            tenant="capone"
            corporatePortalUrl={
              showCorpPortalMenu ? config.corporatePortalUrl : ""
            }
            isExperiencesExperiment={isExperiencesExperiment}
          />
        }
      />
    );

  const renderGenericHeader = (
    isConfirmation: boolean,
    browserRouterProps: RouteComponentProps,
    hideOnScroll: boolean
  ) => (
    <GenericHeader
      isMobile={true}
      shadow={className === "homepage" ? true : false}
      className={clsx("b2b", "has-logo", className, {
        confirmation: isConfirmation,
        "redesign-v2":
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2,
      })}
      left={
        <ButtonWrap onClick={() => browserRouterProps.history.push(PATH_HOME)}>
          <Box className="logo-container">
            <img src={CapOneLogo} alt={CAP_ONE_LOGO_ALT} />
          </Box>
        </ButtonWrap>
      }
      right={hamburger}
      hideOnScroll={hideOnScroll}
    />
  );

  return (
    <Switch>
      <Route
        path={[
          PATH_FLIGHTS,
          PATH_HOTELS,
          PATH_STAYS,
          PATH_CARS,
          PATH_TRAVEL_WALLET,
        ]}
        render={(browserRouterProps: RouteComponentProps) =>
          renderGenericHeader(false, browserRouterProps, false)
        }
        exact
      />
      <Route
        path={[
          PATH_FLIGHTS_BOOK_CONFIRMATION,
          PATH_FLIGHTS_BOOK_WITH_PF_CONFIRMATION,
          PATH_FLIGHTS_BOOK_WITH_VI_CONFIRMATION,
          PATH_HOTELS_BOOK_CONFIRMATION,
          PATH_CARS_BOOK_CONFIRMATION,
          PATH_PACKAGES_BOOK_CONFIRMATION,
        ]}
        render={(browserRouterProps: RouteComponentProps) =>
          renderGenericHeader(true, browserRouterProps, false)
        }
        exact
      />
      <Route
        path={PATH_FLIGHTS_SEARCH}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps, false)
            : null
        }
      />
      <Route
        path={[PATH_HOTELS_SEARCH, PATH_STAYS_SEARCH]}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps, false)
            : null
        }
      />
      <Route
        path={PATH_CARS_SEARCH}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps, false)
            : null
        }
      />
      <Route
        path={[
          PATH_PREMIER_COLLECTION_SEARCH,
          PATH_PREMIER_VACATION_RENTALS_SEARCH,
        ]}
        render={(browserRouterProps: RouteComponentProps) =>
          mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2
            ? renderGenericHeader(false, browserRouterProps, false)
            : null
        }
      />

      <Route
        path="*"
        render={(browserRouterProps: RouteComponentProps) =>
          renderGenericHeader(
            false,
            browserRouterProps,
            isGlobalMobileNavExperiment &&
              HIDE_ON_SCROLL_PATHS.includes(
                browserRouterProps.location.pathname
              )
          )
        }
      />
    </Switch>
  );
};

export default MobileHeader;
