import React from "react";
import { HotelShopChooseRoom } from "halifax";
import { RouteComponentProps } from "react-router-dom";
import { DesktopHotelShopChooseRoomConnectorProps } from "./container";

import { config } from "../../../../api/config";
import "./styles.scss";
import { getRoomPolicyComplianceType } from "@capone/common/src/utils/hotelShopPolicyCompliance";
import { CalendarPickerButton } from "../../../search/components/PackagesSearchControl/components";
import {
  CorpRoomInfoProducts,
  SelectedTravelOfferScreen,
  TripCategory,
} from "redmond";
import { TravelWalletDetailsBanner } from "../../../travel-wallet/components";

export interface DesktopHotelShopChooseRoomProps
  extends DesktopHotelShopChooseRoomConnectorProps,
    RouteComponentProps {}

export const DesktopHotelShopChooseRoom = (
  props: DesktopHotelShopChooseRoomProps
) => {
  const {
    departureDate,
    setDepartureDate,
    returnDate,
    setReturnDate,
    packageRatesById,
    travelersCount,
    selectedLodging,
    showOfferBasedOnSelectedLodging,
    loading,
  } = props;

  return (
    <HotelShopChooseRoom
      {...props}
      datepicker={
        <CalendarPickerButton
          classes={["room-calendar-button"]}
          tripCategory={TripCategory.ROUND_TRIP}
          departureDate={departureDate}
          returnDate={returnDate}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
        />
      }
      onClickContinue={() =>
        document
          .getElementsByClassName("reserve-cta")[0]
          ?.scrollIntoView({ behavior: "smooth", block: "end" })
      }
      buttonClassName="b2b"
      policyComplianceType={getRoomPolicyComplianceType(
        props.roomInfoProducts as CorpRoomInfoProducts[]
      )}
      tenant={config.TENANT}
      packagesByRateId={packageRatesById}
      isPackageHotel
      travelersCount={travelersCount}
      showOffer={showOfferBasedOnSelectedLodging}
      hotelOffer={
        <TravelWalletDetailsBanner
          offer={selectedLodging?.bestOfferThisLodging!}
          showButton={false}
          className="pkg-hotel-shop-offer"
          screen={SelectedTravelOfferScreen.PACKAGES_HOTEL_SHOP}
          {...props}
          bannerTextType="shop"
        />
      }
      loading={loading}
    />
  );
};
