import React from "react";

export const AMENITIES_TITLE = "Popular Amenities";
export const getAmenitiesSubtitle = (isLifestyleCollection?: boolean) => {
    return `Enjoy your ${
        isLifestyleCollection ? "" : "luxury"
    } stay with some of these popular amenities`;
};
export const ADDITIONAL_BENEFITS = (
    <>
        Plus, enjoy other premium benefits like{" "}
        <strong>room upgrades, early check-in and late checkout</strong> when
        available.
    </>
);
